import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import config from '../../config';
import { history } from '../../routes';
import { getOwner, getObjectWithJsonDataToFormValues, capitalize } from '../../libraries/utils';
import Icon from '../../libraries/icons';

import Button from '../../components/commons/Button';
import logo from '../../assets/img/logo.png';
import benefitsActions from '../../context/benefits/actions';
import IconCoins from '../../components/customs/IconCoins';
// import businessesActions from '../../context/businesses/actions';
import Loader from '../../components/commons/Loader';
// import categoriesAction from '../../context/categories/actions';
import BussinessHomeCard from '../../components/customs/BussinessHomeCard';
import PrizeHomeCard from '../../components/customs/PrizeHomeCard';
import companiesAction from '../../context/companies/actions';
import levelsAction from '../../context/levels/actions';

import Instructive from '../../components/commons/Instructive';

import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import UserProfileImage from '../../components/commons/UserProfileImage';
import BottomNav from '../../components/commons/BottomNav';

class Prizes extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      prizes: [],
      areNewPrizes: false,
    }
  }
  
  componentDidMount() {
    this.setState({companiesIds: this.props.auth.user.ancestors.companies.map(c => c.id)}, this.getData)
  }


  getData = async () => {
    this.setState({loading: true, userLevel: this.props.auth.user.rewards.level})
    await this.getPrizes()
    this.getLevels()
    this.setState({loading: false})
  }

  getLevels = async () => {
    await this.props.onGetLevels();
    const { levels } = this.props;
    if (levels.error) {
      notify(this.t(levels.error.message));
    } else {
      console.log("levels", levels)
      this.setState({ levels: levels.item })
    }  
  }

  getPrizes = async () => {
    const params = {enabled: 1, type: ["prize"], owner: this.props.auth.user.unique_id} // Revisar
    await this.props.onGetAllBenefits(params);
    const { benefits } = this.props;
    if (benefits.error) {
      notify(this.t(benefits.error.message));
    } else {
      let prizes = benefits.items.filter(b => this.state.companiesIds.includes(b.owner))
      let packs = benefits.items.map(b => b.pack).filter((value, index, self) => self.indexOf(value) === index)
      let prizes_by_pack = packs.map(p => {return {[p]: [...benefits.items.filter(b => b.pack === p)]}})
      console.log("prizes_by_pack", prizes_by_pack)
      this.setState({ prizes, prizes_by_pack })
      this.thereAreNewPrizes()
    }

  }

  

  thereAreNewPrizes = () => {
    const { prizes } = this.state
    const lastSeenPrize = localStorage.getItem("lastSeenPrize")

    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    const areNewPrize = prizes.some(p => (new Date(p.created_at) > new Date(oneWeekAgo)) && (new Date(p.created_at) > new Date(lastSeenPrize)))
    if(areNewPrize) {
      localStorage.setItem("lastSeenPrize", new Date())
      this.setState({areNewPrizes: true})
      return
    }
    this.setState({areNewPrizes: false})
  }

  gotoBack = () => {
    //FIXME: go back
  }

  getPointsLeftForNextLevel = () => {
    if(this.state.userLevel === config.MAX_LEVEL) return null
    return this.state.levels[(this.state.userLevel+1).toString()]["min"] - this.props.auth.user.rewards.total.points
  }

  render() {
    const { prizes, userLevel, loading, areNewPrizes } = this.state

    return (

            <LayoutWithSidebar
        main={{ className: "pb-[48px]"}}
        header={{ 
          logo: true,
          //content: <NavButtonBar options={buttonBarUpper} active={topNav} onClick={this.onNavBarClick} />
        }}
 
        container={{ className: "px-0"}}
      >
        <ToastContainer/>
        {/* {!user && <div className='h-48 grid place-content-center'><Loader spinnerClassName='h-16 w-16 text-primary' /></div>} */}
        <div className='px-3 bg-white border-t border-gray-100 flex'>
          <div className={"px-4 py-3 text-center border-b-4 border-transparent font-medium flex-1 rounded-none " + (false ? " border-b-secondary font-semibold text-base-content " : " text-neutral ") + ""}
            onClick={() => {history.push(config.ROUTES.USER.RANKING)}}
          >
            <h5 className='whitespace-nowrap'>{capitalize(this.t("ranking"))}</h5>
          </div>
          <div className={"px-4 py-3 text-center border-b-4 border-transparent font-medium flex-1 rounded-none " + (true ? " border-b-secondary font-semibold text-base-content " : " text-neutral ") + ""}
            onClick={() => {}}>
            <h5 className='whitespace-nowrap flex justify-center gap-1 '>
              {capitalize(this.t("premios"))} 
              {areNewPrizes && <div  className="h-3 w-3 bg-error rounded-full" />}
            </h5>
          </div>
        </div>
        <section className="">
      <div className="w-full max-w-lg mx-auto">
      <Instructive slides={[
        {
          title:"Premios",
          body:"Acá vas a poder encontrar los premios que ofrece tu empresa por tus logros de impacto positivo alcanzados.",
          img: "win"
        }]} instructive={"show_prizes"}> 
        <section className="bg-success bg-opacity-20 p-4">
          {userLevel !== "0" ? 
            <h2>Tu nivel es: <span className="ml-2 text-lg badge bg-base-content border-none">{userLevel}</span></h2>
            : <h2>Aún no tienes nivel</h2>
          }
          <small>{`¡Te faltan ${this.state.levels ? this.getPointsLeftForNextLevel() : '.'} puntos para llegar al próximo nivel!`}</small>
        </section>
        <section className="container-fluid p-3">
          <h4 className="">Premios de tu empresa</h4>
          <h6 className="mb-2">* Sujeto a términos y condiciones de cada premio</h6>
          <div className="mb-4">
            {loading && (<Loader/>) }
            {!loading && prizes && prizes.length === 0 && (
              <div className="text-center p-10">
                {/* <Icon className="h-24 w-24 mx-auto mb-3" name="stethoscope" /> */}
                <h4 className="mb-1">{this.t("No hay premios disponibles")}</h4>
              </div>
            )}                
            <div className="flex flex-col gap-3">
              {prizes && prizes.map(prize => 
                <PrizeHomeCard benefit={prize} userLevel={this.state.userLevel} key={prize.id}/>
              )}
            </div>
          </div>
        </section>
        </Instructive>
      </div>  
      </section>
        <BottomNav/>
      </LayoutWithSidebar>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    benefits: state.benefits.list,
    // businesses: state.businesses.list,
    auth: state.users.auth,
    // categories: state.categories.list,
    company: state.companies.current,
    companies: state.companies.list,
    levels: state.levels.current
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllBenefits: (params) => dispatch(benefitsActions.getAll(params)),
    // onGetAllBusinesses: (params) => dispatch(businessesActions.getAll(params)),
    // onGetAllCategories: (params) => dispatch(categoriesAction.getAll(params)),
    onGetCompany: (id) => dispatch(companiesAction.get(id)),
    onGetCompanies: (params) => dispatch(companiesAction.getAll(params)),
    onGetLevels: (params) => dispatch(levelsAction.get(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Prizes));
