import React from 'react';
import Icon from '../../libraries/icons';
import config from '../../config';

export default function IconPoints({ points, color, ...rest })  {
  const leyend = rest.leyend ? rest.leyend : '';
  
  const icon = (value) => {
    const cfg = config.VALUE_RANGE_ICONS.POINTS;
    if (value <= cfg.LOW.value) {
      return cfg.LOW.icon;
    } else if (value > cfg.LOW.value && value <= cfg.MEDIUM.value) {
      return cfg.MEDIUM.icon;
    } else {
      return cfg.HIGH.icon;
    }
  }

  return (
    <div className={
      " items-center py-1 px-2  "
      + (rest.className ? rest.className : "bg-opacity-10 rounded bg-success flex gap-1")
    }>
      <Icon color={color} className={"inline " + (rest.iconClassName ? rest.iconClassName : "w-4")} name={icon(points)} />
        {points} 
        <span className='text-base-content d-block'> {leyend}</span>
    </div>
  );
}


