import React from 'react';
import { withTranslation } from 'react-i18next';
import config from '../../config';
import { history } from '../../routes';

import Button from '../../components/commons/Button';
import img from '../../assets/img/instructive.png';

import img_win from '../../assets/img/onboarding/img-win.png'
import img_loans from '../../assets/img/onboarding/img-loans.png'
import img_learn from '../../assets/img/onboarding/img-learn.png'
import img_howtolend from '../../assets/img/onboarding/img-howtolend.png'
import img_howtoborrow from '../../assets/img/onboarding/img-howtoborrow.png'
import img_feed from '../../assets/img/onboarding/img-feed.png'
import img_contribute from '../../assets/img/onboarding/img-contribute.png'
import img_challenge from '../../assets/img/onboarding/img-challenge.png'
import img_cantfind from '../../assets/img/onboarding/img-cant-find.png'
import warning_action from '../../assets/img/onboarding/warning_action.svg'

//const images = Object.freeze({ slide0, slide1, slide2, slide3 });
class Instructive extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      already_seen: false,
      closed: false,
      slides: [],
      slide: 0,
      imgs: {
        "loans": img_loans, 
        "learn": img_learn, 
        "howtolend": img_howtolend, 
        "howtoborrow": img_howtoborrow, 
        "feed": img_feed, 
        "contribute": img_contribute, 
        "challenge": img_challenge, 
        "cantfind": img_cantfind, 
        "win": img_win, 
        "warning_action":warning_action
      }
    }
  }
  
  componentDidMount() {
    console.log('Instructive PAGE', );
    
    if (localStorage.getItem(this.props.instructive)) {
      let already_seen = !JSON.parse(localStorage.getItem(this.props.instructive))
      this.setState({already_seen, closed: already_seen, slides: this.props.slides})
    } else {
      this.setState( { slides: this.props.slides} )
    }
    // console.log("INSTRUCTIVE==================",!JSON.parse(localStorage.getItem(this.props.instructive)))
  }
 

  onPressOk = () => {
    //config.SLIDER_SCREEN
    let slide = this.state.slide;
    if (slide < this.state.slides.length -1) {
      slide++;
      this.setState({ slide });
    } else {
      localStorage.setItem(this.props.instructive, JSON.stringify(false));
      this.setState({closed: true})
    }
  };  

  render() {
    const { slide, slides, imgs } = this.state;
    console.log("slides[slide]", slides[slide]?.img)
    if(this.state.closed) return <>{this.props.children}</>;
    return (
      <main className="h-full z-[60] absolute top-0 left-0 w-full text-center pt-32 bg-primary bg-opacity-70">
        <div className="container p-10 rounded-t-2xl bg-white max-w-md mx-auto h-full w-full flex flex-col items-center justify-around md:justify-start">
          {
            <>
              <div className="h-80 md:h-96 flex items-center">
                <img src={imgs[slides[slide]?.img]} alt="" />
              </div>
              <div className="p-10">
                <h1 className="text-primary text-2xl mb-5">{this.t(slides[slide]?.title)}</h1>
                <p className="text-gray-600 mb-10">{this.t(slides[slide]?.body)}</p>
              </div>
              <Button
                className="btn-block btn-primary pointer-events-auto"
                title={this.t(`${this.props.labelButton??"OK"}`)}
                onClick={this.onPressOk}
              />
            </>
          }
        </div>
      </main>
    ) 
  }
}

export default withTranslation()(Instructive);
