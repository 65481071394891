import React from 'react';
import { Link } from 'react-router-dom';
import ActivityElement from './ActivityElement';
import PublicActivityCard from './PublicActivityElement';
import { useSelector } from 'react-redux';

export default function ActivityCard({ statuses,types, sub_types, activity, user, route, ...rest }) {
  const authUser = useSelector( state => state.users.auth.user);
  return (
   /*  <Link
      key={rest.key}
      to="/"
    > */
      <div className={"overflow-hidden border-b border-gray-100 " + (rest.className ? rest.className : "")}>
        {activity.json_data?.owner?.id === authUser.id ?
          <ActivityElement
          /*  time = {time} */
            statuses={statuses}
            activity={activity}
            user={user}
            types = {types}
            subtypes = {sub_types}
            {...rest}
          />
          :
          <PublicActivityCard
          /*  time = {time} */
            statuses={statuses}
            activity={activity}
            user={user}
            types = {types}
            subtypes = {sub_types}
            {...rest}
          />
        }
      </div>
    /* </Link>   */  
  )
}