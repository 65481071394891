import React from 'react';
import Icon from '../../libraries/icons';
import { selectIconSize } from '../../libraries/utils';
import Loader from '../commons/Loader';

const TextWithIconButtonInput = ({ input, meta, label, submitting, ...rest }) => {
  return (
    <div className={'form-control w-full' + (rest.className ? rest.className : '')}>
      {label && (
        <label
          htmlFor={input.name}
          className={
            'label ' + (rest.labelClassName ? rest.labelClassName : '')
          }
        >
          <span className="label-text">{label}</span>
        </label>
      )}
      <div className={
         "relative flex items-center "
         + (meta?.error && meta?.touched ? 'input-error invalid-shadow ' : '')
         + (rest.inputClassName ? rest.inputClassName : ' input')}>
        <input
          {...input}
          type="text"
          className={
            'w-full border-none outline-none'
          }
          placeholder={rest.placeholder}
          disabled={rest.disabled}
          readOnly={rest.readOnly}
        />
        <button
          type="submit"
          className={'relative right-0  ' + (rest.className ? rest.className : `px-2  border-l flex items-center ${rest.invert ? 'flex-row-reverse': ''}`)}
          onClick={() => rest.onClick ? rest.onClick(input) : null}
          disabled={rest.readOnly}
        >
      
          {rest.icon && (
            submitting ?
            <Loader clean containerClassName={'w-4 px-1'} spinnerClassName="text-primary h-4 w-4" />
            :
            <div>
              <Icon
                className={
                  selectIconSize(rest.className) + (rest.title ? (rest.invert ? ' ml-2' : ' mr-2') : '')
                }
                name={rest.icon}
              />
              {rest.title}
            </div>
          )}
          
        </button>
      </div>
    </div>
  );
};

export default TextWithIconButtonInput;
