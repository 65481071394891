import React from 'react';
import { Link } from 'react-router-dom';
import { getObjectWithJsonDataToFormValues, formatFullname, renderDate  } from '../../libraries/utils';
import Avatar from '../commons/Avatar';
import StarRating from "react-svg-star-rating";

export default function RatingProfileCard({data, type, ...rest}) {
	const { borrower, owner, user_rating, service_rating, rateUser, rateOwner, product, offer, product_rating, rateProduct } = getObjectWithJsonDataToFormValues(
    	data,
    	['borrower', 'owner', 'user_rating', 'service_rating', 'rateUser', 'rateOwner', 'product', 'offer', 'rateProduct', 'product_rating']
  	);

	let profile_image = null, first_name = null, last_name = null, opinion = null, label = null, id = null;
  let rating = 0
	let product_ = product ? product?.name : offer?.name

	if(type === "given"){
    profile_image = owner.profile_image
    id = owner.id
    first_name = owner.first_name
    last_name = owner.last_name
		label = owner.username
		rating = user_rating
		opinion = rateOwner
	}
	if(type === "received"){
    profile_image = borrower.profile_image
    id = borrower.id
    first_name = borrower.first_name
    last_name = borrower.last_name
		label = borrower.username
		rating = service_rating
		opinion = rateUser
	}
	if(type === "product"){
    profile_image = borrower.profile_image
    id = borrower.id
    first_name = borrower.first_name
    last_name = borrower.last_name
		label = borrower.username
		rating = product_rating
		opinion = rateProduct
	}
	if(type === "favor"){
    profile_image = borrower.profile_image
    id = borrower.id
    first_name = borrower.first_name
    last_name = borrower.last_name
		label = borrower.username
		rating = service_rating
		opinion = rateProduct
	}

	return (
		// <div className={"overflow-hidden border-b border-gray-100 "}>
    //   <div className="flex p-4 flex-row">
    //     <div onClick="">
    //       <Avatar className="rounded-full w-10 h-10" containerClassName="mr-3"
    //         image={profile_image}
    //         label={label}
    //       />       
    //       <div className="w-full flex flex-col">
    //         <h4 className="">{username}</h4>
    //       </div>
    //     </div>
    //     <div>
    //       {type !== "product" && (
    //         <h5 className="">{product_}</h5>
    //       )}
    //       <StarRating containerClassName="flex mt-2 mb-3 justify-center" unit="float" initialRating={rating} size={16} isReadOnly={true}/>
    //       <p>{opinion}</p>
    //     </div>
    //   </div>
    // </div>
    <div className="flex flex-col p-2 border-b card white z-20">
      <div className="flex items-center">
        <Avatar className="rounded-full w-10 h-10" containerClassName="mr-3"
          image={profile_image}
          label={label}
          id={id}
        />
        <div className="flex justify-between w-full">
          <div className="flex flex-col">
            <h5>{formatFullname({ first_name, last_name })}</h5>
            {type !== "product" && (
              <small className='flex items-center text-primary'>{product_}</small>
            )}
          </div>
          <small className='text-gray-400'>{renderDate({ISOdate:data.created_at, showDuration: true,isLocal:true})}</small>
        </div>
      </div>
      <div className='border-t border-gray-200 p-2 mt-2'>
        <StarRating containerClassName="flex" unit="float" initialRating={rating} size={16} isReadOnly={true}/>
        <p className="w-full mt-2">"{opinion}"</p>
      </div>
    </div>



  )
}