import React, { } from 'react';
import { history } from '../../routes';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import { getObjectWithJsonDataToFormValues } from '../../libraries/utils';

import IconPoints from './IconPoints';
import IconCoins from './IconCoins';
import Image from '../commons/Image'
import ButtonIcon from '../commons/ButtonIcon';

import clsx from 'clsx';

export default function InventoryCardMyOffers({ data, ...rest }) {
  const { t, i18n } = useTranslation();

  const { name, status, img, coins, points, type, id } = getObjectWithJsonDataToFormValues(
    data,
    ['id', 'name', 'status', 'img', 'coins', 'points', 'type']
  );

  let onClickEdit = () => {
    let route = "";
    let fromPath = config.ROUTES.USER.INVENTORY
    route = { pathname: config.ROUTES.PRODUCTS.EDIT.replace(':id', id), state: { fromPath } }
    if (route) history.push(route)
  }

  let onClickView = () => {
    let route = "";
    let fromPath = config.ROUTES.USER.INVENTORY
    switch (type) {
      case 'products':
        route = { pathname: config.ROUTES.PRODUCTS.DETAIL.replace(':id', id), state: { fromPath } }
        break;
      case 'services':
        route = { pathname: config.ROUTES.SERVICES.DETAIL.replace(':id', id), state: { fromPath } }
        break;
      case 'favors':
        route = { pathname: config.ROUTES.FAVORS.DETAIL.replace(':id', id), state: { fromPath } }
        break;
      default:
        break;
    }
    if (route) history.push(route)
  }

  return (
    <div className={"card card-compact white overflow-hidden " + (rest.className ? rest.className : "")}>
      <div className='flex justify-between'>
        <div className='py-2 px-3 flex flex-1 flex-col gap-2'>
          <div className="flex justify-between items-center">
            <h5 onClick={onClickView} className="">{t(name)}</h5>
            <ButtonIcon
              buttonClassName="btn-ghost btn-xs"
              className="w-4 h-4"
              onClick={onClickEdit}
              icon="edit_pencil"
            />
          </div>
          <div className="flex gap-2" >
            <IconCoins coins={coins} />
            <IconPoints points={points} />
          </div>
          {/* <div className="badge badge-outline badge-primary mt-4 ">{t(type)}</div>  */}

          <div className="flex justify-between items-center gap-2">
            {/* Esta condicion es para que no muestre el cartel de prestado en los favores */}
            {!(type === "favors" && status === config.STATUS.PRODUCTS.BORROWED) && <h6 className={
              clsx({
                "badge badge-outline": true,
                "badge-gray-500": (status === config.STATUS.PRODUCTS.DISABLED),
                "badge-primary": (status === config.STATUS.PRODUCTS.BORROWED),
                "badge-secondary": (status === config.STATUS.PRODUCTS.AVAILABLE),
              })
            }>{t(status)}</h6>}
            {/* Esta condicion es para que no muestre el cartel de disponible cuando el favor esta prestado ya que para que los favores no quieren que diga prestado*/}
            {(type === "favors" && status === config.STATUS.PRODUCTS.BORROWED) && <h6 className={
              clsx({
                "badge badge-outline": true,
                "badge-secondary": (status === config.STATUS.PRODUCTS.BORROWED),
              })
            }>{t(config.STATUS.PRODUCTS.AVAILABLE)}</h6>}

            {status === config.STATUS.PRODUCTS.BORROWED && type !== "favors" && (
              <small className="text-gray-500">{t("Disponible pronto")}</small>
            )}
            {/* {status === config.STATUS.PRODUCTS.DISABLED && (
                <h6 className="badge badge-outline text-error">{t("DESHABILITADO")}</h6>
              )} */}
          </div>
        </div>

        {(type === "products") && <div className="w-1/3">
          <img className="object-cover w-full h-full max-h-44" src={img} />
        </div>}
      </div>
    </div>
  )
}

