const types = {
  CLEAR_CURRENT: 'COMPANIES_CLEAR_CURRENT',
  GET_REQUEST: 'COMPANIES_GET_REQUEST',
  GET_SUCCESS: 'COMPANIES_GET_SUCCESS',
  GET_FAILURE: 'COMPANIES_GET_FAILURE',

  SAVE_REQUEST: 'COMPANIES_SAVER_REQUEST',
  SAVE_SUCCESS: 'COMPANIES_SAVE_SUCCESS',
  SAVE_FAILURE: 'COMPANIES_SAVE_FAILURE',

  GETALL_REQUEST: 'COMPANIES_GETALL_REQUEST',
  GETALL_SUCCESS: 'COMPANIES_GETALL_SUCCESS',
  GETALL_FAILURE: 'COMPANIES_GETALL_FAILURE',

  DELETE_REQUEST: 'COMPANIES_DELETE_REQUEST',
  DELETE_SUCCESS: 'COMPANIES_DELETE_SUCCESS',
  DELETE_FAILURE: 'COMPANIES_DELETE_FAILURE',    

  UPDATE_REQUEST: 'COMPANIES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'COMPANIES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'COMPANIES_UPDATE_FAILURE'    

};

export default types;