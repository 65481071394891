import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import actions from "../../context/actions/actions";
import { id } from "date-fns/locale";
import { notify } from "../../libraries/notifications";
import ActionCard from "../../components/customs/ActionCard";
import Button from "../../components/commons/Button";
import { history } from "../../routes";
import Loader from "../../components/commons/Loader";
import config from "../../config/";
import { saveLocalStorage } from "./useSaveGetLocalStoragel";
import { RepoructorVideo } from "../onboarding/RepoructorVideo";
import ButtonIcon from "../../components/commons/ButtonIcon";

class FlowStep1 extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.idFlow = this.props.match.params.id;
    this.currentUrl = this.props.location?.pathname;
    this.state = {
      action: null,
      flow: null,
      isOpenModal:false,
      so:'Android'
    };
  }
  
  openModal  =()=>  this.setState({isOpenModal:true})
  closeModal =()=> this.setState({isOpenModal:false})

  async getFlow() {
    const flow = await config.ACTION_BY_DEFAULT.find((f) => f.id == this.idFlow);
    if (flow) {
      // alert(JSON.stringify(flow?.action.id))
      await this.getAction({ id: flow?.action.id })
      this.setState({ flow: flow });
    }
  }

  async getAction(id) {
    await this.props.onGetAction(id)
    const error = this.props.action.error
    if (error) {
      notify(error)
    } else {
      this.setState({ action: this.props.action.item })
    }
    // this.setState({ action: this.state.flow?.action });
  }

  componentDidMount() {
    this.getFlow();
    const userAgent = window.navigator.userAgent;
    let op = 'Android';
 if (/Android/.test(userAgent)) {
      op = 'Android';
    } else if (/iPhone|iPad|iPod/.test(userAgent)) {
      op = 'iOS';
    }
    this.setState({so:op}) 
  }

  render() {
    const arrPasos={
      iOS:[
      'Accedé a la web de Sharyco desde el navegador de tu celular <a href="https://app.sharyco.com" aria-label="Sharyco website">https://app.sharyco.com</a>',
      "Iniciá sesión completando tus datos",
      "Presioná el icono central de la barra inferior “para compartir”, desplazá hacia arriba el menú para ver todas las opciones y seleccioná “Agregar al inicio”'",
      "Pulsá el botón “Agregar”",
      "Así aparecerá el icono de la web en tu pantalla de celular"
    ],
    Android:[
      'Accedé a la web de Sharyco desde el navegador de tu celular <a href="https://app.sharyco.com" aria-label="Sharyco website">https://app.sharyco.com</a>',
      "Iniciá sesión completando tus datos",
      "Pulsa el botón de ajustes (los 3 puntitos arriba a la derecha) y luego seleccioná “Agregar a la pantalla”",
      "Pulsá el botón “Agregar”",
      "Y luego el de “Agregar automáticamente”",
      "¡Y listo! Así aparecerá el icono de la web en tu pantalla de celular"
    ]
  }
    return (
      <main className="h-full text-center">
        <Modal isOpen={this.state.isOpenModal} onClose={this.closeModal}>
        {this.state.action&&<RepoructorVideo video={this.state.action.json_data.video}   />}
        <div>
            <h3 className="font-bold text-xl mt-3">¿Cómo se hace?</h3>
            <ul className="flex flex-col gap-1 text-left px-4 py-3">
               {
                arrPasos[this.state.so].map((p,i)=>(
                  <li key={p} className="flex gap-3">
                    <div className="text-sm rounded-full bg-base-200 h-[20px] w-[20px] flex justify-center items-center font-bold p-[11px]">
                      {i+1}

                    </div>
                    <p className="text-base text-gray-500" dangerouslySetInnerHTML={{ __html: p }}></p>
                  </li>
                ))
               }
            </ul>
            <div className="items-center gap-3 flex px-4 py-3">
              <ButtonIcon
                className=""
                icon="arrow_left"
                title={this.t(`${!this.state?.action?.json_data?.sub_type||this.state?.action?.json_data?.sub_type == 'regular' ? 'Hacer acción' : 'Mirar video'}`).toUpperCase()}
                onClick={() => {
                  this.closeModal();
                }
                }
              />
              <Button
                className="flex-1 btn-primary"
                title={this.t(`${!this.state?.action?.json_data?.sub_type||this.state?.action?.json_data?.sub_type == 'regular' ? 'Hacer acción' : 'Mirar video'}`).toUpperCase()}
                onClick={() => {
                  this.closeModal();
                  history.push({ pathname: `/flow/${this.idFlow}/step2`, state: { id: this.state?.action.id, fromPath: this.props.location.pathname } })
                }
                }
              />
          </div>
        </div>
        </Modal>
        {this.props.action.loading && <Loader />}
        {!this.props.action.error && !this.props.action.loading && !this.props.action.item && <p>Ocurrio un error</p>}
        {this.state.action && (
          <CardOnboarding>
              <div className="mt-20">
                <h1 className="text-primary text-2xl mb-5">
                  {this.t(this.state.flow?.title)}
                </h1>
                <p className="text-gray-600 mt-10 text-lg">
                  {this.t(this.state.flow?.subtile)}
                </p>
              </div>
            <div className="w-full">
              <div className="text-left">
                <ActionCard data={this.state.action} route={""} />
              </div>
              {this.state.action &&
                (this.state.action.json_data.sub_type == 'regular' ||
                  !this.state.action.json_data?.sub_type) &&
                this.state.action.json_data?.video &&
                <button className="text-center mt-6 mx-auto font-bold underline text-xl" onClick={() => this.openModal()}>Ver cómo se hace</button>}
            </div>
             <Button
              className="btn-block btn-primary"
              title={this.t(`${!this.state?.action?.json_data?.sub_type||this.state?.action?.json_data?.sub_type == 'regular' ? 'Hacer acción' : 'Mirar video'}`).toUpperCase()}
              onClick={() => {
                 history.push({ pathname: `/flow/${this.idFlow}/step2`, state: { id: this.state?.action.id, fromPath: this.props.location.pathname } })
              }
              }
            />
          </CardOnboarding>
        )}
    
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    action: state.actions.current,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetAction: (params) => dispatch(actions.get(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FlowStep1));

export const CardOnboarding = ({ children }) => {
  return (
    <div className="container max-w-md h-full w-full px-2 pb-4 mx-auto">
      <div className=" w-full h-full flex flex-col items-center justify-between md:justify-center gap-1">
        {children}
      </div>
    </div>
  );
}; 

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto border shadow-lg rounded-md bg-white max-w-[450px]">
          <div className=" ">
            {children}
        </div>
      </div>
    </div>
  );
}; 
