import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import config from '../../config';

import { ReactComponent as Trofeo } from '../../assets/icons/customs/trofeo.svg';
import { ReactComponent as Flecha } from '../../assets/icons/customs/flecha_challenge.svg';
import { ReactComponent as Heart } from '../../assets/icons/heart.svg';
import CompletedBar from './CompletedBar';
import { strToDateWithoutTZ } from '../../libraries/utils';


import MultiLineTextDisplay from '../commons/MultiLineTextDisplay';

function ChallegesCard({ route, data, ...rest }) { 
  const today = new Date()
  const endDate = strToDateWithoutTZ(data.end_date);
  
  let completed = today > endDate
  
  const participando = data.joined



  return (
    <Link
      className={clsx( "card white overflow-hidden ", rest.className)}
      to={route}
    >
      <div className="relative h-40 overflow-hidden flex justify-center items-end">
        
        {
          data.image ? <img className='object-contain' src={data.image} alt="Challenge" width="100%" />
                      : <div className="w-full h-full bg-gray-300"></div>
        }
        <div  className={clsx(`absolute top-0 w-full h-full  `,
          completed ? 'opacity-60 bg-secondary' 
                    : 'opacity-60 bg-gradient-to-b from-success' 
        )}/>
        <Trofeo fill="white" className={clsx(
          completed ? 'absolute w-h-20 h-20 top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2'
                    : 'absolute w-6 h-6 top-3 left-3'
        )}/>

        {participando && <span className=" absolute right-3 top-3 badge border-none text-primary bg-base-200 bg-opacity-70 badge-sm transition-all duration-150">
          <p className='text-xs'>{!completed ? "Participando" : "Finalizó"}</p>
        </span>}

        {/* <span className=" absolute right-2 bottom-4 badge badge-primary badge-sm ">
          <p className='text-xs'>Ganan {data.winner === "first_n" ? data.winner_number : "todos"} </p>
        </span> */}
        <div className='absolute w-full'>
          <CompletedBar start={data.start_date} end={data.end_date} finished={completed} />
        </div>
      </div>
      <div className="p-3">
        <div className='flex justify-between items-center'>
          <h5 className="font-bold">{data.name}</h5>
          {data?.visibility && data.visibility === 'referents' && <p className='font-normal text-xs bg-yellow-100 py-1 px-2 rounded-md'>Sólo referentes</p>}
        </div>        
        <div className="relative flex justify-between mt-1">
          <MultiLineTextDisplay className='flex-1 font-normal line-clamp-2' text={data.details}/>
          
            <Flecha className='w-10 ml-3' />
        </div>
      </div>
    </Link>    
  )
}

export default memo(ChallegesCard)
