import React from 'react';

//bottomBar
import { ReactComponent as home } from '../assets/icons/outline/home.svg';
import { ReactComponent as close } from '../assets/icons/outline/x.svg';
import { ReactComponent as search } from '../assets/icons/outline/search.svg';
import { ReactComponent as plus_circle } from '../assets/icons/outline/plus-circle.svg';
import { ReactComponent as bell } from '../assets/icons/outline/bell.svg';
import { ReactComponent as chat_alt } from '../assets/icons/outline/chat-alt.svg';
//sidebar
import { ReactComponent as color_swatch } from '../assets/icons/outline/color-swatch.svg';
import { ReactComponent as loans } from '../assets/icons/customs/loans.svg';
import { ReactComponent as returns } from '../assets/icons/customs/returns.svg';
import { ReactComponent as heart } from '../assets/icons/outline/heart.svg';
import { ReactComponent as document_search } from '../assets/icons/outline/document-search.svg';
import { ReactComponent as lightning_bolt } from '../assets/icons/outline/lightning-bolt.svg';
import { ReactComponent as refresh } from '../assets/icons/outline/refresh.svg';
//homeTabs
import { ReactComponent as collection } from '../assets/icons/outline/collection.svg';
import { ReactComponent as tag } from '../assets/icons/outline/tag.svg';
import { ReactComponent as globe } from '../assets/icons/outline/globe.svg';
import { ReactComponent as flag } from '../assets/icons/outline/flag.svg';
import { ReactComponent as fire } from '../assets/icons/outline/fire.svg';
import { ReactComponent as gift } from '../assets/icons/outline/gift.svg';
//customs
import { ReactComponent as coin1 } from '../assets/icons/customs/1coin.svg';
import { ReactComponent as coin2_4 } from '../assets/icons/customs/2-4coin.svg';
import { ReactComponent as coin5 } from '../assets/icons/customs/5+coin.svg';
import { ReactComponent as impact1 } from '../assets/icons/customs/1impact.svg';
import { ReactComponent as impact3 } from '../assets/icons/customs/3impact.svg';
import { ReactComponent as impact5 } from '../assets/icons/customs/5impact.svg';
import { ReactComponent as sharypoints } from '../assets/icons/customs/sharypoints.svg';
import { ReactComponent as sharypoints_main } from '../assets/icons/customs/sharypoints_main.svg';
import { ReactComponent as sharycredits } from '../assets/icons/customs/sharycredits.svg';

import { ReactComponent as ranking } from '../assets/icons/customs/ranking.svg';
import { ReactComponent as hand_world } from '../assets/icons/customs/hand_world.svg';
import { ReactComponent as sad } from '../assets/icons/customs/sad.svg';

import { ReactComponent as x_circle } from '../assets/icons/outline/x-circle.svg';
import { ReactComponent as photograph } from '../assets/icons/outline/photograph.svg';
import { ReactComponent as support } from '../assets/icons/outline/support.svg';
import { ReactComponent as globe_alt } from '../assets/icons/outline/globe-alt.svg';
import { ReactComponent as qrcode } from '../assets/icons/outline/qrcode.svg';
import { ReactComponent as view_grid } from '../assets/icons/outline/view-grid.svg';
import { ReactComponent as view_list } from '../assets/icons/outline/view-list.svg';
import { ReactComponent as receipt_tax } from '../assets/icons/outline/receipt-tax.svg';
import { ReactComponent as question } from '../assets/icons/outline/question-mark-circle.svg';
import { ReactComponent as muro } from '../assets/icons/outline/chart-square-bar.svg';

import { ReactComponent as view_tile } from '../assets/icons/view-tile.svg';
import { ReactComponent as thumbs_up } from '../assets/icons/thumbs-up.svg';
import { ReactComponent as dashboard } from '../assets/icons/dashboard.svg';
import { ReactComponent as repost } from '../assets/icons/repost.svg';
import { ReactComponent as user } from '../assets/icons/user.svg';
import { ReactComponent as user_outline } from '../assets/icons/outline/user.svg';
import { ReactComponent as user_group } from '../assets/icons/user-group.svg';
import { ReactComponent as calculator } from '../assets/icons/calculator.svg';
import { ReactComponent as document } from '../assets/icons/document.svg';
import { ReactComponent as cog } from '../assets/icons/cog.svg';
import { ReactComponent as shield } from '../assets/icons/shield.svg';
import { ReactComponent as cheveron_right } from '../assets/icons/cheveron-right.svg';
import { ReactComponent as cheveron_left } from '../assets/icons/cheveron-left.svg';
import { ReactComponent as cheveron_down } from '../assets/icons/cheveron-down.svg';
import { ReactComponent as checkmark } from '../assets/icons/checkmark.svg';
import { ReactComponent as save_disk } from '../assets/icons/save-disk.svg';
import { ReactComponent as download } from '../assets/icons/download.svg';
import { ReactComponent as edit_pencil } from '../assets/icons/outline/pencil.svg';
import { ReactComponent as trash } from '../assets/icons/trash.svg';
import { ReactComponent as information_solid } from '../assets/icons/information-solid.svg';
import { ReactComponent as information_outline } from '../assets/icons/information-outline.svg';
import { ReactComponent as document_add } from '../assets/icons/document-add.svg';
import { ReactComponent as close_solid } from '../assets/icons/close-solid.svg';
import { ReactComponent as close_outline } from '../assets/icons/close-outline.svg';
import { ReactComponent as add_solid } from '../assets/icons/add-solid.svg';
import { ReactComponent as add_square_outline } from '../assets/icons/add-square-outline.svg';
import { ReactComponent as plus } from '../assets/icons/plus.svg';
import { ReactComponent as remove_square_outline } from '../assets/icons/remove-square-outline.svg';
import { ReactComponent as view_show } from '../assets/icons/view-show.svg';
import { ReactComponent as pin } from '../assets/icons/pin.svg';
import { ReactComponent as clean_inside } from '../assets/icons/car-clean-inside.svg';
import { ReactComponent as clean_outside } from '../assets/icons/clean-outside.svg';
import { ReactComponent as gout } from '../assets/icons/gout.svg';
import { ReactComponent as time } from '../assets/icons/time-circle.svg';
import { ReactComponent as clean_complete } from '../assets/icons/clean-complete.svg';
import { ReactComponent as my_washes_circle } from '../assets/icons/my-washes-circle.svg';
import { ReactComponent as my_washes } from '../assets/icons/my-washes.svg';
import { ReactComponent as help } from '../assets/icons/help.svg';
import { ReactComponent as favor } from '../assets/icons/customs/help.svg';
import { ReactComponent as phone } from '../assets/icons/phone.svg';
import { ReactComponent as arrow_left } from '../assets/icons/outline/arrow-left.svg';
import { ReactComponent as arrow_right } from '../assets/icons/arrow-right.svg';
import { ReactComponent as dots_vertical } from '../assets/icons/dots-vertical.svg';
import { ReactComponent as user_info } from '../assets/icons/user-info.svg';
import { ReactComponent as language } from '../assets/icons/language.svg';
import { ReactComponent as terms } from '../assets/icons/terms.svg';
import { ReactComponent as logout } from '../assets/icons/logout.svg';
import { ReactComponent as location } from '../assets/icons/location.svg';
import { ReactComponent as calendar } from '../assets/icons/calendar.svg';
import { ReactComponent as cancel } from '../assets/icons/cancel.svg';
import { ReactComponent as star } from '../assets/icons/solid/star.svg';
import { ReactComponent as more_horizontal } from '../assets/icons/navigation-more.svg';
import { ReactComponent as menu } from '../assets/icons/menu.svg';


import { ReactComponent as check } from '../assets/icons/outline/check.svg';
import { ReactComponent as badge_check } from '../assets/icons/outline/badge-check.svg';
import { ReactComponent as whatsapp } from '../assets/icons/customs/whatsapp.svg';

import { ReactComponent as paper_clip } from '../assets/icons/outline/paper-clip.svg';
import { ReactComponent as paper_airplane } from '../assets/icons/outline/paper-airplane.svg';
import { ReactComponent as emoji_face } from '../assets/icons/outline/emoji-face.svg';
import { ReactComponent as clock } from '../assets/icons/outline/clock.svg';
import { ReactComponent as notification } from '../assets/icons/notification.svg';

import { ReactComponent as home_actions } from '../assets/icons/home/actions.svg';
import { ReactComponent as home_challenges } from '../assets/icons/home/challenges.svg';
import { ReactComponent as home_feed } from '../assets/icons/home/feed.svg';
import { ReactComponent as home_offers } from '../assets/icons/home/offers.svg';
import { ReactComponent as key } from '../assets/icons/outline/key.svg';
import { ReactComponent as conversation } from '../assets/icons/outline/chat-alt-2.svg';

import { ReactComponent as cloud } from '../assets/icons/customs/cloud.svg';
import { ReactComponent as drop } from '../assets/icons/customs/drop.svg';
import { ReactComponent as trash_arrow } from '../assets/icons/customs/trash.svg';
import { ReactComponent as apple } from '../assets/icons/customs/apple.svg';

import { ReactComponent as company } from '../assets/icons/home/company.svg';
import { ReactComponent as megaphone } from '../assets/icons/customs/megaphone.svg';

import { ReactComponent as alimentos } from '../assets/icons/customs/categories/alimentos.svg'
import { ReactComponent as community_service } from '../assets/icons/customs/categories/community-service.svg';
import { ReactComponent as compras } from '../assets/icons/customs/categories/compras.svg';
import { ReactComponent as donaciones } from '../assets/icons/customs/categories/donaciones.svg';
import { ReactComponent as energia } from '../assets/icons/customs/categories/energia.svg';
import { ReactComponent as familia } from '../assets/icons/customs/categories/familia.svg';
import { ReactComponent as hogar } from '../assets/icons/customs/categories/hogar.svg';
import { ReactComponent as naturaleza } from '../assets/icons/customs/categories/naturaleza.svg';
import { ReactComponent as oficina } from '../assets/icons/customs/categories/oficina.svg';
import { ReactComponent as residuos } from '../assets/icons/customs/categories/residuos.svg';
import { ReactComponent as salud } from '../assets/icons/customs/categories/salud.svg';
import { ReactComponent as transporte } from '../assets/icons/customs/categories/transporte.svg';
import { ReactComponent as rectangule } from '../assets/icons/customs/rectangule.svg';
import { ReactComponent as square } from '../assets/icons/customs/square.svg';



// stroke="none" fill="currentColor"

const iconMap =  {
  home,
  close,
  search,
  plus_circle,
  bell,
  chat_alt,
  color_swatch,
  collection,
  loans,
  returns,
  heart,
  document_search,
  lightning_bolt,
  tag,
  globe,
  flag,
  receipt_tax,
  fire,
  x_circle,
  photograph,
  badge_check,
  support,
  globe_alt,
  qrcode,
  gift,
  view_grid,
  view_list,
  hand_world,
  notification,
  view_tile,
  thumbs_up,
  dashboard,
  repost,
  user,
  user_outline,
  user_group,
  calculator,
  document,
  cog,
  shield,
  cheveron_right,
  cheveron_left,
  cheveron_down,
  checkmark,
  save_disk,
  download,
  edit_pencil,
  trash,
  information_solid,
  information_outline,
  document_add,
  close_solid,
  close_outline,
  add_solid,
  add_square_outline,
  remove_square_outline,
  plus,
  view_show,
  pin,
  clean_inside,
  clean_outside,
  gout,
  time,
  clean_complete,
  my_washes_circle,
  my_washes,
  help,
  phone,
  arrow_left,
  arrow_right,
  dots_vertical,
  user_info,
  language,
  terms,
  logout,
  location,
  calendar,
  cancel,
  star,
  coin1,
  coin2_4,
  coin5,
  impact1,
  impact3,
  impact5,
  sharypoints,
  sharypoints_main,
  sharycredits,
  more_horizontal,
  menu,
  whatsapp,
  community_service,
  ranking,
  paper_clip,
  paper_airplane,
  emoji_face,
  check,
  clock,
  home_actions,
  home_challenges,
  home_feed,
  home_offers,
  question,
  refresh,
  sad,
  key,
  conversation,
  drop,
  cloud,
  trash_arrow,
  company,
  apple,
  favor,
  megaphone,
  alimentos,
  compras,
  energia,
  familia,
  hogar,
  oficina,
  residuos,
  salud,
  transporte,
  donaciones,
  naturaleza,
  rectangule,
  square,
};

const Icon = ({ name, size, color, ...rest }) => {
  const Icon = iconMap[name];
  if (typeof Icon === 'undefined') return null;
  return <Icon color={color} style={{ width: size, height: size }} {...rest} name={name} />;
};

export default Icon;

