import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import { ToastContainer, notify } from "../../libraries/notifications";

import { history } from '../../routes';
import config from "../../config";

import transactionsAction from '../../context/transactions/actions';

import BottomNav from '../../components/commons/BottomNav';
import Loader from '../../components/commons/Loader';
import Button from '../../components/commons/Button';
import Icon from "../../libraries/icons";
import LayoutSmall from "../../components/layout/LayoutSmall";
import RequestElement from "../../components/customs/RequestElement";
import SearchInput from "../../components/forms/SearchInput";
import { isEmptyObject, } from '../../libraries/utils';

class Requests extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.from = this.props.location.state?.from || { pathname: '/' };
    this.state = {
      requests: [],
      search: "",
    };
  }

  componentDidMount() {
    console.log('Requests');
    console.log("this.props.location.state.fromYoBusco", this.props.location.state)
    if (this.props.location.state) {
      if(this.props.location.state.fromYoBusco){
        this.setState({onlyRequestsFromUser: true})
      }
    }

    this.getRequests();
  }

  getRequests = async () => {
    const {search} = this.state
    const params = {
      type: [
        config.TRANSACTIONS.REQUEST.TYPE,
      ],
      owner: this.props.auth.user.unique_id,
    }

    if (search && search !== ""){
      //params.name = `%${search.toLowerCase()}`
      //params.where = {"json_data":[]};
      //params.where.json_data.push({"field":"details", "value":`${search.toLowerCase()}`})
    }
    
    await this.props.onGetAllRequests(params);
    const { requests } = this.props;
    if (requests.error) {
      notify(this.t(requests.error.message));
    } else {
      let r = []
      if(this.state.onlyRequestsFromUser){
        r = requests.items.filter(item => item.created_by === this.props.auth.user.id)
      } else {
        r = requests.items.filter(item => item.created_by !== this.props.auth.user.id)        
      }
      if (search && search !== ""){
        r = r.filter(item => item.json_data.details.toLowerCase().includes(search) || item.json_data.category.name.toLowerCase().includes(search))
      }
      this.setState({ requests: r});
    }
  };

  searchClear = (form) => {
    form.change('search', undefined);
    this.searchOnClick({search: ''});
  }

  searchOnClick = async (values) => {
    console.log("Search on Click", values.search);
    if (this.state.search !== values.search) this.setState({ search: values.search || '' }, () => this.getRequests() );
  };

  goBack = () => {
    history.push({ pathname: config.ROUTES.HOME, state: { from: config.ROUTES.MAIN.OFFER }});
  }

  gotoAdd = () => {
    history.push({
      pathname: config.ROUTES.ADD,
      state:{
        fromPath: config.ROUTES.REQUESTS.LIST,
      }
    })
  }

  render() {
    const { requests } = this.state;

    return (
      <LayoutSmall
        main={{ className: "pb-14" }}
        header={{
          title: this.t("Necesidades de la comunidad"),
          className: "bg-primary",
          left: { icon: 'arrow_left', action: () => this.goBack() },
          right: (this.state.onlyRequestsFromUser ? {icon: 'plus', action: this.gotoAdd} : null)
        }}
        container={{ className: "px-0" }}
      >
        <ToastContainer />
        <section className="min-h-full py-6 bg-base-200 max-w-lg mx-auto">
          <div className="px-4">
            <Form initialValues={{}} onSubmit={this.searchOnClick}>
              {({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} className="w-full mb-4">
                  <Field name="search" component={SearchInput} placeholder={this.t("Search")} onClick={this.searchOnClick} onClearClick={() => this.searchClear(form) }/>
                </form>
              )}
            </Form>
          </div>
          <div>
              
            { this.props.requests.loading && (<Loader/>) }
            {!this.props.requests.loading && requests.length === 0 && (
              <div className="text-center p-10">
                <Icon className="h-24 w-24 mx-auto mb-3" name="stethoscope" />
                <h4 className="mb-1">{this.t("No hay necesidades")}</h4>
              </div>
            )}
            { requests && requests.map((request, index) => {
                return (
                  <RequestElement 
                    key={'p' + index}
                      route={
                        {pathname: config.ROUTES.REQUESTS.PREVIEW.replace(":id", request.id)}
//                        {
//                          pathname: !this.state.onlyRequestsFromUser ? config.ROUTES.OFFERS.DETAILS.replace(':type', request.sub_type) : config.ROUTES.REQUESTS.LIST,
//                          state: {
//                            type:request.sub_type,
//                            category:request.json_data.category,
//                            categories_selected:request.json_data.categories,
//                            fromRequest: true,
//                            requesterId: request.json_data.requester.id,
//                            categoryFromRequest: request.json_data.category.name,
//                            fromPath: config.ROUTES.REQUESTS.LIST,
//                            //detailsFromRequest: request.json_data.details,
//                          }
//                        }
                    } 
                    request={request}
                  />
                )
              })
            }
          </div>
        </section>
        <BottomNav/>
      </LayoutSmall>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    requests: state.transactions.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllRequests: (params) => dispatch(transactionsAction.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Requests));
