import alliances from '../assets/icons/ODS/alliances.png';
import education from '../assets/icons/ODS/education.png';
import energy from '../assets/icons/ODS/energy.png';
import gender_equality from '../assets/icons/ODS/gender-equality.png';
import health from '../assets/icons/ODS/health.png';
import hunger from '../assets/icons/ODS/hunger.png';
import iconalliances from '../assets/icons/ODS/icons/alliances.png';
import iconeducation from '../assets/icons/ODS/icons/education.png';
import iconenergy from '../assets/icons/ODS/icons/energy.png';
import icongender_equality from '../assets/icons/ODS/icons/gender_equality.png';
import iconhealth from '../assets/icons/ODS/icons/health.png';
import iconhunger from '../assets/icons/ODS/icons/hunger.png';
import iconindustry from '../assets/icons/ODS/icons/industry.png';
import iconinequalities from '../assets/icons/ODS/icons/inequalities.png';
import iconpeace from '../assets/icons/ODS/icons/peace.png';
import iconpoverty from '../assets/icons/ODS/icons/poverty.png';
import iconproduction from '../assets/icons/ODS/icons/production.png';
import iconsustainable from '../assets/icons/ODS/icons/sustainable.png';
import iconterrestrial_eco from '../assets/icons/ODS/icons/terrestrial_eco.png';
import iconundersea from '../assets/icons/ODS/icons/undersea.png';
import iconwater from '../assets/icons/ODS/icons/water.png';
import iconweather from '../assets/icons/ODS/icons/weather.png';
import iconwork from '../assets/icons/ODS/icons/work.png';
import industry from '../assets/icons/ODS/industry.png';
import inequalities from '../assets/icons/ODS/inequalities.png';
import peace from '../assets/icons/ODS/peace.png';
import poverty from '../assets/icons/ODS/poverty.png';
import production from '../assets/icons/ODS/production.png';
import sustainable from '../assets/icons/ODS/sustainable.png';
import terrestrial_eco from '../assets/icons/ODS/terrestrial-eco.png';
import undersea from '../assets/icons/ODS/undersea.png';
import water from '../assets/icons/ODS/water.png';
import weather from '../assets/icons/ODS/weather.png';
import work from '../assets/icons/ODS/work.png';
import config from './routes';


const customs = {
  URL_MANUAL_USUARIO:'https://drive.google.com/file/d/1sNcgBOI9H59S1CzhdBPV2EsxrHQFCfbD/view?usp=drive_link',
  COMPANY: {
    SHORT_NAME: 'SHARYCO',
    LONG_NAME: 'SHARYCO'
  },
  SUPPORTED_LANGUAGES: ['en', 'es'],
  DEFAULT_LANGUAGE: 'es',
  MONEY_SYMBOL: '$',
  MONEY_CODE: 'u$s',
  MONEY_POSITION: 'prefix', //'sufix'
  MONEY_SPACE: true,
  CURRENCIES: ['USD', 'ARS'],
  CURRENCY: 'ARS',
  LOCALE: 'es-ES',
  USER_TYPE: {
    REGULAR: 'regular',
    ADVANCED: 'advanced',
    INITIATIVES: 'initiatives',
    BENEFITS: 'benefits',
  },
  USER_ACCOUNTS_INTEGRATED: true,
  USER_ACCOUNTS: {
    TYPES: {
      OWNER: 'owner',
      GUEST: 'guest'
    },
    STATUS: {
      ACTIVE: 'active',
      INACTIVE: 'inactive',
      REVOKED: 'revoked'
    }
  },

  POINTS: {
    unit: 'pts',
    factor: 1,
  },

  OWNER: {
    SYSTEM: "system"
  },
  VALUE_RANGE_ICONS: {
    POINTS: {
      LOW: { value: 2, icon: 'impact1' },
      MEDIUM: { value: 5, icon: 'impact3' },
      HIGH: { value: 10, icon: 'impact5' },
    },
    COINS: {
      LOW: { value: 100, icon: 'sharypoints' },
      MEDIUM: { value: 200, icon: 'sharypoints' },
      HIGH: { value: 500, icon: 'sharypoints' },
    }
  },
  TYPES: {
    ROOT: 'root',
    CATEGORIES: {
      PRODUCTS: 'products',
      SERVICES: 'services',
      FAVORS: 'favors',
      ACTIONS: 'actions',
    },
    OFFERS: {
      PRODUCTS: 'products',
      FAVORS: 'favors',
      REQUESTS: 'requests'
    },
    MESSAGES: {
      RECEIVED: 'received',
      SENDED: 'sended'
    },
    INITIATIVES: {
      DONATIONS: 'donations',
      RECYCLING: 'recycling',
      VOLUNTEERING: 'volunteering'
    },
    BENEFITS: 'benefit',
    PRIZE: 'prize',
    // ACTIONS: ['transport', 'food', 'home' , 'energy', 'outdoors']
  },
  STATUS: {
    PRODUCTS: {
      AVAILABLE: 'available',
      BORROWED: 'borrowed',
      DISABLED: 'disabled'
    },
    SERVICES: {
      AVAILABLE: 'available',
      DISABLED: 'disabled'
    },
    FAVORS: {
      AVAILABLE: 'available',
      DISABLED: 'disabled'
    },
  },
  TRANSACTIONS: {
    BORROW: {
      TYPE: 'borrow',
      SUB_TYPE: {
        FAVOR: 'favor',
        PRODUCT: 'product',
        SERVICE: 'service',
      },
      STATUS: {
        NEW: 'new', // "getting borrowed" (pedir prestado)
        ACCEPTED: 'accepted', //"owner accept the loan" (aceptar)
        CANCELED: 'canceled', //"owner or user cancel the loan" (cancelar/rechazar)
        ON_USER: 'on_user', // user gets the product (recibi)
        ON_OWNER: "on_owner", // user send the product back to the owner (devolvi)
        REVIEW: 'review', //??
        COMPLETED: 'completed' // owner gets the product back (confirma devolucion)
      }
    },
    ACTION: {
      TYPE: 'action',
      SUB_TYPE: {
        TRANSPORT: 'transport',
        FOOD: 'food',
        HOME: 'home',
        ENERGY: 'energy',
        OUTDOORS: 'outdoors'
      },
      STATUS: {
        COMPLETED: 'completed'
      }
    },
    BENEFIT: {
      TYPE: 'benefit',
      SUB_TYPE: {
        USER: 'user',
        BUSINESS: 'business',
        MASLOW: 'maslow',
        PRIZE: 'prize',
      },
      STATUS: {
        GENERATED: 'generated',
        CONSUMED: 'consumed',
        CANCELED: 'canceled',
        RESERVED: 'reserved',
      },
    },
    INITIATIVE: {
      TYPE: 'initiative',
      SUB_TYPE: {
        DONATIONS: 'donations',
        RECYCLING: 'recycling',
        VOLUNTEERING: 'volunteering'
      },
      STATUS: {
        POSTULATED: 'postulated',
        CANCELED: 'canceled',
        ATTENDED: 'attended',
        PENDING: 'pending', //attended to volunteering but no confirmed
      }
    },
    REQUEST: {
      TYPE: 'request',
      SUB_TYPE: {
        FAVOR: 'favor',
        PRODUCT: 'product',
        SERVICE: 'service',
      },
    },
  },


  OPTIONS: {
    PRODUCTS: {
      // CATEGORY: ['electronica', 'decoracion', 'cocina','herramientas','limpieza','ropa', "transporte","aire libre"],
      BRANDS: ['sin marca', 'especificar marca'],
      PRODUCT_STATE: ['regular', 'bueno', 'muy bueno', 'excelente', 'nuevo']
    }
  },
  SIDEBAR: {
    MENU: {
      MAIN: [
        { icon: 'collection', label: 'Muro', route: config.ROUTES.HOME },
        { icon: 'color_swatch', label: 'Mi Inventario', route: config.ROUTES.USER.INVENTORY },
        { icon: 'loans', label: 'Lo que presté', route: config.ROUTES.USER.LOANS },//, notifications: true },
        { icon: 'returns', label: 'Lo que pedí', route: config.ROUTES.USER.RETURNS },//, notifications: true },
        // { icon: 'heart', label: 'Favoritos', route: config.ROUTES.USER.FAVOURITES },
        { icon: 'lightning_bolt', label: 'Mi actividad', route: config.ROUTES.USER.ACTIVITIES },
        { icon: 'bell', label: 'Notifications', route: config.ROUTES.NOTIFICATIONS, notifications: true },
        { icon: 'chat_alt', label: 'Mensajes', route: config.ROUTES.MESSAGES, notifications: true },
        { icon: 'company', label: 'Mi empresa', route: config.ROUTES.USER.DIRECTORY },
      ],
      SECONDARY: [
        // { /* icon: 'help',*/ label: '¿Cómo funciona?', route: config.ROUTES.HOW_WORKS },
        { /*icon: '',*/ label: 'Sugerencias y Soporte', route: config.ROUTES.ASSISTANCE, textClassName: 'red-600' },
        { /*icon: 'phone',*/ label: 'Contáctanos', route: config.ROUTES.CONTACT_US },
        // { /*icon: '',*/ label: 'Terms and conditions', route: config.ROUTES.TERMS },
        { /*icon: '',*/ label: 'Políticas de Privacidad', route: config.ROUTES.PRIVACY },

      ],
      ADMIN: [
      ],
    },
  },
  BUTTONBAR: {
    UPPER: [
      { icon: 'collection', label: 'Feed', route: config.ROUTES.MAIN.FEED },
      { icon: 'tag', label: 'Economía circular', route: config.ROUTES.MAIN.OFFER },
      { icon: 'community_service', label: 'Initiatives', route: config.ROUTES.MAIN.INITIATIVES },
      { icon: 'hand_world', label: 'Acciones sostenibles', route: config.ROUTES.MAIN.ACTIONS },
      { icon: 'receipt_tax', label: 'Benefits', route: config.ROUTES.MAIN.BENEFITS },
      { icon: 'gift', label: 'Premios', route: config.ROUTES.MAIN.PRIZES },
      { icon: 'ranking', label: 'Ranking', route: config.ROUTES.MAIN.RANKING },
    ],
    BOTTOM: [
      { icon: 'home_feed', label: 'muro', route: config.ROUTES.HOME },
      { icon: 'home_actions', label: 'acciones sostenibles', route: config.ROUTES.HOME_OPTIONS.ACTIONS },
      { icon: 'home_challenges', label: 'desafíos', route: config.ROUTES.HOME_OPTIONS.CHALLENGES },
      { icon: 'home_offers', label: 'préstamos', route: config.ROUTES.HOME_OPTIONS.PRODUCTS },
      { icon: 'search', label: 'buscar', route: config.ROUTES.HOME_OPTIONS.SEARCH },
      //{ icon: 'search', label: 'Buscar', route: config.ROUTES.SEARCH_OFFERS },
      //{ icon: 'plus_circle', label: 'Agregar', route: config.ROUTES.ADD },
      //{ icon: 'bell', label: 'Notificaciones', route: config.ROUTES.NOTIFICATIONS, notifications: true },
      //{ icon: 'chat_alt', label: 'Mensajes', route: config.ROUTES.MESSAGES, messages: true },
    ],
  },
  SLIDER: {
    SHOW: true,
    CONTENT: [
      {
        title: 'Sé Solidario',
        body: 'Pertenecé a la comunidad más grande de sostenibilidad y solidaridad. ',
        // title: 'slider.title_1',
        // body: 'slider.body_1',
        button: 'slider.button_1',
      },
      {
        title: 'Divertite, aprendé y ayudá',
        body: 'Aprenderás sobre cómo podés llevar un estilo de vida más sostenible.',
        // title: 'slider.title_2',
        // body: 'slider.body_2',
        button: 'slider.button_1',
      },
      {
        title: 'Impactá y Ganá',
        body: 'Tendrás acceso a productos, servicios y favores que presten los miembros de la comunidad.',
        // title: 'slider.title_3',
        // body: 'slider.body_3',
        button: 'slider.button_1',
      },
      {
        title: 'Ganás Vos, Gana el Planeta',
        // body: 'Con los puntos de impacto que generes tendrás acceso a descuentos de marcas aliadas sostenibles.',
        body: '',
        // title: 'slider.title_3',
        // body: 'slider.body_3',
        button: 'slider.button_3',
      },
    ]
  },
  MESSAGES: {
    TYPE: {
      MESSAGE: 'message',
      NOTIFICATION: 'notification',
      COMMENTS: 'comments',
      QUESTION: 'question'
    }
  },
  SIGNUP_USER_FIELDS: {
    LOCATION: false,
    DNI: true,
  },
  MAX_LEVEL: 7,
  VALIDATE_BENEFTIS: true,
  ADMIN_MAIL: "admin@sharyco.com",
  // BENEFITS: {
  //   TYPES: [
  //     { label: 'Para empresas', value: 'business' },
  //     { label: 'Para usuarios', value: 'user' }
  //   ],
  // }
  ODS: [
    { squareIcon: poverty, icon: iconpoverty, code: '1', text: 'Poner fin a la pobreza en todas sus formas en todo el mundo' },
    { squareIcon: hunger, icon: iconhunger, code: '2', text: 'Poner fin al hambre' },
    { squareIcon: health, icon: iconhealth, code: '3', text: 'Garantizar una vida sana y promover el bienestar para todos en todas las edades' },
    { squareIcon: education, icon: iconeducation, code: '4', text: 'Garantizar una educación inclusiva, equitativa y de calidad y promover oportunidades de aprendizaje durante toda la vida para todos' },
    { squareIcon: gender_equality, icon: icongender_equality, code: '5', text: 'Lograr la igualdad entre los géneros y empoderar a todas las mujeres y las niñas' },
    { squareIcon: water, icon: iconwater, code: '6', text: 'Garantizar la disponibilidad de agua y su gestión sostenible y el saneamiento para todos' },
    { squareIcon: energy, icon: iconenergy, code: '7', text: 'Garantizar el acceso a una energía asequible, segura, sostenible y moderna' },
    { squareIcon: work, icon: iconwork, code: '8', text: 'Promover el crecimiento económico inclusivo y sostenible, el empleo y el trabajo decente para todos' },
    { squareIcon: industry, icon: iconindustry, code: '9', text: 'Construir infraestructuras resilientes, promover la industrialización sostenible y fomentar la innovación' },
    { squareIcon: inequalities, icon: iconinequalities, code: '10', text: 'Reducir la desigualdad en y entre los países' },
    { squareIcon: production, icon: iconproduction, code: '11', text: 'Lograr que las ciudades sean más inclusivas, seguras, resilientes y sostenibles' },
    { squareIcon: sustainable, icon: iconsustainable, code: '12', text: 'Garantizar modalidades de consumo y producción sostenibles' },
    { squareIcon: weather, icon: iconweather, code: '13', text: 'Adoptar medidas urgentes para combatir el cambio climático y sus efectos' },
    { squareIcon: undersea, icon: iconundersea, code: '14', text: 'Conservar y utilizar sosteniblemente los océanos, los mares y los recursos marinos' },
    { squareIcon: terrestrial_eco, icon: iconterrestrial_eco, code: '15', text: 'Gestionar sosteniblemente los bosques, luchar contra la desertificación, detener e invertir la degradación de las tierras, detener la pérdida de biodiversidad' },
    { squareIcon: peace, icon: iconpeace, code: '16', text: 'Promover sociedades justas, pacíficas e inclusivas' },
    { squareIcon: alliances, icon: iconalliances, code: '17', text: 'Revitalizar la Alianza Mundial para el Desarrollo Sostenible' }
  ],

  ACTION_BY_DEFAULT: [
    {
      id: '1',
      condition: '',
      title:'Dá tu primer paso',
      subtile:'Mirá este video y en pocos segundos conocé qué es Sharyco',
      challenge: {
        "id": "1aa2ecfa-2c17-41e8-bce2-317890f10e95",
        "name": "Conocé que hace Sharyco",
        "type": "activities",
        "details": " ",
        "owner": "d8402e8a-24a1-49cb-8890-50449a9c5891",
        "enabled": 1,
        "visibility": "all",
        "json_data": {
          benefits: {
            "id": "ecd82fe8-624d-4fb0-adec-e999d4733f66",
            "challenge": "1aa2ecfa-2c17-41e8-bce2-317890f10e95",
            "benefit": "coins",
            "order_number": 2,
            "quantity": 500
          }
        },
        "participants_type": "company",
        "condition_type": "points",
        "status": "started",
        "winner": "first_n",
        "winner_number": 3,
        "start_date": new Date().toString(),
        "end_date": new Date().toString(),
        "image": "https://sharyco-public-files.s3.amazonaws.com/production_challenges_326447_imgupload_edit.jpeg",
        "info": null,
        "buttons":[{label:'Prestar producto',url:'/add/offers/products'},{label:'Prestar Favor',url:'/add/offers/favors'}],
        "title":"Seguí sumando puntos",
        "subtitle":"prestando productos o favores",
        "details":" Además de ganar puntos Shary y Puntos de Impacto por tus acciones sostenibles, podés seguir ganando puntos prestando productos y favores a tus compañeros de trabajo",
        "question":"¿Qué prestarías?",
      },
      action: {
        "id": "77bd42bf-e845-45fa-afdc-6cf26cae0ed4"
    }
    },
    {
      id: '2',
      condition: '',
      title:'Ser sostenible está en tus manos',
      subtile:'Descargá el ícono de la webapp de Sharyco a tu Celular como acceso directo y ganá créditos para canjear por lo que quieras',
      challenge: {
        "id": "9e2cc414-1363-454c-bfc0-4e3ba58e03522",
        "name": "¡Descargá el acceso directo a tu cel!",
        "type": "activities",
        "details": " ",
        "owner": "d8402e8a-24a1-49cb-8890-50449a9c5891",
        "enabled": 1,
        "visibility": "all",
        "json_data": {
          benefits: {
            "id": "1561f16f-f9dc-4b67-886d-292d70687e1a",
            "challenge": "9e2cc414-1363-454c-bfc0-4e3ba58e03522",
            "benefit": "credits",
            "order_number": 1,
            "quantity": 1
          }
        },
        "participants_type": "company",
        "condition_type": "points",
        "status": "started",
        "winner": "first_n",
        "winner_number": 3,
        "start_date": new Date().toString(),
        "end_date": new Date().toString(),
        "image": "https://sharyco-public-files.s3.amazonaws.com/production_challenges_326447_imgupload_edit.jpeg",
        "info": null
      },
      action:  {
        "id": "a0df5b09-2a52-4e76-9d93-cd56fb0943fc"
      } 
    },
    {
      id: '3',
      condition: '',
      title:'Sigue aprendiendo #vidasostenible',
      subtile:'Mirá este video y aprendé qué es ser sostenible y ganate un premio sorpresa',
      challenge: {
        "id": "e0852c15-4d40-4c5f-a005-ad53250cbbbb",
        "name": "¡Aprender a ser sostenible!",
        "type": "activities",
        "details": " ",
        "owner": "d8402e8a-24a1-49cb-8890-50449a9c5891",
        "enabled": 1,
        "visibility": "all",
        "json_data": {
          benefits: { 
            "id": "8333110c-5443-4e55-a3d4-f8074b12270d",
            "challenge": "e0852c15-4d40-4c5f-a005-ad53250cbbbb",
            "benefit": "8333110c-5443-4e55-a3d4-f8074b12270d",
            "order_number": 3,
            "quantity": null
          }
        },
        "participants_type": "company",
        "condition_type": "points",
        "status": "started",
        "winner": "first_n",
        "winner_number": 3,
        "start_date": new Date().toString(),
        "end_date": new Date().toString(),
        "image": "https://sharyco-public-files.s3.amazonaws.com/production_challenges_326447_imgupload_edit.jpeg",
        "info": null
      },
      action: {
        "id": "4482f24c-f731-4cd7-98fa-6eb35b1e4293"
      },
    },
    {
      id: '4',
      condition: '',
      title:'Sumá tu granito de arena',
      subtile:'Hacé tu primera acción sostenible que te ayudará ahorrar y además ayudará al planeta. ',
      challenge: {
        "id": "f9ab3624-8cc4-40ad-97dc-bf7b2d20def32323",
        "name": "Completaste tu acción sostenible",
        "type": "activities",
        "details": " ",
        "owner": "d8402e8a-24a1-49cb-8890-50449a9c5891",
        "enabled": 1,
        "visibility": "all",
        "json_data": {
          benefits: { 
            "id": "8333110c-5443-4e55-a3d4-f8074b12270d",
            "challenge": "f9ab3624-8cc4-40ad-97dc-bf7b2d20def32323",
            "benefit": "coins",
            "order_number": 3,
            "quantity": 10
          }
        },
        "participants_type": "company",
        "condition_type": "points",
        "status": "started",
        "winner": "first_n",
        "participants_type": "company",
        "winner_number": 3,
        "start_date": new Date().toString(),
        "end_date": new Date().toString(),
        "image": "https://sharyco-public-files.s3.amazonaws.com/production_challenges_326447_imgupload_edit.jpeg",
        "info": null,
        "buttons":[{label:'Pedir producto',url:'/h/products'},{label:'Pedir Favor',url:'/h/favors'}],//'add/offers/products':'/add/offers/favors'
        "title":"Pedí prestado",
        "subtitle":"con tus Puntos Shary",
        "details":"Podés pedir prestado lo que necesitás a tus compañeros de trabajo con los Puntos Shary que ganás por tus acciones de impacto positivo.",
        "question":"¿Qué pedirías?",
      },
      action: {
        "id": "441086b1-43d3-4a39-ac2f-0e8b5be00d03"
      },
    },

  ]
};
// Ejemplo de premios en challenges
//   [
//     {
//         "id": "1561f16f-f9dc-4b67-886d-292d70687e1a",
//         "challenge": "febe4a58-e045-4f7a-9788-ba86c79dc08e",
//         "benefit": "credits",
//         "order_number": 1,
//         "quantity": 500
//     },
//     {
//         "id": "8333110c-5443-4e55-a3d4-f8074b12270d",
//         "challenge": "febe4a58-e045-4f7a-9788-ba86c79dc08e",
//         "benefit": "coins",
//         "order_number": 3,
//         "quantity": 300
//     },
//     {
//         "id": "ecd82fe8-624d-4fb0-adec-e999d4733f66",
//         "challenge": "febe4a58-e045-4f7a-9788-ba86c79dc08e",
//         "benefit": "d9138af4-0f3d-4315-a226-0835c89f0c64",
//         "order_number": 2,
//         "quantity": null
//     }
// ]

customs.STATUS['TRANSACTIONS'] = {
  [customs.TRANSACTIONS.BORROW.STATUS.NEW]: { name: customs.TRANSACTIONS.BORROW.STATUS.NEW, nombre: "pendiente de aceptar" },
  [customs.TRANSACTIONS.BORROW.STATUS.CANCELED]: { name: customs.TRANSACTIONS.BORROW.STATUS.CANCELED, nombre: "cancelado", className: 'text-white border-red-600 bg-red-600' },
  [customs.TRANSACTIONS.BORROW.STATUS.ACCEPTED]: { name: customs.TRANSACTIONS.BORROW.STATUS.ACCEPTED, nombre: "aceptado", className: 'text-white bg-primary' },
  [customs.TRANSACTIONS.BORROW.STATUS.ON_USER]: { name: customs.TRANSACTIONS.BORROW.STATUS.ON_USER, nombre: "en uso" },
  [customs.TRANSACTIONS.BORROW.STATUS.ON_OWNER]: { name: customs.TRANSACTIONS.BORROW.STATUS.ON_OWNER, nombre: "confirmar devolución" },
  [customs.TRANSACTIONS.BORROW.STATUS.COMPLETED]: { name: customs.TRANSACTIONS.BORROW.STATUS.COMPLETED, nombre: "completo" },
  [customs.TRANSACTIONS.INITIATIVE.STATUS.POSTULATED]: { name: customs.TRANSACTIONS.INITIATIVE.STATUS.POSTULATED, nombre: "postulado" },
  [customs.TRANSACTIONS.INITIATIVE.STATUS.CANCELED]: { name: customs.TRANSACTIONS.INITIATIVE.STATUS.CANCELED, nombre: "cancelado", className: 'text-white border-red-600 bg-red-600' },
  [customs.TRANSACTIONS.INITIATIVE.STATUS.ATTENDED]: { name: customs.TRANSACTIONS.INITIATIVE.STATUS.ATTENDED, nombre: "asistido" },
  [customs.TRANSACTIONS.INITIATIVE.STATUS.PENDING]: { name: customs.TRANSACTIONS.INITIATIVE.STATUS.PENDING, nombre: "iniciado" },
}

customs.TRANSACTIONS['TYPES'] = {
  [customs.TRANSACTIONS.BORROW.TYPE]: { name: customs.TRANSACTIONS.BORROW.TYPE },
  [customs.TRANSACTIONS.INITIATIVE.TYPE]: { name: customs.TRANSACTIONS.INITIATIVE.TYPE },
  [customs.TRANSACTIONS.ACTION.TYPE]: { name: customs.TRANSACTIONS.ACTION.TYPE },
}


customs.TRANSACTIONS.INITIATIVE['SUB_TYPES'] = {
  [customs.TRANSACTIONS.INITIATIVE.SUB_TYPE.DONATIONS]: { name: customs.TRANSACTIONS.INITIATIVE.SUB_TYPE.DONATIONS },
  [customs.TRANSACTIONS.INITIATIVE.SUB_TYPE.RECYCLING]: { name: customs.TRANSACTIONS.INITIATIVE.SUB_TYPE.RECYCLING },
  [customs.TRANSACTIONS.INITIATIVE.SUB_TYPE.VOLUNTEERING]: { name: customs.TRANSACTIONS.INITIATIVE.SUB_TYPE.VOLUNTEERING },
}

customs.ACTIONS_SUB_TYPES= {
  Normal:'regular',
  Question:'questions',
  Video : 'video' 
 }
export default customs;