import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatFullname, isJSON, renderDate } from '../../libraries/utils';
import Avatar from '../../components/commons/Avatar';
import Icon from '../../libraries/icons';
import { Link } from 'react-router-dom';

export default function MessageElement({ data, route, type, owner, ...rest }) {
  const { t, i18n } = useTranslation();

  const isOwner = data.source.id === owner
  
  const {profile_image, username, json_data} = isOwner ? data.target : data.source

  const {first_name, last_name} = {...(isJSON(json_data) ? JSON.parse(json_data) : json_data)}

  const notifications = data.json_data[`unread_messages_for_${isOwner ? 'source':'target'}`]

  const notificationsForOtherPart = data.json_data[`unread_messages_for_${isOwner ? 'target':'source'}`]

  //console.log("data.json_data?.last_message_portion", data.json_data)
  return (    
    <Link to={route} key={rest.key} className={`flex items-center p-3 ${rest.className && rest.className}` + (notifications > 0 ? ' bg-primary bg-opacity-[0.15]' : '')}>
      <Avatar
        image={profile_image}
        label={username}
        className="rounded-full w-12 h-12"
      />
      <div className="ml-3 mr-auto flex justify-between w-full items-start">
        <div className="font-normal flex flex-col">
          <b className='text-primary'>{formatFullname({first_name, last_name})}</b>
          <p className='flex items-center text-neutral'>
            <Icon name='conversation' size={14}  className='mr-1' />
            {((data.json_data?.last_message_from === 'source' && isOwner) || (data.json_data?.last_message_from === 'target' && !isOwner)) && <span className="text-neutral mr-1" ><Icon className={`h-4 w-4 ${notificationsForOtherPart === 0 ? 'text-success': ''}`} name='check'/></span>}
            <span>{data.json_data?.last_message_portion?.slice(0, window.screen.width / 17)  + (data.json_data?.last_message_portion?.length >= window.screen.width / 14 ? '...': '')}</span>
          </p>
        </div>
        <div className='flex flex-col items-end'>
          <small className={(notifications ? 'text-primary' :'text-gray-300') + ' font-light text-xs w-max'}>{renderDate({ISOdate:data?.json_data?.last_message_at, t, isLocal: true})}</small>
          {notifications > 0 && <small className="text-white bg-primary h-5 w-5 flex rounded-full items-center justify-center">{notifications}</small>}
        </div>
      </div>
    </Link>
  )
}