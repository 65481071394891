import React from 'react';
import Icon from '../../libraries/icons';
import { useTranslation } from 'react-i18next';

export default function OfferOptions({data, parent, ...rest}) {
  const { t, i18n } = useTranslation();
  return(
    <div  className="flex px-3 py-4 border-b" onClick={() => rest.onClick && rest.onClick(data)}>
      {parent &&<span className="pl-3 flex">{t(parent.name)}<Icon className="h-6 w-6 ml-3 text-base-300" name="cheveron_right" /></span>} 
      <span className="px-3">{t(data.name)}</span> 
      <Icon className="h-6 w-6 ml-auto text-base-300" name="cheveron_right" />
    </div>
  )
}

/*
    <div  className="flex px-3 py-2 border-b">
      <Link
        to={route}>
        <span className="px-3 py-2">{t(data.name)}</span> 
      </Link>
      <Icon className="h-6 w-6 ml-auto text-base-300" name="cheveron_right" />
    </div>

*/