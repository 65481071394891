import React from 'react';

const RadioInput = ({ name, input, meta, label, type, value, alert, options, setInputs, ...rest }) => {
  return (
    <div>
    <label className={"cursor-pointer label" + (rest.labelClassName && rest.labelClassName)}>
      <span className="label-text">{label}</span>
      <>
        <input
          {...input}
          name={input.name}
          checked={input.checked}
          onChange={(e) => {
            input.onChange(e)
            if(rest.setExternal) rest.setExternal(input.value)
          }}
          type="radio"
          className={"Radio"}
        
        />
      </>
      </label>
      {!rest.noError && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">{meta && meta.touched && meta.error}</span>
        </label>
      )}

    </div>
  )
}

export default RadioInput;