import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import config from '../../config';
import { history } from '../../routes';
import { getOwner } from '../../libraries/utils';

import BusinessListCard from '../../components/customs/BusinessListCard';
import LayoutSmall from '../../components/layout/LayoutSmall'
import BottomNav from '../../components/commons/BottomNav';
import businessesActions from '../../context/businesses/actions';
import Loader from '../../components/commons/Loader';

class Businesses extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      businesses: [],
    }
  }
  
  componentDidMount() {
    console.log('BENEFITS');
    let params = {}
    if(this.props.location.state?.businessId){
      params.owner = this.props.location.state.businessId
    }
    this.getBusinesses(params)
  }

  goBack = () => {
    history.push({ pathname: config.ROUTES.HOME, state: { from: config.ROUTES.MAIN.BENEFITS }});
  }

  getBusinesses = async (params) => {
    //const params = { page_num };
    const { search } = this.state;
    if (search && search !== '') {
      params.where = { json_data: [] };
      if (search && search !== '') {
        params.where = { custom: { name: `%${search.toLowerCase()}` } };
      }
    }
    await this.props.onGetAllBusinesses({...params, type: "benefits", enabled: 1});
    const { businesses } = this.props;
    if (businesses.error) {
      notify(this.t(businesses.error.message));
    } else {
      this.setState({ businesses: businesses.items });
    }
  };

  render() {
    const { businesses }  = this.state
    return (
      <LayoutSmall
        main={{ className: ""}}
        header={{ 
          title: "Premios",
          className: "bg-white text-primary flex justify-between p-3",
          left: { icon: 'arrow_left', action: this.goBack }
        }}
        container={{ className: "px-0"}}
      > 
        <ToastContainer/>
        <div className="w-full max-w-lg mx-auto p-4 pb-16">
          <section className="">
            <div className="flex justify-between items-center mb-2">
              <h4 className="mb-0"></h4>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-2">
              {businesses && businesses.map((business, index) => {
                  return (
                    <BusinessListCard 
                      className="mb-3"
                      key={index}
                      business={business}
                      route={{ pathname: config.ROUTES.BENEFITS.LIST, state: { from: config.ROUTES.BENEFITS.BUSSINESES_LIST, businessId: business.id }}}
                    />
                  )
                })
              }
            </div>
          </section>
        </div>
        <BottomNav/>
      </LayoutSmall>        
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    businesses: state.businesses.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllBusinesses: params => dispatch(businessesActions.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Businesses));
