import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../routes';
import { ToastContainer, notify } from '../../libraries/notifications';
import { isEmptyObject, getObjectWithJsonDataToFormValues, } from '../../libraries/utils';

import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import IconCoins from '../../components/customs/IconCoins';
import IconPoints from '../../components/customs/IconPoints';
import UserOwnerElement from '../../components/customs/UserOwnerElement';
import RatingProfileCard from '../../components/customs/RatingProfileCard';

import favorsAction from '../../context/favors/actions';
import transactionsAction from '../../context/transactions/actions';
import usersAction from '../../context/users/actions';
import messagesActions from '../../context/messages/actions';
import logo from '../../assets/img/logo.png';

import { Form, Field } from "react-final-form";
import TextareaInput from '../../components/forms/TextareaInput';
import internalsActions from '../../context/internals/actions';
import SmallRating from '../../components/commons/SmallRating';
import CommentElement from '../../components/customs/CommentElement'

class Favor extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      favor: {},
      owner: {},
      loading: true,
    }
  }
  
  componentDidMount() {
    const params = this.props.match.params;
    console.log('FAVOR_PAGE', params);
    if (params.id){
      this.getFavor(params);
    } else {
      this.goBack();
    }
    if (!isEmptyObject(this.props.location.state)) { 
      const { from, fromPath } = this.props.location.state;
      this.setState({ from, fromPath });   
      console.log('FROM', from);
    } 
  }

  goBack = () => {
    history.push({ pathname: this.state.fromPath || this.state.from || this.props.location.myCustomProps || config.ROUTES.HOME, state: { from: config.ROUTES.MAIN.OFFER }});
  }

  getFavor = async (params)=> {
    await this.props.onGetFavor(params);
    const { favor } = this.props;
    if (favor.error) { 
      notify(this.t(favor.error.message));
    } else {
      await this.props.onGetOwner({ id: favor.item.owner });
      const owner = this.props.owner;
      console.log(favor.item.owner)
      if (owner.error) { 
        notify(this.t(owner.error.message));
      } else {
        await this.props.onGetComments({ type:config.MESSAGES.TYPE.QUESTION, subject:config.TYPES.OFFERS.PRODUCTS, related_to:favor.item.id, order_direction: 'DESC'});
        const { comments } = this.props;
        if (comments.error) { 
          notify(this.t(comments.error.message));
        } else {

        const favorData = getObjectWithJsonDataToFormValues(
          favor.item,
          ['id', 'owner', 'img', 'name', 'status', 'details', 'points', 'coins', 'enabled', 'rate_total', 'rate_count']
        );
        const ownerData = getObjectWithJsonDataToFormValues(
          owner.item,
          ['id', 'accounts', 'username', 'first_name', 'last_name', 'profile_image', 'rating', 'email', 'rate_total', 'rate_count',]
        );
        const userData = getObjectWithJsonDataToFormValues(
          this.props.auth.user,
          ['id', 'accounts', 'username', 'first_name', 'last_name', 'profile_image', 'rating', 'rewards', 'email', 'rate_total', 'rate_count',]
        );
        
        let opinions = await this.getOpinions(favorData.id)

        this.setState({ favor: favorData, owner: ownerData, user: userData, productComments: comments.items, opinions, loading: false })
        }  
      }
    }    
  }

  getOpinions = async (productId) => {
    await this.props.onGetTransactions({target: productId});
    const { transactions } = this.props;
    if (transactions.error) {
      notify(this.t(transactions.error.message));
      return []
    } else {
      let opinions = transactions.items.filter(t => t.json_data.service_rating)
      return opinions
    }
  }

  saveBorrow = async ()=> {
    this.setState({ loading: true })
    const { favor, owner, user } = this.state;

    await this.props.onUpdateFavor({ id: favor.id, status: config.STATUS.PRODUCTS.BORROWED });
    console.log('AFTER CHANGE FAVOR STATUS');
    if (this.props.favor.error) { 
      notify(this.t(this.props.favor.error.message));
    } else {
      let data = {
        json_data:{
          offer:favor,
          owner, 
          borrower: user,
          coins: favor.coins,
          points:favor.points          
        },
        // owner: config.OWNER.SYSTEM,
        target: favor.id, 
        source: favor.owner,
        type: config.TRANSACTIONS.BORROW.TYPE,
        sub_type: config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR,
        status: config.TRANSACTIONS.BORROW.STATUS.NEW,
        owner: this.props.auth.user.unique_id,
      }
      console.log('TRANSACTION', data);
      await this.props.onSaveTransaction(data);
      console.log('AFTER SAVE TRANSACTION');
      const transaction = this.props.transaction;
      if (transaction.error) { 
        notify(this.t(transaction.error.message));
        this.setState({ loading: false });
      } else {
        console.log(transaction)
        let notificationData = {
          type:config.MESSAGES.TYPE.NOTIFICATION,
          source: user.id,
          target: owner.id,
          related_to: favor.id,
          parent: " " ,
         /*  readed:false, */
          subject: null,
          body:" te pidió un favor: " + favor.name, // The body is used to redirect at /notifications. Change it there too.
          json_data: {
            favor,
            redirect_notification_route: config.ROUTES.LOANS,
          },
        }
        await this.props.onSaveNotification(notificationData);
        console.log('AFTER SAVE NOTIFICATION');
        const notification = this.props.message;
        if (notification.error) { 
          notify(this.t(notification.error.message));
        } else {
          history.push({
            pathname: config.ROUTES.FAVORS.SUCCESS.replace(':id', favor.id),
            state: {
              title: "Reserva enviada",
              // headline: "El dueño del favor tiene 24 horas para responder tu petición.",
              button:{
                text: "Continuar",
                route: config.ROUTES.USER.RETURNS,
              },
            }
          })
        }
      }
    }
  }

  sendMessage = (product, owner) => {
      console.log('MESSAGE', product);
      history.push({
        pathname: config.ROUTES.SEND_MESSAGE,
        state: {
          fromPath: config.ROUTES.PRODUCTS.DETAIL.replace(':id', product.id),
          product,
          owner
          }
        }
      );
    }

  commentProduct = async (values, owner, product) => {
    console.log("OWNER",owner.id)
    const user = this.props.auth.user.id;
    console.log("this.state.productComments", this.state.productComments)
    if(this.state.productComments){
      if(this.state.productComments.length === 1){
        if(this.state.productComments[0].source.id === this.props.auth.user.id &&
          !this.state.productComments[0].json_data.answered){
          console.log("same last message")
          this.setState({errorMessage: "Debes esperar a que te respondan el mensaje anterior para mandar otro comentario en el mismo producto"})
          return
        }
      }
      if(this.state.productComments.length > 1){
        if(this.state.productComments[1].source.id === this.props.auth.user.id &&
          !this.state.productComments[1].json_data.answered){
          console.log("same last message")
          this.setState({errorMessage: "Debes esperar a que te respondan el mensaje anterior para mandar otro comentario en el mismo producto"})
          return
        }
      }
    }


    let parent_ = config.TYPES.ROOT
    let same_conversation_message = await this.getMessage(user,  owner.id, product.id)

    if(same_conversation_message){
      parent_ = same_conversation_message.id
    }


    let data = {
      type: config.MESSAGES.TYPE.QUESTION,
      source: user,
      target: owner.id,
      related_to: product.id,
      parent: parent_,
     /*  readed:false, */
      subject: config.TYPES.OFFERS.PRODUCTS,
      body:values.message.replaceAll('\n', ' '),
      json_data:{
        answered: 0,
        product_name: product.name,
        product_img: product.img,
        unread_messages_for_source:0,
        unread_messages_for_target:1,
        last_message_from: 'source',
        last_message_portion:values.message.replaceAll('\n', ' ').slice(0,30),
        last_message_at: new Date().toISOString()
      }
    }
    console.log('MESSAGE', data);
    await this.props.onSaveMessage(data);
    console.log('AFTER SAVE MESSAGE');
    const sendedMessage = this.props.message
    if (sendedMessage.error) { 
      notify(this.t(sendedMessage.error.message));
    } else {
      console.log(sendedMessage)
      let mailData = {
        template: "message_owner",
        locale: "es",
        to: this.state.owner.email,
        first_name: "",
        last_name:  "",
        params: {
            // subject: "Comentaron uno de tus productos",
            owner: `${this.state.owner.first_name} ${this.state.owner.last_name}`,
            user: `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`,
            publication_name: `${product.name}.`,
            message: `${values.message}`,
            //product_route: config.ROUTES.PRODUCTS.DETAIL.replace(':id', product.id),
            product_route: config.ROUTES.QUESTION.replace(':root_id', sendedMessage.item.id),
        }
      }
      await this.props.onSendMail(mailData);
      const internals = this.props.internals;
      if (internals.error) {
        notify(this.t(internals.error.message));
      }

      history.push({
        pathname: config.ROUTES.MESSAGE_SUCCESS.replace(':id', sendedMessage.item.id),
        state: {
          title: "Comentaste el favor",
          headline: "Podés verlo en detalle en tu casilla de mensajes enviados",
          button:{
            text: "Continuar",
            route: config.ROUTES.FAVORS.DETAIL.replace(':id', product.id),
          },
        }
      })

    }}

  getMessage = async (source, target, related_to) => {
    let params = {source, target, related_to, parent:'root'}
    await this.props.onGetAllMessages(params);
    const messages = this.props.messages
    if (messages.error) { 
      notify(this.t(messages.error.message));
      return null
    } else {
      let messages_ = [...messages.items] 
      //if(!related_to){
      //  messages_ = messages.items.filter(m => m.related_to === "" || m.related_to === null) 
      //}
      //messages_ = messages.items.filter(m => m.parent === "root") 
      if(messages_.length){
        return messages_[0]
      }
      
      return null
    }
  }

  render() {
    const { favor, owner, submitting, user, productComments, opinions, loading } = this.state;
    //const couldBorrow = (user && user.rewards && user.rewards.total && user.rewards.total.coins && favor.coins <= user.rewards.total.coins) ? true : false
    const couldBorrow = (user && user.rewards && user.rewards.total && user.rewards.total.coins && parseInt(favor.coins) <= parseInt(user.rewards.total.coins) && favor.status !== config.STATUS.PRODUCTS.DISABLED)
    const is_mine = favor && owner && (favor.owner === this.props.auth.user.id)
    const rating = favor?.rate_total && (favor?.rate_count !== 0) ? favor?.rate_total/favor?.rate_count : 0

    return (
      <LayoutSmall
        main={{ className: ""}}
        header={{ 
          className: "bg-gradient-to-b from-primary-focus to-transparent bg-transparent fixed text-primary z-10",
          left: !this.props.favor.loading ? { icon: 'arrow_left', action: this.goBack } : {},
          /* right: { icon: 'heart'} */
        }}
        loading={loading}        
        container={{ className: "px-0"}}
      >
        <ToastContainer />
        {/* <section className=" mx-auto md:w-3/5 md:flex md:flex-col"> 
        <img src={product.img} alt="" className='mx-auto w-full md:w-1/2 aspect-square object-contain' /> */}
          <section className=" mx-auto md:w-3/5 md:flex md:flex-col">

        
            <div className="bg-white shadow">
              <div className="container px-4 relative h-56 flex">
                <img src={favor.img ? favor.img : logo} alt="" className='mx-auto w-full md:w-1/2 aspect-square object-contain'/>
                <SmallRating containerClassName=' absolute px-1 right-2 bottom-2 h-6' rate={rating} />
                {/* <StarRating containerClassName="flex justify-end -mt-6" unit="float" initialRating={rating} size={16} isReadOnly={true}/> */}
              </div>
              <div className='p-3 w-full'>
                <h1 className="">{favor.name}</h1>
                <UserOwnerElement className="flex items-center" starClassName="ml-2" owner={owner} route={{pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(':id', owner.id ), state: {fromPath: config.ROUTES.FAVORS.DETAIL.replace(':id', favor.id)}}}/>
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-2 my-4">
                    <IconCoins negative={true} coins={favor.coins}/>
                    <IconPoints points={favor.points}/>
                  </div>
                  {/* <small>{this.t("por día")}</small> */}
                  {/* <small className="text-gray-500">{product.distance} km</small> */}
                </div>
                
                  {/* <ButtonIcon buttonClassName="btn-ghost bg-base-100" className="w-6 h-6 text-neutral" icon="chat_alt"
                    onClick={this.sendMessage}
                  /> */}
                  {!is_mine && 
                    <div className="flex items-center gap-3">
                      <Button className="btn btn-primary flex-1" title={this.t("Pedir")}
                        disabled={!couldBorrow || favor.status === config.STATUS.PRODUCTS.DISABLED || favor.owner === this.props.auth.user.id || submitting}
                        onClick={this.saveBorrow}
                      />
                    </div>
                  }
                
                {!is_mine && !couldBorrow && <h5 className="text-warning">{this.t("Sus Puntos Shary no son suficientes para pedir este favor o el favor está inactivo.")}</h5>}
              </div>
            </div>
                  
              {/* <Button className="btn btn-xs btn-ghost text-primary underline" title={this.t("Ver comentarios")}
                onClick={this.goComments}
              /> */}
              

              {/* <h4 className="mb-4">{favor.status}</h4> */}
              {/* <Button className="btn btn-xs btn-ghost text-primary underline" title={this.t("Consultar disponibilidad")}
                onClick={this.getAvailability}
              /> */}
              
            <section className="p-4 container-fluid" >
            {/* <section className='container-fluid p-3'> */}
              <h4 className="mb-2">{this.t("Descripción")}</h4>
              <p className="text-neutral mt-2 mb-1">{favor.details}</p>
            </section>

                <div className ="p-3 container-fluid">
                  <Form  onSubmit={(values) => this.commentProduct(values, owner, favor)}>
                    {({ handleSubmit, form, submitting, pristine, values }) => (
                      <form onSubmit={handleSubmit} className='w-full'>
                        <div className="w-full mt-3 md:mb-0">
                          <h4 className="mb-2">{this.t("Preguntas")}</h4>
                          <Field name="message" component={TextareaInput} placeholder={this.t("Escriba comentario")} />
                        </div>
                        <Button
                          className="btn-outline btn-sm shadow-none btn-primary btn-block"
                          title={this.t("Send")}
                          onClick={handleSubmit}
                          disabled={ submitting || pristine}
                        />
                      </form>
                    )}
                  </Form>
                </div>
                <div className='p-4 container-fluid'>
                  {productComments && productComments.length === 0 && <p className ="p-4">{this.t('No hay comentarios')}</p>}
                  {productComments && productComments.map((comment, index) => (<CommentElement key={index} data={comment}/>))}

                </div>

                <section className="p-4 container-fluid" >
                  {opinions && 
                    <>
                      <h4 className="mb-4">{this.t(`Opiniones sobre ${favor.name}`)}</h4>
                      {opinions.length === 0 && <p>No hay opiniones</p>}
                      <div className='flex flex-col md:w-full gap-3'>
                        {opinions.slice(0,4).map((opinion,index)=> {
                          return(<RatingProfileCard data={opinion} key={index} type="favor"/>)
                        })}
                      </div>
                    </>
                  }
                </section>
        </section>

      </LayoutSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    favor: state.favors.current,
    owner: state.users.current,
    transaction: state.transactions.current,
    transactions: state.transactions.list,
    message: state.messages.current,
    messages: state.messages.list,
    comments: state.messages.list,
    internals: state.internals,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetFavor: (params) => dispatch(favorsAction.get(params)),
    onGetOwner: (params) => dispatch(usersAction.get(params)),
    onUpdateFavor: (params) => dispatch(favorsAction.saveOrUpdate(params)),
    onSaveTransaction: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
    onGetTransactions: (params) => dispatch(transactionsAction.getAll(params)),
    onSaveMessage: (params) => dispatch(messagesActions.saveOrUpdate(params)),
    onSaveNotification: (params) => dispatch(messagesActions.saveOrUpdate(params)),
    onGetComments: (params) => dispatch(messagesActions.getAll(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
    onGetAllMessages: (params) => dispatch(messagesActions.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Favor));



