import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StarRating from "react-svg-star-rating";
import { formatFullname, getObjectWithJsonDataToFormValues } from '../../libraries/utils';


export default function UserOwnerElement({ owner, route, ...rest }) {
  const { t, i18n } = useTranslation();  
  const owner_ = owner.json_data ? getObjectWithJsonDataToFormValues(owner, ['rate_total', 'rate_count']) : owner

  const rate_total = owner_.rate_total
  const rate_count = owner_.rate_count
  const rating = rate_total && (rate_count !== 0) ? rate_total/rate_count : 0

  return (
    <div className={"mt-1 " + (rest.className ? rest.className : "")}>
      <div className="flex font-normal">
        <small className="text-gray-500">{t("de")}</small>
        <small className="text-primary block ml-1 truncate ...">
          {route ? 
            <Link to={route} className='font-light'>{t(formatFullname(owner))}</Link>
          : 
            t(formatFullname(owner))
          }
        </small>
      </div>
      <div className={"" + (rest.starClassName ? rest.starClassName : "")}>
        <StarRating containerClassName="flex" unit="float" initialRating={rating} size={13} isReadOnly={true}/>
      </div>
    </div>

  )
}