import config from '../../config';
import { request } from '../../libraries/request'

const getAll = (params = {}, token) => {
  console.log('SERVICES::TRANSACTIONS::get', params, token);
  if (params && params.page_num && config.QUERIES_PAGE_SIZE) {
    params.page_size = config.QUERIES_PAGE_SIZE;
  }
  if (!params.order_by) { 
    params.order_by = 'transactions__created_at';
    params.order_direction = 'DESC';
  }

  return request({
    url: config.BASE_API_URL + config.API.TRANSACTIONS,
    accessToken: token,
    data: params,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {
      response.data.data.forEach((e, index) => {
        response.data.data[index].created_at = e.created_at && new Date(e.created_at);
      });      
      response.data.query.page_size = config.QUERIES_PAGE_SIZE;
    }
    return response;
  })
}

const save = (params, token) => {
  console.log('SERVICES::TRANSACTIONS::save', params, token);
  return request({
    url: config.BASE_API_URL + config.API.TRANSACTIONS,
    accessToken: token,
    method: 'POST',
    data: params,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const update = (params, token) => {
  console.log('SERVICES::TRANSACTIONS::update', params, token);
  if(params?.created_at) params.created_at = new Date(params.created_at).toISOString().slice(0,19)
  if(params?.updated_at) params.updated_at = params.updated_at.slice(0,19)
  return request({
    url: config.BASE_API_URL + config.API.TRANSACTION.replace('{id}', params.id),
    accessToken: token,
    method: 'PATCH',
    data: params,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const get = (params, token) => {
  console.log('SERVICES::TRANSACTIONS::get', params.id, token);
  return request({
    url: config.BASE_API_URL + config.API.TRANSACTION.replace('{id}', params.id),
    accessToken: token,
    // data: {},
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })

}

const del = (params, token) => {
  console.log('SERVICES::TRANSACTIONS::remove', params.id, token);
  return request({
    url: config.BASE_API_URL + config.API.TRANSACTION.replace('{id}', params.id),
    accessToken: token,
    method: 'DELETE',
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

export default {
  getAll,
  save,
  get,
  del,
  update,
};