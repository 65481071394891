import { combineReducers } from 'redux';
import types from './types';

export const level = (state = {
  item: null,
  loading: false,
  error: null,
}, action) => {
  // console.log('::::::REDUCER::BUSINESSES::business::', action);
  switch (action.type) {
    case types.GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.GET_SUCCESS:
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        error: null,
      };
    default:
      return state
  }
}

export default combineReducers({
  current: level,
});

