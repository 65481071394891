import React from 'react';
import { history } from '../../routes';
import config from '../../config';

export default function Avatar({ image, className, ...rest }) {
  const gotoProfile = () => {
    if(rest.id){
      history.push(
        {
          pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(':id', rest.id ),
          from: rest.from ? rest.from : config.ROUTES.HOME,
        })
    }
  }
  return (
    <div className={"avatar " + (rest.containerClassName ? rest.containerClassName : "") + (!image ? " placeholder" : "")} onClick={gotoProfile}>
      <div className={(className ? className : "rounded-full w-20 h-20") + (!image ? " bg-neutral-focus text-neutral-content" : "")}>
        { image ? (
          <img src={image} alt="" />
        ):(
          <span>{rest.label || ""}</span>
        )}
      </div>
    </div>
  )
}