import React from 'react';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../libraries/notifications';
import config from '../config';
import { isEmptyObject } from '../libraries/utils';

import LayoutWithSidebar from '../components/layout/LayoutWithSidebar';
import NavButtonBar from '../components/commons/NavButtonBar';
import BottomNav from '../components/commons/BottomNav';
import Feed from './home/Feed';
import Offer from './home/Offer';
import Initiatives from './home/Initiatives';
import Actions from './home/Actions';
import Benefits from './home/Benefits';
import Prizes from './home/Prizes';
import Ranking from './home/Ranking';
import { connect } from 'react-redux';
import settingsActions from '../context/settings/actions';
import { history } from '../routes';
import Swal from 'sweetalert2';
import actions from '../context/users/actions';
import { differenceInDays, formatDistance, isBefore } from 'date-fns';
import { startOfDay } from 'date-fns';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      topNav: config.ROUTES.MAIN.FEED,
      bottonNav: config.ROUTES.HOME,
      buttonBarUpper: [],
    }
  }

 async componentDidMount() {
    await this.getSettingSharyco()
    await this.getSettings();
    await  this.userToken();
 
    if (!isEmptyObject(this.props.location.state)) { 
      const { from } = this.props.location.state;
      this.setState({ topNav: from, from });   
    } 
  }


  userToken = async () => {
      await this.props.onGetToken();
      if(this.state.show_onboarding){
        this.validarFlujo()    
      }
  }

  validarFlujo=()=>{

    const arraysFlows = config.ACTION_BY_DEFAULT
    if(!this.props.auth.user?.tour){
      const idFlow=arraysFlows[0].id
      return history.push(config.ROUTES.FLOW.STEP1.replace(':id',idFlow)); //config.ROUTES.HOME
    } else {
      // console.log("=======================")
      // 'this.props.auth.user?.tour' es una cadena que contiene fechas separadas por comas
      const array = this.props.auth.user?.tour.split(',');
      // console.log(array)
      const index = array.indexOf('0');
      if (index < 0) {
        console.log(`Index= ${index} ya hizo todos los flows goHome`);
        return history.push(config.ROUTES.HOME);
      }
      const idFlow = arraysFlows[index]?.id;
      
      // Obtener la fecha del último flujo
      const fechaUltimoFlowHecho = array[index - 1];
      const fecha = new Date(fechaUltimoFlowHecho);
      const hoy= startOfDay(new Date())
      const distanciaEnDias = differenceInDays(hoy,fecha)
      
      console.log("Fecha Ultimo flow ",fecha)
      console.log("Fecha de hoy  ",hoy)
      console.log("Diferencia en dias de ultFlow y hoy  ",distanciaEnDias)
      console.log("Configuracion dias x flow",this.state.timeBetweenFlows)
      console.log("=======================")

      if(this.state.timeBetweenFlows===0) {
        console.log(`Tiempo entre flows = ${this.state.timeBetweenFlows} goFlow`);
        return history.push(config.ROUTES.FLOW.STEP1.replace(':id', idFlow));
      }
    
      if(differenceInDays(hoy,fecha)>=this.state.timeBetweenFlows ||
      (differenceInDays(hoy,fecha)===this.state.timeBetweenFlows-1 && isBefore(fecha,hoy))
    ){
       return history.push(config.ROUTES.FLOW.STEP1.replace(':id', idFlow));
      }else{
        return history.push(config.ROUTES.HOME);
      }
    }
  }
  onNavBarClick = (option) => {
    console.log('OPTION', option);
    this.setState({ topNav: option });
  }

  getSettingsValueByCode = (settingsList, code) => {
    let get_false_if_zero = (v) => v === "false" ? false : true
    let setting = settingsList.find(s => s.code === code)
    if(!setting) return false
    return setting ? get_false_if_zero(setting.value) : true
  }

  getSettingSharyco = async () => {
    await this.props.onGetAllSettings({
      "type": "sharyco_admin_settings",
      "owner": "system",
      "code": "tiempo_flows"
    });
    const { settings } = this.props;
    if (settings.error) {
      notify(this.t(settings.error.message));
    } else {
      if(settings.items.length>0){
        this.setState({timeBetweenFlows:Number(settings.items[0].value)})
      }else{
        this.setState({timeBetweenFlows:0})
      }
    }
  }

  getSettings = async () => {
    await this.props.onGetAllSettings({ owner: this.props.auth.user.unique_id });
    const { settings } = this.props;
    if (settings.error) {
      notify(this.t(settings.error.message));
    } else {
      let show_offers = this.getSettingsValueByCode(settings.items, "show_offers")
      let show_benefits = this.getSettingsValueByCode(settings.items, "show_benefits")
      let show_actions = this.getSettingsValueByCode(settings.items, "show_actions")
      let show_initiatives = this.getSettingsValueByCode(settings.items, "show_initiatives")
      let show_onboarding = this.getSettingsValueByCode(settings.items, "show_onboarding")
      //let show_prizes = this.getSettingsValueByCode(settings.items, "show_prizes")
      let show_prizes = true
      
      //show_offers = true
      //show_benefits = true
      //show_actions = true
      //show_initiatives = true
      
      let options = [...config.BUTTONBAR.UPPER]
      if(!show_offers) options = options.filter(o => o.route !== config.ROUTES.MAIN.OFFER)
      if(!show_benefits) options = options.filter(o => o.route !== config.ROUTES.MAIN.BENEFITS)
      if(!show_actions) options = options.filter(o => o.route !== config.ROUTES.MAIN.ACTIONS)
      if(!show_initiatives) options = options.filter(o => o.route !== config.ROUTES.MAIN.INITIATIVES)
      if(!show_prizes) options = options.filter(o => o.route !== config.ROUTES.MAIN.PRIZES)

      this.setState({ 
        settings: settings.items, 
        show_offers,
        show_benefits,
        show_actions,
        show_initiatives,
        show_prizes,
        show_onboarding,
        buttonBarUpper: options,
      });
      console.log("settings", settings)
      console.log("settings", show_offers)
      console.log("settings", options)
    }
  }


  render() {
    const { topNav, bottonNav, show_offers, show_benefits, show_actions, show_initiatives, show_prizes, buttonBarUpper} = this.state;
    return (
      <LayoutWithSidebar
        main={{ className: "pb-[48px]"}}
        header={{ 
          logo: true,
          //content: <NavButtonBar options={buttonBarUpper} active={topNav} onClick={this.onNavBarClick} />
        }}
 
        container={{ className: "px-0"}}
      >
        <ToastContainer/>
        <Feed/> 
        {/*{ topNav === config.ROUTES.MAIN.FEED && }
        { topNav === config.ROUTES.MAIN.OFFER && show_offers && <Offer/> }
        { (topNav === config.ROUTES.MAIN.INITIATIVES || 
          topNav === config.ROUTES.VOLUNTEERING.DETAIL ||
          topNav === config.ROUTES.RECYCLING.DETAIL ||
          topNav === config.ROUTES.DONATIONS.DETAIL ) && show_initiatives && <Initiatives/> }
        {(topNav === config.ROUTES.MAIN.ACTIONS || topNav === config.ROUTES.ACTIONS.SHARE) && show_actions && <Actions/> }
        { topNav === config.ROUTES.MAIN.BENEFITS && show_benefits && <Benefits/> }
        { topNav === config.ROUTES.MAIN.PRIZES && show_prizes && <Prizes/> }
        { topNav === config.ROUTES.MAIN.RANKING && <Ranking/> }*/}
        <BottomNav/>
      </LayoutWithSidebar>      
    ) 
  }
}


const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    settings: state.settings.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllSettings: (params) => dispatch(settingsActions.getAll(params)),
    onGetToken: () => dispatch(actions.getFromToken()),
    onGetSetting:(params)=>dispatch(settingsActions.getAll(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Home));

