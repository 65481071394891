import React from 'react';
import Icon from '../../libraries/icons';
import config from '../../config';

export default function IconPoints({ points, color, negative, legendClassName, numberClassName, ...rest })  {
  const leyend = rest.leyend ? rest.leyend : '';
  //const negative = parseInt(points) < 0
  const back_color = negative ? "bg-error" : "bg-success"
  const text_color = negative ? "text-error" : "text-success"
   
  const icon = (value) => {
    const cfg = config.VALUE_RANGE_ICONS.POINTS;
    value = value * -1 
    if (value <= cfg.LOW.value) {
      return cfg.LOW.icon;
    } else if (value > cfg.LOW.value && value <= cfg.MEDIUM.value) {
      return cfg.MEDIUM.icon;
    } else {
      return cfg.HIGH.icon;
    }
  }

  return (
    <div className={
      "bg-success bg-opacity-10 flex gap-2 rounded items-center py-1 px-2  "
      + (rest.className ? rest.className : `rounded ${back_color} flex gap-1`)
    }>
      <Icon color={color} className={`${text_color} ` + (rest.iconClassName ? rest.iconClassName : "w-6")} name={icon(points)} /> 
      <span className={`${numberClassName ?  numberClassName : ''} `}>{negative ? "-" : "+"}{points}</span>
      <span className={`${legendClassName ?  legendClassName : ''} `}>{leyend}</span>

    </div>
  );
}


