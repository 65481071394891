import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'

import userActions from '../../context/users/actions';
import companyActions from '../../context/companies/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { composeValidators, validateEmail, validateIsfilled, getTimeZone, formatOnlyNumberInput } from '../../libraries/utils';

import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import SelectInput from '../../components/forms/SelectInput';
import CheckboxInput from '../../components/forms/CheckboxInput';

class SignupInvitation extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      signupData: {},
    }
  }
  
  componentDidMount() {
    console.log('VERIFY PAGE', this.props);
    const params = this.props.match.params;
    if (params.hash) {
      this.getData(params.hash)
    } else {
      history.push(config.ROUTES.LOGIN);
    }
  }

  checkHash = async (params) => {
    await this.props.onGetFromHash(params)
    const user = this.props.user;
    if (user.error) { 
      history.push(config.ROUTES.LOGIN);
    } else {
      console.log("user.item", user.item)
      let signupData = {
        email: user.item.email,
        phone: user.item.phone,
        first_name: user.item.json_data?.first_name,
        last_name: user.item.json_data?.last_name,
        identification_type: user.item.json_data?.identification_type,
        identification: user.item.json_data?.identification,
        //FIX ME: location
      }
      this.setState({ user: this.props.user.item, signupData });
    }
  }

  getCompany = async (id) => {
    await this.props.onGetCompany(id)
    const company = this.props.company;
    if (company.error) { 
      history.push(config.ROUTES.LOGIN);
    } else {
      this.setState({ company: this.props.company.item });
      console.log("company", this.props.company.item)
    }
  }

  //get data
  getData = async (hash) => {
    this.setState({loading:true})
    await this.checkHash({hash})
    await this.getCompany(this.state.user.unique_id)
    this.setState({loading:false})
  }
  
  onSubmit = async (values) => {
    console.log('SUBMIT', this.state);

    //const user = this.state.user    
    const data = {...values, id:this.state.user.id, unique_id: this.state.company.id, user_type: config.USER_TYPE.REGULAR, role: ""}
    delete data.terms
    data.json_data = {
      identification: data.identification,
      identification_type: data.identification_type,
    }
    delete data.identification
    delete data.identification_type

    await this.props.onUpdate(data)
    if (this.props.user.error) {
      //this.setState({ submitting: false });
      notify(this.t(this.props.user.error.message));
    } else {
      history.push(config.ROUTES.USER.PASSWORD.replace(':hash', this.props.user.item.hash));
    }
  }

  onTerms = () => {
    history.push(config.ROUTES.TERMS);
  }

  onLogin = () => {
    history.push(config.ROUTES.LOGIN);
  }

  render() {
    const required = value => (validateIsfilled(value) ? undefined : this.t("This field is required"))
    const email = value => (validateEmail(value) ? undefined : this.t("Not a valid email"))
    const dnilength = value => (value.length >= 7 && value.length <= 8 ? undefined : this.t("EL documento debe tener 7 u 8 numeros"))
    const dni_types = [{value: "DNI", label: "DNI"},{value: "LE", label: "LE"},{value: "LC", label: "LC"},{value: "DNI Internacional", label: "DNI Internacional"}]

    return (
      <LayoutSmall
        main={{ className: "bg-primary"}}
        header={{ logo: true }}
      >
        <ToastContainer/>
        <h2 className="text-primary-content text-center mb-5 mt-5">{this.t("Register")}</h2>
        {this.state.company && <>
            <div className="rounded-full bg-white bg-opacity-30 overflow-hidden flex items-center text-center m-auto mb-3 w-16 h-16"><img src={this.state.company.json_data?.image} alt="" width="70"/></div>
            <p className="text-secondary text-center mb-5 mt-5">Registrarse dentro del grupo {this.state.company.name}</p>
          </>}

        <Form
          initialValues={this.state.signupData || {}}
          onSubmit={this.onSubmit}
        >
          {({ handleSubmit, form, submitting, pristine, values }) => (
          <>
            <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto px-4">
              <div className="w-full mb-3 md:mb-0">
                <Field name="first_name" component={TextInput} placeholder={this.t("First name")} validate={required}/>
              </div>
              <div className="w-full mb-3 md:mb-0">
                <Field name="last_name" component={TextInput} placeholder={this.t("Last name")} validate={required}/>
              </div>
              <div className="w-full mb-3 md:mb-0">
                <Field name="email" component={TextInput} placeholder={this.t("Email")} validate={composeValidators(required, email)}/>
              </div>
              <div className="w-full mb-3 md:mb-0">
                <Field name="phone" component={TextInput} placeholder={this.t("Phone number")} validate={required}/>
              </div>
             
              {config.SIGNUP_USER_FIELDS.LOCATION && <div className="w-full mb-3 md:mb-0">
                <Field name="location" component={TextInput} placeholder={this.t("Direccion")} validate={required}/>
              </div>}
              {config.SIGNUP_USER_FIELDS.DNI && 
               <div className="flex w-full mb-3 md:mb-0">
                <div className="w-1/3 mr-3">
                  <Field name="identification_type"  component={SelectInput} placeholder={this.t("Tipo de Doc")}
                    options={dni_types} empty={this.t("Seleccione")} validate={required} selectClassName="w-full"
                  />
                </div>
                <div className="w-2/3">
                  <Field name="identification" parse={v => formatOnlyNumberInput(v,8)} component={TextInput} placeholder={this.t("Nro de Documento")} validate={composeValidators(required,dnilength)}/>
                </div>
              </div>}

              <div className="w-full mb-3 md:mb-0">
                <Field name="terms" component={CheckboxInput} label={this.t("I accept terms and conditions")}
                  validate={required}
                  checkboxClassName="checkbox-secondary"
                  type="checkbox"
                />
              </div>
              <div className="w-full">
                <Button
                  className="btn-secondary btn-block"
                  title={this.t("Next")}
                  onClick={handleSubmit}
                  disabled={this.state.submitting || pristine}
                />
              </div>
            </form>
          </>
        )}
        </Form>
        <div className="w-full px-3 mt-5">
          <Button
            className="btn-link btn-block underline text-primary-content"
            title={this.t("Terms and conditions")}
            onClick={this.onTerms}
          />
        </div>
        <div className="mt-2 p-2 flex justify-center items-center">
          <p className="text-primary-content text-center mr-1">{this.t("Has an account?")}</p>
          <Button
              className="btn-link mb-0 underline text-primary-content"
              title={this.t("Login with your user")}
              onClick={this.onLogin}
            />
        </div>
      </LayoutSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    user: state.users.current,  
    company: state.companies.current,  
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetFromHash: (params) => dispatch(userActions.getFromHash(params)),
    onUpdate: (values) => dispatch(userActions.saveOrUpdate(values, 'register')),
    onGetCompany: (params) => dispatch(companyActions.get(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SignupInvitation));
