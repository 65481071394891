import React from 'react';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import { Routes, history } from './routes';

import Error from './pages/commons/Error';
import Splash from './components/commons/Splash';
import Slider from './pages/commons/Slider';

import oauth from './context/oauth/actions'
import config from './config';
import { getTimeZone, getClientIp, getLatLng } from './libraries/utils';
import internalsActions from './context/internals/actions';

import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { ToastContainer } from 'react-toastify';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      splash: true,
      slider: true,
    }
    this.currentPathname =  null;
    this.currentSearch =  null;
    this.currentState =  null;
  }

  componentDidMount() {
    console.log('STARTING_APP', process.env.NODE_ENV);
    this.props.onOauth();
    const stored = localStorage.getItem(config.SLIDER_SCREEN);
    this.setState({ slider: stored ? JSON.parse(stored) : config.SLIDER.SHOW });
    console.log('SLIDER', stored);

    // Configure history so the user can't do back in the browser
    history.listen((newLocation, action) => {
      // console.warn('HISTORY LISTEN', newLocation, action);
      // console.warn('CURRENT LOCATION', this.currentPathname, this.currentSearch, this.currentState);
      if (action === "PUSH") {
        if (
          newLocation.pathname !== this.currentPathname ||
          newLocation.search !== this.currentSearch
        ) {
          //let fromPathApp = this.currentPathname
          // Save new location
          this.currentPathname = newLocation.pathname;
          this.currentSearch = newLocation.search;
          this.currentState = newLocation.state;
          // Clone location object and push it to history
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search,
            state: {...newLocation.state, }, //fromPathApp
          });
        }
      } else {
        // Send user back if they try to navigate back
        history.go(1);
      }
    });
    // Get information about the device
    //this.getClientInfo();
  }

  getClientInfo = async () => {
    // const geonames = Geonames({
    //   username: 'lavadoelex',
    //   lan: 'es',
    //   encoding: 'JSON'
    // });
    
    //const ip = await getClientIp();
//    //console.warn('CLIENT IP', ip);
//
    //await this.props.onGetClientIpInfo({ ip });
    //const { data } = this.props.internals;
    //console.log('INTERNALS', this.props.internals, data);
    //if (data && data.error) { 
    //  console.error('ERROR', this.t(data.error.message));
    //} else {
    //  console.warn('USER IP INFO', data && data.geoip);
    //}    
    //console.warn('TIME ZONE', getTimeZone(), data && data.geoip.timezone );

    //getLatLng((latlng) => {
    //  console.warn('LAT & LNG', latlng);
    //});

  }


  render() {
    const { loading, error, granted } = this.props.oauth;
    if (loading || this.state.splash) { 
      setTimeout(() => {
        this.setState({ splash: false });
      }, 100);
      return (<Splash />);
    } else if (this.state.slider) {
      return (<Slider close={(slider) => this.setState({ slider }) } />);
    }
    if (error) return ( 
      <Error title="API ERROR" headline="Access not granted!" button="Close"/>
    );

    if (granted && !this.state.splash) return ( 
      <Router history={history}>
        <Routes/>
      </Router>  
    );

    return (<Splash />);
  }
}

const mapStateToProps = state => {
  return {
    oauth: state.oauth,
    internals: state.internals,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onOauth: () => dispatch(oauth.getFromToken()),
    onGetClientIpInfo: (params) => dispatch(internalsActions.getClientIpInfo(params)),
  };
};

let AppComponent =  connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

const queryClient = new QueryClient()

export default function QueryWrapper() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppComponent />
      <ToastContainer/>
    </QueryClientProvider>
  )
}