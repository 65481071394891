import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {  withRouter } from 'react-router-dom';
import challengesActions from '../../context/challenges/actions'

import ChallengesWrapper from './ChallengesWrapper'
import Loader from '../../components/commons/Loader';
import UserProfileImage from '../../components/commons/UserProfileImage';

import { ReactComponent as Trofeo } from '../../assets/icons/customs/trofeo.svg';
import { ReactComponent as TrofeoUno } from '../../assets/icons/customs/trofeo_uno.svg';


class Leaderboard extends React.Component {
  constructor(props) {
    super(props)
    this.t = this.props.t;
    this.state = {
      loading: true,
      leaderboard: [],
    }
  }
  async componentDidMount() {
    const { id } = this.props.match.params
    await this.props.getLeaderboard(id)

    const challenge = this.props.challenge.current
    // const conditionType = challenge.item?.condition_type;
    if(challenge?.leaderboard?.leaderboard){
      var leaderboard = [...challenge?.leaderboard?.leaderboard]
    }

    this.setState({leaderboard, loading: false })
  }

  render() {
    const { loading, leaderboard } = this.state
    const condition_type = this.props.challenge.current.item?.condition_type

    return (
      <ChallengesWrapper>
        {loading && <Loader />}
        {!loading && leaderboard && !leaderboard.length && <p>Este desafío no tiene actividad todavía.</p>}
        {!loading && leaderboard && leaderboard.length > 0 && (
          <div className="flex flex-col gap-3">
            <div className="w-full px-3 pb-1 flex gap-2 items-center rounded-lg bg-[#297F87DD]">
              <div className="w-8 self-start flex-shrink-0">
                <TrofeoUno />
              </div>
              <div className="w-full flex items-center justify-between flex-shrink">
                <div className="pt-1 flex gap-2 items-center">
                  <UserProfileImage picture={{className:'h-8 w-8 !m-0'}} data={leaderboard[0].user} />
                  <p className="text-white">{leaderboard[0].user?.name}</p>
                </div>
                <div className="flex gap-3">
                  <Trofeo className="w-5" />
                  <span className="text-[#FFC93F] font-bold">
                    {condition_type !== "actions" &&  `${leaderboard[0].points}pts`}
                    {condition_type === "actions" &&  `${leaderboard[0].number_of_actions} ${leaderboard[0].number_of_actions > 1 ? 'acciones' : 'acción'}`}
                  </span>
                </div>
              </div>
            </div>

            {leaderboard.map((u, i) => {
              if (i === 0) return null
              return (
                <div key={u.user.id} className="w-full px-3 pb-1 flex gap-2 items-center rounded-lg bg-white">
                  <div className="w-8 p-3 font-bold text-black flex-shrink-0">
                    {i + 1}
                  </div>
                  <div className="w-full flex items-center justify-between flex-shrink">
                    <div className="pt-1 flex gap-2 items-center">
                      <UserProfileImage picture={{className:'h-7 w-7 !m-0'}} data={u.user} />
                      <p>{u.user?.name}</p>
                    </div>
                    <div className="flex gap-3">
                      <span className="text-[#FFC93F] font-bold">
                        {condition_type !== "actions" &&  `${u.points}pts`}
                        {condition_type === "actions" &&  `${u.number_of_actions} ${u.number_of_actions > 1 ? 'acciones' : 'acción'}`}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </ChallengesWrapper>
    )
  }

}



const mapStateToProps = state => {
  return {
    user: state.users.auth.user,
    challenge: state.challenges,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLeaderboard: (id) => dispatch(challengesActions.getLeaderboard(id)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Leaderboard)))
