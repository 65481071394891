import types from './types';
import challenges from './services';

const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_CURRENT });
  }
}

const getAll = (params) => {

  function request() { return { type: types.GETALL_REQUEST } }
  function success(payload) { return { type: types.GETALL_SUCCESS, payload } }
  function failure(error) { return { type: types.GETALL_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request());
    const response = await challenges.getAll(params, getState().users.auth.token)
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };  

}

const get = (id) => {

  const request = (id) => { return { type: types.GET_REQUEST, id } };
  const success = (payload) => { return { type: types.GET_SUCCESS, payload } }
  const failure = (error) => { return { type: types.GET_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await challenges.get(id, getState().users.auth.token)
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };

}

const saveOrUpdate = (benefit) => {

  function request(payload) { return { type: types.SAVE_REQUEST, payload } }
  function success(payload) { return { type: types.SAVE_SUCCESS, payload } }
  function failure(error) { return { type: types.SAVE_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(benefit));

    let response;
    if (benefit.id) {  // editing a existing record
      response = await challenges.update(benefit, getState().users.auth.token)
    } else {
      response = await challenges.save(benefit, getState().users.auth.token)
    }
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };

}

const del = (id) => {
 
  function request(id) { return { type: types.DELETE_REQUEST, id } }
  function success(id) { return { type: types.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: types.DELETE_FAILURE, id, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await challenges.del(id, getState().users.auth.token)
    if (response.success) {
      dispatch(success(id));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };
}


// especiales
const getActivity = (id) => {

  const request = (id) => { return { type:      types.GET_ACTIVITY_REQUEST, id } };
  const success = (payload) => { return { type: types.GET_ACTIVITY_SUCCESS, payload } }
  const failure = (error) => { return { type:   types.GET_ACTIVITY_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await challenges.getActivity(id, getState().users.auth.token)
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };
}

const join = (data) => {
  const request = (data) => { return { type: types.JOIN_SUCCESS, id: data } };
  const success = (payload) => { return { type: types.JOIN_SUCCESS, payload } }
  const failure = (error) => { return { type:   types.JOIN_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(data));
    let response = await challenges.join(data, getState().users.auth.token)
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };
}

const leave = (data) => {
  const request = (data) => { return { type: types.LEAVE_SUCCESS, id: data } };
  const success = (payload) => { return { type: types.LEAVE_SUCCESS, payload } }
  const failure = (error) => { return { type:   types.LEAVE_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(data));
    let response = await challenges.leave(data, getState().users.auth.token)
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };
}

const getParticipants = (id) => {

  const request = (id) => { return { type:      types.GET_PARTICIPANTS_REQUEST, id } };
  const success = (payload) => { return { type: types.GET_PARTICIPANTS_SUCCESS, payload } }
  const failure = (error) => { return { type:   types.GET_PARTICIPANTS_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await challenges.getParticipants(id, getState().users.auth.token)
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };
}


const getLeaderboard = (id) => {

  const request = (id) => { return { type:      types.GET_LEADERBOARD_REQUEST, id } };
  const success = (payload) => { return { type: types.GET_LEADERBOARD_SUCCESS, payload } }
  const failure = (error) => { return { type:   types.GET_LEADERBOARD_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await challenges.getLeaderboard(id, getState().users.auth.token)
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };
}

const getAwards = (id) => {

  const request = (id) => { return { type:      types.GET_AWARDS_REQUEST, id } };
  const success = (payload) => { return { type: types.GET_AWARDS_SUCCESS, payload } }
  const failure = (error) => { return { type:   types.GET_AWARDS_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await challenges.getAwards(id, getState().users.auth.token)
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };
}

const getActivities = (id) => {

  const request = (id) => { return { type:      types.GET_ACTIVITIES_REQUEST, id } };
  const success = (payload) => { return { type: types.GET_ACTIVITIES_SUCCESS, payload } }
  const failure = (error) => { return { type:   types.GET_ACTIVITIES_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await challenges.getActivities(id, getState().users.auth.token)
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };
}

const actions = {
  clearCurrent,
  get,
  getAll,
  saveOrUpdate,
  del,
  getActivity,
  join,
  getParticipants,
  getLeaderboard,
  getAwards,
  leave,
  getActivities,
};

export default actions