import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';

import config from '../../config';

import VolunteeringCard from '../../components/customs/VolunteeringCard';
import BottomNav from '../../components/commons/BottomNav';
import LayoutSmall from '../../components/layout/LayoutSmall';
import volunteeringAction from "../../context/volunteering/actions";


class Volunteering extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;    
    this.state = {
      volunteering_jobs: []   
    }
  }
  
  componentDidMount() {
    console.log('INICIATIVAS - VOLUNTARIADOS');
    this.getVolunteering();
  }

  getVolunteering = async () => {
    await this.props.onGetAllVolunteering();
    const { volunteering_list } = this.props;
    if (volunteering_list.error) {
      notify(this.t(volunteering_list.error.message));
    } else {
      this.setState({ volunteering_list: volunteering_list.items});
    }
  };

  goBack = () => {
    history.push({ pathname: config.ROUTES.HOME, state: { from: config.ROUTES.MAIN.INITIATIVES }});
  }
 
  render() {
    const { volunteering_list } = this.state;
    return (
      <LayoutSmall
        main={{ className: ""}}
        header={{ 
          title: "Ayuda comunitaria",
          className: "bg-white text-primary flex justify-between p-3",
          left: { icon: 'arrow_left', action: this.goBack }
        }}
        container={{ className: "px-0"}}
      >    
        <section className="p-4">
          {volunteering_list && (volunteering_list.length === 0) && <small className="mb-1 text-gray-500">{this.t("No hay voluntariados disponibles")}</small>}
          {volunteering_list && volunteering_list.map((volunt, index) => {
              return (
                <VolunteeringCard
                  className="mb-3"
                  key={index}
                  data={volunt}
                  route={{ pathname: config.ROUTES.VOLUNTEERING.DETAIL.replace(':id', volunt.id), state: { from: config.ROUTES.VOLUNTEERING.LIST }}}
                />
              )
            })
          }
        </section>
        <BottomNav/>
      </LayoutSmall>
    ) 
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    volunteering_list: state.volunteering.list
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllVolunteering: (params) => dispatch(volunteeringAction.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Volunteering));
