import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';

import config from '../../config';

import RecyclingCard from '../../components/customs/RecyclingCard';
import BottomNav from '../../components/commons/BottomNav';
import LayoutSmall from '../../components/layout/LayoutSmall';
import recyclingAction from "../../context/recycling/actions";


class Recycling extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;    
    this.state = {
      recycled_products: [],
    }
  }
  
  componentDidMount() {
    console.log('INICIATIVAS - RECICLADOS');
    this.getRecycling();
  }

  getRecycling = async () => {
    await this.props.onGetAllRecycling();
    const { recycled_products } = this.props;
    if (recycled_products.error) {
      notify(this.t(recycled_products.error.message));
    } else {
      this.setState({ recycled_products: recycled_products.items});
    }
  };

  goBack = () => {
    history.push({ pathname: config.ROUTES.HOME, state: { from: config.ROUTES.MAIN.INITIATIVES }});
  }
 
  render() {
    const { recycled_products } = this.state;
    return (
      <LayoutSmall
        main={{ className: ""}}
        header={{ 
          title: "Reciclados",
          className: "bg-white text-primary flex justify-between p-3",
          left: { icon: 'arrow_left', action: this.goBack }
        }}
        container={{ className: "px-0"}}
      >    
        <section className="p-4">
          {recycled_products && (recycled_products.length === 0) && <small className="mb-1 text-gray-500">{this.t("No hay reciclajes disponibles")}</small>}
          {recycled_products && recycled_products.map((recycle, index) => {
              return (
                <RecyclingCard
                  className="mb-3"
                  key={index}
                  data={recycle}
                  route={{ pathname: config.ROUTES.RECYCLING.DETAIL.replace(':id', recycle.id), state: { from: config.ROUTES.RECYCLING.LIST }}}
                />
              )
            })
          }
        </section>
        <BottomNav/>
      </LayoutSmall>
    ) 
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    recycled_products:state.recycling.list
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllRecycling: (params) => dispatch(recyclingAction.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Recycling));


