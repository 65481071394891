
import React from 'react';
import { ToastContainer, notify } from '../../libraries/notifications';

import Sidebar from '../../components/layout/Sidebar';
import ListProduct from '../../components/customs/ActivityCard';
import Icon from '../../libraries/icons';
import { withTranslation } from 'react-i18next';


class Favourites extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.from = this.props.location.state?.from || { pathname: '/' };
    this.state = {
     favorites : []
    }
  }
  
  componentDidMount() {
    console.log('Favourites');
  }

 
  render() {
    const {favorites} = this.state
    return (
      <main className="light-primary-bg w-full min-h-full">
        <Sidebar 
          header={{ 
            title: "Favoritos",
          }}
        />
        <ToastContainer/>
        <div className="container bg-white shadow">
        {this.state.favorites && favorites.length === 0 && (
              <div className="text-center p-10 text-gray-400">
                <Icon className="h-14 w-14 mx-auto mb-2" name="loans" />
                <p className="mb-1">{this.t("No se registran favoritos")}</p>
              </div>
            )}
        {
              this.state.favorites.map((p, index) => {
                return (
                  <ListProduct 
                    key={'img' + index}
                    url={p.url}
                    title={p.title}
                    points={p.points}
                    name={p.name}
                    distance={p.distance}
                  />
                )
              })
            }
        </div>

      </main>
    ) 
  }
}

export default (withTranslation()(Favourites));
