export default {
  CLEAR_CURRENT: 'FAVORS_CLEAR_CURRENT',
  GET_REQUEST: 'FAVORS_GET_REQUEST',
  GET_SUCCESS: 'FAVORS_GET_SUCCESS',
  GET_FAILURE: 'FAVORS_GET_FAILURE',

  SAVE_REQUEST: 'FAVORS_SAVE_REQUEST',
  SAVE_SUCCESS: 'FAVORS_SAVE_SUCCESS',
  SAVE_FAILURE: 'FAVORS_SAVE_FAILURE',

  GETALL_REQUEST: 'FAVORS_GETALL_REQUEST',
  GETALL_SUCCESS: 'FAVORS_GETALL_SUCCESS',
  GETALL_FAILURE: 'FAVORS_GETALL_FAILURE',

  DELETE_REQUEST: 'FAVORS_DELETE_REQUEST',
  DELETE_SUCCESS: 'FAVORS_DELETE_SUCCESS',
  DELETE_FAILURE: 'FAVORS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'FAVORS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'FAVORS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'FAVORS_UPDATE_FAILURE'    

};