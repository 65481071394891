import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import './localization/i18n';
import Loader from './components/commons/Loader';
import App from './App';
// import * as serviceWorker from './serviceWorker';

import './assets/css/tailwind.css';
import './assets/css/App.css';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<Loader size="full" containerClassName="bg-primary" />}>
      <Provider store={store}>
        <App />
      </Provider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);