import React from 'react';
import { useTranslation } from 'react-i18next';
import { getObjectWithJsonDataToFormValues, getRewardFromLevel  } from '../../libraries/utils';
import { zonedTimeToUtc } from 'date-fns-tz';
import config from '../../config';
import { history } from '../../routes';
import BusinessNameImage from './BusinessNameImage';

export default function BenefitHomeCard({benefit, userLevel, ...rest}){
  const gotoBenefit = () => {
    history.push({ pathname: config.ROUTES.BENEFITS.DETAIL.replace(':id', benefit.id), state: { fromPath: config.ROUTES.MAIN.PRIZES }})
  }
  const { url, name, picture, values, pack} = getObjectWithJsonDataToFormValues(
    benefit,
    ['id', 'name', 'picture', 'values', 'pack']
  );
  
  const user_current_level_benefit = getRewardFromLevel(userLevel, {values})

  const createdAtDate = new Date(benefit.created_at);
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
  
  const isNew = createdAtDate >= oneWeekAgo

  return (
     <div className="flex flex-row card white overflow-hidden" onClick={gotoBenefit} key={rest.key}>
      {isNew && <div className="badge absolute right-4 bottom-2 badge-sm bg-error text-white border-none">Nuevo</div>}
        <div className="w-48 h-24 overflow-hidden flex justify-center items-center">
        {(user_current_level_benefit &&
            <div className="badge badge-primary absolute left-1 top-1">
              <p className="font-black text-xs">{user_current_level_benefit}%</p>
            </div>
            )}
          <img className="w-full object-cover" alt="" src={picture}/>
        </div>
          
        <div className="w-full py-2 px-4">
          <div>
            <div className="">
              <h5 className="ellipsis-3lines ...">{name}</h5>
              {pack && <div className="badge badge-ghost text-xs mt-2">{pack}</div>}
            </div>
            {rest.business && <BusinessNameImage business={rest.business} />}
          </div>
          {/* <div className="flex justify-between items-center">
            <div className="flex">
              <IconCoins className="mr-4" coins="2"/>
            </div>
            {service.distance && <small className="text-gray-500">{service.distance} km</small>}
          </div> */}
        </div>
      </div>
    )
}