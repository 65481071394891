import config from '../../config';
import { request } from '../../libraries/request'

const get = (id, token) => {
  console.log('SERVICES::LEVELS::get', id, token);
  return request({
    url: config.BASE_API_URL + config.API.LEVELS,
    accessToken: token,
    // data: {},
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })

}

const services = {
  get,
};

export default services;