import config from '../../config';
import { request } from '../../libraries/request';

const getAll = (params = {}, token) => {
  if (params && params.page_num && config.QUERIES_PAGE_SIZE) {
    params.page_size = config.QUERIES_PAGE_SIZE;
  }
  if (!params.order_by) {
    // params.order_by = 'created_at';
    params.order_direction = 'DESC';
  }

  return request({
    url: config.BASE_API_URL + config.API.CHALLENGES,
    accessToken: token,
    data: params
  }).then(response => {
    if (response.success) {
      response.data.query.page_size = config.QUERIES_PAGE_SIZE;

    }
    console.log('::::::REDUCER::CHALLENGES::getAll::', response);
    return response;
  });
};

const save = (data, token) => {
  return request({
    url: config.BASE_API_URL + config.API.CHALLENGES,
    accessToken: token,
    method: 'POST',
    data
  }).then(response => {
    if (response.success) {
    }
    return response;
  });
};

const update = (data, token) => {
  return request({
    url: config.BASE_API_URL + config.API.CHALLENGE.replace('{id}', data.challenge_id),
    accessToken: token,
    method: 'PATCH',
    data: {
      participant: data.participant,
    }
  }).then(response => {
    if (response.success) {
    }
    return response;
  });
};

const get = (id, token) => {
  return request({
    url: config.BASE_API_URL + config.API.CHALLENGE.replace('{id}', id),
    accessToken: token
    // data: {},
  }).then(response => {

    return response;
  });
};

const del = (id, token) => {
  return request({
    url: config.BASE_API_URL + config.API.CHALLENGE.replace('{id}', id),
    accessToken: token,
    method: 'DELETE'
  }).then(response => {
    if (response.success) {
    }
    return response;
  });
};

const getActivity = (id, token) => {
  return request({
    url: config.BASE_API_URL + config.API.CHALLENGE_ACTIVITY.replace('{id}', id),
    accessToken: token
    // data: {},
  }).then(response => {

    return response;
  });
};

const getParticipants = (id, token) => {
  return request({
    url: config.BASE_API_URL + config.API.CHALLENGE_PARTICIPANTS.replace('{id}', id),
    accessToken: token
    // data: {},
  }).then(response => {

    return response;
  });
};

const join = (data, token) => {
  return request({
    url: config.BASE_API_URL + config.API.CHALLENGE_PARTICIPANTS.replace('{id}', data.challenge_id),
    accessToken: token,
    method: 'POST',
    data
  }).then(response => {
    if (response.success) {
    }
    return response;
  });
};

const leave = (data, token) => {
  return request({
    url: config.BASE_API_URL + config.API.CHALLENGE_PARTICIPANTS.replace('{id}', data.challenge_id),
    accessToken: token,
    method: 'DELETE',
    data
  }).then(response => {
    if (response.success) {
    }
    return response;
  });
};

//GET_LEADERBOARD_REQUEST
//GET_LEADERBOARD_SUCCESS
//GET_LEADERBOARD_FAILURE
const getLeaderboard = (id, token) => {
  return request({
    url: config.BASE_API_URL + config.API.CHALLENGE_LEADERBOARD.replace('{id}', id),
    accessToken: token
    // data: {},
  }).then(response => {

    return response;
  });
}

const getAwards = (id, token) => {
  return request({
    url: config.BASE_API_URL + config.API.CHALLENGE_AWARDS.replace('{id}', id),
    accessToken: token
    // data: {},
  }).then(response => {

    return response;
  });
}

const getActivities = (id, token) => {
  return request({
    url: config.BASE_API_URL + config.API.CHALLENGE_ACTIVITIES.replace('{id}', id),
    accessToken: token
    // data: {},
  }).then(response => {

    return response;
  });
}

const services = {
  getAll,
  save,
  get,
  del,
  update,
  getActivity,
  join,
  getParticipants,
  getLeaderboard,
  getAwards,
  leave,
  getActivities,
};
export default services;
