const api = {
  LOCALSTORAGE_APP_TOKEN: 'LOCALSTORAGE_APP_TOKEN',
  LOCALSTORAGE_USER_TOKEN: 'REACT_USER_TOKEN_AUTH',

  QUERIES_PAGE_SIZE: 10,
  API: {
    OAUTH: '/oauth/token',
    INTERNAL: {
      INFO: {
        IP: '/internal/ip/{ip}',
      },
    },
    FILES: '/files/upload',
    SETTINGS: '/settings',
    TOKEN: '/users/token',
    LOGIN: '/users/login',
    SOCIAL_LOGIN: '/users/login/social',
    REGISTER: '/users/register',
    FORGOT: '/users/forgot',
    VERIFY: '/users/verify',
    VERIFY_RETRY: '/users/verify/retry',
    USER: '/users/{id}',
    USERS: '/users',
    HASH: '/users/hash/{hash}',
    SOCIAL: '/users/social',
    POINTS: '/users/{id}/rewards',
    LEVELS: '/users/rewards/levels',

    PRODUCT: '/products/{id}',
    PRODUCTS: '/products',
    CATEGORY: '/categories/{id}',
    CATEGORY_PATH: '/categories/{id}/path',
    CATEGORIES: '/categories',

    INITIATIVE: '/initiatives/{id}',
    INITIATIVES: '/initiatives',

    ASSISTANT: '/initiatives/{initiativeId}/assistants/{id}',
    ASSISTANTS: '/initiatives/{initiativeId}/assistants',
    

    ACTION: '/actions/{id}',
    ACTIONS: '/actions',


    LOCATION: '/locations/{id}',
    LOCATIONS: '/locations',
    TRANSACTION: '/transactions/{id}',
    TRANSACTIONS: '/transactions',

    MESSAGE:'/messages/{id}',
    MESSAGES:'/messages',
    
    USERS_RANKING: '/users/ranking',
    USERS_BALANCE: '/users/balance',

    BENEFIT:'/benefits/{id}',
    BENEFITS:'/benefits',
    BUSINESS: '/businesses/{id}',
    BUSINESSES: '/businesses',
    COMPANY: '/companies/{id}',
    COMPANIES: '/companies',
    
    CHALLENGE: '/challenges/{id}',
    CHALLENGES: '/challenges',
    CHALLENGE_PARTICIPANTS: '/challenges/{id}/participants',
    CHALLENGE_ACTIVITY: '/challenges/{id}/activity',
    CHALLENGE_AWARDS: '/challenges/{id}/benefits',
    CHALLENGE_LEADERBOARD: '/challenges/{id}/leaderboard',
    CHALLENGE_ACTIVITIES: '/challenges/{id}/activities',
    
    SEND_MAIL: '/internal/mailer/send',

    INVITES: '/users/{id}/invites',
  },
};
export default api;