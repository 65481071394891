import React from "react";
import config from '../../config';
import { Link } from "react-router-dom";
import Icon from '../../libraries/icons';
import { history } from '../../routes';

const NewProductModal = ({ t, open, setOpen }) => {
  //FIXME: pasar este estado a config/customs.js
  const [offers, setOffers] = React.useState([
    {
      title: "Product",
      key: "products",
      route: {
        pathname: config.ROUTES.CATEGORY.replace(":type", "offers").replace(
          ":sub_type",
          config.TYPES.OFFERS.PRODUCTS
        ),
        state: {
          fromPath: config.ROUTES.HOME_OPTIONS.OFFERS,
        }
      },
      icon: "tag",
    },
    {
      title: "Favor",
      key: "favors",
      route: {
        pathname: config.ROUTES.CATEGORY.replace(":type", "offers").replace(
          ":sub_type",
          config.TYPES.OFFERS.FAVORS
        ),
        fromPath: config.ROUTES.HOME_OPTIONS.FAVORS,
      }, 
      icon: "favor",
    },
    { title: "Petición", key: "requests", route: null, icon: "megaphone" },
  ]);

  const [offersInitialState, setOffersInitialState] = React.useState(offers)
  const [modalTittle, setmodalTittle] = React.useState("Agregar nuevo");

  const checkClickOutside = (e) => {
    if (!document.getElementById("modalbox_newproducts").contains(e.target)) {
      setOpen(false);
      setOffers(offersInitialState);
    }
  };

  return (
    <>
      {/* The button to open modal */}
      {/*<label htmlFor="modal-new-product" className="btn">open modal</label>*/}

      {/* Put this part before </body> tag */}
      <input
        type="checkbox"
        id="modal-new-product"
        className="modal-toggle"
        checked={open}
      />
      <div
        className="modal modal-bottom sm:modal-middle"
        onClick={checkClickOutside}
      >
        <div
          className="modal-box bg-base-200 relative pt-8"
          id="modalbox_newproducts"
        >
          <div className="swap-modal-bottom"></div>
          <div className="flex gap-3 justify-between mb-4">
            <div className="flex gap-3 items-center">
              {/*<Icon className="w-6" name='arrow_left' />*/}
              <h3>{modalTittle}</h3>
            </div>
          </div>

          {offers &&
            offers.map((offer, index) => {
              //console.log('offers', offer)
              let route = {
                pathname: offer.route?.pathname,
                state: {
                  fromPath: config.ROUTES.USER.INVENTORY,
                },
              };

              if (offer?.key === "requests") {
                route = null
              }



              let changeModal = () => {
                setOffers([
                  {
                    title: "Pedir producto",
                    route: {
                      pathname: config.ROUTES.CATEGORY.replace(":type", "requests").replace(
                        ":sub_type",
                        config.TYPES.OFFERS.PRODUCTS
                      ),
                      fromPath: config.ROUTES.HOME_OPTIONS.PRODUCTS,
                    },
                    icon: "tag",
                  },
                  {
                    title: "Pedir favor",
                    route: {
                      pathname: config.ROUTES.CATEGORY.replace(":type", "requests").replace(
                        ":sub_type",
                        config.TYPES.OFFERS.FAVORS
                      ),
                      fromPath: config.ROUTES.HOME_OPTIONS.PRODUCTS,
                    },
                    icon: "favor",
                  },
                ]);
                setmodalTittle("Agregar Petición");
              };
              
              return (
                <div
                  //to={route}
                  key={index}
                  className="card white p-3 mb-3"
                  onClick={route ? () => history.push(route) : changeModal}
                >
                  <div className="flex justify-between items-center">
                    <div className="flex gap-3 items-center">
                      <Icon
                        className="h-10 inline-block text-primary"
                        name={offer.icon}
                      />
                      <p className="font-bold">{t(offer.title)}</p>
                    </div>
                    <Icon className="w-6" name="arrow_right" />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default NewProductModal;
