import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import actions from "../../context/actions/actions";
import { notify } from "../../libraries/notifications";
import Button from "../../components/commons/Button";
import { history } from "../../routes";
import Loader from "../../components/commons/Loader";
import config from "../../config/";
import ChallengesCard from "../../components/customs/ChallengesCard";
import imgProgress from "../../assets/img/img-progress.png";
import { CardOnboarding } from "./FlowStep1";
import { saveLocalStorage } from "./useSaveGetLocalStoragel";

class FlowStep5 extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.id = this.props.match.params.id;
    this.idFlow = this.props.match.params.idFlow;
    this.currentUrl = this.props.location?.pathname;
    this.state = {
      challenge: null,
    };
  }


  getFlow() {
    const flow = config.ACTION_BY_DEFAULT.find(f => f.id == this.idFlow)
    if (flow) this.setState({ flow: flow, challenge: flow?.challenge, action: flow?.challenge?.json_data.action })
  }


  async getAction() {
    await this.props.onGetAction({ id: this.id });
    const error = this.props.action.error;
    if (error) {
      notify(error);
    } else {
      this.setState({ action: this.props.action.item });
    }
  }

  componentDidMount() {

    // const lastRouteVisited = saveLocalStorage(this.currentUrl);
    // if (lastRouteVisited && lastRouteVisited !== this.currentUrl) {
    //   history.push(lastRouteVisited);
    // } else {
    //   console.log("get flow");
      this.getFlow();
    //   saveLocalStorage(this.currentUrl);
    // }
  }

  async saveChallenge() {
      history.push(`/flow/${this.idFlow}/step6`)
  }

  render() {
    return (
      <main className="h-full text-center ">
        {this.props.action.loading && <Loader />}
        {this.state.challenge &&
          <CardOnboarding>
            <div className="h-80 md:h-72 flex items-center w-full">
              <img
                src={imgProgress}
                alt=""
                width={"250px"}
                className="mx-auto"
              />
            </div>
            <span>
              <h1 className="text-primary text-2xl mb-5">
                {this.t("Completaste 1 desafío")}
              </h1>
              {/* <p className="text-gray-600 mb-16">{this.t(this.state.action.name)}</p> */}
            </span>
            <div className="w-full">
              <ChallengesCard
                data={this.state.challenge}
                route={""}
              />
            </div>
            <Button
              className="btn-block btn-primary mt-10"
              title={this.t("Siguiente").toUpperCase()}
              onClick={() => this.saveChallenge()}
            />
          </CardOnboarding>
        }
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.auth.user,
    action: state.actions.current,
    transaction: state.transactions.current
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetAction: (params) => dispatch(actions.get(params)),
   };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FlowStep5));
