import YoutubePlayer from "react-player/youtube"

export const RepoructorVideo=({video, videoViewed = ()=>{}, videoPaused = ()=>{}, onReady=()=>{} ,controls=false})=>{

  return(
    <>
    <YoutubePlayer
      playing={false}
      url={video}
      width={'100%'}
      // height={'100%'}
      style={{
        // height: '100px'
        objectFit:'contain',
        padding:1,
        zIndex:0
      }}
      muted={false}
      controls={controls}
      progressInterval={1000}
      onProgress={({playedSeconds,loadedSeconds})=>console.log(`${playedSeconds}/${loadedSeconds}`)}
      onEnded={videoViewed}
      onPause={videoPaused}
      onReady={onReady}
      />
    </>
  )
}