import React from "react";
import Icon from "../../libraries/icons";

export default function IconCredits({ credits, color, negative,defaultIconColor, legendClassName, numberClassName, ...rest }) {
  const legend = rest.leyend ? rest.leyend : "";
  const back_color = negative ? "bg-warning" : "bg-success";
  const text_color =  negative ? "text-warning" : "text-success";

  return (
    <div
      className={
        "bg-opacity-5 items-center py-1 px-2 " +
        (rest.className ? rest.className : `rounded ${back_color} flex gap-1`)
      }
    >
      <Icon
        color={color}
        className={
          `${defaultIconColor ?? text_color} ` + (rest.iconClassName ? rest.iconClassName : "w-4")
        }
        name={"sharycredits"}
      />
      {credits&&<span className="text-base-content text-sm font-normal">
        {negative ? "-" : "+"}
        <span className={`${numberClassName ?  numberClassName : ''} `}>{credits}</span> 
        <span className={`${legendClassName ?  legendClassName : ''} `}>{legend}</span>
      </span>}
    </div>
  );
}
