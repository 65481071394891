import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from "react-final-form";
import { WhenFieldChangesDo } from '../../libraries/forms';
import { notify } from '../../libraries/notifications';
import config from '../../config';

import userActions from '../../context/users/actions';

import transactionsAction from "../../context/transactions/actions";
import companiesAction from '../../context/companies/actions';

import { capitalize, toBool } from '../../libraries/utils';
import Avatar from '../../components/commons/Avatar';
import Loader from '../../components/commons/Loader';
import SelectInput from '../../components/forms/SelectInput';

class Ranking extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      users:null,
      unique_id: this.props.auth.user.unique_id,
      viewAll: true,
    }
  }

  
  componentDidMount() {
    console.log('Ranking PAGE');
    let companiesIds = this.props.auth.user.ancestors.companies.map(c => c.id)
    this.setState({companiesIds, unique_id: companiesIds, viewAll: true}, this.getData)     
  }

  getData = async () => {
    this.setState({loadingRanking: true})
    //if(!this.state.companiesIds){
    //  this.setState({companiesIds: this.props.auth.user.ancestors.companies.map(c => c.id)})
    //  //await this.getCompany()
    //  this.setViewAll(true)
    //}
    await this.props.onGetUsersRanking({user_type: "regular", unique_id: this.state.unique_id});
    const { ranking } = this.props
    if(ranking?.error){
      notify(this.t("Error obteniendo la clasificación"));
    } else {
      this.setState({ranking})
    }
    //await this.getUsers();
    //this.getAllTransactions();

    this.setState({loadingRanking: false})
  }

  getUsers = async () => {
    console.log('GETTING USERS');
    await this.props.onGetUsers({ visibility: "public", user_type: "regular"});
    console.log('AFTER GET');
    const error = this.props.users.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      const users = this.props.users.items
      this.setState({ users });
    }
  }

  getCompany = async () => {
    await this.props.onGetCompany(this.props.auth.user.unique_id);
    const { company } = this.props;
    if (company.error) {
      notify(this.t(company.error.message));
    } else {
      this.setState({ 
        company: company.item
      })
      await this.getCompanies(company.item.owner)
    }    
  }

  getCompanies = async (owner) => {
    await this.props.onGetCompanies({ owner });
    const { companies } = this.props;
    if (companies.error) {
      notify(this.t(companies.error.message));
    } else {
      this.setState({ 
        companies: companies.items,
        companiesIds: companies.items.map(c => c.id),
      })
    }    
  }

  getAllTransactions = async () => {
    const params = {
      type: [
        config.TRANSACTIONS.INITIATIVE.TYPE,
        config.TRANSACTIONS.ACTION.TYPE,
        config.TRANSACTIONS.BORROW.TYPE
      ],
      status: [
        "attended",
        "completed",
        "accepted",
        "on_user",
        "on_owner",
        "review",
      ]
      /* source: config.OWNER.SYSTEM */
    }
    await this.props.onGetAllTransactions(params);
    const { transactions } = this.props;
    if (transactions.error) { 
      notify(this.t(transactions.error.message));
    } else {
      //console.log("items", transactions.items)
      const ranking = {}
      transactions.items.forEach(trx => {
        //console.log("ranking", ranking)
        //console.log("coins", trx.json_data?.coins, "points", trx.json_data?.points)
        //console.log(trx)
        if(trx.type === config.TRANSACTIONS.BORROW.TYPE){
          const borrower = trx.json_data?.owner.id
          if(borrower){
            //console.log(borrower, "points", parseInt(trx.json_data.points))
            if(ranking[borrower]){
              ranking[borrower]["coins"]  += parseInt(trx.json_data.coins)
              ranking[borrower]["points"] += parseInt(trx.json_data.points)
            } else {
              ranking[borrower] = {
                "coins": parseInt(trx.json_data.coins),
                "points": parseInt(trx.json_data.points)
              }
            }
          }
        }
        if(trx.type === config.TRANSACTIONS.ACTION.TYPE){
          const user = trx.json_data?.user.id
          //console.log(trx)
          if(user){
            if(ranking[user]){
              ranking[user]["coins"]  += parseInt(trx.json_data.coins)
              ranking[user]["points"] += parseInt(trx.json_data.points)
            } else {
              ranking[user] = {
                "coins": parseInt(trx.json_data.coins),
                "points": parseInt(trx.json_data.points)
              }
            }
          }
        }
        if(trx.type === config.TRANSACTIONS.INITIATIVE.TYPE){
          const assistant = trx.json_data?.assistant.id
          if(assistant){
            //console.log(assistant, "points", parseInt(trx.json_data.points))
            if(ranking[assistant]){
              ranking[assistant]["coins"]  += parseInt(trx.json_data.coins)
              ranking[assistant]["points"] += parseInt(trx.json_data.points)
            } else {
              ranking[assistant] = {
                "coins": parseInt(trx.json_data.coins),
                "points": parseInt(trx.json_data.points)
              }
            }
          }
        }
      
      })
      const users = this.state.users.filter(u => ranking[u.id]).sort((u1,u2) => {
          return ranking[u2.id]["points"] - ranking[u1.id]["points"]
        }) 
      this.setState({
        feed: transactions.items,
        ranking: ranking,
        users,
      });
    }    
  }

  fullname = (user) => {
    return `${user.json_data?.first_name} ${user.json_data?.last_name}`;
  }

  setViewAll = (viewAll) => {
    let unique_id = this.props.auth.user.unique_id
    this.setState({viewAll, unique_id: viewAll ? this.state.companiesIds : unique_id}, this.getData)
  }

  onSubmit = (value) => {
    console.log('VALUES', value);
    this.setViewAll(toBool(value));
  }
  
  render() {
    const { users, ranking, viewAll } = this.state;
    const options = [{ value: true, label: capitalize(this.t('all the accounts')) },{ value: false, label: capitalize(this.t('my office')) }];
    return (
      <div className="w-full max-w-lg mx-auto">
        <section className="p-3">
          <div className='flex justify-between pb-2 border-b'>
            <Form initialValues={{ viewAll }} onSubmit={this.onSubmit}>
              {({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  <Field name="viewAll" component={SelectInput} selectClassName="select-ghost select-sm w-40" options={options} noError={true} />
                  <WhenFieldChangesDo field="viewAll" action={({ field, value, values }) => { this.onSubmit(value); }} />
                </form>
              )}
            </Form>
          </div>
        </section>
        <section className="p-3">
          {this.state.loadingRanking && (<Loader/>)}
          <div className="card white">
            <ul>
              { ranking && ranking.map((user, index) => {
                  if(user.username){
                    if(user.rewards.total.points === 0) return null
                  }
                  return (
                    user.username && (
                      <li className="flex items-center py-2 px-4 border-b border-gray-100" key={user.id}>
                        <p className="text-gray-300 text-2xl w-8">{index+1}</p>
                        {/*{user.profile_image && <div className="w-10 h-10 mr-2 overflow-hidden flex justify-center items-center rounded-full">
                          <img alt="" src={user.profile_image} />
                        </div>}*/}
                        <Avatar
                          image={user.profile_image}
                          label={user.name}
                          className="rounded-full w-10 h-10"
                          containerClassName="mr-2"
                        />
                        <h5 className="mr-auto"> {this.fullname(user)} </h5>
                        <h3 className="text-primary"> {user.rewards.total.points} </h3>
                      </li>
                    )
                  )
                })
              }
            </ul>
          </div>
        </section>
      </div> 
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    users: state.users.list,
    transactions: state.transactions.list,
    ranking: state.users.list.ranking,
    company: state.companies.current,
    companies: state.companies.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUsers: (params) => dispatch(userActions.getAll(params)),
    onGetAllTransactions: (params) => dispatch(transactionsAction.getAll(params)),
    onGetUsersRanking: (params) => dispatch(userActions.getRanking(params)),
    onGetCompanies: (params) => dispatch(companiesAction.getAll(params)),
    onGetCompany: (id) => dispatch(companiesAction.get(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Ranking));
