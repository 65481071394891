import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';
import Button from '../../components/commons/Button';
import { Form, Field } from 'react-final-form';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Swal from 'sweetalert2';

import TextareaInput from '../../components/forms/TextareaInput';
import RatingIcons from '../../components/commons/RatingIcons';
import transactionsAction from '../../context/transactions/actions';
import { isEmptyObject } from '../../libraries/utils';
import ProductElement from '../../components/customs/ProductElement';
import MessagesActions from '../../context/messages/actions';
import {  getObjectWithJsonDataToFormValues } from '../../libraries/utils';
import internalsActions from '../../context/internals/actions';
import Loader from '../../components/commons/Loader';

class ConfirmReturn extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      imageUpdated: false,
      files: {},
      transaction:null,
      status:"",
      product_rating:0,
      service_rating:0,
      uploadLoading: false,
    }
  }

  
  componentDidMount() {
    console.log('DEVOLVI EL PRODUCTO');
    const params = this.props.match.params;
    console.log('PARAMS', params);
    if(params.id){
      this.getTransaction(params);
    }else {
      history.push(config.ROUTES.OFFER);
    }   
  }

  getTransaction = async (params)=> {
    await this.props.onGet(params);
    console.log('AFTER GET TRANSACTION');
    const { transaction } = this.props;
    if (transaction.error) { 
      notify(this.t(transaction.error.message));
    } else {
      const transactionData = getObjectWithJsonDataToFormValues(
        transaction.item,
        ['id', 'status', 'owner', 'product', 'offer', 'borrower']
      );

     this.setState({transaction:transactionData});
    }    
  }

  onSaveProductRating = (index) => {
    console.log('RATING PRODUCT', index);
    this.setState({ product_rating: index});
  }; 

  onSaveServiceRating = (index) => {
    console.log('RATING SERVICE', index);
    this.setState({ service_rating: index});
  }; 

  onSubmit= async (values) => {
    this.setState({uploadLoading: true})
    console.log("SUBMITTING")
    const {transaction, product_rating, service_rating} = this.state
    const {rateProduct, rateUser} = values

    let status ="";
    status = config.TRANSACTIONS.BORROW.STATUS.ON_OWNER
    //if (transaction.status === config.TRANSACTIONS.BORROW.STATUS.COMPLETED){
    //  status = config.TRANSACTIONS.BORROW.STATUS.COMPLETED
    //} else {
    //  status = config.TRANSACTIONS.BORROW.STATUS.ON_OWNER
    //}
    
    let data = {
      id:transaction.id,
      status,
      json_data:{
        product_rating,
        service_rating,
        rateProduct: rateProduct ? rateProduct.replaceAll('\n', ' ') : null,
        rateUser: rateUser ? rateUser.replaceAll('\n', ' ') : null,
      }
    }

    //let line_5 = "Si tuviste algún problema podés enviarle un mensaje desde la sección de PRÉSTAMOS en el sidebar de la aplicación."
    let line_5 = ""
    if(service_rating) line_5 += `Te puntuó con ${service_rating} Estrellas`;
    if(rateUser) line_5 += ` y realizó el siguiente comentario sobre vos: ${rateUser}`;
    if(product_rating) line_5 += `. Además puntuó al Producto/servicio/favor con ${product_rating} Estrellas`;
    if(rateProduct) line_5 += ` y realizó el siguiente comentario: ${rateProduct}`;


    console.log("DATA", data)
    await this.props.onChangeTransactionState(data);
    console.log('AFTER TRANSACTION STATUS CHANGING');
    if (transaction.error) { 
      notify(this.t(transaction.error.message));
      this.setState({uploadLoading: false})
    } else {
      let notificationData = {  
        type:config.MESSAGES.TYPE.NOTIFICATION,
        source:this.props.auth.user.id,
        target: transaction.owner.id,
        related_to: transaction.product ? transaction.product.id : transaction.offer.id,
        parent: "" ,
        subject: transaction.product ? transaction.product.type : transaction.offer.type,
        body: " devolvió:  " + (transaction.product ? transaction.product.name : transaction.offer.name) + ".Confirmar la devolución. ",
        json_data: {
          product:transaction.product ? transaction.product : transaction.offer,  
          redirect_notification_route: config.ROUTES.RETURNS,
        },
      }
      await this.props.onSaveNotification(notificationData);
      console.log('AFTER SAVE NOTIFICATION');
      const notification = this.props.message;
      if (notification.error) { 
        notify(this.t(notification.error.message));
      } 
      
      //let mailData = {
      //  template: "sharyco_generic",
      //  locale: "es",
      //  to:transaction.owner.email,
      //  first_name: transaction.owner.first_name,
      //  last_name:  transaction.owner.last_name,
      //  params: {
      //      subject: "Confirmación de entrega",
      //      line_1: `Hola ${transaction.owner.first_name} ${transaction.owner.last_name}`,
      //      line_2: `${transaction.borrower.first_name} ${transaction.borrower.last_name}`,
      //      line_3: " ha confirmado que te ha entregado ",
      //      line_4: `${transaction.product ? transaction.product.name : transaction.offer.name}`,
      //      line_5,
      //      link_app: config.ROUTES.USER.LOANS,
      //      //has_button: false
      //  }
      //}

      const getSubTypeString = (subtype) => subtype === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR ? "favor" : "servicio"

      let mailData = {
        template: "return_confirm",
        locale: "es",
        to:transaction.owner.email,
        first_name: transaction.owner.first_name,
        last_name:  transaction.owner.last_name,
        params: {
            // subject: "Confirmación de entrega",
            owner_name: `${transaction.owner.first_name} ${transaction.owner.last_name}`,
            borrower_name: `${transaction.borrower.first_name} ${transaction.borrower.last_name}`,
            product_name: `${transaction.product ? transaction.product.name : transaction.offer.name}`,
            user_stars: `${service_rating}`,
            product_stars: `${product_rating}`,
            user_comment: rateUser,
            product_comment: rateProduct,
            product_type: `${transaction.product ? "producto" : getSubTypeString(transaction.sub_type)}`,
            link_app: config.ROUTES.USER.LOANS,
            //has_button: false
        }
      }
      
      await this.props.onSendMail(mailData);
      const internals = this.props.internals;
      if (internals.error) {
        notify(this.t(internals.error.message));
      }

      history.push({ pathname: config.ROUTES.HOME, state: { from: config.ROUTES.MAIN.OFFER }});
    }
  }

  onSearch = (value) =>{
    console.log('searching', value);
  }

  onClearImageField = ({ name }) => {
    console.log('CLEAR IMAGE FIELD:', name);
    if (name) this.setState({ files: {...this.state.files, [name]: null }});
  }

  onFileChangeImageInput = async ({file, data, name}) => {
    this.setState({ submitting: true });
    console.log('ON FILE CHANGE');
    
    data.append("identifier", `confirmreturn_${ parseInt(Math.random()*1000000)}`)
    await this.props.onPictureUpload(data);
    console.log('FILES', this.props.files);
    const error = this.props.files.error;
    console.log('UPLOADED');

    if (error) {
      notify(this.t(error.message));
    } else {
      console.log("UPLOADED", this.props.files)
      this.setState({imageUpdated: true});
      this.setState({files: {...this.state.files, [name]:this.props.files.files.fileInfo.location}})
    }

    console.log('ON FILE CHANGE FINISH');
    this.setState({ submitting: false });

    return this.props.files.files.fileInfo.location
  }

  render() {
    const {transaction, product_rating, service_rating} = this.state 
    const is_product_loan = transaction && transaction.product
    const getSubTypeString = (subtype) => subtype === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR ? "favor" : "servicio"

    return (
      <LayoutSmall
        main={{ className: ""}}
        header={{ 
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.USER.RETURNS) },
          className: "bg-primary",
          title:this.t("Confirmar devolución") 
        }}
        container={{ className: "px-0"}}
        loading={this.props.transaction.loading}
      >
        <ToastContainer/>
        { this.state.uploadLoading && (<Loader/>) }
        { transaction && !this.state.uploadLoading && (
        <section className="p-3">
          <ProductElement 
            product={transaction.product ? transaction.product : transaction.offer}
            owner={transaction.owner}
            points="120"
            left={true}
          />
          <div className="pt-6">
          <Form onSubmit={(values) => this.onSubmit(values)}>
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="w-full mb-2 mt-3">
                  <h4>{this.t("Calificá al usuario")}</h4>
                  <h5>{this.t("¿Qué te pareció el trato y atención de " + transaction.owner.first_name + "?")}</h5>
                  <RatingIcons
                    className="text-gray-300 my-3"
                    initalRating={service_rating} 
                    onClick={this.onSaveServiceRating}
                    disabled={false}
                  />
                  <Field name="rateUser" component={TextareaInput} placeholder="Comentario" />
                </div>
                <div className="w-full mb-2">
                  <h4>{this.t(`Calificá el ${is_product_loan ? "producto" : getSubTypeString(transaction.sub_type)}`)}</h4>
                  <h5>{this.t(`¿Qué te pareció el ${is_product_loan ? "producto" : getSubTypeString(transaction.sub_type)}?`)}</h5>
                  <RatingIcons
                    className="text-gray-300 my-3"
                    initalRating= {product_rating} 
                    onClick={this.onSaveProductRating}
                    disabled={false}
                  />
                  <Field name="rateProduct" component={TextareaInput} placeholder="Comentario"/>
                </div>
                
                <Button
                  className="btn-primary btn-block mt-10"
                  title={this.t("Confirmar")}
                  onClick={handleSubmit}
                  disabled={submitting || service_rating === 0 || product_rating === 0 }
                />
              </form>
            )}
          </Form>
          </div>
        </section>)}
      </LayoutSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    transaction: state.transactions.current,
    message: state.messages.current,
    internals: state.internals,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGet: (params) => dispatch(transactionsAction.get(params)),
    onChangeTransactionState: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
    onSaveNotification: (params) => dispatch(MessagesActions.saveOrUpdate(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
  };
};


export default connect(
  mapStateToProps,mapDispatchToProps
)(withTranslation()(ConfirmReturn));

