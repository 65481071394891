import React from 'react';
import { Link } from 'react-router-dom';
import IconPoints from './IconPoints';
import IconCoins from './IconCoins';
import { capitalize, getObjectWithJsonDataToFormValues  } from '../../libraries/utils';
import UserOwnerElement from './UserOwnerElement';
import { useTranslation } from 'react-i18next';
import Icon from '../../libraries/icons';
import Avatar from '../commons/Avatar';
import SmallRating from '../commons/SmallRating';
import ButtonIcon from '../commons/ButtonIcon';
import { ReactComponent as Flecha } from '../../assets/icons/customs/flecha_challenge.svg';


export default function FavorElement({ favor, route, ...rest }) {
  const { t, i18n } = useTranslation(); 
  
  const { img, coins, points, url, name } = getObjectWithJsonDataToFormValues(
    favor,
    ['id', 'name', 'img', 'coins', 'points','url','title']
  );

  const owner = getObjectWithJsonDataToFormValues(
    favor.user,
    ['id', 'username', 'first_name', 'last_name', 'profile_image', 'rating', 'rate_total', 'rate_count']
  );

  const favor_rating = owner.rate_total && (owner.rate_count !== 0) ? owner.rate_total/owner.rate_count : 0
  return (
    <Link
    key={rest.key}
    to={route}
  >
    <div className={"card flex-row white overflow-hidden flex h-full " + (rest.className ? rest.className : "")} >
      {/* <SmallRating containerClassName=' absolute px-1 right-2 bottom-2 h-6' rate={favor_rating} /> */}
      <div className="flex gap-3 p-3 h-full w-full">
        <div className='w-8 h-8 mt-1'>
          <img src={(owner.profile_image)} className='object-cover rounded-full' />
        </div>
        <div className="leading-tight flex justify-between items-start flex-col h-full gap-1 w-full">
          
          <div>
            <small className='text-primary font-normal'>{t(owner.last_name)} {t(owner.first_name)}</small>
            <h5>{t(name)}</h5>
          </div>

          <div className="flex justify-between items-center gap-2 w-full">
            <div className="flex items-center gap-2">
            <IconCoins iconClassName="h-4" color='red' negative={true} coins={coins} showNumber={true}/>
            <IconPoints iconClassName="h-4" color='green' points={points} showNumber={true}/>
            </div>
            <Flecha className='w-10 ml-3' />
          </div>
          {/* <ButtonIcon
            buttonClassName="btn-primary btn-sm flex-row-reverse gap-2"
            className="w-4 h-4"
            icon="refresh"
            text={capitalize(t("pedir"))}
            onClick=""
          /> */}
        </div>
      </div>
      {/* <div className="flex items-stretch justify-center w-1/2 overflow-hidden">
        {img ? 
          (<img alt="" className='object-cover w-full max-h-44' src={img}/>)
          : <></>
        }
      </div>  */}
    </div>   
  </Link>

  )
}