import React from 'react';
import { withTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import Icon from '../../libraries/icons';


class InfoHowWorks extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
    }
  }
  
  componentDidMount() {
    console.log('HOW WORKS PAGE');
  }

  onExit = async values => {
    history.push(config.ROUTES.HOME);        
  }
 
  render() {
    return (
      <LayoutSmall
        header={{ 
          className: "bg-primary",
          title: this.t("How works?"),
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.INFO) }
        }}      
        main={{ 
          className: "light-primary-bg text-gray-700",
        }}
      >
         <div  className="card bg-gray-200 p-3 text-center w-min px-10">
            <p className="mb-2">
              <strong>
                <Icon className="block text-primary w-16 mb-2 mx-auto" name="globe_alt" />
                <a className='mb-2 mx-auto text-xl' href={config.URL_MANUAL_USUARIO} download={config.URL_MANUAL_USUARIO}>Descargar Manual</a>
              </strong>
            </p>
          </div>
        <p className="mt-3 mb-1">
        <br />
        <h3>¿CÓMO FUNCIONA SHARYCO?</h3>
  <p>Las diversas interacciones disponibles en la aplicación te premiarán con PUNTOS SHARY y PUNTOS DE IMPACTO para que puedas canjearlos por lo que quieras dentro de la app.</p>
  <br />
  <h3>¿QUÉ SON LOS PUNTOS SHARY?</h3>
  <p>Los Puntos SHARY son los puntos que ganás por generar impacto positivo realizando acciones sostenibles y de servicio comunitario. Estos puntos los podrás utilizar en la SECCIÓN de ECONOMÍA CIRCULAR por lo que podés gastarlos e ir sumando más, haciendo más acciones dentro de la comunidad, para poder seguir pidiendo prestadas las cosas que necesites.</p>
  <br />
  <h3>¿QUÉ SON LOS PUNTOS DE IMPACTO?</h3>
  <p>Los Puntos de Impacto son los puntos que ganás por generar impacto positivo prestando porductos servicios o favores, realizando acciones de servicio comunitario o acciones sostenibles. Los Puntos de Impacto no se gastan, solo se suman y miden el nivel de impacto acumulado que vas generando.</p>
  <p>Según la cantidad de puntos de impacto que llevás acumulados estarás en un nivel determinado y según el nivel en el que estés los beneficios y descuentos a los que tenés acceso.</p>
  <p>Debajo podrás ver la tabla de los puntos requeridos por nivel</p>
  <p>Nivel 1: de 3 a 39 puntos de impacto</p>
  <p>Nivel 2: de 40 a 363 puntos de impacto</p>
  <p>Nivel 3: más de 364 puntos de impacto</p>
  <br />
  <h3>¿QUÉ SE PUEDE HACER CON LOS PUNTOS SHARY GANADOS?</h3>
  <p>1- Pedir Productos Prestados a otros miembros de la comunidad (SECCIÓN ECONOMÍA CIRCULAR)</p>
  <p>2- Pedir Servicios Prestados a otros miembros de la comunidad (SECCIÓN ECONOMÍA CIRCULAR)</p>
  <p>3- Pedir Favores a otros miembros de la comunidad (SECCIÓN ECONOMÍA CIRCULAR)</p>
  <br />
  <h3>¿QUÉ SE PUEDE HACER CON LOS PUNTOS DE IMPACTO GANADOS?</h3>
  <p>Adquirir los descuentos que aparecen en la SECCIÓN de BENEFICIOS según tu nivel de impacto. (ver niveles en punto xx)</p>
  <p>Deberás tener al menos 3 puntos de impacto para poder empezar a disfrutar de los beneficios de las marcas aliadas.</p>
  <br />
  <h3>¿CÓMO CANJEO UN BENEFICIO QUE ME INTERESA?</h3>
  <p>El proceso de canje de beneficios es muy simple, una vez que visualizás un beneficio que deseas, apretás en el botón de canjear y se descargará un código de QR donde aparecen los datos de cómo debes hacer para poder hacer efectivo el descuento. Cada marca tendrá su propio proceso, algunos te pedirán que muestres el código en la caja del local, otros que se los envíes por whatsapp mail.</p>
  <p>Empezá a participar en la comunidad y accedé a los beneficios que traen nuestras marcas aliadas</p>
  <p>En el caso de beneficios que otorga la empresa deberás canjear y descargar el código QR y enviarlo al encargado de RRHH de tu empresa</p>
  <br />
  <h3>¿CÓMO PUEDO GANAR PUNTOS SHARY Y PUNTOS DE IMPACTO?</h3>
  <p>1- A través del préstamos de productos, favores o servicios en la SECCIÓN ECONOMÍA CIRCULAR</p>
  <p>Para subir un producto, servicio o favor, la plataforma cuenta con un listado de los elementos permitidos, se podrá elegir qué producto, servicio o favor subir del listado ya establecido, dado el caso que no se encuentra en la base de datos el productos servicio o favor que se quiere ofrecer se debe solicitar la autorización de la publicación a SharyCo quien podrá aceptar o rechazar la misma luego de su revisión como descrito EN EL PUNTO XX</p>
  <p>Para subir un producto, servicio o favor se deberá completar la siguiente información.</p>
  <p>a.Categoría y subcategoría (hogar, entretenimiento, deportes, favores, servicios,etc)y luego elegir el producto, servicio o favor del listado</p>
  <p>b.Nombre de producto, servicio o favor</p>
  <p>c.Foto</p>
  <p>d.Descripción de características base.</p>
  <p>e.Valor del préstamo en Puntos Shary. (La plataforma le brindará un rango recomendable para fijar como monto de Puntos Shary requeridos para el préstamo pero no necesariamente debe encontrarse dentro de ese rango. El Prestamista es libre de poner el monto que desee.)</p>
  <p>f.Días disponibles (calendario)</p>
  <p>g. Marca (En el caso de producto)</p>
  <p>2-A través de ayuda comunitaria en las actividades de la SECCIÓN SERVICIO COMUNITARIO</p>
  <p>Si lo que estás buscando es la manera de sumar puntos y te interesó alguna actividad de ayuda comunitaria, reciclaje o donación, podés ingresar a las publicaciones que te interesan, ver todo el detalle y consultar a las instituciones cualquier duda. </p>
  <p>Si ya no tenés dudas y querés participar podés apretar el botón de postularte en el caso de los voluntariados y ganarás los puntos una vez realizada la actividad.(Ver punto xx cuando se computan los puntos) </p>
  <p>3- A través de acciones de sostenibilidad en la SECCIÓN ACCIONES SOSTENIBLES</p>
  <p>Si querés ganar puntos de impacto o shary con hábitos sostenibles debés ingresar a la sección de acciones sostenibles y elegir la acción que más te guste. Al darle click a una actividad que te interese, se te abrirá una pantalla con toda la información con consejos o explicando porqué es bueno realizar cierta acción o ejemplos de videos o manuales que podrás descargar para aprender cómo hacer cierta actividad.</p>
  <p>Una vez que sepas cómo realizar la actividad, apretá el botón de “hacer” sacá una foto, agrega un comentario (opcional) y ganá Puntos Shary y Puntos de Impacto.</p>
  <br />
  <h3>PREMIOS Y CASTIGOS POR COMPORTAMIENTO DENTRO DE LA COMUNIDAD</h3>
  <p>Tabla de cómo se pueden ganar puntos shary por un buen comportamiento dentro de la comunidad de SHARYCO</p>
  <table>
    <tbody className="border-collapse border border-gray-400">
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Premios</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Cantidad de Puntos Shary</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Si sos usuario con perfil empresa comenzás con puntos de regalo</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>500</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Completando validación de perfil</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>200</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Primera vez que hace alguna transacción que gana puntos</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>se le otorga 200 Puntos Shary como premio por primera interacción en app</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Primer producto, servicio o favor que sube</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>200 puntos extras por el primer producto subido</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Subiendo productos/servicios o favor a perfil</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Según valor de producto/servicios o favor que se sube</p>
          <p>Valor de Pdt menor a 300 gana 50 puntos</p>
          <p>Valor de Pdt entre 300 y 700 Gana 100</p>
          <p>Valor de Pdt mayor a 700 gana 150 puntos</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Notificaciones de App o marcas</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Los usuarios podrán ganar de 100 hasta 250 puntos si hacen lo que dice la app o una marca, por ejemplo si pedis prestado unos botines nike ganas 100 puntos, o si compras zapatillas nike en los locales adheridos ganas 250 puntos, o si la app dice que es happy hour y cada producto que subas de cierta categora tiene un extra de 100 puntos</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Prestando productos/servicios</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>valor producto/servicio</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Entregando/Devolviendo productos a tiempo</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>50</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Hacer reviews de personas y productos</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>20 puntos por dar puntaje y 20 puntos por dejar comentarios por la persona y el producto si corresponde (vale por cada uno por separado)</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Devolviendo productos en forma</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>50</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Premio Mensual si mantenes reputacion de 4.5 + y actividad de al menos 1 transaccion en el mes</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>100</p>
        </td>
      </tr>
    </tbody>
  </table>
  <p>Tabla de cómo se pierden puntos shary, puntos de impacto, estrellas en puntaje de perfil o suspensión directa de la plataforma por un mal comportamiento en la comunidad de SHARYCO</p>
  <table>
    <tbody>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Penalizaciones</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Disminución de Puntos Shary</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Disminución de puntos de impacto</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Disminuci/on de estrellas de perfil</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Eliminación de comunidad</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Robo</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>x</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Daño intencional a persona o producto</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>x</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Fraude/Engaño</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>x</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Bullying</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>3 veces</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Discriminación</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>3 veces</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Productos no permitidos</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>3 veces</p>
        </td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Daño accidental de persona o daño total de producto y no coopera para encontrar la solución</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>-200</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>-50</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>-1</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Datos de cuenta no son reales</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>se le quitan todos los puntos y no puede seguir interactuando hasta que actualice y confirme nuevos datos</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Estado/descripción de producto no es real</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>-150</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>-5</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>-1</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Cancelar pedido si sos el prestador menos de 2 hs antes de entrega</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>-150</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>-2</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>-1 si se repite 3 veces en un período de tiempo menor a un mes</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>Entrega o devolución de producto o servicio o favor fuera de tiempo</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>-50</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>-2</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>-1 si se repite 3 veces en un período de tiempo menor a un mes</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>No entregar o devolver productos en forma</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>-150</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>-3</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300">
          <p>-1 si se repite 3 veces en un período de tiempo menor a un mes</p>
        </td>
        <td colSpan="1" rowSpan="1" className="border border-gray-300"></td>
      </tr>
    </tbody>
  </table>
  <br />
  <h3>QUÉ SECCIONES CONTIENE LA APP</h3>
  <div>
  <ul>
    <li>SECCIÓN ECONOMÍA CIRCULAR:Contiene todos los productos, servicios y favores que ofrecen otros usuarios de la comunidad y están disponibles para pedir prestados por un período determinado. Además contiene todos los favores, productos y servicios que busca la comunidad pero aún no encuentra alguien que los ofrezca y con los cuales podrán ganar puntos de impacto y puntos shary si los ofrecen.</li>
    <li>SECCIÓN SERVICIO COMUNITARIO:Contiene las opciones de ayuda comunitaria, reciclado y donaciones de nuestras ONGs aliadas y en las cuales pueden participar</li>
    <li>SECCIÓN ACCIONES SOSTENIBLES:Contiene una lista de más de 70 acciones sostenibles ordenadas por categoría. Cada acción contiene la cantidad de puntos de impacto y Puntos Shary que ganarían por realizarla y además contiene información con consejos o explicando porqué es bueno realizar cierta acción o ejemplos de videos o manuales que podrán descargar para aprender cómo hacer cierta actividad.</li>
    <li>SECCIÓN BENEFICIOS:Según la cantidad de puntos de impacto generados como usuario, tendrás acceso a diferentes BENEFICIOS, ya sean DESCUENTOS de comercios y marcas sostenibles aliadas y/o PREMIOS que la empresa decida otorgar (esto es opcional según la empresa)</li>
    <li>SECCIÓN RANKING:Habrá un ranking general según los puntos de impacto acumulados de cada usuario, y los mejores tendrán acceso a ciertos PREMIOS que defina la empresa, además de los de la sección de beneficios.(opcional para la empresa)</li>
    <li>SECCIÓN PERFIL:Incluirá la siguiente información de cada usuario</li>
  </ul>
  <ul>
    <li>El saldo de sus Puntos Shary y PUNTOS DE IMPACTO.</li>
    <li>Su puntaje de reputación y comentarios</li>
    <li>La cantidad de personas que los siguen y a su vez ellos siguen.</li>
    <li>Las MEDALLAS que van ganando o el nivel en que se encuentran según el impacto positivo generado.</li>
    <li>Su puesto en el RANKING empresa según sus PUNTOS DE IMPACTO acumulados.</li>
    <li>El HISTORIAL de su actividad.</li>
  </ul>
  <ul>
    <li>SECCIÓN MURO:podrás verla actividad de toda la comunidad cerrada e interactuarcomentando o dando un Me Gusta en las actividades que realizan los otros compañeros de trabajo y a su vez tus compañeros también podrán ver tus acciones.</li>
  </ul>
  <ul>
    <li>Primero te aparecerán las acciones de las personas que decides seguir y luego el del resto</li>
  </ul>
  </div>
  <br />
  <h3>¿CóMO HAGO PARA BUSCAR UN PRODUCTO FAVOR O SERVICIO QUE QUIERO PEDIR PRESTADO?</h3>
  <p>a.Podés buscar algo que te interesa desde la lupa y escribir el nombre del producto o categoría o servicio etc</p>
  <p>b.Podés buscar mirando las fotos y publicaciones de los productos, servicios y favores disponibles en la SECCIÓN ECONOMÍA CIRCULAR</p>
  <p>c.Podés buscar por filtros de puntaje, distancia de tu ubicación, Puntos Shary requeridos,etc.</p>
  <p>d.Si no encontrás el producto, servicio o favor dentro de las cosas que ofrecen todos los usuarios, podrás informar en la comunidad qué es lo que estás buscando para que alguien te lo pueda ofrecer. (VER pregunta X para ver el proceso de como pedir algo que no aparece)</p>
  <br />
  <h3>¿CÓMO HAGO PARA SOLICITAR EN LA COMUNIDAD UN PRODUCTO FAVOR O SERVICIO QUE AÚN NO APARECE?</h3>
  <p>Para poder solicitarle a la comunidad algo que estás buscando pero que no encontrás debés apretar en el botón XX que se encuentra en XX y completar el formulario con los siguientes datos</p>
  <p>a.Elegir de la lista la Categoría y subcategoría (hogar, entretenimiento, deportes, favores, servicios,etc)y luego elegir el producto, servicio o favor en particular</p>
  <p>b.Describir lo que estás buscando</p>
  <p>c.Foto (opcional)</p>
  <p>d.Valor del pedido en Puntos Shary. (La plataforma te brindará un rango recomendable para fijar el monto de Puntos Shary que ofrecés a la persona que te hará el préstamo de lo que estés necesitando, pero no necesariamente debe encontrarse dentro de ese rango. Sos libre de poner el monto que desees.)</p>
  <p>f.Días que lo necesitás (calendario)</p>
  <p>g. Marca (En el caso de producto)</p>
  <p>Una vez completado se subirá al final de la SECCIÓN DE ECONOMÍA CIRCULAR</p>
  <br />
  <h3>¿CÓMO HAGO PARA PEDIR UN PRODUCTO FAVOR O SERVICIO QUE OFRECE OTRO USUARIO?</h3>
  <p>Si encontrás algo que te interesa en la sección de economía circular, podés darle click y ver toda la información, del producto o servicio y el perfil de la persona que lo presta.</p>
  <p>Si luego de ver toda la información del producto, servicio o favor y Prestamista lo querés, le das click al botón de PEDIR</p>
  <p>Se abre automáticamente una pantalla para configurar requisitos del pedido. Antes de finalizar el pedido por cualquier duda podés enviarle un mensaje privado al Prestamista con la consulta que tengas</p>
  <p>Al dar clic en botón de finalizar pedido se envía una notificación al prestamista para que acepte el pedido. Una vez aceptado o rechazado te llegará una notificación y deberán comunicarse por mensajería interna para coordinar la entrega del producto, servicio o favor de ser aceptado.</p>
  <br />
  <h3>¿CÓMO ES EL PROCESO LUEGO DE ACEPTADO UN PRÉSTAMO, SERVICIO O FAVOR?</h3>
  <p>En el caso del préstamo de un producto, en el momento que se lleva a cabo la entrega, la persona que pidió algo prestado deberá ingresar a la app en la sección de actividad y la publicación solicitada y desde allí sacar una foto del producto en el momento de la entrega y escribir comentarios del status y características o daños del mismo. Una vez que subís la foto y comentarios le llegará una notificación a la persona que te lo prestó para que pueda confirmar que está bien lo que comentás. </p>
  <p>Ambas personas deberán validar lo que se escriba para que tenga validez. Es obligatorio este paso, de lo contrario no podrá realizar la siguiente transacción de no finalizar este proceso.</p>
  <p>Llegado el día de entrega luego del uso, cuando se devuelve el producto, la persona que lo prestó deberá ir a la publicación del producto en préstamo en su perfil y deberá sacar foto del producto y describir los comentarios de daños o cambios que haya sufrido el mismo.  Una vez que sube la foto y comentarios le llegará una notificación a la persona que se lo pidió prestado para que pueda confirmar que está bien lo que comenta quien lo prestó. </p>
  <p>Ambas personas deberán validar lo que se escriba para que tenga validez.  Es obligatorio este paso, de lo contrario no podrá realizar la siguiente transacción de no finalizar este proceso.</p>
  <p>En el caso de préstamos de servicios o favores una vez finalizado el mismo se pasa directo a la sección de puntaje de la persona y del servicio o favor.</p>
  <br />
  <h3>¿CUÁNDO SE PUEDE PONER PUNTAJES Y COMENTARIOS DE LA EXPERIENCIA DE PRÉSTAMO DE PRODUCTO, SERVICIO O FAVOR?</h3>
  <p>Finalizada la entrega del servicio o favor y la sección de status de devolución dado en el caso de que se trate de un producto, la persona que prestó algo debe dar un puntaje (Obligatorio) y comentario (opcional) de la persona que hizo el pedido además del tiempo y forma de entrega del producto.</p>
  <p>A su vez la persona que pide prestado deberá dar puntaje (Obligatorio) y comentario (Opcional) de la persona que prestó algo. Además deberá dar puntaje (Obligatorio) al producto/servicio/favor utilizado y dejar comentario de ser necesario (opcional)</p>
  <p>Si surge algún siniestro con el producto, servicio o favor al finalizar el proceso de puntaje y comentarios podrán dar clic en la sección de reclamos e incidencias.</p>
  <br />
  <h3>¿CUÁNDO SE COMPUTAN LOS PUNTOS QUE GANE O GASTÉ?</h3>
  <ul>
    <li>EN EL CASO DE PRÉSTAMOS EN SECCIÓN ECONOMÍA CIRCULAR</li>
  </ul>
  <p>Una vez finalizado todo el proceso de puntajesDEL PUNTO XXX, En aquel momento se restan automáticamente los puntos Shary que vale el producto,servicio o favor pedido del perfil de la persona que realizó el pedido, y a la vez Sharyco le otorga ese monto de puntos a la persona que dió el préstamo. Además de los puntos otorgados por el producto, servicio o favor, se les restará o sumará puntos considerando las reglas y castigos detallados en lastablas del punto XX.</p>
  <p>Finalizado el préstamo también se les sumará a cada persona los puntos de impacto correspondientes.</p>
  <ul>
    <li>EN EL CASO DE ACTIVIDADES REALIZADAS EN LA SECCIÓN DE SERVICIO COMUNITARIO</li>
  </ul>
  <p>Para confirmar que hiciste la actividad en el caso de una ayuda comunitaria, el día que se realice el voluntariado, se te pedirá que escanees o ingreses el código QR que se te guardó en tu actividad en la publicación de la actividad que vas a realizar. Debes confirmar tu comienzo de la actividad y nuevamente al retirarte para confirmar el tiempo transcurrido y que se ha realizado la actividad. Una vez escaneados ambos códigos el puntaje ganado se verá automáticamente reflejado en tu perfil. Es necesario acercarse a los organizadores del evento para saber quién es el encargado de escanear el QR o escribir el código que te brindó la aplicación en el momento de la confirmación de la postulación dado el caso que no lleves tu celular a la actividad.</p>
  <p>En el caso de las donaciones y reciclado los puntos se computarán en el momento que entregues los productos en el lugar que sea acordado. El proceso será también con escaneo de un código que tendrás en la publicación en la que te postulaste y se te guardó en tu actividad para confirmar que se ha realizado la donación o reciclado. Una vez leído el código por el encargado de la actividad, los puntos se verán automáticamente reflejados en tu perfil</p>
  <ul>
    <li>EN EL CASO DE ACTIVIDADES REALIZADAS EN LA SECCIÓN DE ACCIONES SOSTENIBLES</li>
  </ul>
  <p>Para poder ganar los puntos realizando la acción que te interesa, debes darle click a la acción y te abrirá la información de esa acción donde habrá un botón para poder subir una foto o un videodesde la app para corroborar que estás realizando la acción correspondiente. Una vez subida la foto o video y comentario (opcional) se te otorgarán los puntos de impacto y puntos shary correspondientes y la actividad se subirá directamente a la sección de MURO para que la comunidad pueda ver cómo estás aportando al planeta.</p>
  <p>Dado el caso de que alguien haga un reclamo de la veracidad de que se ha realizado tal actividad, se llevará a cabo un análisis del reclamo para confirmar si está en lo correcto, o si la foto o video no coincide con la acción y lo encuentran los auditores de la app, se descontarán automáticamente los puntos de impacto y Shary otorgados y además se eliminarán puntos según la tabla de penalizaciones en (punto XXX)</p>
  <br />
  <h3>¿CÓMOHAGO PARA AGREGAR UN PRODUCTO/FAVOR/SERVICIO QUE NO APARECE EN EL LISTADO?</h3>
  <p>Si querés cargar un producto, favor o servicio que no aparece en ninguna de las categorías como opción, podés ir al botón de "solicitar agregar elemento" en la base de datos y debés completar los datos que aparecen en el formulario con toda la información del producto,favor o servicio.</p>
  <p>Luego de enviada la solicitud, dicha publicación quedará sujeta a revisión de Sharyco, por el plazo de hasta 7 días hábiles.</p>
  <p>Luego de cumplido el plazo, Sharyco podrá tomar tres resoluciones con relación a la publicación que se quiere subir sujeto a revisión:</p>
  <p>a) Aprobar la misma, por lo que tu publicación pasará automáticamente a estar disponible en tu perfil y a la vista de toda la comunidad y se agregará dicho producto, favor o servicio a las opciones de la plataforma para que cualquier persona lo pueda ofrecer.</p>
  <p>b) Observar la misma. En este supuesto Sharyco procederá a informar las observaciones al Usuario y la forma de subsanar las mismas. Una vez que todas las observaciones sean subsanadas, la publicación del producto pasará automáticamente a estar disponible en tu perfil y a la vista de toda la comunidad y se agregará dicho producto, favor o servicio a las opciones de la plataforma para que cualquier persona lo pueda ofrecer.</p>
  <p>c) Negar la misma. Se le enviará un mensaje al usuario informando por qué se ha rechazado su publicación.</p>
  <p></p>
  <br />
  <h3>¿CUANDO PUEDO SUSPENDER LA PUBLICACIÓN DE UN PRODUCTO QUE SUBÍ EN MI INVENTARIO?</h3>
  <p>En caso que un producto se rompa, esté en reparación, se reemplazará por uno nuevo, etc, la publicación del producto podrá ser suspendida, no estando habilitada para que un Usuario pueda solicitar el producto, favor o servicio.</p>
  <p>La publicación que fuera suspendida, podrá ser rehabilitada asignando una nueva descripción, fotos, etc.</p>
  <p></p>
  <br />
  <h3>¿ESTÁ PROHIBIDO PRESTAR ALGO QUE SE PIDIÓ EN PRESTAMOS EN LA APLICACIÓN?</h3>
  <p>Se dejaexpresamente aclarado que no podrá ofrecer en préstamo en la plataforma de SHARYCO un producto que se haya pedido en préstamo en la misma.</p>
  <p></p>
  <br />
  <h3>¿CUÁLES SON LAS RESTRICCIONES DE LOS PRODUCTOS QUE SE PUEDEN OFRECER?</h3>
  <p>Dentro de la plataforma no se puede ofrecer o venderlos siguientes productos:</p>
  <p>1) Alimentos;</p>
  <p>2) Cosméticos;</p>
  <p>3) Vinos y bebidas alcohólicas;</p>
  <p>4) Tabacos;</p>
  <p>5) Medicamentos;</p>
  <p>6) Drogas, narcóticos o estupefacientes prohibidos;</p>
  <p>7) Armas de fuego o cualquier clase de dispositivo, agresivos químicos de toda naturaleza y demás materiales que se clasifiquen como armas de guerra, pólvoras, explosivos y afines, y armas, municiones y demás materiales clasificados de uso civil; materias explosivas, inflamables o radiactivas y mercaderías y/o residuos peligrosos.</p>
  <p>8) trofeos de caza (animal), animales vivos, partes de animal como marfil y aleta de tiburón, restos animales, o subproductos animales;</p>
  <p>9) Restos humanos o cenizas;</p>
  <p>10)   Mercaderías falsificadas;</p>
  <p>11)   Lingotes (de cualquier metal precioso);</p>
  <p>12)   Dinero en efectivo (moneda legal vigente);</p>
  <p>13)   Piedras preciosas y cualquier producto cuyo transporte haya sido prohibido por algún reglamento, ley o estatuto correspondiente a cualquier gobierno federal estatal o local de cualquier país hacia el cual o a través del cual se realice el envío.</p>
  <p>14)   Ni cualquier otro producto cuya comercialización estuviera prohibida o restringida de acuerdo con la legislación vigente en la República Argentina.</p>
  <p></p>
  <p>INHABILITACION O SUSPENSION DE USUARIO. EFECTOS.</p>
  <p>a)  SUSPENSION</p>
  <p>Al ser suspendido, el Usuario recibirá una notificación comunicando la causa y extensión de la suspensión.</p>
  <p>Cuando un Usuario es suspendido no puede publicar, pedir u ofertar artículos servicios o favores, participar en foros, actividadesni hacer preguntas a los Usuariosque prestan productos servicios o favorespor todo el plazo de duración de la suspensión.</p>
  <p>Al mismo tiempo, todas sus publicaciones son dadas de baja del sitio. Las mismas podrán ser republicadas al fin de la suspensión ingresando desde el menú de navegación en SU CUENTA.</p>
  <p></p>
  <p>b)  INHABILITACION</p>
  <p>Al ser inhabilitado, el Usuario recibirá una notificación comunicando la causa.</p>
  <p>Cuando un Usuario es inhabilitado no puede realizar ninguna actividad dentro de la plataforma.Al mismo tiempo, todas las publicaciones podrán ser dadas de baja y no se podrán reestablecer.</p>
  <p></p>
  <p></p>
  
  <h3>¿Puedo cambiar el correo electrónico de usuario?</h3>
  <p>Sí, solo debes rellenar este formulario.</p>
  <br />
  <h3>¿Qué hago si detecto diferencias entre los puntos que gané y las actividades que hice?</h3>
  <p>Si encuentras diferencias en el cálculo completa el siguiente formulario y enseguida lo revisaremos.</p>
  <br />
  <h3>Olvidé mi contraseña, ¿cómo la recupero?</h3>
  <p>En la pantalla de inicio de la aplicación SHARYCO, haz clic en el botón de recuperar contraseña.</p>
  <br />
  <h3>¿Puedo cambiar el correo electrónico de usuario?</h3>
  <p>Sí, solo debes rellenar este <a href="=https://gobetterfly.com/modulos/faq-es-change-email-modal/">formulario</a>.</p>
  <br />
  <h3>¿Quién tiene acceso a mis datos y cómo se usan?</h3>
  <p>Solo nosotros tenemos acceso a tu información, ya que es confidencial.. Para tu tranquilidad, no compartimos ni vendemos información con terceros.</p>
  <br />
  <h3>¿Puedo crearme una cuenta y usar la app sin ser parte de un plan empresa?</h3>
  <p>Por el momento nuestro servicio se enfoca exclusivamente en empresas y sus colaboradores. Esperamos prontamente entregar este beneficio a personas naturales.</p>
  <br />
  <h3>¿Qué hago si detecto diferencias entre los puntos que gané y las actividades que hice?</h3>
  <p>Si encuentras diferencias en el cálculo completa el siguiente <a href="https://gobetterfly.com/modulos/faq-es-life-insurance-inconsistency-modal">formulario</a>y enseguida lo revisaremos.</p>
  </p>
  </LayoutSmall>
    ) 
  }
}

export default withTranslation()(InfoHowWorks);