import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
//import { getOwner } from '../../libraries/utils';
import { history } from '../../routes';
import config from '../../config';

/* import TextInput from '../../components/forms/TextInput';
import TextareaInput from '../../components/forms/TextareaInput';
import SelectInput from '../../components/forms/SelectInput';
import { Form, Field } from 'react-final-form'; */
import Button from '../../components/commons/Button';
import IconCoins from '../../components/customs/IconCoins';
import IconPoints from '../../components/customs/IconPoints';
import { isEmptyObject, getObjectWithJsonDataToFormValues } from '../../libraries/utils';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Swal from 'sweetalert2';
import Icon from '../../libraries/icons';
import transactionsAction from '../../context/transactions/actions';
import messagesActions from '../../context/messages/actions';

class Request extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      type: null,
      category: null,
      productItems: {},
      productImg:"",
      editing:false,
      productId:"",
    }   
  }

  componentDidMount() {
    const params = this.props.match.params;
    console.log('TEST___', this.props.history.location.state.fromPath);

    console.log('REQUESTS PAGE', params);
    if (params.id){
      this.getTransaction(params.id);
    } else {
      this.goBack();
    }    
  }
  
  getTransaction = async (id)=> {
    await this.props.onGetTransaction({id});
    const { request } = this.props;
    if (request.error) {
      notify(this.t(request.error.message));
    } else {
      const requestData = getObjectWithJsonDataToFormValues(
        request.item,
        ['id', 'categories', 'sub_type', 'category', 'details', 'requester', 'category', 'created_by']
      );

      const categoryData = getObjectWithJsonDataToFormValues(
        requestData.category,
        ['id', 'name', 'min_value', 'max_value',]
      );

      this.setState({request: requestData, category: categoryData})
      console.log("asdf", request)
    }
  }

  gotoSubir = () => {
    const {request} = this.state
    const state = {
      type:request.sub_type,
      category:request.category,
      categories_selected:request.categories,
      fromRequest: true,
      requesterId: request.requester.id,
      requester: request.requester,
      categoryFromRequest: request.category.name,
      fromPath: config.ROUTES.REQUESTS.LIST,
      //detailsFromRequest: request.json_data.details,
    }

    history.push({pathname: config.ROUTES.OFFERS.DETAILS.replace(':type', request.sub_type), state})

  }

  deleteRequest= () => {
    const id = this.props.match.params.id;

    Swal.fire({
      title: this.t("¿Queres borrar este pedido?"),
      text: this.t("You can't roll back this operation"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.t("Yes"),
      cancelButtonText: this.t("Cancel"),
    }).then(async result => {
      if(result.isDismissed) return
      await this.props.onDeleteTransaction({id})
      await Swal.fire('', this.t("Operation sussceful"), 'success');
      this.goBack()
    })


  }
  goBack = () => {
    const { editing,request} = this.state
    const { history } = this.props

    const isMine = request?.created_by === this.props.auth.user.id
    if(history.location?.state?.fromPath) {
     return history.push({
        pathname: history.location?.state?.fromPath,
      })
    }

    if(editing || isMine) return history.push(config.ROUTES.USER.INVENTORY)
    history.push({
      pathname: config.ROUTES.HOME_OPTIONS.REQUESTS,
      state:{ from: config.ROUTES.MAIN.OFFER }
    })

  }

  render() {
    const { editing } = this.state

    const { request, category } = this.state
    const  isMine = request?.created_by === this.props.auth.user.id
    return (
      <LayoutSmall
        main={{ className: ""}}
        header={{ 
          left: { icon: 'arrow_left', action: this.goBack},
        }}
        container={{ className: "px-0"}}
        /* loading={this.props.locations.loading} */
      >
        <ToastContainer/>
        {request && 
          <>
          <section className="bg-primary text-primary-content text-center px-4 pb-4">
          {/*<Icon className="h-16 w-16 mx-auto mb-1" name="pin" />*/}
          <h1>{"Pedido"}</h1>
        </section>
        <section className="">
        
          <section className="max-w-md mx-auto"> 
            {/* <div className="bg-white shadow">
              <div className="container p-4">
                <img src={""} alt="" width="100%" />
              </div>
            </div> */}
            <div className="ml-1 mb-1 max-w text-sm breadcrumbs">
              <ul>
                {request.categories.map((category, index) => (
                  <li key={index}>{category.name}</li> 
                ))}
                </ul>
            </div>

            <section className="container-fluid p-4">
              {/*<div className="flex items-center justify-between">
              </div>*/}
                <h1 className="">{category.name}</h1>
                <p>{request.details}</p>
                <p>Pedido de: {request.requester?.first_name} {request.requester?.last_name}</p>
              <div className="flex justify-between items-center">
                <div className="flex items-center my-4">
                  {/*<small className="text-gray-400">{this.t("Puntos Shary")}</small><IconCoins iconClassName="h-6 w-auto" className="ml-2 mr-8 text-lg" coins={"coins"}/>
                  <small className="text-gray-400">{this.t("Ganás")}</small><IconPoints iconClassName="h-6 w-auto text-primary" className="ml-2 text-lg" points={"points"}/>*/}
                </div>
              </div>

              <div className="w-full mb-3 md:mb-0">
                <h4>{this.t("Puntos Shary sugeridos")}</h4>
                <div className="flex">
                  <h5>{this.t("Min")} <strong>{category.min_value}</strong></h5>
                  <h5 className="ml-5">{this.t("Max")} <strong>{category.max_value}</strong></h5>
                </div>
                {/*  <p className="mt-2">{this.t("Por día del producto prestado u hora del servicio o favor brindado.")}</p> */}
              </div>
            </section>
          </section>


          <div className="p-3">
            {!isMine && <Button
              onClick={this.gotoSubir}
              className="btn-primary btn-block"
              title={editing === true ? this.t("Guardar cambios") : this.t("Subir esta solicitud")}
            />}
            {isMine && <Button
              onClick={this.deleteRequest}
              className="btn-primary btn-block"
              title={this.t("Borrar pedido")}
            />}

          </div>
        </section>
        </>}
      
      </LayoutSmall>      
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,    
    request: state.transactions.current,
    message: state.messages.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetTransaction: (id) => dispatch(transactionsAction.get(id)),
    onDeleteTransaction: (id) => dispatch(transactionsAction.del(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Request));
