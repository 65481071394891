import { memo } from "react";
import { differenceInDays, differenceInHours } from "date-fns";
import clsx from "clsx";
import { strToDateWithoutTZ } from "../../libraries/utils";

function CompletedBar({ start, end, finished }) {
  let completedPercentage = 0  
  let message = '';

  if (start && end) {
    const startDate = strToDateWithoutTZ(start)
    const endDate = strToDateWithoutTZ(end)
    const today = new Date()

    const duration = differenceInHours(endDate, startDate);
    const completed = differenceInHours(today, startDate);
    
    if (startDate > today) {
      const daysToStart = differenceInDays(startDate, today);

      if(daysToStart < 1) {
        const hoursLeft = differenceInHours(startDate, today);
        message = `faltan ${hoursLeft} ${hoursLeft === 1 ? 'hora' : 'horas'} para empezar`;
      } else {
        message = `faltan ${Math.abs(daysToStart)} dias para empezar`;
      }
      
    } else {
      if (completed > duration) completedPercentage = 100
      else completedPercentage = Math.floor((completed / duration) * 100);
    }  
  } else {
    completedPercentage = 0
  }

  return (
    <>
      {!finished && (
        <div className="h-3 w-full top-0 bg-base-300 bg-opacity-80 -mt-[12px]">
          {!message ? (
            <div
              className="h-3 px-2 w-full min-w-[2rem] leading-full justify-end flex items-center bg-success bg-opacity-80 text-xs text-white transition-all duration-1000 ease-in-out"
              style={{ width: `${completedPercentage}%` }}
            >
              {completedPercentage}%
            </div>
          ) : (
            <div
              className="h-3 px-2 w-full leading-full justify-center flex items-center bg-gray-400 text-xs text-white uppercase transition-all duration-1000 ease-in-out font-extrabold"
            >
              {message}
            </div>
          )}
        </div>
      )}
      {finished && (
        <div className="-mt-[12px] h-3 p-2 w-full leading-full justify-center flex items-center bg-secondary text-xs text-white uppercase transition-all duration-1000 ease-in-out font-extrabold">
          completado
        </div>
      )}
    </>
  )
}

export default memo(CompletedBar)