const routes = {
  ROUTES: {
    HOME: '/',
    HOME_OPTIONS: {
      OFFERS: '/h/offers',
      SEARCH: '/h/search',
      ACTIONS: '/h/actions',
      CHALLENGES: '/h/challenges',
      PRODUCTS: '/h/products',
      FAVORS: '/h/favors',
      REQUESTS: '/h/requests',
    },
    LOGIN: '/login',
    SIGNUP: '/signup',
    SIGNUP_INVITATION: '/signup/:hash',
    SIGNUP_FULL: '/social/signup',
    FORGOT: '/forgot',
    VERIFY: '/verify/:hash',
    LOGIN_BENEFITS_INITIATIVES: '/login/:login_type',
    
    USER: {
      PASSWORD: '/user/password/:hash',
      PASSWORD_CHANGE: '/user/change',
      PROFILE: '/user/profile',
      PUBLIC_PROFILE: '/user/profile/:id',
      PROFILE_RATINGS: '/user/ratings',
      INFO: '/user/info',
      SETTINGS: '/user/settings',
      RANKING: '/user/ranking',
      CREDITS_HISTORY: '/user/credits-history',

      REFERRALS: '/user/referrals',

      INVENTORY: '/user/inventory',
      FAVOURITES: '/user/favourites',
      ACTIVITIES: '/user/activities',

      LOANS: '/user/loans',
      LOAN: '/user/loans/:id',
      CONFIRM_RETURNED:'/user/loans/:id/confirm-returned',

      RETURNS: '/user/returns',
      RETURN: '/user/returns/:id',
      CONFIRM_LOAN: '/user/returns/:id/confirm-loan',
      CONFIRM_RETURN: '/user/returns/:id/confirm-return',

      DIRECTORY: '/user/directory',
    },

    MAIN: {
      FEED: '/feed',
      OFFER: '/offers',
      INITIATIVES: '/initiatives',
      ACTIONS: '/actions',
      BENEFITS: '/benefits',
      PRIZES: '/prizes',
      RANKING: '/ranking',
    },

    PRODUCTS: {
      LIST: '/offers/products',
      DETAIL: '/offers/products/:id',
      CALENDAR: '/offers/products/:id/calendar',
      SUCCESS: '/offers/products/:id/success',
      EDIT:'/offers/products/:id/edit',
    },
    SERVICES: {
      LIST: '/offers/services',
      DETAIL: '/offers/services/:id',
      CALENDAR: '/offers/services/:id/calendar',
      SUCCESS: '/offers/services/:id/success',
    },
    FAVORS: {
      LIST: '/offers/favors',
      DETAIL: '/offers/favors/:id',
      CALENDAR: '/offers/favors/:id/calendar',
      SUCCESS: '/offers/favors/:id/success',
    },

    VOLUNTEERING: {
      LIST: '/initiatives/volunteering',
      DETAIL: '/initiatives/volunteering/:id',
      SUCCESS: '/initiatives/volunteering/:id/success',
    },
    DONATIONS: {
      LIST: '/initiatives/donations',
      DETAIL: '/initiatives/donations/:id',
      SUCCESS: '/initiatives/donations/:id/success',
    },
    RECYCLING: {
      LIST: '/initiatives/recycling',
      DETAIL: '/initiatives/recycling/:id',
      SUCCESS: '/initiatives/recycling/:id/success',
    },

    ACTIONS: {
      LIST: '/actions',
      DETAIL: '/actions/:id',
      SHARE: '/actions/:id/share',
      SUCCESS: '/actions/:id/success',
      ODS: '/ods',
    },

    CHALLENGES : {
      BASE: "/challenges",
      ACTIVITY: "/challenges/:id/activity",
      IMPACT: "/challenges/:id/impact",
      INFO: "/challenges/:id/info",
      LEADERBOARD: "/challenges/:id/leaderboard",
  
    },

    FEED: {
      DETAIL: '/feed/:id',
    },
    
    PRIZES: {
      LIST: '/prizes',
      BUSINESSES_LIST: '/prizes/businesses',
      DETAIL: '/prizes/:id',
      SUCCESS: '/prizes/:id/success',
    },

    BENEFITS: {
      LIST: '/benefits',
      BUSINESSES_LIST: '/benefits/businesses',
      DETAIL: '/benefits/:id',
      SUCCESS: '/benefits/:id/success',
    },
    QR_PAGE: '/qr',

    ADD: '/add',
    CATEGORY: '/add/:type/:sub_type',

    OFFERS: { 
      LIST: '/offers/:type',
      DETAIL: '/offers/:type/:id',
      EDIT: '/offers/:type/:id/edit',
      CALENDAR: '/offers/:type/:id/calendar',
      SUCCESS: '/offers/:type/:id/success',      

      DETAILS: '/add/offers/:type/details',
      AVAILABILITY: '/add/offers/:type/availability',
    },

    REQUESTS: { 
      DETAILS: '/add/requests/:type/details',
      SUCCESS: '/requests/:type/:id/success',
      LIST:'/requests',
      PREVIEW: '/requests/:id'
    },

    // STEP1: '/step1',  
    // STEP2: '/step2',  
    STEP3: '/step3',  
    // STEP31: '/step31',  
    

    HELP: '/help',
    TERMS: '/terms',
    PRIVACY: '/privacy',
    HOW_WORKS: '/how_works',
    CONTACT_US: '/contact_us',
    ASSISTANCE:'/assistance',
    INFO:'/info',
   


    SEARCH_OFFERS: '/search',
    SEND_MESSAGE:'/send_message',
    MESSAGE_SUCCESS: '/messages/:id/success',
    MESSAGES: '/messages',
    NOTIFICATIONS: '/notifications',
    CHAT: '/chat/:root_id',
    QUESTION: '/question/:root_id',


 
    SUCCESS: '/success',
    // CATEGORY_PAGE: '/category',
   // SEARCH_LIST: '/searching',

    // SCANQR: '/scanqr',
    // VOLUNTEERING_ATTENDANCE: '/initiatives/confirm',
    // VOLUNTEERING_ATTENDANCE_CONFIRMATION: '/initiatives/confirm/:volunteering_id',
    // VOLUNTEERING_ATTENDANCE_CONFIRMATION_SUCCESS: '/initiatives/confirm/:code/success',

    // BENEFITS_SCANQR: '/consume/scanqr',
    // BENEFITS_CONSUME: '/consume/benefits/',
    // BENEFITS_CONSUME_CONFIRMATION: '/consume/benefits/:benefit_id',
    // BENEFITS_CONSUME_CONFIRMATION_SUCCESS: '/consume/benefits/:code/success',

    REDIRECT: '/redirect/:route',

    FLOW: {
      BASE: '/flow/:id/',
      STEP1: '/flow/:id/step1',
      STEP2: '/flow/:idFlow/step2',
      STEP3: '/flow/:idFlow/step3',
      STEP4: '/flow/:id/step4',
      STEP5: '/flow/:idFlow/step5',
      STEP6: '/flow/:idFlow/step6',
      STEP7: '/flow/:idFlow/step7',
    }
    
    //success
    //failure
    //iniciatives/confirm/
    //iniciatives/confirm/scanqr
    //iniciatives/confirm/:id
  }
};
export default routes;

