import { combineReducers } from 'redux';

import users from './users';
import oauth from './oauth';
import files from './files';
import locations from './locations';
import cells from './cells';
import transactions from './transactions';
import internals from './internals';
import products from './products';
import services from './services';
import favors from './favors';
import donations from './donations';
import volunteering from './volunteering';
import recycling from './recycling';
import actions  from './actions';
import assistants from './assistants';
import categories from './categories';
import messages from './messages';
import benefits from './benefits';
import businesses from './businesses';
import companies from './companies';
import settings from './settings';
import levels from './levels';
import notifications from './notifications';
import challenges from './challenges';

export default combineReducers({
  users: users.reducers,
  oauth: oauth.reducers,
  files: files.reducers,
  locations: locations.reducers,
  products: products.reducers,
  services: services.reducers,
  favors: favors.reducers,
  cells: cells.reducers,
  transactions: transactions.reducers,
  internals: internals.reducers,
  donations: donations.reducers,
  volunteering: volunteering.reducers,
  recycling: recycling.reducers,
  assistants: assistants.reducers,
  actions: actions.reducers,
  categories: categories.reducers,
  messages: messages.reducers,
  benefits: benefits.reducers,
  businesses: businesses.reducers,
  companies: companies.reducers,
  settings: settings.reducers,
  levels: levels.reducers,
  notifications: notifications.reducers,
  challenges: challenges.reducers,
});
