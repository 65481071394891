import React from 'react';
import { useTranslation } from 'react-i18next';
import {isFunction, formatFullname, getObjectWithJsonDataToFormValues  } from '../../libraries/utils';

import Button from '../commons/Button';
import Avatar from '../commons/Avatar';
import IconCoins from '../../components/customs/IconCoins';
import IconPoints from '../../components/customs/IconPoints';

import { Link } from 'react-router-dom';
import { history } from '../../routes';
import config from '../../config';

import ButtonIcon from '../commons/ButtonIcon';
import Icon from '../../libraries/icons';


export default function ReturnCard({ loan, statuses, actions, ...rest }) {
  const { t } = useTranslation();  

  const { id, product, offer, status, owner, created_at }  = getObjectWithJsonDataToFormValues(
    loan,
    ['id', 'owner', 'product','status','borrower','type','sub_type','created_at', 'coins', 'points', 'offer']
  );

  const timeRemains = t("timeRemains", { date: new Date(created_at) })

  const gotoSendMessage = (user) => {
    history.push({
      pathname: config.ROUTES.SEND_MESSAGE,
      state: {
        fromPath: config.ROUTES.USER.RETURNS,
        user
        }
      }
    );
  }

  const gotoReclamo = () => {
    history.push({
      pathname: config.ROUTES.SEND_MESSAGE,
      state: {
        fromPath:config.ROUTES.USER.RETURNS,
        reclamo: {
          type: "reclamo",
          related_to: id,
        },
        }
      }
    );
  }

  return (
    loan && (
      <div className={"card white card-compact shadow-xl " + (rest.className ? rest.className : "")}>
        <div className="card-body">
          <Link className="w-full flex"
              to={{ 
                pathname: config.ROUTES.PRODUCTS.DETAIL.replace(':id', product ? product.id : offer.id),
                myCustomProps: config.ROUTES.USER.RETURNS,
                state: {
                  fromPath: config.ROUTES.USER.RETURNS,
                }
              }}
          >
            {(product || offer?.img) ? (
              <Avatar
                image={product ? product.img : offer.img}
                className="rounded-full w-10 h-10"
                containerClassName="mr-2"
              />
              ) : (<div className="rounded-full w-10 h-10"></div>)
            }
            <div className='w-full'>
              <h4>{t(product ? product.name : offer.name)}</h4>
              <div className='flex items-center justify-between mt-1'>
                <div className="flex gap-2 flex-1">
                  <IconCoins negative={true} coins={product ? product.coins : offer.coins}/>
                  <IconPoints points={product ? product.points : offer.points}/>
                </div>
                <div className="flex items-center">
                  <Icon className="h-3 w-3 mr-1 text-gray-500 leading-tight" name="clock" />
                  <small className="text-gray-500 font-normal">{timeRemains}</small>
                </div>   
              </div>
              {/* <small>{this.t("por día")}</small> */}
              {/* <small className="text-gray-500">{product.distance} km</small> */}
            </div>              
          </Link>
          <div className="flex bg-base-200 rounded items-center p-2 mt-1 mb-1">
            <Link className="flex"
              to={{ 
                pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(':id', owner.id),
                myCustomProps: config.ROUTES.USER.RETURNS
              }}
            >
              <Avatar
                image={owner.profile_image}
                label={owner.username}
                className="rounded-full w-6 h-6"
                containerClassName="mr-2"
              />
              <h5>{t(formatFullname(owner))}</h5>
            </Link>
            <div className="ml-auto">
              <ButtonIcon buttonClassName="btn-sm btn-ghost bg-base-100" className="w-4 h-4 text-neutral mr-1" icon="chat_alt"
                onClick={() => {gotoSendMessage(owner)}}
              />
              <ButtonIcon buttonClassName="btn-sm btn-ghost capitalize text-xs btn-block rounded-none text-gray-500 w-1/2" className="mr-1 w-4 h-4" 
                onClick={gotoReclamo} icon="flag"/>
            </div>
          </div>       
          <div className="card-actions">
            {actions && actions.map((action, index) => {
              if (action.status.includes(status)) {
                return (
                    <Button
                      key={index}
                      disabled = {action.disabled && action.disabled(loan)}
                      className={"btn-sm flex-1 " + (action.className ? action.className : "")}
                      title={action.title}
                      onClick={() => { if (isFunction(action.onClick)) action.onClick(loan); }}
                    />
                  );
              } else {
                return null
              }}           
            )}             
          </div>
        </div>
      </div>
    )

  )
}