import React from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../config';
import { formatFullname } from '../../libraries/utils';
import Avatar from '../../components/commons/Avatar';
import Icon from '../../libraries/icons';
import { history } from '../../routes';

export default function NotifyElement({ data,route,type, ...rest }) {
  const { t, i18n } = useTranslation();

  const parsedSource = data.source.json_data ? JSON.parse(data.source.json_data) : data.source 
  const parsedTarget = data.target.json_data ? JSON.parse(data.target.json_data) : data.target
  const createdAt = new Date(data.created_at)

  let userName= "";
  let userImg = "";
  let fullName="";
 
  switch (type){
    case "received":
       userName= data.source.username
       userImg= data.source.profile_image
       fullName = formatFullname(parsedSource)
      break;

    case "sended":
      userName= data.target.username
      userImg= data.target.profile_image
      fullName = formatFullname(parsedTarget)
      break; 
    default: 
      
  }
    console.log(route)
    return (    
      <div key={rest.key} className={`flex items-center p-3 ${rest.className && rest.className}` + (data.readed === 0 ? ' bg-primary bg-opacity-[0.15]' : '')}
        onClick={() => route && history.push(route)}
      >
        <Avatar
          image={userImg}
          label={userName}
          className="rounded-full w-12 h-12"
        />
        <div className="ml-3 mr-auto">
          <p className="text-gray-500">
            <b className='text-primary'>{fullName} </b>{data.body}
          </p>
          {createdAt && <h6 className='text-gray-300'>{t("timeRemains", { date: new Date(createdAt) })}</h6>}
        </div>
      </div>
    )
  // }
}