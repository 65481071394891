import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatFullname, isJSON, renderDate } from '../../libraries/utils';
import Avatar from '../../components/commons/Avatar';
import Icon from '../../libraries/icons';
import { history } from '../../routes';

export default function CommentElement({ data, route, type, owner, ...rest }) {
  const { t } = useTranslation();

  const { id, profile_image, username, json_data } = data.source
  const parsedData = isJSON(json_data) ? JSON.parse(json_data) : json_data
  const { first_name, last_name } = parsedData || data.source

  return (
    <div className="flex items-center p-2 border-b">
      <Avatar
        image={profile_image}
        label={username}
        className="rounded-full w-10 h-10"
        id={id}
      />
      <div className="flex justify-between w-full items-center ml-3 mr-auto">
        <div className="flex flex-col text-gray-700">
          <b>{formatFullname({ first_name, last_name })}</b>
          <p className='flex items-center'>{data.body}</p>
           {data.json_data?.answered ? (
           <p className='text-gray-500 pl-3 flex items-center mt-2'>
             <Icon name='chat_alt' className='w-4 h-4 mr-1'/>
             {data.json_data?.last_message_portion}
           </p>
         ) : null}
        </div>
        
        <div className='flex flex-col items-end'>
          <small className='text-gray-400 w-max'>{renderDate({ISOdate:data.created_at, showDuration: true})}</small>
        </div>
      </div>
    </div>
  )
}