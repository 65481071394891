import Button from "../../components/commons/Button";
import imgGift from "../../assets/img/img-stepping_up.png";
import React, { Component, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import actions from "../../context/actions/actions";
import actionBenefits from "../../context/benefits/actions";
import { notify } from "../../libraries/notifications";
import Loader from "../../components/commons/Loader";
import { history } from "../../routes";
import config from "../../config/";
import IconCredits from "../../components/customs/IconCredits";
import { CardOnboarding } from "./FlowStep1";
import BenefitListCard from "../../components/customs/BenefitListCard";


class FlowStep7 extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.id = this.props.match.params.id;
    this.idFlow = this.props.match.params.idFlow;
    this.currentUrl = this.props.location?.pathname;
    this.state = {
      action: null,
      benefit: null
    }
  }

  getFlow() {
    const flow = config.ACTION_BY_DEFAULT.find(f => f.id == this.idFlow)
    if (flow) this.setState({ flow: flow, challenge: flow?.challenge, action: flow?.action })
  }

  async getAction() {
    await this.props.onGetAction({ id: this.id })
    const error = this.props.action.error
    if (error) {
      notify(error)
    } else {
      this.setState({ action: this.props.action.item })
    }
  }
  async getBenefit() {
    await this.props.onGetBenefit(this.state.challenge?.json_data.benefits?.benefit)
    const error = this.props.benefit.error
    if (error) {
      notify(error)
    } else {
      this.setState({ benefit: this.props.benefit.item })
    }
  }

  componentDidMount() {
    this.getFlow();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.challenge !== prevState.challenge) {
      if (this.state.challenge?.json_data.benefits?.quantity == null) {
        this.getBenefit()
      }
    }

  }

  render() {

    const isCoins = this.state.challenge?.json_data.benefits?.benefit == 'coins' && this.state.challenge?.json_data.benefits?.quantity !== null ? true : false
    const isCredits = this.state.challenge?.json_data.benefits?.benefit == 'credits' && this.state.challenge?.json_data.benefits?.quantity !== null ? true : false
    // const isBenebits = this.state.challenge?.json_data.benefits?.quantity === null && this.state.benefit ? true : false

    return (
      <main className="h-full text-center ">
        {this.props.action?.loading && <Loader />}
        {this.state.action && this.state.challenge &&
          <CardOnboarding>
            {isCoins && <CoinsContent
              buttons={this.state.challenge.buttons}
              detail={this.state.challenge.details}
              title={this.state.challenge.title}
              subtitle={this.state.challenge.subtitle}
              question={this.state.challenge.question}
              pathname={this.props.location.pathname}
            />}
            {isCredits && <CreditsContent challenge={this.state.challenge} userCode={this.props.auth.user?.location || 'argentina'} />}
          </CardOnboarding>
        }
      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    action: state.actions.current,
    benefit: state.benefits.current,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetAction: (params) => dispatch(actions.get(params)),
    onGetBenefit: (params) => dispatch(actionBenefits.get(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(FlowStep7));

const CreditsContent = ({ challenge, userCode }) => {
  const [benefits, setbenefits] = useState(null)
  const dispatch = useDispatch();
  const benefitsFound = useSelector(state => state.benefits.list);
  const userLevel = useSelector(state => state.users.auth.user.rewards.level);

  useEffect(() => {
    getAllBenefits()
  }, [])

  useEffect(() => {
    setbenefits(benefitsFound.items)
  }, [benefitsFound.items])


  const getAllBenefits = async () => dispatch(actionBenefits.getAll({ enabled: 1, type: ["maslow"], code: userCode }))


  return (
    <div className=" h-full w-full flex flex-col justify-between md:justify-center gap-4 items-center pt-10">
      <div className=" w-full flex flex-col gap-1 items-center px-1">
        <IconCredits iconClassName="h-10" defaultIconColor="text-primary" color='#2A8189' credits={null} />
        <h1 className="text-2x font-normal"><span className="font-bold block">Canjeá</span> con tus créditos</h1>
        <p className="text-base text-neutral">Cuanto más ayudás más te beneficiás.
          Canjeá los créditos que ganás por lo que quieras en el marketplace de créditos</p>
      </div>

      <section className="w-[105%] -mr-4 md:-mr-0 md:w-full">
        <div className="flex scrolling-wrapper gap-4 mt-2  w-full py-4 ">
          {benefits && benefits.map((benefit, index) => {
            return (
              <BenefitListCard
                key={index}
                benefit={benefit}
                business={benefit.updatedBusiness}
                route={{ pathname: config.ROUTES.BENEFITS.DETAIL.replace(':id', benefit.id), state: { fromPath: config.ROUTES.BENEFITS.LIST } }}
                userLevel={userLevel} // O donde sea que obtengas userLevel
                className="min-w-[250px] " // Ajusta el tamaño mínimo aquí
              />
            )
          })}
        </div>
      </section>
      <div className="flex justify-between w-full gap-2">
        <Button
          className="flex-1 btn btn-outline md:mt-5"
          title={("Ahora no").toUpperCase()}
          onClick={() => history.push('/')}
        />
        <Button
          className="flex-1 btn btn-primary md:mt-5"
          title={("Ver todos").toUpperCase()}
          onClick={() => history.replace(config.ROUTES.BENEFITS.LIST)}
        />
      </div>
    </div>
  )
}
const CoinsContent = ({ buttons, title, subtitle, detail, question, pathname }) => {
  const [OptionSelected, setOptionSelected] = useState(null);

  const onChangeSelected = (e) => setOptionSelected(e.target.value)
  return (
    <div className="flex flex-col items-center justify-between h-full w-full">
      <div className="">
        <img src={imgGift} alt="" width={"350px"} />
      </div>
      <div>
        <h1 className="md:text-3xl ">{title}</h1>
        <h2 className="md:text-3xl text-2xl">{subtitle}</h2>
      </div>
      <p className="md:text-xl px-10 text-base">{detail}</p>
      <div className="">
        <h3 className="mb-5">{question}</h3>
        <div className='flex flex-col gap-2'>
          {buttons?.map((op, i) => (
            <Button
              key={op.label + i}
              className={`btn  basis-1/2  ${i % 2 == 0 ? 'btn-primary' : 'btn-outline'} md:mt-1`}
              title={(op.label).toUpperCase()}
              onClick={() => history.push({
                pathname: op.url,
                state: { fromPath: pathname }
              })}
            />
          ))}
          <Button
            className="basis-1/2 btn btn-link  border-2 md:mt-1"
            title={("Ahora no").toUpperCase()}
            onClick={() => history.push('/')}
          />
        </div>
        {/* <p>Opción seleccionada: {OptionSelected !== null ? (OptionSelected === '0' ? 'Prestar producto' : 'Brindar Favor') : 'Ninguna'}</p> */}
      </div>
    </div>
  )
}