import React from 'react';
import { Link } from 'react-router-dom';
/* import Icon from '../../libraries/icons'; */
import IconPoints from './IconPoints';
import IconCoins from './IconCoins';
import { getObjectWithJsonDataToFormValues  } from '../../libraries/utils';
import { useTranslation } from 'react-i18next';
import Button from '../commons/Button';
import { history } from '../../routes';
import BusinessNameImage from './BusinessNameImage';

export default function VolunteeringCard({route, data,user, ...rest }) {
  const { t, i18n } = useTranslation();  
  
  const { name, img, coins, points, assistants} = getObjectWithJsonDataToFormValues(
    data,
    ['id', 'name', 'img', 'coins', 'points','assistants']
  );
  /* console.log("ASSITANTS",assistants);

  const assistants_me = assistants.filter(asist => asist.assistant === user.id);
  
  if(assistants_me.length > 0){
    let assitant = assistants_me[0];
    console.log("ASISTENTE",assitant)  
  } */

  return (
    <Link
      className={"card white overflow-hidden block " + (rest.className ? rest.className : "")}
      to={route}
    >
      {/* <div className="items-center text-center w-auto">
        <h4 className="text-white bg-error">{t("YA TE POSTULASTE")}</h4>
      </div> */}
      <div className="max-h-40 overflow-hidden flex justify-center items-center">
        <img src={img} alt="" width="100%"/>
      </div>
      <div className="justify-between items-center py-3 px-4 w-full">
        <div className="mb-4">
          <h5 className='font-bold'>{t(name)}</h5>
          {rest.business && <BusinessNameImage business={rest.business} />}
        </div>
        <div className="flex mb-4">
          {coins && coins !== "0" && <IconCoins iconClassName="h-4 w-auto" className="mr-4" coins={coins}/>}
          {points && points !== "0" &&<IconPoints iconClassName="h-4 w-auto text-primary" points={points}/>}
        </div>
        <Button
          className="btn-primary btn-sm btn-block mt-2"
          title={t("Ver más")}
          onClick={() => history.push(route)}
        />
      </div>
    </Link>
  )
}