import React from 'react';

function StarIcon(props) {
  const { fill = 'none' } = props;
  return (
    <svg className="w-10 h-10" fill={fill} stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path></svg>
  );
}

function RatingIcon(props) {
  const {
    index,
    rating,
    hoverRating,
    onMouseEnter,
    onMouseLeave,
    onSaveRating,
    className
  } = props;

  const fill = React.useMemo(() => {
    // console.log('USEMEMO', rating, hoverRating, index);
    if (hoverRating >= index) {
      return 'yellow';
    } else if (!hoverRating && rating >= index) {
      return 'yellow';
    }
    return 'none';
  }, [rating, hoverRating, index]);

  return (
    <div
      className={"cursor-pointer " + (className ? className : "" )}
      onMouseEnter={() => onMouseEnter(index)} 
      onMouseLeave={() => onMouseLeave()} 
      onClick={() => onSaveRating(index)}>
      <StarIcon fill={fill} />
    </div>
  )
}

export default function RatingIcons({ initalRating, onClick, disabled = true, className }) {
  const [rating, setRating] = React.useState(0);
  const [hoverRating, setHoverRating] = React.useState(0);

  const onMouseEnter = (index) => {
    if (!disabled) setHoverRating(index);
  };
  const onMouseLeave = () => {
    if (!disabled) setHoverRating(0);
  };
  const onSaveRating = (index) => {
    if (!disabled) setRating(index);
    if (!disabled && onClick) onClick(index);
  };
  return(
    <div className="box flex">
      {[1, 2, 3, 4, 5].map((index) => {
        return (
          <RatingIcon
            className={className}
            key={index} 
            index={index} 
            rating={initalRating} 
            hoverRating={hoverRating} 
            onMouseEnter={onMouseEnter} 
            onMouseLeave={onMouseLeave} 
            onSaveRating={onSaveRating}
            disabled={disabled}
          />
        )
      })}
    </div>
  );
}
