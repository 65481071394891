export default {
  CLEAR_CURRENT: 'ACTIONS_CLEAR_CURRENT',
  GET_REQUEST: 'ACTIONS_GET_REQUEST',
  GET_SUCCESS: 'ACTIONS_GET_SUCCESS',
  GET_FAILURE: 'ACTIONS_GET_FAILURE',

  SAVE_REQUEST: 'ACTIONS_SAVE_REQUEST',
  SAVE_SUCCESS: 'ACTIONS_SAVE_SUCCESS',
  SAVE_FAILURE: 'ACTIONS_SAVE_FAILURE',

  GETALL_REQUEST: 'ACTIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'ACTIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'ACTIONS_GETALL_FAILURE',

  DELETE_REQUEST: 'ACTIONS_DELETE_REQUEST',
  DELETE_SUCCESS: 'ACTIONS_DELETE_SUCCESS',
  DELETE_FAILURE: 'ACTIONS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'ACTIONS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ACTIONS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ACTIONS_UPDATE_FAILURE'    

};