import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import challengesActions from '../../context/challenges/actions'
import { history } from '../../routes';
import config from '../../config';
import ChallengesWrapper from './ChallengesWrapper'
import Icon from '../../libraries/icons'
import Loader from '../../components/commons/Loader';


class Impact extends React.Component {
  constructor(props) {
    super(props)
    this.t = this.props.t;
    this.state = {
      loading: true,
      leaderboard: [],
      challengeODS: [],
    }
  }
  
  async componentDidMount() {
    const { id } = this.props.match.params
    await this.props.getLeaderboard(id)
    await this.props.getActivity(id)
    
    const activity = this.props.activity.length

    const challenge = this.props.challenge.current
    const leaderboard = challenge?.leaderboard?.leaderboard
    const points = leaderboard.length ? leaderboard.map(p => parseInt(p.impact_points)) : [0]

    var challengeODSActions = {};
    for(let activityItem of this.props.activity){
      const itemODS = activityItem.json_data?.action?.ods?.split(",");
      if(itemODS)
        for(let odsCode of itemODS){
          if(odsCode.trim() !== ""){
            challengeODSActions[odsCode.trim()] = (challengeODSActions[odsCode.trim()] + 1 || 1); 
          }
        }
    }     

    const challengeODS = Object.keys(challengeODSActions).length > 0 
      ? Object.keys(challengeODSActions).map(odsCode => {
          const ods = config.ODS.find(configODS => odsCode.trim() === configODS.code);
          if(ods) return {...ods, actionsAmount: challengeODSActions[odsCode]}
          else return undefined;
        }) 
      : []

    const pointsReduced = points.reduce((acc, o) => acc + parseInt(o), 0)
    this.setState({challengeODS, leaderboard, activity, participants: leaderboard?.length, points: pointsReduced, loading: false })
  }

  render() {
    const { challengeODS, participants, points, activity, loading} = this.state

    return (
    <ChallengesWrapper>
      <div className='flex flex-col gap-3'>
        {loading && <Loader />}
        {!loading && (
          <>
            <div className='w-full p-2 flex justify-between items-center border-2 rounded-lg bg-[#CEF7ED] border-white shadow-[0px_0px_20px_rgba(41,127,135,0.2)]'>
              <div className='leading-3	'>
                <h3 className='text-[#43C7A0] pb-2'>{points || 0} Puntos</h3>
                <span className='text-xs text-[#759699]'>{this.t("Puntos de impacto totales")}</span>
              </div>
              <div className='w-10'>
                {/* <Icon name='cloud' size={35} /> */}
              </div>
            </div>

            <div className='w-full p-2 flex justify-between items-center border-2 rounded-lg bg-[#D7EEFE] border-white shadow-[0px_0px_20px_rgba(41,127,135,0.2)]'>
              <div className='leading-3	'>
                <h3 className='text-[#57ACC1] pb-2'>{activity || 0} Acciones</h3>
                <span className='text-xs text-[#759699]'>{this.t("Cantidad de acciones")}</span>
              </div>
              <div className='w-10'>
                {/* <Icon name='drop' size={35} /> */}
              </div>
            </div>

            <div className='w-full p-2 flex justify-between items-center border-2 rounded-lg bg-[#FFFCEB] border-white shadow-[0px_0px_20px_rgba(41,127,135,0.2)]'>
              <div className='leading-3	'>
                <h3 className='text-[#E3C662] pb-2'>{participants || 0} Usuarios</h3>
                <span className='text-xs text-[#759699]'>{this.t("Cantidad de usuarios que realizaron acciones")}</span>
              </div>
              <div className='w-10'>
                {/* <Icon name='trash_arrow' size={35} /> */}
              </div>
            </div>
          </>
        )}
      </div>

      {challengeODS.length > 0 && <div className='mt-8 card shadow-[0_3px_5px_3px_rgba(0,0,0,0.2)] p-2 bg-success bg-opacity-50 mb-4'>
        <h4 className='text-base mb-2 mx-auto'>Este desafío contribuye a estos ODS:</h4>
        <div className='flex mx-auto items-center justify-center flex-wrap mb-2'>
          {challengeODS && challengeODS.map(ods => (
            <div key={ods.code}>
              <img key={ods.code} src={ods.squareIcon} alt='ods' className='p-1 mx-2 w-16 h-16' />
              <p className="text-center text-gray-700">{ods.actionsAmount} {ods.actionsAmount > 1 ? "acciones" : "acción"}</p>
            </div>
          ))}
        </div>
          <p className='border-t justify-center items-center gap-2 border-primary border-opacity-25 text-sm font-medium pt-2 flex'
            onClick={()=>history.push({pathname: config.ROUTES.ACTIONS.ODS, state: { from: document.location.pathname }})}
          >
            <Icon name='information_solid' size={16} /> Aprendé más sobre las ODS
          </p>
      </div>}
      
    </ChallengesWrapper>
    )
  }

}



const mapStateToProps = state => {
  return {
    user: state.users.auth.user,
    challenge: state.challenges,
    activity: state.challenges.current.activity,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLeaderboard: (id) => dispatch(challengesActions.getLeaderboard(id)),
    getActivity: (id) => dispatch(challengesActions.getActivity(id)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Impact))