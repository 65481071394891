export default {
  CLEAR_CURRENT: 'DONATIONS_CLEAR_CURRENT',
  GET_REQUEST: 'DONATIONS_GET_REQUEST',
  GET_SUCCESS: 'DONATIONS_GET_SUCCESS',
  GET_FAILURE: 'DONATIONS_GET_FAILURE',

  SAVE_REQUEST: 'DONATIONS_SAVE_REQUEST',
  SAVE_SUCCESS: 'DONATIONS_SAVE_SUCCESS',
  SAVE_FAILURE: 'DONATIONS_SAVE_FAILURE',

  GETALL_REQUEST: 'DONATIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'DONATIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'DONATIONS_GETALL_FAILURE',

  DELETE_REQUEST: 'DONATIONS_DELETE_REQUEST',
  DELETE_SUCCESS: 'DONATIONS_DELETE_SUCCESS',
  DELETE_FAILURE: 'DONATIONS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'DONATIONS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'DONATIONS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'DONATIONS_UPDATE_FAILURE'    

};