import React from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../../routes';
import Button from '../commons/Button';
import IconPoints from './IconPoints';
import IconCoins from './IconCoins';
import { getObjectWithJsonDataToFormValues  } from '../../libraries/utils';
import BusinessNameImage from './BusinessNameImage';

export default function DonationCard({ route, data, ...rest }) {
  const { t, i18n } = useTranslation();

  const {name, img, coins, points} = getObjectWithJsonDataToFormValues(
    data,
    ['id','name', 'img', 'coins', 'points']
  );

  return (
    <div className={"card white overflow-hidden " + (rest.className ? rest.className : "")}>
      <div className="h-40 overflow-hidden flex justify-center items-center">
        <img src={img} alt="" className="h-full w-auto max-w-none" />
      </div>
      <div className="p-3 w-full">
        <div className="mb-3">
          <p className="h-10 ellipsis-2-line">{name}</p>
          {rest.business && <BusinessNameImage business={rest.business} />}
        </div>
        <div className="flex mb-3">
          {coins && coins !== "0" && <IconCoins iconClassName="h-4 w-auto" className="mr-4 text-sm" coins={coins}/>}
          {points && points !== "0" &&<IconPoints iconClassName="h-4 w-auto text-primary" className="text-sm" points={points}/>}
        </div>
        <Button
          className="btn-primary btn-xs btn-block mt-1"
          title={t("Ver más")}
          onClick={() => history.push(route)}
        />
      </div>
    </div>
  )
}