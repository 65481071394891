import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatFullname, isJSON, convertUTCDateToLocalDate, renderDate } from '../../libraries/utils';
import Avatar from '../../components/commons/Avatar';
import Icon from '../../libraries/icons';
import { history } from '../../routes';
import { Link } from 'react-router-dom';
import {intervalToDuration} from 'date-fns'

export default function QuestionElement({ data, route, type, owner, ...rest }) {
  const { t, i18n } = useTranslation();

  const isOwner = data.source.id === owner
  
  const {profile_image, username, json_data} = isOwner ? data.target : data.source

  const parsedData = isJSON(json_data) ? JSON.parse(json_data) : json_data

  const {first_name, last_name} = parsedData
  const {answered, product_name, product_img, last_message_at} = data.json_data
  const notificationsForOtherPart = data.json_data[`unread_messages_for_${isOwner ? 'target':'source'}`]


  const notifications = data.json_data[`unread_messages_for_${isOwner ? 'source':'target'}`]

  return (    
    <Link to={route} key={rest.key} className={`flex items-center p-3 ${rest.className && rest.className}` + (notifications > 0 ? ' bg-primary bg-opacity-[0.15]' : '')}>
      <Avatar
        image={product_img || profile_image}
        label={username}
        className="rounded-full w-12 h-12"
      />
      <div className="ml-3 mr-auto flex justify-between w-full items-start">
        <div className="text-gray-500 flex flex-col">
          <p className='font-normal'>
            
            {answered
              ? (isOwner
                ? <>Te respondieron en <b className='text-primary'>{product_name}</b></>
                : <>Respondiste a <b className='text-primary'>{formatFullname({first_name, last_name})}</b> en <b>{product_name}</b></>
              )
              : isOwner
                ? <>Preguntaste en <b>{product_name}</b></>
                : <><b className='text-base-content'>{formatFullname({first_name, last_name})}</b> preguntó por <b className='text-primary'>{product_name}</b></>
            }
          </p>
          <p className='flex items-center font-normal text-neutral'>
            <Icon name='conversation' size={14}  className='mr-1' />
            {((data.json_data?.last_message_from === 'source' && isOwner) || (data.json_data?.last_message_from === 'target' && !isOwner)) &&
                <span className="text-neutral mr-1" >
                  <Icon className={`h-4 w-4 ${notificationsForOtherPart === 0 ? 'text-success': ''}`} name='check'/>
                </span>
            }
            <span>{data.json_data?.last_message_portion?.slice(0, window.screen.width / 17)  + (data.json_data?.last_message_portion?.length >= window.screen.width / 14 ? '...': '')}</span>
          </p>
        </div>
        <div className='flex flex-col items-end gap-3'>
          <small className={(notifications ? 'text-primary' :'text-gray-300') + ' font-light text-xs w-max'}>{renderDate({ISOdate:last_message_at, isLocal:true, t})}</small>
          {notifications > 0 && <small className="text-white bg-primary h-5 w-5 flex rounded-full items-center justify-center">{notifications}</small>}
        </div>
      </div>
    </Link>
  )
}