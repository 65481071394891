export const StepsNewUser = (op, data=false) => {

  switch (op) {
    case 0:
      return {
        header: {
          imagen: '',
          video: '',
          title: 'Da tu primer paso',
          subtitle: 'Mira este video y en 30 segunbdos conoce que es Sharyco'
        },
        body: {
          children: <FirstStep />
        },
        footer: {
          buttons: [
            {
              title: 'Mirar Video',
              action: () => { }
            }
          ]
        }
      }
    case 1:
      return {
        header: {
          imagen: '',
          video: 'https://www.youtube.com/shorts/VYDRpPJmSYo',
          title: '',
          subtitle: ''
        },
        body: {
          children: <SecondStep disabledButton={!data} />
        },
        footer: {
          buttons: [
            {
              title: 'Contestar',
              action: () => { },
              disabled:!data
            }
          ]
        }
      }
    case 2:
      return {
        header: {
          imagen: require('./fotos/foto2.png'),
          video: '',
          title: '',
          subtitle: ''
        },
        body: {
          children: <SecondStep disabledButton={!data} />
        },
        footer: {
          buttons: [
            {
              title: 'Siguiente',
              action: () => { },
              // disabled: true
            }
          ]
        }
      }
    default:
      break;
  }
}

const FirstStep = () => {
  return (
    <div className='mx-auto flex justify-center mt-8'>
      <img src={require('./fotos/Cards.png')} />
    </div>
  )
}
const SecondStep = ({ disabledButton = true }) => {
  return (
    <div className='h-full bg-white flex flex-col   py-8 items-center pt-4 px-8'>
      <h1 className='text-primary  font-bold mb-6'>Questions</h1>
      <ul className=' flex flex-col gap-4 w-full'>
        {[...Array(4).keys()].map((op) =>
          <li key={op} className='flex w-full py-3 px-2 bg-gray-100 gap-2 rounded-md'>
            <input
              disabled={disabledButton}
              type="checkbox"
              // defaultChecked 
              className="checkbox checkbox-white  bg-opacity-25 border rounded-full" />

            <p className='text-center text-primary font-bold'>
              {`Opción ${op}`}
            </p>
          </li>
        )}
      </ul>
    </div>
  )
}
