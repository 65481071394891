import ActionCard from "./ActionCard"
import InventoryCard from "./InventoryCard"
import config from '../../config';
import ChallengesCard from "./ChallengesCard";

export default function SearchResults  ({ offers = [] }) {
  const actions = offers.filter(c => c.cardType === "action")
  const challenges = offers.filter(c => c.cardType === "challenge")
  const products = offers.filter(c => c.cardType !== "action" && c.cardType !== "challenge" )

  return (
    <>
      {actions.length > 0 && (
        <>
          <h3>Acciones</h3>
          {actions.map(action => (
            <ActionCard
              key={action.id}
              data={action}
              route={config.ROUTES.ACTIONS.DETAIL.replace(":id", action.id)}
            />
          ))}
        </>
      )}
      {products.length > 0 && (
        <>
          <h3>Productos</h3>
          {products.map(action => (
            <InventoryCard
              edit = {false}
              key={action.id}
              data={action}
            />
          ))}
        </>
      )}
      {challenges.length > 0 && (
        <>
          <h3>Desafíos</h3>
          {challenges.map(challenge => (
            <ChallengesCard
              key={challenge.id}
              data={challenge}
              route={config.ROUTES.CHALLENGES.INFO.replace(':id', challenge.id)}
            />
          ))}
        </>
      )}
      <div className='my-7'/> 
    </>
  )
}