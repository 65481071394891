import React from 'react';
import Icon from '../../libraries/icons';

export default function SmallRating({rate, fixed=1, ...rest}) {
  const value = parseFloat(rate || 0).toFixed(fixed)
  return (
    <div className={`badge badge-sm badge-ghost ${rest.containerClassName || "absolute px-1 right-2 top-2"}`}>
      <Icon className={`h-4 w-4 mr-1 ${rest.starClassName || "text-yellow-300"}`} name="star" />
      <small className={` ${rest.textClassName || ""}`}>{value}</small>
    </div>
  ) 
};


