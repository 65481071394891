import React from 'react';
import { Link } from 'react-router-dom';
import IconPoints from './IconPoints';
import IconCoins from './IconCoins';
import { useTranslation } from 'react-i18next';
import { getObjectWithJsonDataToFormValues  } from '../../libraries/utils';
import UserOwnerElement from './UserOwnerElement';
import Avatar from '../commons/Avatar';

import Icon from '../../libraries/icons';

export default function ServiceElement({ service, route,  ...rest }) {
  const { t, i18n } = useTranslation();  

  const { img, coins, points, name } = getObjectWithJsonDataToFormValues(
    service,
    ['id', 'name', 'img', 'coins', 'points','url']
  );

  const owner = getObjectWithJsonDataToFormValues(
    service.user,
    ['id', 'username', 'first_name', 'last_name', 'profile_picture', 'rating', 'rate_total', 'rate_count']
  );

  return (
    <Link
      key={rest.key}
      to={route}
    >
      <div className={"overflow-hidden border-b border-gray-100 " + (rest.className ? rest.className : "")}>
        <div className="flex p-4">
          <Avatar
            image={owner.profile_image}
            label={owner.username}
            className="rounded-full w-10 h-10"
            containerClassName="mr-3"
          />       
          <div className="w-full flex justify-between flex-col">
            <div>
              <h5 className="font-bold">{t(name)}</h5>
            </div>
            <UserOwnerElement className="flex items-center mb-3" starClassName="ml-2" owner={owner} />
            <div className="flex justify-between items-center">
              <div className="flex">
                <IconCoins className="mr-4 text-sm" coins={coins}/>
                <IconPoints iconClassName="text-primary" className="text-sm" points={points}/>         
              </div>
              {/* {service.distance && <small className="text-gray-500">{service.distance} km</small>} */}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}