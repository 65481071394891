import React from 'react';
import { getObjectWithJsonDataToFormValues  } from '../../libraries/utils';
import { useTranslation } from 'react-i18next';

export default function UserProfileImage({ data, picture, username, ...rest }) {
  const { t, i18n } = useTranslation();

  const { profile_image, name, first_name,last_name} = data ? getObjectWithJsonDataToFormValues(
    data,
    ['id', 'profile_image', 'name', 'first_name','last_name']
  ) : {};

  const fullname = `${first_name} ${last_name}`;
  

  return (
    <>
      <div className={
          "rounded-full bg-white bg-opacity-30 overflow-hidden flex items-center text-center m-auto mb-3 "
          + (picture && picture.className ? picture.className : "w-16 h-16")
        }
        onClick={()=> { picture && picture.onClick && picture.onClick(); }}
      >
        {profile_image ? (
          <img className="w-full h-full object-cover" src={profile_image} alt="" />
        ) : (
          <span className="m-auto text-white text-2xl">{name}</span>
        )}
      </div>
      {/* <h4 className={username && username.className ? username.className : "h4 font-normal text-white mb-0"}>
        {fullname}
      </h4> */}
    </>
  );
}


