import React from 'react';
import { getObjectWithJsonDataToFormValues, } from '../../libraries/utils';

export default function BusinessNameImage({ business }) {
  const { image, name, } = business ? getObjectWithJsonDataToFormValues(
    business,
    ['name', 'image']
  ) : {};

  return (
    <div className="flex items-center">
      <div className="h-8 w-8 p-1 rounded-full flex items-center justify-center mr-2 border border-gray-200">
        <img className="" src={image} alt="" />
      </div>
      <p className="text-xs ellipsis-1-line">{name}</p>
    </div>
  )
}