import clsx from 'clsx';
import React from 'react';
import Icon from '../../libraries/icons';

export default function Button({ ...rest }) {
  let icon = {
    name: rest.icon,
    position: "left"
  };
  if(typeof rest.icon === "object") icon = rest.icon
  
  return (
    <button
      type="button"
      className={clsx("inline-flex items-center justify-center btn shadow-md",
        {
          "gap-2": icon.position === "left" || icon.position === "right",
          "disabled": rest.submitted || rest.disabled
        },
        rest.className || "btn-sm btn-gray",
      )}
      onClick={() => rest.onClick && rest.onClick(rest.value)}
      disabled={rest.disabled}
    >
      {icon.name && <Icon name={icon.name}  className={clsx("h-4 w-4", 
        {
          "order-first": icon.position === "left",
          "order-last": icon.position === "right",
          "-ml-1": icon.position === "left",
          "-mr-1": icon.position === "right",
        }
      )} />}
      
      <span className="font-bold tracking-wider">{rest.title}</span>
    </button>
  )
}