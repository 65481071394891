import React from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../../routes';
import Button from '../commons/Button';
import { getObjectWithJsonDataToFormValues  } from '../../libraries/utils';

export default function BenefitListCard({ route, business, ...rest }) {
  const { t, i18n } = useTranslation();
  const { url, name, image} = getObjectWithJsonDataToFormValues(
    business,
    ['id', 'name', 'image']
  );

  return (
    <div className={"card white overflow-hidden " + (rest.className ? rest.className : "")}>
      <div className="h-40 overflow-hidden flex justify-center items-center">
        <img alt="" src={image} />
      </div>
      <div className="p-3 w-full">
        <h5 className="mb-4">{name}</h5>
       {/*  <p className="points">{points} pts</p> */}
        <Button
          className="btn-primary btn-sm btn-block mt-2"
          title={t("Ver")}
          onClick={() => history.push(route)}
        />
      </div>
    </div>
  )
}