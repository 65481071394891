import React  from 'react';
import Sidebar from './Sidebar';
import Loader from '../commons/Loader';

export default function LayoutWithSidebar({ main, header, footer, container, loading, children }) {
  return (
    <main className={"flex flex-col h-screen " + (main && main.className ? main.className : "") }>
     { <Sidebar header={header} />}
      {header && header.content && (
        <div>
          {header.content}
        </div>
      )}
      <div className={(!container || (container && container.overflow !== false) ? "flex-1 overflow-y-auto " : "") + (container && container.className ? container.className : "")}>
        {loading ? (
          <Loader size="full"/>
        ) : (
          children
        )}
      </div>
      {footer && footer.content && (
        <div>
          {footer.content}
        </div>
      )}
    </main>
  )
}
