import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from '../../libraries/icons';


export default function MenuItem({ item, location, ...rest }) {
  const { t, i18n } = useTranslation();
  if (!item) return null;
  const { icon, label, route } = item;
  const isSelected = (route, location)  => {
    return location.pathname === route;    
  }
  return (
    route ? (
      <Link
        className={"flex items-center mt-1 py-2 px-3 rounded-md tracking-wider text-base-300 text-sm w-full " + (isSelected(route, location) ? " text-white bg-base-content" : "")}
        to={route}
      >
        {icon && <Icon className={"h-6 w-6 mr-3 " + (isSelected(route, location) ? "text-secondary" : "" )} name={icon} />}
        <span className={(item.textClassName ? item.textClassName : "") + " "}>{t(label)}</span>
      </Link>
    ) : (
      // Only a title
      <div className="flex items-center py-1 px-2 rounded-md text-primary-200">
        {icon && <Icon className="h-7 w-7 mr-3" name={icon} />}
        <span className={" " + rest.bold ? "font-bold" : ""}>{t(label)}</span>
      </div>
    )
  )
}
