import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { capitalize, getObjectWithJsonDataToFormValues  } from '../../libraries/utils';

import UserOwnerElement from './UserOwnerElement'
import IconPoints from './IconPoints';
import IconCoins from './IconCoins';
import ButtonIcon from '../commons/ButtonIcon';

import Icon from '../../libraries/icons';
import SmallRating from '../commons/SmallRating';

export default function ProductCard({ route, product, ...rest }) {
  const { t, i18n } = useTranslation();  

  const { img, name, coins, points, rate_total: product_rate_total, rate_count: product_rate_count } = getObjectWithJsonDataToFormValues(
    product,
    ['id', 'name', 'img', 'coins', 'points', 'rate_total', 'rate_count']
  );

  const owner = getObjectWithJsonDataToFormValues(
    product.user,
    ['id', 'username', 'first_name', 'last_name', 'profile_picture', 'rating', 'rate_total', 'rate_count']
  );

  const product_rating = product_rate_total && (product_rate_count !== 0) ? product_rate_total/product_rate_count : 0

  return (
    <Link
      key={rest.key}
      to={route}
    >
      <div className={"card flex-row white overflow-hidden flex h-full " + (rest.className ? rest.className : "")} >
        <SmallRating containerClassName=' absolute px-1 right-2 bottom-2 h-6' rate={product_rating} />
        <div className="overflow-hidden w-1/2">
          <div className="p-3 leading-tight flex justify-between items-start flex-col h-full gap-3">
            <div>
              <h5>{t(name)}</h5>
              <UserOwnerElement starClassName="mt-1" owner={owner} />
            </div>

            <div className="flex justify-between items-center gap-2">
              <IconCoins iconClassName="h-4" color='red' negative={true} coins={coins} showNumber={true}/>
              <IconPoints className="bg-success bg-opacity-10 flex gap-1 rounded" iconClassName="h-4" color='green' points={points} showNumber={true}/>
            </div>
            <ButtonIcon
              buttonClassName="btn-primary btn-sm flex-row-reverse gap-2"
              className="w-4 h-4"
              icon="refresh"
              text={capitalize(t("ver producto"))}
              // onClick=""
            />
          </div>
        </div>
        <div className="flex items-stretch justify-center w-1/2 overflow-hidden">
          {img ? 
            (<img alt="" className='object-cover w-full max-h-44' src={img}/>)
            : (<Icon className="h-4 w-4 mr-1" name="clock" />)
          }
        </div> 
      </div>   
    </Link>
  )
}




