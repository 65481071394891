import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import config from '../../config';
/* import { history } from '../../routes';
import { getOwner } from '../../libraries/utils'; */

import ActionCard from '../../components/customs/ActionCard';
import Loader from '../../components/commons/Loader';
import Icon from '../../libraries/icons';


import actionsAction from "../../context/actions/actions";
import categoriesAction from '../../context/categories/actions';

import Instructive from '../../components/commons/Instructive';

import { ToastContainer, notify } from '../../libraries/notifications';
import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import BottomNav from '../../components/commons/BottomNav';

class Actions extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.scrollContainerRef = React.createRef();
    this.state = {
      actions:[],
      filters: {
        type: null
      },
      categories: [],
    }
  }
  
  async componentDidMount() {
    console.log('ACTIONS');
    const { state } = this.props.location;
    if (state && state.filters) {
      this.setState({filters: state.filters})
    }
    await this.getCategories();
    await this.getActions();
  
  }

  getActions = async () => {
    this.setState({loading: true})
    const { filters } = this.state
    let params = {}

    if ((filters.type && filters.type !== "")){
      this.setState({ actions: [] });
      params = { type: filters.type, order_by: "order_index", order_direction: "ASC"  }
    }

    await this.props.onGetAllActions({...params, enabled: 1});
    const { actions } = this.props;
    if (actions.error) {
      notify(this.t(actions.error.message));
    } else {
      this.setState({ actions: actions.items });
    }    
    this.setState({loading: false})
  }


  getCategories = async (next) => {
    await this.props.onGetAllCategories({ type: config.TYPES.CATEGORIES.ACTIONS, order_by: 'order_index', order_direction: 'ASC' });
    const { categories } = this.props;
    if (categories.error) {
      notify(this.t(categories.error.message));
    } else {
      this.setState((prevState) => {
        if (prevState.filters.type) {
          return { categories: categories.items };
        } else {
          categories.items.sort((a, b) => a.name.localeCompare(b.name))
          return {
            categories: categories.items,
            filters: { type: categories.items[0] ? categories.items[0].id : null },
          };
        }
      });
    }
  }
  

  filterActions = (type) => {
    this.setState({ filters: { type }}, () => this.getActions())
    
  }
  scrollLeft = () => {
    if (this.scrollContainerRef.current) {
      this.scrollContainerRef.current.scrollBy({
        left: -200, // Desplaza 200px a la izquierda
        behavior: 'smooth',
      });
    }
  };
  
  scrollRight = () => {
    if (this.scrollContainerRef.current) {
      this.scrollContainerRef.current.scrollBy({
        left: 200, // Desplaza 200px a la derecha
        behavior: 'smooth',
      });
    }
  }

  

  render() {
    const { actions, filters, categories } = this.state;
    
    return (
      <LayoutWithSidebar
        main={{ className: "pb-14"}}
        header={{ 
          logo: true,
          //content: <NavButtonBar options={buttonBarUpper} active={topNav} onClick={this.onNavBarClick} />
        }}
 
        container={{ className: "px-0"}}
      >
      <ToastContainer/>
      <div className='px-5 bg-white border-t border-gray-100'>
        <div className="scrolling-wrapper mx-3" ref={this.scrollContainerRef}>
          <button onClick={this.scrollLeft}>
            <Icon className="text-gray-300 fixed left-1 top-16 w-5 h-14 z-10" name="cheveron_left" onClick={this.scrollLeft}/>
          </button>
          <button onClick={this.scrollRight}>
            <Icon className="text-gray-300 fixed right-1 top-16 w-5 h-14 z-10" name="cheveron_right" onClick={this.scrollRight}/>
          </button>
          {categories
          .sort((a,b) => a.name.localeCompare(b.name))
          .map((category,index) => {
            return(
              <div
                key={index}
                className={"px-4 py-3 text-center border-b-4 border-transparent font-medium flex-1 rounded-none " + (category.id===filters.type ? " border-b-secondary font-semibold text-base-content " : " text-neutral ") + ""}
                onClick={() => this.filterActions(category.id)}
              >
                <h5 className='whitespace-nowrap'>{this.t(category.name)}</h5>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full max-w-lg mx-auto">
        {this.state.loading && (<Loader/>)}
        <section className="p-3">
          <Instructive
            slides={[
              {
                title:"Tus acciones son las que suman",
                body:"Tené en cuenta que las fotos que subís deben ser de acciones propias hechas dentro del último mes. ‘No son válidas’ acciones de terceros o imágenes bajadas de internet.",
                img:'warning_action'
              },
            ]}
            instructive={"show_acciones_confirm"} 
            labelButton="Estoy de acuerdo"
          >
          </Instructive>
        </section>
        <Instructive
          slides={[
            {
              title:"Ganá",
              body:"Ganá puntos por cada acción solidaria o sostenible que realices.",
              img: "win"
            },
            {
              title:"Aprendé",
              body:"Aprendé sobre qué podés hacer para empezar a vivir de forma sostenible",
              img: "learn"
            },
            {
              title:"Contribuí",
              body:"Sacate una foto realizando la acción y ganá puntos. Todos tus compañeros podrán ver cómo contribuís en el muro de la app.",
              img: "contribute"
            }
          ]}
          instructive={"show_actions"}
        > 
          <div className="grid gap-4 px-3 pt-5">
            { actions && actions.map((action, index) => {
                return (
                  <ActionCard 
                    key={index}
                    data={action}
                    route={config.ROUTES.ACTIONS.DETAIL.replace(':id', action.id)}
                    filters={filters}
                    
                  />
                )
              })
            }
          </div>
        </Instructive>
        
      </div> 
        <BottomNav/>
      </LayoutWithSidebar>  
  
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    actions:state.actions.list,
    categories : state.categories.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllActions: (params) => dispatch(actionsAction.getAll(params)),
    onGetAllCategories: (params) => dispatch(categoriesAction.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Actions));

