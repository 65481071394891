import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';

import { getOwner } from '../../libraries/utils';

import config from '../../config';
import Icon from '../../libraries/icons';
import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import MessageElement from '../../components/customs/MessageElement';
import QuestionElement from '../../components/customs/QuestionElement';
import MessagesActions from '../../context/messages/actions';

import BottomNav from '../../components/commons/BottomNav';


class Notifications extends React.Component {

  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      messages: [],
      owner: getOwner(this.props.auth.user)
    }
  }
  
  componentDidMount() {
    this.getUserMessages();
  }

  compareDate = (a, b) => {
    const fechaA = a?.json_data?.last_message_at || a.created_at;
    const fechaB = b?.json_data?.last_message_at || b.created_at;

    return new Date(fechaB) - new Date(fechaA);
  }

  getUserMessages = async (params = {}) => {
    const { owner } = this.state
    params.order_direction = 'DESC'

    params.order_by = 'JSON_EXTRACT(messages__json_data, "$.last_message_at")'
    //params.parent = 'root'
    //params.parent = {
    //  comparison:'!=',
    //  value:'root'
    //}
    params.type = {
      comparison:'!=',
      value:'comments'
    }
    params.where = {custom:{
      target: owner,
      comparison: 'OR',
      source: owner
    }}
    await this.props.onGetAllSendedMessages(params);
    const { messages } = this.props;
    if (messages.error) {
      notify(this.t(messages.error.message));
    } else {
      //messages.items.filter(m => m.type !== 'notification')

      let roots = ['root']
      let messages_ = []
      for(let i = 0; i < messages.items.length; i++){
        if(!roots.includes(messages.items[i].parent)){
          messages_.push(messages.items[i])
          roots.push(messages.items[i].parent)
        } else if (messages.items[i].parent === 'root' && !roots.includes(messages.items[i].id)) {
          messages_.push(messages.items[i])
          roots.push(messages.items[i].id)
        }
      }

      this.setState({ messages: messages_.filter(m => m.type !== 'notification').sort(this.compareDate)})
      // this.setState({ messages: [...this.state.messages, ...messages.items].sort((a,b) => Date.parse(b.json_data.last_message_at) - Date.parse(a.json_data.last_message_at))})
      } 
  }

  render() {
    const { messages, owner } = this.state;
    

    return (
      <LayoutWithSidebar
        main={{ className: ""}}
        header={{ 
          className: "",
          title: this.t("Mensajes"),
          // right: { icon: 'arrow_left', action: ()=> history.push(config.ROUTES.HOME), className: 'z-20 h-6 w-6', buttonClassName: ' ml-auto' },
        }}
        loading={messages.loading}
      >
        <ToastContainer/>
        <section className="mt-4">

          {messages && !this.props.messages.loading && messages.length === 0 && (
            <div className="text-center p-10">
              <Icon className="h-24 w-24 mx-auto mb-3" name="bell" />
              <h4 className="mb-1">{this.t("No tienes mensajes")}</h4>
            </div>
          )}
          <div className="bg-white mb-[3rem]">
            { messages && messages.map((message, index) => {
              const props = {
                key: index,
                data: message,
                className: "border-b",
                owner: owner
              }
              const elements = {
                message : () => 
                  <MessageElement {...props}
                    route={{
                      pathname: config.ROUTES.CHAT.replace(':root_id', (message.parent !== 'root') ? message.parent : message.id), 
                      state: { fromPath: config.ROUTES.MESSAGES, from: config.ROUTES.MESSAGES, message }
                    }}
                  />,
                question : () => 
                  <QuestionElement {...props}
                    route={{
                      pathname: config.ROUTES.QUESTION.replace(':root_id', (message.parent !== 'root') ? message.parent : message.id),
                      state: { fromPath: config.ROUTES.MESSAGES, from: config.ROUTES.MESSAGES, message }
                    }}
                  />
              }
              return elements[message.type]()
            })}
          </div>
        </section>
        <BottomNav/>
      </LayoutWithSidebar>
    )
  }
}


const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    messages: state.messages.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllSendedMessages: (params) => dispatch(MessagesActions.getAll(params)),
    onGetAllReceivedMessages: (params) => dispatch(MessagesActions.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Notifications));
   

  
