import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../commons/Button';
import {isFunction, getObjectWithJsonDataToFormValues, capitalize} from '../../libraries/utils';
import Avatar from '../commons/Avatar';
import IconPoints from './IconPoints';
import IconCoins from './IconCoins';
import { history } from '../../routes';
import config from '../../config';
import Icon from '../../libraries/icons';
import ButtonIcon from '../commons/ButtonIcon';
import { useSelector, useDispatch } from 'react-redux';
import usersActions from '../../context/users/actions';


const customEqual = (oldValue, newValue) => oldValue === newValue

export default function LoanCard({ loan, actions, statuses, ...rest }) {
  const { t, i18n } = useTranslation();
  const user = useSelector(state => state.users.user, customEqual);
  const dispatch = useDispatch();
  const [borrowerUser, setBorrowerUser] = useState();
  const [rating, setRating] = useState(0);

  const { id, product, offer, service, status, sub_type, borrower, coins, points, created_at } = getObjectWithJsonDataToFormValues(
    loan,
    ['id', 'owner', 'product','status','borrower','type','sub_type','created_at', 'coins', 'points', 'offer', 'service']
  );
  
  const fullname = (borrower) => {
    return loan ? `${borrower.first_name} ${borrower.last_name}`: '';
  }

  const timeRemains = t("timeRemains", { date: new Date(created_at) })

  const gotoSendMessage = (user) => {
    history.push({
      pathname: config.ROUTES.SEND_MESSAGE,
      state: {
        fromPath:config.ROUTES.USER.LOANS,
        loan,
        }
      }
    );
  }

  useEffect(()=>{
    dispatch(usersActions.get({id: borrower.id}))
  }, [])
  useEffect(()=>{
    setBorrowerUser(user);
    console.log(user, borrowerUser);
    const rate_total = borrowerUser?.item.json_data.rate_total;
    const rate_count = borrowerUser?.item.json_data.rate_count;
    const rating = rate_total && (rate_count !== 0) ? rate_total/rate_count : 0;
    setRating(rating)
    console.log(rating)
  }, [user, rating, borrowerUser])

  const gotoReclamo = () => {
    history.push({
      pathname: config.ROUTES.SEND_MESSAGE,
      state: {
        fromPath:config.ROUTES.USER.LOANS,
        reclamo: {
          type: "reclamo",
          related_to: id,
        },
        }
      }
    );
  }
  
  
  return (
    loan && (
      <div className={"card white p-3 overflow-hidden border-b border-gray-100 flex flex-row " + (rest.className ? rest.className : "") }>
        <div className="w-full">
          <div className="w-full flex justify-between">
              <div className="flex-row">
                <div className="flex" onClick={() => history.push({pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(':id', borrower.id ), state:{fromPath: config.ROUTES.USER.LOANS}})}>
                  <Avatar
                    image={borrower.profile_image}
                    label={fullname(borrower)}
                    className="rounded-full w-10 h-10"
                    containerClassName="mr-2"
                  />
                  <div className="flex-row">
                    <h4>{fullname(borrower)}</h4>
                    {/* <StarRating containerClassName="flex" unit="float" initialRating={rating} size={14} isReadOnly={true}/> */}
                  </div>
                </div>
            </div>
            <div className="flex flex-col mb-4">
              <button className="link link-primary text-xs text-right" onClick={rest.onClick && rest.onClick}>{t("Ver detalles")}</button>
              <div className='flex items-center'>
                <Icon className="h-3 w-3 mr-1" name="clock" />
                <h6 className="text-gray-300">{timeRemains}</h6>
              </div>
            </div>
            {/* <div  className="flex overflow-hidden">
              
              <ButtonIcon buttonClassName="btn-sm bg-base-100 text-sm" className="w-4 h-4 text-neutral" icon="chat_alt"
                onClick={() => {gotoSendMessage(borrower)}}/>
              <ButtonIcon buttonClassName="btn-sm btn-ghost capitalize text-xs btn-block rounded-none text-gray-500 w-1/2" className="mr-1 w-4 h-4" 
                onClick={gotoReclamo} icon="flag"/>
            </div> */}
          </div>
          <div className="flex rounded p-2 mb-1 border-t border-t-gray-100" onClick={rest.onClick && rest.onClick}>
            {(product || offer?.img) ? (
              <Avatar
                image={product ? product.img : offer.img}
                className="rounded-full w-10 h-10"
                containerClassName="mr-2"
              />
              ) : (<div className="rounded-full w-10 h-10"></div>)
            }
            <div className="flex-row">
              <h5 className='mb-1 text-primary'>{capitalize(t(product ? product.name : offer.name))}</h5>
              <div className="flex gap-2">
                <IconCoins coins={coins}/>
                <IconPoints points={points}/>   
              </div>
            </div>
          </div>
          
          <div className="card-actions justify-end border-t border-t-gray-100 pt-2">
            {actions && actions.map((action, index) => {
              if (action.status.includes(status)) {
                return (
                  <Button
                    key={index}
                    disabled = {action.disabled}
                    className={"btn-sm flex-1 " + (action.className ? action.className : "")}
                    title={action.title}
                    onClick={() => { if (isFunction(action.onClick)) action.onClick(loan); }}
                  />);
              } else {
                return null
              }}
            )}
            <ButtonIcon 
              buttonClassName="btn-sm bg-base-100 border border-gray-300 text-sm" 
              className="w-4 h-4 text-neutral" 
              icon="chat_alt"
              onClick={() => {gotoSendMessage(borrower)}}
            />
            <ButtonIcon
              buttonClassName="btn-sm bg-base-100 border border-gray-300 text-sm"
              className="w-4 h-4 text-neutral"
              onClick={() => { gotoReclamo() }}
              icon="flag"
            />
          </div>
        </div>

      </div>
    )

  )
}