import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import Swal from 'sweetalert2';

import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';

import ProductElement from '../../components/customs/ProductElement';
import ImageUploadFileInput from '../../components/forms/ImageUploadFileInput';
import ImageUploadFileInputEdit from '../../components/forms/ImageUploadFileInputEdit';
import Button from '../../components/commons/Button';

import LayoutSmall from '../../components/layout/LayoutSmall';
import SwitchboxInput from '../../components/forms/SwitchboxInput';
import RadioInput from '../../components/forms/RadioInput';
import TextareaInput from '../../components/forms/TextareaInput';

import transactionsAction from '../../context/transactions/actions';
import filesActions from '../../context/files/actions';
import { getObjectWithJsonDataToFormValues, validateIsfilled  } from '../../libraries/utils';
import internalsActions from '../../context/internals/actions';
import RatingIcons from '../../components/commons/RatingIcons';
import Loader from '../../components/commons/Loader';
import actions from '../../context/messages/actions';

class ConfirmLoan extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      imageUpdated: false,
      files: {},
      transaction: null,
      status: "",
      user: this.props.auth.user.id,
      entregoATiempo: true,
      estaEnMismoEstado: true,
      product_rating:0,
      user_rating:0,
      uploadLoading: false,
    }
  }
  
  componentDidMount() {
    console.log('RECIBI EL PRODUCTO');
    const params = this.props.match.params;
    console.log('PARAMS', params);
    if(params.id){
      this.getTransaction(params);
    }else {
      history.push(config.ROUTES.OFFER);
    }
  }

  getTransaction = async (params)=> {
    await this.props.onGet(params);
    console.log('AFTER GET TRANSACTION');
    const { transaction } = this.props;
    if (transaction.error) { 
      notify(this.t(transaction.error.message));
    } else {
      const transactionData = getObjectWithJsonDataToFormValues(
        transaction.item,
        ['id', 'owner', 'product', 'offer', 'status','borrower','type','sub_type','created_at']
      );
     this.setState({ transaction:transactionData});
    }    
  }

  onSubmit = async (values) => {
    this.setState({uploadLoading: true})
    console.log("SUBMITTING")
    const { transaction, product_rating, user_rating} = this.state
    const transaction_sub_type = transaction.sub_type
    //console.log("__________transcation", transaction)
    //return 
    const received_image = this.state.files.identification_picture
    let data = {
      id: transaction.id,
      status: (transaction.sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.PRODUCT) ? 
        config.TRANSACTIONS.BORROW.STATUS.ON_USER : config.TRANSACTIONS.BORROW.STATUS.ON_OWNER,
      json_data: {
          ...values, 
          service_rating: user_rating ? user_rating : null,
          received_image
      }
    }

    if(data.json_data.problemaProducto){
      data.json_data.problemaProducto = data.json_data.problemaProducto.replaceAll('\n', ' ')
    }

    data.json_data.validateLoanStatus = (values.validateLoanStatusRadio === "si") ? true : false
    data.json_data.validateLoanTiming = (values.validateLoanTimingRadio === "si") ? true : false
    delete data.json_data.validateLoanTimingRadio
    delete data.json_data.validateLoanStatusRadio
    if(data.json_data.validateLoanTiming && data.json_data.validateLoanStatus) data.json_data.problemaProducto = null  
    //console.log(data)
    //return

  /*******
    let mailStatus = (values.validateLoanStatusRadio === "si") ? "sí" : "no"
    let mailTiming = (values.validateLoanTimingRadio === "si") ? "sí" : "no"
    let line_5 = `Confirmó que ${mailTiming} lo recibió a tiempo y que ${mailStatus} es lo que dice la publicación. Felicitaciones, ganaste ${transaction.product ? transaction.product.coins : transaction.offer.coins} Puntos Shary y ${transaction.product ? transaction.product.points : transaction.offer.points} Puntos de impacto por el préstamos. `
    //Si tuviste algún problema podés enviarle un mensaje desde la sección de PRÉSTAMOS en el sidebar de la aplicación.
    let line_6 = ""
    let last_line = ""
    if(!(transaction.sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.PRODUCT)){
      if(user_rating) line_6 += `Te puntuó con ${user_rating} Estrellas`;
      if(values.rateUser) line_6 += ` y realizó el siguiente comentario sobre vos: ${values.rateUser}`;
      if(product_rating) last_line += `Además puntuó al producto/servicio/favor con ${product_rating} Estrellas`;
      if(values.rateProduct) last_line += ` y realizó el siguiente comentario: ${values.rateProduct}`;
      last_line += ' Si tuviste algún problema podés enviarle un mensaje desde la sección de PRÉSTAMOS en el sidebar de la aplicación.'
    }
  ********/
    
    console.log("DATA", data) 
    await this.props.OnChangeTransactionState(data);
    console.log('AFTER TRANSACTION STATUS CHANGING');
    const transactionSaved=this.props.transaction.item
    const errorTrxSaved=this.props.transaction.error
    if (errorTrxSaved) { 
      notify(this.t(errorTrxSaved.message));
      this.setState({uploadLoading: false})
    } else {
   /*******
     let mailData = {
        template: "sharyco_generic",
        locale: "es",
        to:transaction.owner.email,
        first_name: transaction.borrower.first_name,
        last_name:  transaction.borrower.last_name,
        params: {
            subject: "Recepción confirmada",
            line_1: `Hola ${transaction.owner.first_name} ${transaction.owner.last_name}`,
            line_2: `${transaction.borrower.first_name} ${transaction.borrower.last_name}`,
            line_3: " confirmó que recibió lo siguiente ",
            line_4: `${transaction.product ? transaction.product.name : transaction.offer.name}`,
            line_5,
            line_6,
            last_line,
            link_app: config.ROUTES.USER.LOANS,
            has_button: true,
        }
      }
    // ********/ //Este email debe enviarse cuando se reciba el producto

      // let mailTiming = (values.validateLoanTimingRadio === "si") ? "sí" : "no"
      // let mailStatus = (values.validateLoanStatusRadio === "si") ? "sí" : "no"
      // let user_stars = null
      // let user_comment = null
      // let product_stars = null
      // let product_comment = null
      // if(!(transaction.sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.PRODUCT)){
      //   user_stars = user_rating
      //   user_comment = values.rateUser
      //   product_stars = product_rating
      //   product_comment = values.rateProduct
      // }
      // let mailData = {
      //   template: "borrow_confirmed",
      //   locale: "es",
      //   to:transaction.owner.email,
      //   first_name: transaction.borrower.first_name,
      //   last_name:  transaction.borrower.last_name,
      //   params: {
      //     subject: "Recepción confirmada",
      //     owner_name: `${transaction.owner.first_name} ${transaction.owner.last_name}`,
      //     borrower_name: `${transaction.borrower.first_name} ${transaction.borrower.last_name}`,
      //     product_name: `${transaction.product ? transaction.product.name : transaction.offer.name}`,
      //     mailTiming,
      //     mailStatus,
      //     points:`${transaction.product ? transaction.product.points : transaction.offer.points}` ,
      //     coins: `${transaction.product ? transaction.product.coins : transaction.offer.coins}` ,
      //     product_type: this.t(transaction.sub_type),
      //     user_stars,
      //     user_comment,
      //     product_stars,
      //     product_comment,
      //     has_button: true,
      //   }
      // }
      // await this.props.onSendMail(mailData);
      // const internals = this.props.internals;
      // if (internals.error) {
      //   notify(this.t(internals.error.message));
      // }

      let mailData = {
        locale: "es",
        to: transaction.owner.email,
        first_name: transaction.owner.first_name,
        last_name: transaction.owner.last_name,
        params: {
          user_name: `${transaction.borrower.first_name} ${transaction.borrower.last_name}`,
          short_name: `${transaction.borrower.first_name} ${transaction.borrower.last_name}`,
          recipient_variables: {
            full_name: `${transaction.owner.first_name} ${transaction.owner.last_name}`,
          }
        }

      }
      if (transactionSaved.sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR) {
        mailData.template = "borrow_favor_rating_owner"
        mailData.params.favor_name = transaction.offer.name
        mailData.params.experience_comment = transactionSaved.json_data?.rateProduct
        mailData.params.comment_about_owner =transactionSaved.json_data?.rateUser
        mailData.params.rating_stars = transaction.offer?.service_rating
        mailData.params.recipient_variables.impact_points = `${transaction.offer.points}`
        mailData.params.recipient_variables.shary_points = `${transaction.offer.coins}`
      } else {
        mailData.template = "borrow_product_received"
        mailData.params.product_name = `${transaction.product.name}`
        mailData.params.impact_points = `${transaction.product.points}`
        mailData.params.shary_points = `${transaction.product.coins}`
        mailData.params.recipient_variables.impact_points = `${transaction.product.points}`
        mailData.params.recipient_variables.shary_points = `${transaction.product.coins}`
      }

      await this.props.onSendMail(mailData);
      const internals = this.props.internals;
      if (internals.error) {
        notify(this.t(internals.error.message));
      }

      let notificationData = {  
        type:config.MESSAGES.TYPE.NOTIFICATION,
        source:this.props.auth.user.id,
        target: transaction.owner.id,
        related_to: transaction.product ? transaction.product.id : transaction.offer.id,
        parent: "" ,
        subject: transaction.product ? transaction.product.type : transaction.offer.type,
        body: " recibió:  " + (transaction.product ? transaction.product.name : transaction.offer.name),
        json_data: {
          product:transaction.product ? transaction.product : transaction.offer,  
          redirect_notification_route: config.ROUTES.RETURNS,
        },
      }
      await this.props.onSaveNotification(notificationData);
      console.log('AFTER SAVE NOTIFICATION');
      const notification = this.props.message;
      if (notification.error) { 
        notify(this.t(notification.error.message));
      } 
      history.push(config.ROUTES.USER.RETURNS)
    }
  }

  onClearImageField = ({ name }) => {
    console.log('CLEAR IMAGE FIELD:', name);
    if (name) this.setState({ files: {...this.state.files, [name]: null }});
  }


  onFileChangeImageInput = async ({file, data, name}) => {
    this.setState({ submitting: true });
    console.log('ON FILE CHANGE');
    
    data.append("identifier", `confirmloan_${ parseInt(Math.random()*1000000)}`)
    await this.props.onPictureUpload(data);
    console.log('FILES', this.props.files);
    const error = this.props.files.error;
    console.log('UPLOADED');

    if (error) {
      notify(this.t(error.message));
    } else {
      console.log("UPLOADED", this.props.files)
      this.setState({imageUpdated: true});
      this.setState({files: {...this.state.files, [name]:this.props.files.files.fileInfo.location}})
    }

    console.log('ON FILE CHANGE FINISH');
    this.setState({ submitting: false });

    return this.props.files.files.fileInfo.location
  }

  setEstaEnMismoEstado = (v) => {
    this.setState({estaEnMismoEstado: (v==='si' ? true : false)})
  }

  setEntregoATiempo = (v) => {
    this.setState({entregoATiempo: (v==='si' ? true : false)})    
  }

  onSaveProductRating = (index) => {
    console.log('RATING PRODUCT', index);
    this.setState({ product_rating: index});
  }; 

  onSaveUserRating = (index) => {
    console.log('RATING SERVICE', index);
    this.setState({ user_rating: index});
  }; 

  render() {
    const { transaction, product_rating, user_rating} = this.state 
    const is_product_loan = transaction && transaction.product
    const required = value => ((validateIsfilled(value) || (this.state.estaEnMismoEstado && this.state.entregoATiempo)) ? undefined : this.t("This field is required"))
    const getSubTypeString = (subtype) => subtype === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR ? "favor" : "servicio"

    return (
      <LayoutSmall
        main={{ className: ""}}
        header={{ 
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.USER.RETURNS) },
          className: "bg-primary",
          title: transaction && transaction.product ? this.t("Recibí el producto") : this.t(`Recibí el ${getSubTypeString(transaction?.sub_type)}`)
        }}
        loading={this.props.transaction.loading}        
        container={{ className: "px-0"}}
      >
        <ToastContainer/>
          { this.state.uploadLoading && (<Loader/>) }
          { transaction && !this.state.uploadLoading && (
          <section className="p-3">
            <ProductElement 
              product={transaction.product ? transaction.product : transaction.offer}
              owner={transaction.owner}
            />
            <div className="pt-6">
            <Form onSubmit={(values) => this.onSubmit(values)}initialValues={
              {
                validateLoanTimingRadio: "si",
                validateLoanStatusRadio: "si",
              }
            }>
              {({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  {is_product_loan && <div className="w-full  mb-3">
                    <Field 
                      name="identification_picture" 
                      component={ImageUploadFileInputEdit}
                      img={this.state.files.identification_picture} 
                      placeholder={this.t("Fotos del estado del producto")} 
                      label={this.t("Fotos del estado del producto")}
                      inputOnChange={this.onFileChangeImageInput}
                      clickButtonClear={this.onClearImageField}
                      inputClassName="input-bordered shadow-none"
                    />
                     {!this.state.files.identification_picture && <h5 className="text-center ">Adjuntar una foto para poder continuar </h5>}
                  </div>}
                  <div className="w-full mb-3">
                    <p>{is_product_loan ? this.t("¿Está en el mismo estado que dice la publicación?") : this.t(`¿Realizó el ${getSubTypeString(transaction?.sub_type)} correctamente?`)}</p>
                    <div className="flex w-1/2">
                      <div className="w-1/2">
                        <Field type="radio" value="si" name="validateLoanStatusRadio" component={RadioInput} labelClassName="block" label={this.t("Si") } setExternal={this.setEstaEnMismoEstado}/>
                      </div>
                      <div className="w-1/2">
                        <Field type="radio" value="no" name="validateLoanStatusRadio" component={RadioInput} labelClassName="block" label={this.t("No") } setExternal={this.setEstaEnMismoEstado}/>
                      </div>
                    </div>
      
                  </div>
                  <div className="w-full mb-3">
                    <p>{is_product_loan ? this.t("¿Lo entregó a tiempo?") : this.t(`¿Realizó el ${getSubTypeString(transaction?.sub_type)}  a tiempo?`)}</p>
                    <div className="flex w-1/2">
                      <div className="w-1/2">
                        <Field type="radio" value="si" name="validateLoanTimingRadio" component={RadioInput} labelClassName="block" label={this.t("Si") } setExternal={this.setEntregoATiempo}/>
                      </div>
                      <div className="w-1/2">
                        <Field type="radio" value="no" name="validateLoanTimingRadio" component={RadioInput} labelClassName="block" label={this.t("No") } setExternal={this.setEntregoATiempo}/>
                      </div>
                    </div>
                  </div>
                  {/*(!this.state.estaEnMismoEstado || !this.state.entregoATiempo) &&*/}
                  { (!this.state.estaEnMismoEstado || !this.state.entregoATiempo) && <Field name="problemaProducto" component={TextareaInput} placeholder={this.t("Problema")} 
                    label={is_product_loan ? "¿Qué problema encontraste?" : "¿Qué problema encontraste?"} validate={required}/>}
                    
                    {!is_product_loan && 
                      <>
                        <div className="w-full mb-2 mt-3">
                        <h4>{this.t("Calificá al usuario")}</h4>
                        <h5>{this.t("¿Qué te pareció el trato y atención de " + transaction.owner.first_name + "?")}</h5>
                        <RatingIcons
                          className="text-gray-300 my-3"
                          initalRating= {user_rating} 
                          onClick={this.onSaveUserRating}
                          disabled={false}
                        />
                        <Field name="rateUser" component={TextareaInput} placeholder="Comentario" />
                      </div>
                      <div className="w-full mb-2">
                        <h4>{this.t(`Calificá el ${is_product_loan ? "producto" : getSubTypeString(transaction.sub_type)}`)}</h4>
                        <h5>{this.t(`¿Qué te pareció el ${is_product_loan ? "producto" : getSubTypeString(transaction.sub_type)}?`)}</h5>
                        <RatingIcons
                          className="text-gray-300 my-3"
                          initalRating={product_rating} 
                          onClick={this.onSaveProductRating}
                          disabled={false}
                        />
                        <Field name="rateProduct" component={TextareaInput} placeholder="Comentario"/>
                      </div>
                      </>
                    }
                  <Button
                    className="btn-primary btn-block mt-10"
                    title={this.t("Confirmar")}
                    onClick={handleSubmit}
                    disabled={submitting || (!this.state.files.identification_picture && is_product_loan)}
                  />
                </form>
              )}
            </Form>
            </div>
          </section>
        )}
      </LayoutSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    transaction: state.transactions.current,
    files: state.files,
    internals: state.internals,
    message: state.messages.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGet: (params) => dispatch(transactionsAction.get(params)),
    OnChangeTransactionState: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
    onPictureUpload: (params) => dispatch(filesActions.upload(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
    onSaveNotification: (params) => dispatch(actions.saveOrUpdate(params)),
  };
};



export default connect(
  mapStateToProps,mapDispatchToProps
)(withTranslation()(ConfirmLoan));

