import { format, addMinutes, subMinutes, parse, parseISO, formatDistance, differenceInMinutes, isWithinInterval, subDays, intervalToDuration, isAfter, isBefore, subHours, } from 'date-fns';
import publicIp from 'public-ip';
import config from '../config';

export const id = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}
export const selectIconSize = (ctrClass, defaultClass = 'h-4 w-4') => {
  if (!ctrClass) return defaultClass;
  const iconclass = ctrClass.split(' ');
  if (iconclass.includes('btn-lg')) return 'h-5 w-5';
  if (iconclass.includes('btn-sm')) return 'h-3 w-3';
  if (iconclass.includes('btn-xs')) return 'h-2 w-2';
  return defaultClass;
};
//generates random id;
export const guid = () => {
  return id() + id() + '-' + id() + '-' + id() + '-' + id() + '-' + id() + id() + id();
}

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
};

export const validateEmail = (email = null) => {
  if (email === null) return false;
  // const re    = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const validatePassword = (pass = null) => {
  if (pass === null) return false;
  return pass.length > 3;
};

export const validateStringNotEmpty = (str = null) => {
  if (str === null) return false;
  return str.length > 0;
};

export const validateOnlyNumber = (num = null, allowNegative = false) => {
  // const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;  // For phonenumber //TODO: test it
  // allowNegative /^-? 
  const regex = /^(?:\d+)(?:(\.|,)\d+)?$/
  return regex.test(num);
  // if (num === null) return false;
  // return !isNaN(num);
};

export const parseOnlyLetterAndSpace = str => str.replace(/[^A-Za-z ]/g, '');

export const parseLength = (str, length) => str.substring(0, length);

export const validateAtLeastLength = (str, length) => str && str.trim().length >= length;

export const validateIsfilled = expression => expression && (expression.length > 0 || expression > 0);

export const validateIsTrue = expression => expression;

export const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined)

export const optionSelectGenerator = (array = [], type = 'vector') => {
  let options = [];
  array.forEach(e => {
    if (type === 'vector') {
      options.push({label: e, value: e});
    }
  });
  return options;
}

export const convertUTCDateToLocalDate = (ISOdate) => {
  // TODO: temp fix
  if(process.env.NODE_ENV !== 'development') return new Date(ISOdate)
  const date = new Date(ISOdate)
  const newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  newDate.setHours(hours - offset);
  return newDate;   
}

export  const renderDate = ({ISOdate, showDuration, isLocal, t}) => {
  if (!ISOdate) return
  class DateData {
    constructor(ISOdate) {
      // TODO: temp fix
      if (process.env.NODE_ENV === 'development') {
        if (ISOdate) {
          if (isLocal) {
            this.date = new Date(ISOdate);
          } else {
            this.date = convertUTCDateToLocalDate(ISOdate);
          }
        } else {
          this.date = new Date();
        }
      } else {
        this.date = ISOdate ? new Date(ISOdate) : new Date();
      }
      this.month = this.date.getMonth()
      this.year = this.date.getFullYear()
      this.day = this.date.getDate()
      this.getTime = () => this.date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
    }
  }
  const date = new DateData(ISOdate)

  const today = new DateData()
  if (showDuration) {
    // ** Calculate the duration and return hierarchically the property that has a value larger than 0
    // year = 1 and month = 5 ------> return year
    const duration = intervalToDuration({ start: date.date, end: today.date })
    for (let property of Object.keys(duration)) {
      if (duration[property] > 0) {
        let value = duration[property]
        let unit = ''
        switch (property) {
          case 'years':
            unit = value > 1 ? 'años' : 'año'
            break            
          case 'months':
            unit = value > 1 ? 'meses' : 'mes'
            break
          case 'days':
            unit = value > 1 ? 'días' : 'día'
            break
          case 'hours':
            unit = value > 1 ? 'horas' : 'hora'
            break
          case 'minutes':
            unit = 'min'
            break
          case 'seconds':
            unit = 'seg'
            break
          default:
            unit = ''
        }
        return `${value} ${unit}`
      }
    }
  } else {
    // ** Checks if the object date and todays date have the same year or month
    const equalDates = () => {
      const sameYear = today.year === date.year
      const sameMonth = today.month === date.month
      return sameYear && sameMonth
    }
    // ** Comparisons, we make sure that when the day its the same on both dates, month and year are the same too
    const wasToday = equalDates() && date.day === today.day
    const wasYesterday = equalDates() && date.day + 1 === today.day
    const time = date.getTime()

    if (wasYesterday) return capitalize(t ? t('yesterday') : 'yesterday')
    if (wasToday) return time
    return ISOdate.slice(0, 10)
  }
}

export const toDate = (dateStr, separator = '/') => {
  const parts = dateStr.split(separator);
  return new Date(parts[2], parts[1] - 1, parts[0]);
}

export const fromISO = (dateISO, separator = '/') => {
  const parts = dateISO.substring(0,10).split('-');
  return `${parts[2]}${separator}${parts[1]}${separator}${parts[0]}`;
}

export const fromISOtoDate = (dateISO, tz = true) => {
  let date = new Date(dateISO);
  let userTimezoneOffset = 0;
  if (tz === false) userTimezoneOffset = new Date(date).getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
}

export const isDate = (val) => {
  return val && Object.prototype.toString.call(val) === '[object Date]' && !isNaN(val);
}

export const isEmptyObject = (obj) => {
  if (typeof obj === 'undefined') {
    return true;
  } else if (isObject(obj)) {
    return Object.entries(obj).length === 0;
  }
  return false;
};

export const isObject = (obj) => {
  if (Object.prototype.toString.call(obj) === '[object Object]') return true;
  // ref: https://medium.com/javascript-in-plain-english/javascript-check-if-a-variable-is-an-object-and-nothing-else-not-an-array-a-set-etc-a3987ea08fd7
  // alternatives: if (obj && obj.constructor.name === 'Object') return true;
  return false;
};

export const toBool = str => {
  if ((typeof str === 'boolean' && str === true) || (typeof str === 'string' && str === 'true')) {
    return true;
  }
  return false;
};

export const isNotSetOrTrue = (str) => {
  if (typeof str === 'undefined' || str === null || (typeof str === 'boolean' && str )) {
    return true;
  } else if (typeof str === 'boolean' && str === false) {
    return false;
  }
  return false;
};

export const isEmptyOrUndefined = (str) => {
  if (typeof str === 'undefined' || !str || str.length === 0) {
    return true;
  } 
  return false;
};

export const normalize = (str, key=false) => {
  try {
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    if(key) str = str.replace(/ +/g, "_");
  } catch(e) {
    console.log('>>ERROR normalizing:', e.message);
  }
  return str;
}

export const isJSON = (str) => {
  try {
    const json = JSON.parse(str);
    if (json && isObject(json)) return true;
  } catch (e) {
    return false;
  }
  return false;
};

export const isFunction = (val) => {
  //If our variable is an instance of "Function"
  if (val instanceof Function) {
      return true;
  }
  return false;
}

export const getOwner = (user) => {
  //return id to save in owner field
  let owner;
  if (user) {
    if (config.USER_ACCOUNTS_INTEGRATED && user.accounts) {
      let account = user.accounts.filter(acc => acc.ownership === config.USER_ACCOUNTS.TYPES.OWNER);
      owner = config.USER_ACCOUNTS_INTEGRATED && account.length ? account[0].id : user.id;
    } else {
      owner = user.id;
    }
  }
  return owner;
}

export const capitalize = (string) => {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const capitalizePhrase = (string) => {
  if (typeof string !== 'string') return ''
  return string.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');  
}

export const dateComponents = (date) => {
  if (!isDate(date)) return null;
  let response = [];
  response.push({ short: format(date, 'd') });
  response.push({ short: format(date, 'cccc') });
  response.push({ short: format(date, 'MMM'), long: format(date, 'MMMM') });
  response.push({ dateOnly: format(date, 'yyyy-MM-d') });
  return response;
}

export const timeDistance = (from, to, locale) => {
  console.log('>>>> timeDistance', from, to);
  return formatDistance(to, from, { addSuffix: true, includeSeconds: true, locale });
}

export const changeTimeInDate = (time, day) => {
  return parse(time, 'HH:mm',  new Date(day));
}

export const now = (separator = '-', type = 'date') =>{
  const now = new Date();
  // const offsetMs = now.getTimezoneOffset() * 60 * 1000;
  // const dateLocal = new Date(now.getTime() - offsetMs);
  const dateLocal = new Date(now.setHours(0,0,0,0));
  if (type === 'string') {
    return dateLocal.toISOString().slice(0, 19).replace(/-/g, separator).replace("T", " ");
  } else if (type === 'date') {
    return dateLocal;
  }
}

export const setTimeZeroDateWithTZ = (date, tz = true) => {
  const baseDate = new Date(date).setUTCHours(0,0,0,0);
  const dateZero = new Date(baseDate);
  const offsetMs = !tz ? 0 : new Date(baseDate).getTimezoneOffset() * 60 * 1000;
  return new Date(dateZero.getTime() + offsetMs);
}

export const months = (m = null) => {
  const month = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'setiembre', 'octubre', 'noviembre', 'diciembre'];
  return m !== null ? month[m] : month;
}

export const numToTime = (number) => {
  // Check sign of given number
  const sign = (number >= 0) ? 1 : -1;
  number = number * sign;

  let hour = Math.floor(number);
  let decpart = number - hour;

  let min = 1 / 60;
  // Round to nearest minute
  decpart = min * Math.round(decpart / min);
  let minute = Math.floor(decpart * 60);
  // Add padding if need
  return (sign === 1 ? '' : '-') + formatCompleteWith(hour, 2) + ':' + formatCompleteWith(minute, 2);
}

export const getTimeZone = () => {
  if (Intl) return Intl.DateTimeFormat().resolvedOptions().timeZone;
  return null;
}

export const getClientIp = async () => () => {}

export const getLatLng = (callback) => {
  // Try HTML5 geolocation.
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        if (callback) callback({ lat, lng });
      },
      (err) => {
        console.log('ERROR WITH GEOLOCATION', err);
        if (callback) callback(null);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      } 
    );
  } else {
    console.log('NO HTML GEOLOCATION');
    if (callback) callback(null);
  }  
}

export const formatCompleteWith = (str, len = 0, char = '0') => {
  const length = len === 0 ? str.toString().length : len;
  return char.repeat(length - str.toString().length) + str.toString();
}

export const formatAddressFromGoogle = (address, number) => {
  return `${address.components[1].short_name} ${number ? number : address.components[0].short_name}`;
}

export const formatMoney = (amount, decimalCount = 2, decimal = ",", thousands = ".") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log('FORMATMONEY error:', e)
  }
};

export const formatPhoneNumber = (phoneNumberString) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null
}

export const formatTax = (str) => {
  if (isEmptyOrUndefined(str)) return null;
  let match = str.match(/^(\d{2})(\d{8})(\d{1})$/);
  if (match) {
    return [match[1], match[2], match[3]].join('-');
  }
  return null
}

export const searchJSON = (json, root, search) => {
  // console.log('json', json, search);
  if (!isObject(search)) return false;
  if (isObject(json)) {
    let json_root = '';
    if (!root.startsWith('$')) return false;
    if (root.startsWith('$.')) {
      json_root = root.substring(2, root.length);
    }
    const json_root_array = json_root !== '' ? json_root.split('.') : [];
    const search_names = Object.keys(search);
    const search_values = Object.values(search);
    // console.log('JSON', json_root, json_root_array, search_names, search_values);
    if (json_root_array.length) {
      let level = 0;
      for (let i = 0; i < json_root_array.length; i++) {
        const el = json_root_array[i];
        if (json.hasOwnProperty(el)) {
          // console.log('JSON HAS', el, level);
          if (level === json_root_array.length -1 ) {
            // console.log('LAST LEVEL');
            for (let j = 0; j < search_names.length; j++) {
              const name = search_names[j];
              const value = search_values[j];
              // console.log('SEARCH', name, value, json[el]);
              if (! Array.isArray(json[el])) {
                if (json[el].hasOwnProperty(name) && json[el][name] === value) {
                  // console.log('FOUND', level, name, value);
                  return true;
                }
              } else {
                const result = json[el].filter((el) => {
                  console.log('EL', el)
                  return el.hasOwnProperty(name) && el[name] === value;
                });
                // console.log('RESULT', result, result.length);
                if (result.length) { 
                  return result;
                  break;
                } else {
                  return false;
                }
              }
            }
            level++
          }
        }
      };
    }
    return true
  }
  return false
}


//extrapolateProp(obj,"prop") transforms {a:1,prop:{b:2,c:3}} into {a:1,b:2,c:3}
export const extrapolateProp = (obj, prop) => { 
  if(Object.keys(obj).includes(prop)){
    const new_obj = { ...obj }
    delete new_obj[prop]
    return { ...new_obj, ...obj[prop] }
  } else {
    return obj
  }
}

/*
  Return object with first level json_data at root level and filtered by fields
*/
export const getObjectWithJsonDataToFormValues = (obj , fields = []) => {
  let response = extrapolateProp(obj, 'json_data');
  if (fields.length) {
    response = Object.assign(
      ...Object.keys(response)
        .filter( key => fields.includes(key) === true)
        .map( key => ({ [key]: isJSON(response[key]) ? JSON.parse(response[key]) : response[key] }) )
    );
  }
  return response;
}

const getObjectWithJsonDataFilter = (obj , fields = []) => {
  let response = { ...obj };
  if (!obj) return response;
  if (obj && obj.hasOwnProperty('json_data')) {
    response.json_data = getObjectWithJsonDataFilter(response.json_data, fields);
  }
  //if any of the fields or a json_data field is in the object, return an empty object
  if(!fields.some(x => Object.getOwnPropertyNames(response).includes(x))){
    if(!response.json_data){
      return {}
    } else{
      if(!fields.some(x => Object.getOwnPropertyNames(response.json_data).includes(x))){
        return {}
      }
    }
  }
  
  if (fields.length) {
    response = Object.assign(
      ...Object.keys(response)
        .filter( key => fields.includes(key) === true || key === 'json_data') //json_data is not given in fields
        .map( key => {
            return { [key]: response[key] };
          }
        )
    );
  }
  return response;
}

//pass an object to specifies how to filter json data
//{"main": ["field1", "field2", "fieldN"], "object1": ["field1", "field2", "fieldN"], "objectN": ["field1", "field2", "fieldN"], }
//main: fields outside any object or inside json_data
const getObjectWithJsonObjectFilter = (obj, fields) => {
  const new_obj = {json_data:{}}
  Object.keys(fields).forEach(key => {
    if(key === "main"){
      new_obj[key] = getObjectWithJsonDataFilter(obj, fields[key])
      new_obj.json_data = {...new_obj.json_data, ...new_obj[key].json_data}
      delete new_obj[key].json_data
    } else {
      new_obj.json_data = {...new_obj.json_data, [key]: getObjectWithJsonDataFilter(obj.json_data[key], fields[key])}
      new_obj.json_data[key] = extrapolateProp(new_obj.json_data[key], "json_data") 
    }
  })
  
  return extrapolateProp(new_obj, "main")
}

export const getObjectWithJsonArrayFilter = (obj, fields) => {
  const object_fields = {...Object.assign({}, ...fields.filter(f => isObject(f))), main: fields.filter(f => !isObject(f))}
  return getObjectWithJsonObjectFilter(obj,object_fields)
}



export const chunkArray = (array, chunkSize) => {
  const numberOfChunks = Math.ceil(array.length / chunkSize);

  return [...Array(numberOfChunks)]
    .map((value, index) => {
      return array.slice(index * chunkSize, (index + 1) * chunkSize);
    })
}

export const sortArrayByAttribute = (array, attr) => {
  let array_sorted = array.sort(
    (a1, a2) => (a1[attr] > a2[attr]) ? 1 : (a1[attr] < a2[attr]) ? -1 : 0);  
  return array_sorted;
};



export const currencyTranslate = (current, translation) => {
  return translation && translation[current] ? translation[current] : current;
}

export const permissionsCheck = (path, permissions, action = 'route') => {
  const route = path.substr(1, path.length).split('/');
  console.log('ROUTE & SUBROUTES', route);
  if (['login', 'logout', 'icons'].includes(route[0]) || route[0] === '') return true;

  function actionType(perm) {
    // console.log('ACTION TYPE', route.length, perm.action_type, action, ['delete', 'update', 'insert'].includes(action));
    if (action === 'route' && route.length === 1) {
      return (perm.action_type === 'read' || perm.action_type === 'write');
    }
    if (action === 'route' && route.length > 1 && route[1].includes('new')) {
      return (perm.action_type === 'write');
    }
    if (action === 'route' && route.length > 1 && route[1].includes(':id')) {
      return (perm.action_type === 'read' || perm.action_type === 'write');
    }
    if (action !== 'route' && ['delete', 'update', 'insert'].includes(action)) {
      return (perm.action_type === 'write');
    }

  }

  if ((!Array.isArray(permissions) || !permissions) && action === 'route') { 
    return true;
  } else if ((!Array.isArray(permissions) || !permissions) && ['delete', 'update', 'insert'].includes(action)) {
    return false;
  }
  const found = permissions.filter(p => 
    p.object_type === route[0] && actionType(p)
    // p.action_type === action
  );
  // console.log('FOUND PERMIS', found);
  return found.length > 0;
}



export const paginate = (
  totalItems,
  currentPage = 1,
  pageSize = 10,
  maxPages = 0
) => {
  // calculate total pages
  let totalPages = Math.ceil(totalItems / pageSize);

  maxPages = maxPages === 0 ? totalPages : maxPages;
  // ensure current page isn't out of range
  if (currentPage < 1) {
      currentPage = 1;
  } else if (currentPage > totalPages) {
      currentPage = totalPages;
  }

  let startPage, endPage;
  if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
  } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
      } else {
          // current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
      }
  }

  // calculate start and end item indexes
  let startIndex = (currentPage - 1) * pageSize;
  let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  let pages = 0;
  if (totalPages) {
    pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
  } 

  const disablePrevious = currentPage === 1 ? true : false;
  const disableNext = currentPage === endPage ? true : false;

  // return object with all pager properties required by the view
  return {
    totalItems,
    currentPage,
    pageSize,
    totalPages,
    startPage,
    endPage,
    startIndex,
    endIndex,
    disablePrevious,
    disableNext,
    pages
  };
}


/* enumerateTimeSlots v0.0.4 */
export const enumerateTimeSlots = ({
  slot = null,
  wait = 0,
  start = null,
  end = null,
  timeOffset = 0,
  ampm = null
}) => {
  let timeSlots = [];
  if (slot === null) slot = 15;
  if (wait !== 0 ) slot = slot + wait;
  if (ampm === null) ampm = true;
  if (start === null) start = '00:00';
  if (end === null) end = '23:59';
  if (timeOffset === null) timeOffset = 0;


  const date = new Date();

  let startTime = parse(start, 'HH:mm',  date);
  let endTime = parse(end, 'HH:mm',  date);

  // console.log('BEFORE ', startTime, endTime);
  // console.log('TIMEOFFSET', timeOffset);

  if (timeOffset > 0) {
    // console.log('MAS');
    startTime = addMinutes(startTime, timeOffset);
    endTime = addMinutes(endTime, timeOffset);
  } else if (timeOffset < 0){
    // console.log('MENOS');
    startTime = subMinutes(startTime, timeOffset);
    endTime = subMinutes(endTime, timeOffset);
  }
  console.log('AFTER', startTime, endTime);

  let loop = 0;
  while (startTime <= endTime && loop <= 24) {
    // console.log('startTime', startTime);
    // console.log('endTime', endTime);
    let timeSlot = format(startTime, 'HH:mm');
    // console.log('SLOT', timeSlot);
    // if (ampm) timeSlot = moment(timeSlot, 'HH:mm').format('hh:mm A');
    timeSlots.push(timeSlot);
    startTime = addMinutes(startTime, slot);
    loop++;
  }
  return timeSlots;
};



export const formatFullname = ({ first_name= '', last_name= '' }) => {
  return `${capitalize(first_name)} ${capitalize(last_name)}`;
}

export const formatOnlyNumberInput = (value, maxLength, extras=[]) => {
  if(!value) return value
  //const reg = RegExp(`(?![\\d+|\\${extras.join("")}]+)\\w+`);
  return value.replace(/\D+/g, '').slice(0, maxLength)
}


export const cancelValidation = (date) => {
  if(!date) return false;
  const startTime = new Date(date)
  const currentTime = new Date();
  return differenceInMinutes(startTime,currentTime) > config.MINUTES.TO_CANCEL;
}

export const startValidation = (date) => {
  if(!date) return false;
  const currentTime = new Date();
  const startTime = new Date(date)

  return isWithinInterval(currentTime, {
    start: subMinutes(startTime, config.MINUTES.BEFORE_ATTEND),
    end: addMinutes(startTime, config.MINUTES.AFTER_ATTEND)
  })
}

export const validateBenefitsTransactionsArray = (all_transactions, my_transactions, rules, pack=null, benefit_id=null) => {
  let {group_uses, group_uses_interval, user_uses, user_uses_interval, days_of_week} = rules
  console.log("rules", rules)

  let reasons = []

  if(days_of_week){
    //FIXME: que el panel guarde domingo como 0 y no lunes
    let today_day = (new Date()).getDay()-1
    if(today_day === -1) today_day = 0
      console.log("benefit_check", days_of_week)
    let available_days_of_week = Object.keys(days_of_week).map((k, index) => (days_of_week[k] === true) ? index : null).filter(x => x !== null)
   
    if(available_days_of_week.length !== 0){
      console.log("benefit_check", available_days_of_week, today_day, available_days_of_week.indexOf(today_day))
      if(available_days_of_week.indexOf(today_day) === -1) {
        console.log("benefit_check", "INVALIDATED FOR  day of week")
        reasons = [...reasons, "No disponible este día de la semana"]
      }
    }
  }

  if(group_uses){
    group_uses = parseInt(group_uses)
    group_uses_interval = parseInt(group_uses_interval)

    let all_transactions_this_benefit = all_transactions
    if(benefit_id){
      all_transactions_this_benefit = all_transactions.filter(t => t.json_data.benefit?.id === benefit_id)
    }

    console.log("DEBBF", "max_group_uses", group_uses, group_uses_interval, "group_uses", all_transactions_this_benefit.length)
    console.log("DEBBF", all_transactions_this_benefit)
    
    if(group_uses){
      if(all_transactions_this_benefit.length >= group_uses){
        console.log("benefit_check", "INVALIDATED FOR group_uses")
        reasons = [...reasons, "La empresa ya agotó los usos disponibles"]
      }
    }
    if(group_uses_interval){
      let interval_transactions = all_transactions_this_benefit.filter(t => t.updated_at ? new Date(t.updated_at) > subHours(new Date(), group_uses_interval) : false).length
      if(interval_transactions){
        console.log("benefit_check", "INVALIDATED FOR group_uses_interval")
        reasons = [...reasons, `Disponible para empresa cada ${group_uses_interval} horas`]
      }
    }
  }

  if(user_uses){
    user_uses = parseInt(user_uses)
    user_uses_interval = parseInt(user_uses_interval)
    console.log("max_user_uses", user_uses, user_uses_interval)
    console.log("user_uses", my_transactions.length)
    console.log("my_transactions", my_transactions)

    if(user_uses){
      if(my_transactions.length >= user_uses){
        console.log("benefit_check", "INVALIDATED FOR user_uses")
        if(pack){
          reasons = [...reasons, "Ya agotaste los usos disponibles de este premio o de este grupo de premios"]
        } else {
          reasons = [...reasons, "El usuario ya agotó los usos disponibles"]
        }

      }
    }

    if(user_uses_interval){
      let interval_transactions = my_transactions.filter(t => t.updated_at ? new Date(t.created_at) > subHours(new Date(), user_uses_interval) : false).length
      if(interval_transactions){
        console.log("benefit_check", "INVALIDATED FOR interval_transactions")
        reasons = [...reasons, `Disponible para usuarios particulares cada ${group_uses_interval}`]
      }
    }
  }

  return reasons
}


export const validateBenefitRules = (benefit) => {
  let reasons = []
  let start = new Date(benefit.json_data.rules.start_date)
  let end = new Date(benefit.json_data.rules.end_date);
  end.setDate(end.getDate() + 1); // add one day to the end date to include the last day
  let today = new Date()
  if(!(isAfter(today, start) && isBefore(today, end))){
    if(benefit.json_data.rules.start_date && benefit.json_data.rules.end_date){
      let reason = ""
      if(benefit.json_data.rules.start_date){
        reason += `desde ${getDateFirstPart(start)}`
      }  
      if(benefit.json_data.rules.end_date){
        reason += ` hasta ${getDateFirstPart(end)}`
      }

      console.log("benefit_check", "INVALIDATED FOR start-end date")
      reasons = [...reasons, `disponible ${reason}`]
    }
  }

  return reasons

}

export const daysOfWeekText = (daysOfWeek) => {
  if(typeof(daysOfWeek) === 'object') {
    return Object.keys(daysOfWeek).map(k => {
      if(daysOfWeek[k] && k === 'l') return "Lunes"
      if(daysOfWeek[k] && k === 'm') return "Martes"
      if(daysOfWeek[k] && k === 'x') return "Miércoles"
      if(daysOfWeek[k] && k === 'j') return "Jueves"
      if(daysOfWeek[k] && k === 'v') return "Viernes"
      if(daysOfWeek[k] && k === 's') return "Sábados"
      if(daysOfWeek[k] && k === 'd') return "Domingos"
      return null
    }).filter(d => d)
  }
  if(daysOfWeek.length === 0) return daysOfWeek
  return daysOfWeek.split("-").map(day => {
    if(day === 'l') return "Lunes"
    if(day === 'm') return "Martes"
    if(day === 'x') return "Miércoles"
    if(day === 'j') return "Jueves"
    if(day === 'v') return "Viernes"
    if(day === 's') return "Sábados"
    if(day === 'd') return "Domingos"
  })
}

export const daysOfWeekToString = (daysOfWeek) => {
  let s = ""
  let available_days = daysOfWeekText(daysOfWeek)
  if(available_days.length === 7){
    s = "Disponible todos los días"
  } else if(available_days.length === 1){
    s = `Disponible los ${available_days[0]}` 
  } else {
    s = `Disponible los ${available_days.slice(0,available_days.length-1).join(", ")} y ${available_days[available_days.length-1]}` 
  }
  return s  
}

export const getDateFirstPart = (d) => {
  return new Date(d).toLocaleDateString()
}

export const getRewardFromLevel = (userLevel, benefit) => {
  //console.log('USER LEVEL', userLevel, benefit);
  let user_current_level_benefit = false;
  if (userLevel > 0) {
    try {
      const benefit_user_value = benefit.values[(userLevel - 1).toString()]
      user_current_level_benefit = benefit_user_value.value
    } catch (e) {
      //console.log('No reward level in benefit', e, benefit)
    }
  }
  return user_current_level_benefit
}

//reemplaza los "-" de las fechas como las guardamos en la db por "/"
//eso hace que cuando le hagas new Date() la string de la fecha
//anule el timezone, entonces las fechas que en en la db se guarda en una fecha a las 00
//el new date no le resta 3 horas
//https://twitter.com/Syb3rspace/status/799558118904594432
export const strToDateWithoutTZ = (date_str) => {
  if(!date_str) return null
  return new Date(date_str.replaceAll("-", "/").substring(0,10))
}

export function getTrxType(trx) {
  if(trx.status === 'archived') return 'archived';
  else if(trx.source === trx.target) return 'income';
  else if(trx.sub_type === 'reserved') return 'reserved';
  else return 'egress';
}


export function calculateBalanceFromTrx(trx) {
  let balance = 0;
  let reservedBalance = 0;
  trx
    .forEach(
      (trx) => {
        balance += getTrxType(trx) === 'income' ? -trx.total : +trx.total;
        if(getTrxType(trx) === 'reserved') reservedBalance += trx.total;
      }
    );

  return {balance, reservedBalance};
}