import React from 'react';
import { useTranslation } from 'react-i18next';
import { getObjectWithJsonDataToFormValues  } from '../../libraries/utils';

import UserOwnerElement from './UserOwnerElement'
import IconPoints from './IconPoints';
import IconCoins from './IconCoins';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

export default function ProductElement({ product, owner, ...rest }) {
  const { t, i18n } = useTranslation();
  const user = useSelector( state => state.users.auth.user);

  const { img, name, coins, points } = getObjectWithJsonDataToFormValues(
    product,
    ['id', 'status', 'product', 'owner', 'coins', 'points','img', 'name']
  );

  const { first_name, last_name, rating } = getObjectWithJsonDataToFormValues(
    owner,
    ['id', 'username', 'first_name', 'last_name', 'profile_image', 'rating']
  );

  return (
    <div className={clsx("card white overflow-hidden flex justify-between h-full", rest.className ?? "")} >
      <div className={clsx("flex items-center justify-center overflow-hidden", img && "h-40")}>
        { img && <img alt="" src={img}/> }
      </div>
      <div className="overflow-hidden">
        <div className="p-3 leading-tight">
          <h5 className="">{t(name)}</h5>
          <UserOwnerElement starClassName="mb-4" owner={{first_name, last_name, rating}} />

          <div className="flex justify-between items-center">
            <div className="flex">
              <IconCoins negative={user.id !== owner.id} coins={coins}/>
              <IconPoints points={points}/>
            </div>
          </div>
        </div>
      </div>
    </div>   
  )
}




