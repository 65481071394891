import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';
import IconPoints from './IconPoints';
import IconCoins from './IconCoins';
import Icon from '../../libraries/icons';
import { getObjectWithJsonDataToFormValues  } from '../../libraries/utils';



export default function ActionCard({ route, data, filters, ...rest }) { 
  const { name, coins, points, img } = getObjectWithJsonDataToFormValues(
    data,
    ['id', 'name', 'coins', 'points', 'img']
  );

  const actionOds = data.json_data.ods?.split(',');
  let odsIcons = [];
  actionOds.forEach( o => {
    if (config.ODS.find( e => e.code === o.trim() )) {
      odsIcons.push(config.ODS.find( e => e.code === o.trim() ).icon)
    }
  }); // Quizá haya una mejor forma de hacer esto, es lo primero que se me ocurrió
  return (
    <Link
      className={"card white block overflow-hidden " + (rest.className ? rest.className : "")}
      to={{pathname: route, state: { from: config.ROUTES.HOME_OPTIONS.ACTIONS, filters }}}
    >
      <div className="h-40 flex justify-center items-center">
        <img src={`${img}?v=1`} alt="" className='w-full h-full object-cover' />
      </div>
      <div className="p-4 relative">
        <div className='avatar-group absolute -top-4 right-2 overflow-visible'>
          {odsIcons && odsIcons.slice(0,3).map( (o,i) => (
            <div key={i} className='-ml-2'>
              <img src={o} alt='ods' className='w-6' />
            </div>
          ))}
          {odsIcons && odsIcons.length > 3 && (
              <div className='text-yellow-400 rounded-full -ml-2 w-6 h-6 bg-primary-focus grid place-content-center'>
                <span className='-ml-1 flex'>+{odsIcons.length - 3}</span>
              </div>
            )
          }
        </div>
        <h5>{name}</h5>
        <div className="flex justify-between items-center mt-3">
          <div className={`flex  font-medium gap-2 ${!(route && route.length > 0)?' w-full':'items-center'}`}>
            <IconCoins iconClassName="h-4" color='green' className="bg-success bg-opacity-10 flex gap-2 rounded" coins={coins}/>
            <IconPoints iconClassName="h-4" color='green' className="bg-success bg-opacity-10 flex gap-2 rounded" points={points}/>
          </div>
          {(route && route.length > 0)&& <div className='bg-primary bg-opacity-10 text-primary rounded-md w-8 h-8 grid place-content-center'>
            <Icon size={18} name='arrow_right' />
          </div>}
          {/* <small className="text-gray-500">{json_data.distance} km</small> */}
        </div>
      </div>
    </Link>    
  )
}