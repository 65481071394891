import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from '../../config';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const logged = useSelector(state => state.users.auth.logged);

  return (
    <Route 
      {...rest}
      render = { props => (
        logged ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: config.ROUTES.LOGIN, state: { from: props.location } }} />
        )
      )}
    />
  )
}