import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';

import { PrivateRoute } from '../components/commons/PrivateRoute';
import config from '../config';

import Home from '../pages/Home';
import Redirect from '../pages/Redirect';
import SearchOffers from '../pages/SearchOffers';
import Add from '../pages/offers/Add';
import Notifications from '../pages/user/Notifications';
import Messages from '../pages/user/Messages';
import SendMessage from '../pages/user/SendMessage';
import Chat from '../pages/user/Chat';

import UserSignup from '../pages/auth/UserSignup';
import UserInvitationSignup from '../pages/auth/UserInvitationSignup';
import UserLogin from '../pages/auth/UserLogin';
import UserFullSignup from '../pages/auth/UserFullSignup';
import UserPassword from '../pages/auth/UserPassword';
import UserPasswordChange from '../pages/auth/UserPasswordChange';
import UserVerify from '../pages/auth/UserVerify';
import UserInfo from '../pages/auth/UserInfo';
import UserSettings from '../pages/auth/UserSettings';
import UserForgot from '../pages/auth/UserForgot';
import UserProfile from '../pages/auth/UserProfile';
import UserRanking from '../pages/auth/UserRanking';

import Inventory from '../pages/user/Inventory';
import Loans from '../pages/user/Loans';
import Returns from '../pages/user/Returns';
import Favourites from '../pages/user/Favourites';
import Activities from '../pages/user/Activities';
import ProfileRatings from '../pages/user/ProfileRatings';


import Product from '../pages/offers/Product';
import Products from '../pages/offers/Products';
import Service from '../pages/offers/Service';
import Services from '../pages/offers/Services';
import Favor from '../pages/offers/Favor';
import Favors from '../pages/offers/Favors';


import InfoTerms from '../pages/info/InfoTerms';
import InfoPrivacy from '../pages/info/InfoPrivacy';
import InfoContactUs from '../pages/info/InfoContactUs';
import InfoHowWorks from '../pages/info/InfoHowWorks';
import InfoHelp from '../pages/info/InfoHelp';
import InfoAssistance from '../pages/info/InfoAssistance';
import Info from '../pages/info/Info';

import Volunteering from '../pages/iniciatives/Volunteering';
// import VolunteeringDetail from '../pages/iniciatives/VolunteeringDetail';
// import Donation from '../pages/iniciatives/Donation';
import Donations from '../pages/iniciatives/Donations';
import Recycling from '../pages/iniciatives/Recycling';
// import RecyclingDetail from '../pages/iniciatives/RecyclingDetail';
import Initiative from '../pages/iniciatives/Initiative';

import Action from '../pages/actions/Action';
import ShareAction from '../pages/actions/ShareAction';

import FeedComment from '../pages/FeedComment';

import Benefit from '../pages/benefits/Benefit';
import BenefitsList from '../pages/benefits/BenefitsList';
import BusinessesList from '../pages/benefits/BusinessesList';


//FIXME: por qué esto esta importado 2 veces con el mismo nombre ¿?
import OffersAddDetails from '../pages/offers/Step2';
import OffersAddAvailability from '../pages/offers/Step3';

import SelectCategory from '../pages/offers/Step1';
import Step2 from '../pages/offers/Step2';
import Step3 from '../pages/offers/Step3';

import RequestsAddDetails from '../pages/offers/StepSearch2';
import Requests from '../pages/offers/Requests';



import ConfirmLoan from '../pages/user/ConfirmLoan';
import ConfirmReturned from '../pages/user/ConfirmReturned';
import ConfirmReturn from '../pages/user/ConfirmReturn';

import Error from '../pages/commons/Error';
import Information from "../pages/commons/Information";



import AnswerQuestionClass from '../pages/user/AnswerQuestionClass';

import Request from '../pages/offers/Request';
import Ranking from '../pages/home/Ranking';
import Offer from '../pages/home/Offer';
import Actions from '../pages/home/Actions';
import ChallengesHome from '../pages/home/Challenges';
import HomeFavors from '../pages/home/Favors';
import HomeProducts from '../pages/home/Products';
import HomeRequests from '../pages/home/Requests';

// import Challenges from '../pages/challenges/Challenges';
import ChallengeActivity from '../pages/challenges/Activity';
import ChallengeImpact from '../pages/challenges/Impact';
import ChallengeInfo from '../pages/challenges/Info';
import ChallengeLeaderboard from '../pages/challenges/Leaderboard';

import Prizes from '../pages/home/Prizes';
import Prize from '../pages/benefits/Prize';
// import PrizeBusinessesList from '../pages/benefits/BusinessesList';



import Directory from '../pages/user/Directory';
import AboutODS from '../pages/actions/AboutODS';

import UserReferrals from '../pages/auth/UserReferrals';
import UserCredistsHistory from '../pages/auth/UserCredistsHistory';
import PageOnboarding from '../pages/onboarding/PageOnboarding';

// import QrPage from '../pages/benefits/QrPage';
// import CategoryPage from '../pages/CategoryPage';
// import SearchList from '../pages/SearchList';

//FIXME renombrar a initiatives route
// import { BenefitsInitiativeRoute } from '../components/commons/BenefitsInitiativeRoute';
// import { BenefitsRoute } from '../components/commons/BenefitsRoute';
// import ScanQR from '../pages/user/ScanQR';
// import VolunteeringAttendance from '../pages/user/VolunteeringAttendance';
// import VolunteeringAttendanceConfirmation from '../pages/user/VolunteeringAttendanceConfirmation';

// import BenefitsInitiativesLogin from '../pages/auth/BenefitsInitiativesLogin';
// import BenefitConsume from '../pages/benefits/BenefitConsume';
// import BenefitConsumeConfirmation from '../pages/benefits/BenefitConsumeConfirmation';
// import AnswerQuestion from '../pages/user/AnswerQuestion';

import FlowStep1 from '../pages/flow/FlowStep1';
import FlowStep2 from '../pages/flow/FlowStep2';
import FlowStep3 from '../pages/flow/FlowStep3';
import FlowStep4 from '../pages/flow/FlowStep4';
import FlowStep5 from '../pages/flow/FlowStep5';
import FlowStep6 from '../pages/flow/FlowStep6';
import FlowStep7 from '../pages/flow/FlowStep7';

export const history = createBrowserHistory();

export const Routes = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  return (
    <Switch>
      <Route path={config.ROUTES.TERMS} exact component={InfoTerms} />
      <Route path={config.ROUTES.PRIVACY} exact component={InfoPrivacy} />
      <Route path={config.ROUTES.CONTACT_US} exact component={InfoContactUs} />
      <Route path={config.ROUTES.HOW_WORKS} exact component={InfoHowWorks} />
      <Route path={config.ROUTES.HELP} exact component={InfoHelp} />
      <Route path={config.ROUTES.ASSISTANCE} exact component={InfoAssistance} />
      <Route path={config.ROUTES.INFO} exact component={Info} />

      <Route path={config.ROUTES.LOGIN} exact component={UserLogin} />
      <Route path={config.ROUTES.SIGNUP_INVITATION} exact component={UserSignup} />
      {/* <Route path={config.ROUTES.SIGNUP_INVITATION} exact component={UserInvitationSignup} /> */}
      <Route path={config.ROUTES.SIGNUP_FULL} exact component={UserFullSignup} />
      <Route path={config.ROUTES.USER.PASSWORD} exact component={UserPassword} />
      <Route path={config.ROUTES.VERIFY} exact component={UserVerify} />
      <Route path={config.ROUTES.FORGOT} exact component={UserForgot} />

      <PrivateRoute path={config.ROUTES.USER.PASSWORD_CHANGE} exact component={UserPasswordChange} />
      <PrivateRoute path={config.ROUTES.USER.PROFILE} exact component={UserProfile} />
      <PrivateRoute path={config.ROUTES.USER.PUBLIC_PROFILE} exact component={UserProfile} />
      <PrivateRoute path={config.ROUTES.USER.PROFILE_RATINGS} exact component={ProfileRatings} />     
      <PrivateRoute path={config.ROUTES.USER.INFO} exact component={UserInfo} />      
      <PrivateRoute path={config.ROUTES.USER.SETTINGS} exact component={UserSettings} />
      <PrivateRoute path={config.ROUTES.USER.CREDITS_HISTORY} exact component={UserCredistsHistory} />
      <PrivateRoute path={config.ROUTES.USER.RANKING} exact component={UserRanking} />
      <PrivateRoute path={config.ROUTES.USER.REFERRALS} exact component={UserReferrals} />   

      <PrivateRoute path={config.ROUTES.USER.LOANS} exact component={Loans} />
      <PrivateRoute path={config.ROUTES.USER.RETURNS} exact component={Returns} />
      <PrivateRoute path={config.ROUTES.USER.FAVOURITES} exact component={Favourites} />
      <PrivateRoute path={config.ROUTES.USER.INVENTORY} exact component={Inventory} />
      <PrivateRoute path={config.ROUTES.USER.ACTIVITIES} exact component={Activities} />

      <PrivateRoute path={config.ROUTES.USER.CONFIRM_LOAN} exact component={ConfirmLoan} />
      <PrivateRoute path={config.ROUTES.USER.CONFIRM_RETURNED} exact component={ConfirmReturned} />
      <PrivateRoute path={config.ROUTES.USER.CONFIRM_RETURN} exact component={ConfirmReturn} />

      <PrivateRoute path={config.ROUTES.USER.DIRECTORY} exact component={Directory}  />
      

      <PrivateRoute path={config.ROUTES.PRODUCTS.LIST} exact component={Products} />
      <PrivateRoute path={config.ROUTES.PRODUCTS.DETAIL} exact component={Product} />
      <PrivateRoute path={config.ROUTES.PRODUCTS.SUCCESS} exact component={Information} />
      <PrivateRoute path={config.ROUTES.PRODUCTS.EDIT} exact component={Step2} />

      <PrivateRoute path={config.ROUTES.SERVICES.LIST} exact component={Services} />
      <PrivateRoute path={config.ROUTES.SERVICES.DETAIL} exact component={Service} />
      <PrivateRoute path={config.ROUTES.SERVICES.SUCCESS} exact component={Information} />
    
      <PrivateRoute path={config.ROUTES.FAVORS.LIST} exact component={Favors} />
      <PrivateRoute path={config.ROUTES.FAVORS.DETAIL} exact component={Favor} />
      <PrivateRoute path={config.ROUTES.FAVORS.SUCCESS} exact component={Information} />
     

      <PrivateRoute path={config.ROUTES.VOLUNTEERING.LIST} exact component={Volunteering} />
      <PrivateRoute path={config.ROUTES.VOLUNTEERING.DETAIL} exact component={Initiative} />
      <PrivateRoute path={config.ROUTES.VOLUNTEERING.SUCCESS} exact component={Information} />
      <PrivateRoute path={config.ROUTES.DONATIONS.LIST} exact component={Donations} />
      <PrivateRoute path={config.ROUTES.DONATIONS.DETAIL} exact component={Initiative} />
      <PrivateRoute path={config.ROUTES.DONATIONS.SUCCESS} exact component={Information} />
      <PrivateRoute path={config.ROUTES.RECYCLING.LIST} exact component={Recycling} />
      <PrivateRoute path={config.ROUTES.RECYCLING.DETAIL} exact component={Initiative} />
      <PrivateRoute path={config.ROUTES.RECYCLING.SUCCESS} exact component={Information} />

      <PrivateRoute path={config.ROUTES.ACTIONS.DETAIL} exact component={Action} />
      <PrivateRoute path={config.ROUTES.ACTIONS.SHARE} exact component={ShareAction} />
      <PrivateRoute path={config.ROUTES.ACTIONS.SUCCESS} exact component={Information} />
      <PrivateRoute path={config.ROUTES.ACTIONS.ODS} exact component={AboutODS} />
      <PrivateRoute path={config.ROUTES.FEED.DETAIL} exact component={FeedComment} />

      <PrivateRoute path={config.ROUTES.BENEFITS.LIST} exact component={BenefitsList} />
      <PrivateRoute path={config.ROUTES.BENEFITS.DETAIL} exact component={Benefit} />
      {/* <PrivateRoute path={config.ROUTES.QR_PAGE} exact component={QrPage} /> */}
      <PrivateRoute path={config.ROUTES.BENEFITS.BUSINESSES_LIST} exact component={BusinessesList} />

      <PrivateRoute path={config.ROUTES.PRIZES.LIST} exact component={Prizes} />
      <PrivateRoute path={config.ROUTES.PRIZES.DETAIL} exact component={Prize} />

      {/* <PrivateRoute path={config.ROUTES.SEARCH_LIST} exact component={SearchList} /> */}
      {/* <PrivateRoute path={config.ROUTES.QR_PAGE} exact component={QrPage} /> */}
      {/* <PrivateRoute path={config.ROUTES.CATEGORY_PAGE} exact component={CategoryPage} /> */}
      
      <PrivateRoute path={config.ROUTES.ADD} exact component={Add} />
      {/* 'step 1' */}
      <PrivateRoute path={config.ROUTES.CATEGORY} exact component={SelectCategory} />
      {/* 'step 2 */}

      <PrivateRoute path={config.ROUTES.STEP3} exact component={Step3} /> 


      <PrivateRoute path={config.ROUTES.OFFERS.DETAILS} exact component={OffersAddDetails} />
      <PrivateRoute path={config.ROUTES.REQUESTS.DETAILS} exact component={RequestsAddDetails} />
     
      <PrivateRoute path={config.ROUTES.OFFERS.AVAILABILITY} exact component={OffersAddAvailability} />


      {/* <PrivateRoute path={config.ROUTES.STEP2} exact component={Step2} />*/}
      
      <PrivateRoute path={config.ROUTES.REQUESTS.LIST} exact component={Requests} />
      <PrivateRoute path={config.ROUTES.REQUESTS.PREVIEW} exact component={Request} />

    

      <PrivateRoute path={config.ROUTES.SEARCH_OFFERS} exact component={SearchOffers} />
      <PrivateRoute path={config.ROUTES.NOTIFICATIONS} exact component={Notifications} />
      <PrivateRoute path={config.ROUTES.MESSAGES} exact component={Messages} />
      <PrivateRoute path={config.ROUTES.CHAT} exact component={Chat} />
      <PrivateRoute path={config.ROUTES.QUESTION} exact component={AnswerQuestionClass} />
      <PrivateRoute path={config.ROUTES.SEND_MESSAGE} exact component={SendMessage} />
      <PrivateRoute path={config.ROUTES.MESSAGE_SUCCESS} exact component={Information} />


      
      <PrivateRoute path={config.ROUTES.HOME_OPTIONS.ACTIONS} exact component={Actions} />
      <PrivateRoute path={config.ROUTES.HOME_OPTIONS.OFFERS} exact component={Offer} />
      <PrivateRoute path={config.ROUTES.HOME_OPTIONS.SEARCH} exact component={SearchOffers} />
      <PrivateRoute path={config.ROUTES.HOME_OPTIONS.CHALLENGES} exact component={ChallengesHome} />
      <PrivateRoute path={config.ROUTES.HOME_OPTIONS.PRODUCTS} exact component={HomeProducts} />
      <PrivateRoute path={config.ROUTES.HOME_OPTIONS.FAVORS} exact component={HomeFavors} />
      <PrivateRoute path={config.ROUTES.HOME_OPTIONS.REQUESTS} exact component={HomeRequests} />

      
      {/* <Route path={config.ROUTES.LOGIN_BENEFITS_INITIATIVES} exact component={BenefitsInitiativesLogin}/> */}

      {/* <BenefitsInitiativeRoute path={config.ROUTES.SCANQR} exact component={ScanQR} />
      <BenefitsInitiativeRoute path={config.ROUTES.VOLUNTEERING_ATTENDANCE} exact component={VolunteeringAttendance} />
      <BenefitsInitiativeRoute path={config.ROUTES.VOLUNTEERING_ATTENDANCE_CONFIRMATION} exact component={VolunteeringAttendanceConfirmation} />
      <BenefitsInitiativeRoute path={config.ROUTES.VOLUNTEERING_ATTENDANCE_CONFIRMATION_SUCCESS} exact component={Information} />

      <BenefitsRoute path={config.ROUTES.BENEFITS_SCANQR} exact component={ScanQR} />
      <BenefitsRoute path={config.ROUTES.BENEFITS_CONSUME} exact component={BenefitConsume} />
      <BenefitsRoute path={config.ROUTES.BENEFITS_CONSUME_CONFIRMATION} exact component={BenefitConsumeConfirmation} />
      <BenefitsRoute path={config.ROUTES.BENEFITS_CONSUME_CONFIRMATION_SUCCESS} exact component={Information} /> */}

      <PrivateRoute path={config.ROUTES.REDIRECT} exact component={Redirect} />
      <PrivateRoute path={config.ROUTES.HOME} exact component={Home} />
      {/* <PrivateRoute path={config.ROUTES.HOME} exact component={PageOnboarding} /> */}
      
      {/* <PrivateRoute path={config.ROUTES.CHALLENGES.BASE} component={Challenges}>
      </PrivateRoute> */}
      <PrivateRoute path={config.ROUTES.CHALLENGES.ACTIVITY} exact component={ChallengeActivity}  />
      <PrivateRoute path={config.ROUTES.CHALLENGES.IMPACT} exact component={ChallengeImpact}  />
      <PrivateRoute path={config.ROUTES.CHALLENGES.INFO} exact component={ChallengeInfo}  />
      <PrivateRoute path={config.ROUTES.CHALLENGES.LEADERBOARD} exact component={ChallengeLeaderboard}  />
     
      <PrivateRoute path={config.ROUTES.FLOW.BASE} exact component={FlowStep1}  />
      <PrivateRoute path={config.ROUTES.FLOW.STEP1} exact component={FlowStep1}  />
      <PrivateRoute path={config.ROUTES.FLOW.STEP2} exact component={FlowStep2}  />
      <PrivateRoute path={config.ROUTES.FLOW.STEP3} exact component={FlowStep3}  />
      <PrivateRoute path={config.ROUTES.FLOW.STEP4} exact component={FlowStep4}  />
      <PrivateRoute path={config.ROUTES.FLOW.STEP5} exact component={FlowStep5}  />
      <PrivateRoute path={config.ROUTES.FLOW.STEP6} exact component={FlowStep6}  />
      <PrivateRoute path={config.ROUTES.FLOW.STEP7} exact component={FlowStep7}  />
    
      <Route>
        <Error title={t('404')} headline={t('Page not found!')} button={t('Go back')}/>
      </Route>

    </Switch>
  );
}

