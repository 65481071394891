import React from 'react';
import { history } from '../../routes';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import { capitalize, getObjectWithJsonDataToFormValues  } from '../../libraries/utils';

import IconPoints from './IconPoints';
import IconCoins from './IconCoins';
import Icon from '../../libraries/icons';
import Image from '../commons/Image'
import ButtonIcon from '../commons/ButtonIcon';
import SmallRating from '../../components/commons/SmallRating'


export default function InventoryCard({ data,edit, ...rest }) {
  const { t, i18n } = useTranslation();  

  const { name, status, img, coins, points, type, id, rate_total, rate_count } = getObjectWithJsonDataToFormValues(
    data,
    ['id', 'name','status', 'img', 'coins', 'points', 'type', 'rate_total', 'rate_count']
  );

  let route= "";
  const rating = rate_total && (rate_count !== 0) ? rate_total/rate_count : 0

  if (edit){
    route={pathname: config.ROUTES.PRODUCTS.EDIT.replace(':id', id)}
  } else {
    switch (type){
      case 'products':
          route ={pathname: config.ROUTES.PRODUCTS.DETAIL.replace(':id', id), state:{ fromPath: rest.fromPath ? rest.fromPath : config.ROUTES.SEARCH_OFFERS }}
        break;
      case 'services':
        route = {pathname:config.ROUTES.SERVICES.DETAIL.replace(':id', id),state:{ fromPath: rest.fromPath ? rest.fromPath : config.ROUTES.SEARCH_OFFERS }}
        break;
      case 'favors':
        route = {pathname:config.ROUTES.FAVORS.DETAIL.replace(':id', id),state:{ fromPath: rest.fromPath ? rest.fromPath : config.ROUTES.SEARCH_OFFERS  }}
      break;
  
      case 'volunteering':
        route = {pathname:config.ROUTES.VOLUNTEERING.DETAIL.replace(':id', id),state:{ from: config.ROUTES.SEARCH_OFFERS  }}
      break;
      case 'recycling':
        route = {pathname:config.ROUTES.RECYCLING.DETAIL.replace(':id', id),state:{ from: config.ROUTES.SEARCH_OFFERS  }}
      break;
      case 'donations':
        route = {pathname:config.ROUTES.DONATIONS.DETAIL.replace(':id', id),state:{ from: config.ROUTES.SEARCH_OFFERS  }}
      break; 
      default:
    }
  }
  

  return (
    <div className={"card flex-row white overflow-hidden flex h-full " + (rest.className ? rest.className : "")} onClick={() => route && history.push(route)}>
        <SmallRating containerClassName=' absolute px-1 right-2 bottom-2 h-6' rate={rating} />
        <div className="overflow-hidden w-1/2">
          <div className="p-3 leading-tight flex justify-between items-start flex-col h-full">
            <h5>{t(name)}</h5>
            {/* <UserOwnerElement starClassName="mt-1" owner={owner} /> */}
            <div className="badge badge-sm badge-ghost my-1">{t(type)}</div>
            <div className="flex justify-between items-center gap-2">
              <IconCoins iconClassName="h-4 w-auto mr-2" color='red' coins={coins} showNumber={true}/>
              <IconPoints iconClassName="h-4 w-auto mr-2 text-primary" color='green' points={points} showNumber={true}/>
            </div>
            {status !== config.STATUS.PRODUCTS.DISABLED && (
              <div className="badge badge-outline badge-primary mt-4 mr-2 ">{t(status)}</div> 
            )}
            {status === config.STATUS.PRODUCTS.BORROWED && (
              <h6 className="text-gray-400 mt-2">{t("Disponible pronto")}</h6>
            )}
            {status === config.STATUS.PRODUCTS.DISABLED && (
              <h6 className="text-error mt-2">{t("Deshabilitado")}</h6>
            )}
            {status !== config.STATUS.PRODUCTS.DISABLED && status !== config.STATUS.PRODUCTS.BORROWED &&
              <ButtonIcon
                buttonClassName="btn-primary btn-sm flex-row-reverse gap-2 mt-2"
                className="w-4 h-4"
                icon="refresh"
                text={capitalize(t(`Ver ${type == 'favors' ? 'Favor' : 'Producto'}`))}
                onClick=""
              />
            }
            
          </div>
        </div>
        <div className="flex items-stretch justify-center w-1/2 overflow-hidden">
          {img && 
            <div className='flex justify-center items-center'>
              <img alt="" className='object-cover w-full max-h-44' src={img}/>
            </div>
          }
        </div> 
      </div>  
  )
}

