import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ToastContainer, notify } from "../../libraries/notifications";

import { history } from '../../routes';
import config from "../../config";

import BottomNav from '../../components/commons/BottomNav';
import LayoutSmall from "../../components/layout/LayoutSmall";
import RatingProfileCard from '../../components/customs/RatingProfileCard';
import UserProfileImage from "../../components/commons/UserProfileImage";
import IconPoints from "../../components/customs/IconPoints";
import IconCoins from "../../components/customs/IconCoins";
import { capitalizePhrase } from "../../libraries/utils";
import StarRating from "react-svg-star-rating";
import IconPointsCircle from '../../components/customs/IconPointsCircle';
import IconCoinsCircle from '../../components/customs/IconCoinsCircle';

class UserProfileRatings extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {}
  }

  componentDidMount() {
    console.log('PROFILE RATING')

    if(!this.props.location?.state?.opinions){
      history.push(config.ROUTES.USER.PROFILE);
      return
    }
    const rate_total = this.props.location.state?.user?.json_data?.rate_total || 0
    const rate_count = this.props.location.state?.user?.json_data?.rate_count || 1
    const rating = this.props.location?.state.rating ?? (rate_total && (rate_count !== 0) ? rate_total/rate_count : 0)

    if (this.props.location?.state){
      this.setState({ ...this.props.location?.state, rating })
    } else {
      history.push(config.ROUTES.USER.HOME);
    }
  }
  
  goBack = () => {
    const { isPublicProfile, userId } = this.state
    const path = !isPublicProfile ? config.ROUTES.USER.PROFILE : config.ROUTES.USER.PUBLIC_PROFILE.replace(':id', userId)
    history.push(path);
  }

  render() {
    const {opinions, type, isPublicProfile, rating, user} = this.state
    let title = isPublicProfile ? this.t("Opiniones en los pedidos que solicitó") : this.t("Opiniones en tus pedidos")
    if (type === 'received') {
      title = isPublicProfile ? this.t("Opiniones en los prestamos que realizó") : this.t("Opiniones en tus prestamos")
    }
    
    return (
      <LayoutSmall
        main={{ className: "pb-14" }}
        header={{
          className: "bg-transparent text-primary-content absolute top-0 left-0 z-20",
          left: { icon: 'arrow_left', action: () => this.goBack() },
        }}
        container={{ className: "px-0" }}
      >
        <ToastContainer />
        {user && (
          <section className="text-center relative h-64 bg-primary-focus">
            <div className='bg-base-content absolute top-0 w-full left-0 h-full z-10 bg-opacity-60'></div>
            <img src={user?.profile_image} alt="profile" className='w-full h-full absolute top-0 mix-blend-soft-light blur-sm left-0 z-0 object-cover' />
            <div className="p-4 relative z-10">
              <UserProfileImage data={user} picture={{ onClick: () => history.push(config.ROUTES.USER.INFO) }}/>
              <div className="flex justify-center items-center gap-10 mx-auto">
                <div className="rounded-full text-white w-12 h-12 grid">
                  <IconCoinsCircle  className="sidebar--points" coins={this.props.rewards.total.coins}/>
                </div>
                <div>
                  <h3 className=' text-white'>{capitalizePhrase(user?.name)}</h3>
                  <p className='text-center text-white'>Nivel {this.props.rewards.level}</p>
                </div>
                <div className="rounded-full text-white w-12 h-12 grid">
                  <IconPointsCircle className="sidebar--points"  points={this.props.rewards.total.points} iconClassName="w-5" />
                </div> 
              </div>
              <div className='mx-auto mt-4 gap-2 items-center py-1 px-3 flex border border-white border-opacity-30 rounded-md w-min'>
                {rating &&
                  <>
                    <p className='text-sm font-light text-white'>{rating.toFixed(1)}</p>
                    <StarRating containerClassName="flex" unit="float" initialRating={rating} size={14} isReadOnly={true}/>
                  </>
                }
              </div>
              
            </div>
          </section>
        )}
        <section className="px-4 pb-4 -mt-12">
          <div>
            {opinions?.length === 0 && (
              <div className="text-center p-10">
                {/* <Icon className="h-24 w-24 mx-auto mb-3" name="stethoscope" /> */}
                <h4 className="mb-1">{this.t("No hay opiniones")}</h4>
              </div>
            )}
            {opinions && 
              <div className="mb-4">
                {opinions.map((opinion,index) => 
                  <div className="mb-3" key={index}>
                    <RatingProfileCard key={index} data={opinion} type={type}/>
                  </div>
                )}
              </div>
            }
          </div>
        </section>
        <BottomNav/>
      </LayoutSmall>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    user: state.users.current,
    activities: state.transactions.list,
    rewards: state.users.auth.user.rewards,
    ranking: state.users.list.ranking,
    products: state.products.list,
    levels: state.levels.current,
    companies: state.companies.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserProfileRatings));
