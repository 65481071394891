import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import config from '../../config';
import { history } from '../../routes';
import { getOwner } from '../../libraries/utils';
import Icon from '../../libraries/icons';

import VolunteeringCard from '../../components/customs/VolunteeringCard';
import DonationCard from '../../components/customs/DonationCard';
import RecyclingCard from '../../components/customs/RecyclingCard';
import Button from '../../components/commons/Button';
import donationsActions from "../../context/donations/actions";
import recyclingActions from "../../context/recycling/actions";
import volunteeringActions from "../../context/volunteering/actions";
import businessesActions from '../../context/businesses/actions';
import Loader from '../../components/commons/Loader';

import Instructive from '../../components/commons/Instructive';

class Initiatives extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      recycled_products:[],
      donations:[],
      volunteering_jobs: []
    }
  }
  
  componentDidMount() {
    console.log('INITIATIVES PAGE');
    this.getData()
  }

  getData = async () => {
    await this.getBusinesses();
    this.getVolunteering();
    this.getDonations();
    this.getRecycling();
  }

  getBusinesses = async () => {
    const params = {enabled: 1}
    await this.props.onGetAllBusinesses(params);
    const { businesses } = this.props;
    if (businesses.error) {
      notify(this.t(businesses.error.message));
    } else {
      this.setState({ businesses: businesses.items });
    }
  }


  getDonations = async () => {
    this.setState({loadingDonations: true})
    await this.props.onGetAllDonations();
    const { donations } = this.props;
    if (donations.error) { 
      notify(this.t(donations.error.message));
    } else {
      this.setState({ 
        donations: donations.items
                    .slice(0, 4)
      })
    }    
    this.setState({loadingDonations: false})
  }

  getRecycling = async ()  => {
    this.setState({loadingRecycling: true})
    await this.props.onGetAllRecycling();
    const { recycled_products } = this.props;
    if (recycled_products.error) { 
      notify(this.t(recycled_products.error.message));
    } else {
      this.setState({ 
        recycled_products: recycled_products.items
                    .slice(0, 4)
      })
    }    
    this.setState({loadingRecycling: false})
  }

  getVolunteering = async () => {
    this.setState({loadingVolunteering: true})
    await this.props.onGetAllVolunteering();
    const { volunteering_jobs } = this.props;
    if (volunteering_jobs.error) { 
      notify(this.t(volunteering_jobs.error.message));
    } else {
      this.setState({ 
        volunteering_jobs: volunteering_jobs.items
                    .slice(0, 4)
      })
    }    
    this.setState({loadingVolunteering: false})
  }
  
  render() {
    const user = this.props.auth.user
    const { volunteering_jobs, donations, recycled_products } = this.state
    return (
      <div className="w-full max-w-lg mx-auto">
      <Instructive slides={[
        {
          title:"Servicio Comunitario",
          body:"¡Sumándote a las campañas que publican las ONG´s vas a ganar puntos Shary y puntos de impacto! ¡Doná, reciclá o voluntareá y Ganá!",
          img: "contribute"
        }]} instructive={"show_initiatives"}> 
      <div className="p-4 mb-4 flex flex-row items-start"><Icon className="w-8 mt-2 mr-2" name="information_outline" /><h4> Participá de estas iniciativas de ONG y ganá Puntos</h4></div>
        <section className="p-3">
          <div className="flex justify-between items-center mb-2">
            <h4 className=""> Ayuda comunitaria </h4>
            <Button className="pl-4 btn btn-xs btn-ghost text-primary mt-2" title={this.t("Ver todos")}
              onClick={() => history.push(config.ROUTES.VOLUNTEERING.LIST)}
            />
          </div>
            {this.state.loadingVolunteering && (<Loader/>)}
            {volunteering_jobs && (volunteering_jobs.length === 0) && <small className="mb-1 text-gray-500">{this.t("No hay iniciativas disponibles")}</small>}
            { volunteering_jobs && volunteering_jobs.map((volunt, index) => {
                return (
                  <VolunteeringCard
                    className="mb-3"
                    key={'v' + index}
                    data={volunt}
                    route={config.ROUTES.VOLUNTEERING.DETAIL.replace(':id', volunt.id)}
                    user={user}
                    business={this.state.businesses.find(b => b.id === volunt.owner)}
                  />
                )
              })
            }

        </section>
        <section className="p-3 py-10">
          <div className="flex justify-between items-center">
            <h4 className="mb-0"> Donaciones </h4>
            <Button className="pl-4 btn btn-xs btn-ghost text-primary mt-2" title={this.t("Ver todos")}
              onClick={() => history.push(config.ROUTES.DONATIONS.LIST)}
            />
          </div>
          <div className="grid grid-cols-2 gap-4 mt-2">
            {this.state.loadingDonations && (<Loader/>)}
            {donations && (donations.length === 0) && <small className="mb-1 text-gray-500">{this.t("No hay donaciones disponibles")}</small>}
            {donations && donations.map((donation, index) => {
                return (
                  <DonationCard 
                    key={'d' + index}
                    data={donation}
                    route={config.ROUTES.DONATIONS.DETAIL.replace(':id', donation.id)}
                    business={this.state.businesses.find(b => b.id === donation.owner)}
                  />
                )
              })
            }
          </div>
        </section>

        <section className="p-3 py-10">
          <div className="flex justify-between items-center">
            <h4 className="mb-0"> Reciclados </h4>
            <Button className="pl-4 btn btn-xs btn-ghost text-primary mt-2" title={this.t("Ver todos")}
              onClick={() => history.push(config.ROUTES.RECYCLING.LIST)}
            />
          </div>
          <div className="grid grid-cols-2 gap-4 mt-2">

          {this.state.loadingRecycling && (<Loader/>)}
          {recycled_products && (recycled_products.length === 0) && <small className="mb-1 text-gray-500">{this.t("No hay reciclados disponibles")}</small>}
            {recycled_products && recycled_products.map((recycling, index) => {
                return (
                  <RecyclingCard 
                    key={'d' + index}
                    data={recycling}
                    route={config.ROUTES.RECYCLING.DETAIL.replace(':id', recycling.id)}
                    business={this.state.businesses.find(b => b.id === recycling.owner)}
                  />
                )
              })
            }
          </div>
        </section>
        </Instructive>
      </div>   
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    donations:state.donations.list,
    recycled_products:state.recycling.list,
    volunteering_jobs: state.volunteering.list,
    businesses: state.businesses.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllDonations: () => dispatch(donationsActions.getAll()),
    onGetAllRecycling: () => dispatch(recyclingActions.getAll()),
    onGetAllVolunteering: () => dispatch(volunteeringActions.getAll()),
    onGetAllBusinesses: (params) => dispatch(businessesActions.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Initiatives));
