import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';
import { Link } from 'react-router-dom';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Icon from '../../libraries/icons';
import BottomNav from '../../components/commons/BottomNav';

class Add extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
        offers: [
          { title: 'Products to loan', route: config.ROUTES.CATEGORY.replace(':type', 'offers').replace(':sub_type', config.TYPES.OFFERS.PRODUCTS), icon: 'tag'},
          { title: 'Services to loan', route: config.ROUTES.CATEGORY.replace(':type', 'offers').replace(':sub_type', config.TYPES.OFFERS.SERVICES), icon: 'globe'},
          { title: 'Favors to loan', route: config.ROUTES.CATEGORY.replace(':type', 'offers').replace(':sub_type', config.TYPES.OFFERS.FAVORS), icon: 'support'},
        ],
        requests: [
          { title: 'Productos que necesitás', route: config.ROUTES.CATEGORY.replace(':type', 'requests').replace(':sub_type', config.TYPES.OFFERS.PRODUCTS), icon: 'tag'},
          { title: 'Servicios que necesitás', route: config.ROUTES.CATEGORY.replace(':type', 'requests').replace(':sub_type', config.TYPES.OFFERS.SERVICES), icon: 'globe'},
          { title: 'Favores que necesitás', route: config.ROUTES.CATEGORY.replace(':type', 'requests').replace(':sub_type', config.TYPES.OFFERS.FAVORS), icon: 'support'},
        ]
    }
  }
  
  componentDidMount() {
    if (this.props.location.state?.fromPath) {
      this.setState({fromPath: {
        pathname: this.props.location.state.fromPath,
        state: {
          fromYoBusco: true,
        }
      }})
    }
  }

  render() {
    const { offers,requests } = this.state;
    return (
      <LayoutSmall
        main={{ className: ""}}
        header={{ 
          left: { icon: 'arrow_left', action: () => this.state.fromPath ? history.push(this.state.fromPath) : history.push(config.ROUTES.HOME) },
          className: "bg-primary",
          title: this.t("Agregar")
        }}
        container={{ className: "px-0"}}
      >
        <ToastContainer/>
        <section className="p-3">
          <h3 className="mb-2">Presto / Brindo</h3>
          <div className="grid grid-cols-3 gap-4">
            {offers && offers.map((offer,  index )=> {
              console.log('offers', offer)
              return (
              <Link to={offer.route} key={index} className="card white p-3 mb-3">
                <div className="flex flex-col items-center">
                  <div className="text-center">
                    <Icon className="w-12 inline-block text-primary mb-2" name={offer.icon} />
                    <p>
                      {this.t(offer.title)}
                    </p>
                  </div>
                  {/* <Icon className="h-6 w-6 ml-auto text-base-300" name="cheveron_right" /> */}
                </div>
              </Link>
            )})}
          </div>

          <h3 className="mt-8">¿No encontrás el producto que buscás?</h3>
          <p className="text-gray-600 mb-2">Pedilo en la comunidad</p>
          {requests && requests.map((request, index )=> {
            console.log('option', request)
            return (
            <Link to={request.route} key={index} className="card white p-3 mb-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Icon className="w-8 inline-block text-primary mr-3" name={request.icon} />
                  <p>
                    {this.t(request.title)}
                  </p>
                </div>
                <Icon className="h-6 w-6 ml-auto text-base-300" name="cheveron_right" />
              </div>
            </Link>
          )})}
        </section>
        <BottomNav/>
      </LayoutSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,    
  };
};


export default connect(
  mapStateToProps,
)(withTranslation()(Add));

