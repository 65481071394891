import React from 'react';
import { Link } from 'react-router-dom';
import ActivityBenefitElement from './ActivityBenefitElement'

export default function ActivityBenefitCard({ statuses,types, sub_types, activity, route, ...rest }) {
  return (
   /*  <Link
      key={rest.key}
      to="/"
    > */
      <div className={"overflow-hidden border-b border-gray-100 " + (rest.className ? rest.className : "")}>
        <ActivityBenefitElement
        /*   time = {time} */
          statuses={statuses}
          activity={activity}
          types = {types}
          subtypes = {sub_types}
          {...rest}
        />
      </div>
    /* </Link>   */  
  )
}