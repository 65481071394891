import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/commons/Button";
import { history } from "../../routes";
import { useParams } from "react-router-dom";

import imgSteppingUp from "../../assets/img/img-stepping_up.png";

export default function FlowStep3({ ...rest }) {
  const { id } = useParams();

  // ! PROPS HARDCODEADAS
  const title = "Seguí sumando puntos";
  const desctiption =
    "Además de ganar puntos Shary y Puntos de Impacto por tus acciones sostenibles, podés seguir ganando puntos prestando productos y favores a tus compañeros de trabajo";

  const { t } = useTranslation();

  return (
    <main className="h-full text-center bg-white">
      <div className="container mx-auto h-full w-full flex flex-col items-center justify-between md:justify-center p-4">
        <div className="h-80 md:h-96 flex items-center">
          <img src={imgSteppingUp} alt="" />
        </div>
        <div>
          <h1 className="text-primary mb-5">{t(title)}</h1>
          <p className="text-primary mb-10">{t(desctiption)}</p>
        </div>
        <Button
          className="btn-block btn-primary md:mt-5"
          title={t("Siguiente").toUpperCase()}
          onClick={() => history.push(`/flow/${id}}/step1`)}
        />
      </div>
    </main>
  );
}
