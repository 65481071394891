import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import config from '../../config';
import { history } from '../../routes';
import { getOwner, getObjectWithJsonDataToFormValues } from '../../libraries/utils';

import Button from '../../components/commons/Button';
import logo from '../../assets/img/logo.png';
import IconCoins from '../../components/customs/IconCoins';
import benefitsActions from '../../context/benefits/actions';
import businessesActions from '../../context/businesses/actions';
import Loader from '../../components/commons/Loader';
import BussinessHomeCard from '../../components/customs/BussinessHomeCard';
import BenefitHomeCard from '../../components/customs/BenefitHomeCard';
import levelsAction from '../../context/levels/actions';

class Benefits extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      businesses: [],
      benefits: [],
    }
  }
  
  componentDidMount() {
    console.log('BENEFITS PAGE');
    this.setState({companiesIds: this.props.auth.user.ancestors.companies.map(c => c.id)}, this.getData) 
  }


  getData = async () => {
    this.setState({loading: true, userLevel: this.props.auth.user.rewards.level})
    await this.getBusinesses()
    await this.getBenefits()
    this.getLevels()
    this.setState({loading: false})
  }

  getLevels = async () => {
    await this.props.onGetLevels();
    const { levels } = this.props;
    if (levels.error) {
      notify(this.t(levels.error.message));
    } else {
      console.log("levels", levels)
      this.setState({ levels: levels.item })
    }  
  }

  getBusinesses = async () => {
    const params = {enabled: 1}
    await this.props.onGetAllBusinesses(params);
    const { businesses } = this.props;
    if (businesses.error) {
      notify(this.t(businesses.error.message));
    } else {
      this.setState({ businesses: businesses.items });
    }
  }


  getBenefits = async () => {
    const params = {enabled: 1, type: ["both", "user"]}
    await this.props.onGetAllBenefits(params);
    const { benefits } = this.props;
    if (benefits.error) {
      notify(this.t(benefits.error.message));
    } else {
      if(this.state.filters){
        let benefits_ = benefits.items.filter(b => b.json_data?.category === this.state.filters?.type).slice(0,4)
        for(let i = 0; i < benefits_.length; i++){
          let business = this.state.businesses.find(b => b.id === benefits_[i].json_data?.business?.id)
          if(business){
            benefits_[i].updatedBusiness = {...business}
          }
        }
        this.setState({ benefits: benefits_ });
      } else {
        for(let i = 0; i < benefits.items.length; i++){
          let business = this.state.businesses.find(b => b.id === benefits.items[i].json_data?.business?.id)
          if(business){
            benefits.items[i].updatedBusiness = {...business}
          }
        }
        this.setState({ benefits: benefits.items.slice(0,4) });
      }
      let benefits_owners = benefits.items.map(b => b.owner)
      this.setState({ businesses: this.state.businesses.filter(b => benefits_owners.includes(b.id)) })
    }
  }

  gotoBack = () => {
    //FIXME: go back
  }

  gotoBenefitsList = () => {
    history.push({ pathname: config.ROUTES.BENEFITS.LIST, state: { fromPath: config.ROUTES.HOME }})
  }

  gotoBenefitsBussinesList = () => {
    history.push({ pathname: config.ROUTES.BENEFITS.BUSINESSES_LIST, state: { fromPath: config.ROUTES.HOME }})
  }

  getPointsLeftForNextLevel = () => {
    if(this.state.userLevel == config.MAX_LEVEL) return null
    return this.state.levels[(this.state.userLevel+1).toString()]["min"] - this.props.auth.user.rewards.total.points
  }

  render() {
    const { benefits, businesses, userLevel, loading } = this.state

    return (
      <div className="w-full max-w-lg mx-auto">
        {userLevel !== "0" ? 
          <h2 className="my-4 px-3">Tu nivel es: <span className="ml-2 text-lg badge badge-primary">{userLevel}</span></h2>
          : <h2 className="my-4 mb-2">Aún no tienes nivel</h2>
        }
        <h6 className="px-3">{`¡Te faltan ${this.state.levels ? this.getPointsLeftForNextLevel() : '.'} puntos para llegar al próximo nivel!`}</h6>
        <section className="p-3 mb-3">
          <h4 className="mb-2">Tiendas amigas</h4>
          <div className="card white">
            <div className="p-4 border-b border-gray-100">
              <div className="scrolling-wrapper ">
                {loading && (<Loader/>) }
                {!loading && businesses && businesses.length === 0 && (
                  <div className="text-center p-10">
                    <h4 className="mb-1">{this.t("No hay tiendas amigas")}</h4>
                  </div>
                )}            
                {businesses && businesses.map(business => 
                  <BussinessHomeCard business={business} key={business.id}/>
                )}
              </div>
            </div>
              <Button className="btn btn-xs btn-ghost p-2 mb-3" title={this.t("Ver todos")}
                onClick={this.gotoBenefitsBussinesList}
              />   
          </div>
        </section>

        <section className="container-fluid px-3">
          <h4 className="mb-2">Beneficios disponibles</h4>
          <div className="mb-4">
            <div className="overflow-hidden border-b border-gray-100 ">
              {loading && (<Loader/>) }
              {!loading && benefits && benefits.length === 0 && (
                <div className="text-center p-10">
                  <h4 className="mb-1">{this.t("No hay beneficios disponibles")}</h4>
                </div>
              )}
              {benefits && benefits.map(benefit => 
                <BenefitHomeCard benefit={benefit} userLevel={this.state.userLevel} key={benefit.id} business={benefit.updatedBusiness}/>
              )}
              <Button className="btn btn-xs btn-ghost mt-2 mb-1 mx-auto w-full" title={this.t("Ver todos")}
                onClick={this.gotoBenefitsList}
              />   
            </div>
          </div>
        </section>
      </div>  
    ) 
  }
}

const mapStateToProps = state => {
  return {
    benefits: state.benefits.list,
    businesses: state.businesses.list,
    auth: state.users.auth,
    levels: state.levels.current
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllBenefits: (params) => dispatch(benefitsActions.getAll(params)),
    onGetAllBusinesses: (params) => dispatch(businessesActions.getAll(params)),
    onGetLevels: (params) => dispatch(levelsAction.get(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Benefits));
