import React, { Component } from 'react'
import { RepoructorVideo } from './RepoructorVideo';
import { StepsNewUser } from './StepNewUser';
import { data } from 'autoprefixer';



export default class PageOnboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      indice: 0,
      videoEnded: false,
      videoPaused: true
    }
  }

  onEnded = () => this.setState((prevState) => ({ videoEnded: !prevState.videoEnded })); 



  inrementIndice() {
    this.setState((prevState) => ({ indice: prevState.indice + 1 }));
  }
  componentDidMount() {
    this.setState({data:StepsNewUser(this.state.indice)})
  }
  componentDidUpdate(prevProps, prevState) {
    console.log({prevState,state:this.state});
    if(prevState.indice !== this.state.indice || prevState.videoEnded !== this.state.videoEnded){
      this.setState((prev)=>({data:StepsNewUser(this.state.indice,this.state.videoEnded)}))
    }
  }

  render() {
    if (!this.state.data?.header && !this.state.data?.body && !this.state.data?.footer) return <>Sin data</>
    const { header, body, footer } = this.state.data
    return (
      <div className='bg-white flex flex-col h-full max-w-lg mx-auto my-2'>
        {/* Header */}
        <div className='basis-2/6 flex flex-col justify-end'>
          {header.video && <RepoructorVideo video={header.video} videoViewed={()=>this.onEnded()}/>}
          {header?.imagen && <img src={header?.imagen} alt="img" className='w-4/6 py-2 mx-auto mt-10' />}
          <div className='mx-6 mb-2'>
            {header?.title && <h1 className='text-center mb-4'>{header?.title}</h1>}
            {header?.subtitle && <p className='text-center'>{header?.subtitle}</p>}
          </div>
        </div>
        {/* body */}
        <div className='basis-4/6 px-2 '>
          {body.children}
        </div>
        {/* Footer */}
        <div className='basis-1/6 px-2 flex  gap-2'>
          {
            footer.buttons.map((b, i) => <button key={b.title + i} className={`btn btn-primary self-center flex-1`} onClick={() => this.inrementIndice()} disabled={b.disabled}>{b.title.toLocaleUpperCase()}</button>)
          }
        </div>
      </div>
    )
  }
}