import React from 'react';
import { history } from '../routes';
import config from '../config';

class Redirect extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
    }
  }

  componentDidMount() {
    const params = this.props.match.params;
    if (params.route){
      console.log("route", params.route)
      if(params.route === "benefits"){
        history.push({pathname: config.ROUTES.HOME, state: {from: config.ROUTES.MAIN.BENEFITS}});
      } else if(params.route === "initiatives"){
        history.push({pathname: config.ROUTES.HOME, state: {from: config.ROUTES.MAIN.INITIATIVES}});
      } else if(params.route === "actions"){
        history.push({pathname: config.ROUTES.HOME, state: {from: config.ROUTES.MAIN.ACTIONS}});
      } else if(params.route === "offers"){
        history.push({pathname: config.ROUTES.HOME, state: {from: config.ROUTES.MAIN.OFFER}});
      } else {
        history.push({pathname: config.ROUTES.HOME, state: {from: config.ROUTES.MAIN.FEED}});        
      }
    } else {
      history.push({pathname: config.ROUTES.HOME, state: {from: config.ROUTES.MAIN.FEED}});
    }
  }

  render() {
    return (<></>)
  }
}

export default Redirect;