import React from 'react';
import Icon from '../../libraries/icons';
import { useTranslation } from 'react-i18next';
import { getObjectWithJsonDataToFormValues  } from '../../libraries/utils';
import config from '../../config';
import { Link } from 'react-router-dom';

export default function CategoryCard({ request,route, ...rest }) {
  const { t, i18n } = useTranslation();

  const {id, sub_type, source,target,categories,category,details,created_by} = getObjectWithJsonDataToFormValues(
    request,
    ['id', 'sub_type', 'source','target','categories','category','details','created_by']
  );

  let icon= "";
 
  switch (sub_type){
    case 'products':
       icon= 'tag'
      break;
    case 'services':
      icon= 'globe'
      break;

    case 'favors':
      icon= 'support'
    break; 
    default:
       icon= 'tag'
  }

  return (
    <Link
    key={rest.key}
    to={route}
  >
      <div className={"text-center px-2 " + (rest.className ? rest.className : "")}>
        <Icon className={"h-10 w-10 text-primary m-auto" + (rest.iconClassName ? rest.iconClassName : "")} name={icon} />
        <h5 className=" mt-2">{category.name}</h5>
      </div>
    </Link>
  )
}