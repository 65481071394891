import React from "react";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { history } from "../../routes";
import { isEmptyObject } from '../../libraries/utils';

import Icon from '../../libraries/icons';
import Button from '../../components/commons/Button';

export default function Information(props) {
  const { t, i18n } = useTranslation();
  const location = useLocation();  

  let info;
  if (!isEmptyObject(location.state)) {
    info = location.state;
  } else {
    info = props;
  }

  console.log("INFO", info)
  return (
    <main className="bg-success w-full h-full flex flex-col justify-between">
      <section className="flex justify-center items-center p-4 h-full mb-20 pb-20">
        <div className="w-3/4 flex flex-col items-center text-base-200">
          <Icon className="h-36 w-36 text-white mb-6" name="badge_check" />
          { info && (
            <>
              <h3 className="mb-3 text-center">{t(info.title)}</h3>
              {info.headline && <p className="text-center w-2/3">{t(info.headline)}</p>}
              <Button className="btn-outline border-white text-white mt-10 px-16" title={t(info.button?.text)}
                onClick={() => {
                  if(info.button?.externalRoute){
                    window.open(info.button?.externalRoute, '_blank').focus();
                  }
                  history.push(info.button?.route)
                }}
              />
              {info.button2 && !info.button2.externalRoute && <Button className="btn-ghost px-16 mt-5" title={t(info.button2.text)}
                onClick={() => {
                  //if(info.button2.externalRoute){
                  //  console.log("INFO", info.button2.externalRoute)
                  //  window.open(info.button2.externalRoute, '_blank').focus();
                  //}
                  history.push(info.button2?.route)
                }}
              />}


              {info.button2 && info.button2.externalRoute && <a className="btn-ghost px-16 mt-5" target="_blank" href={info.button2.externalRoute}>{info.button2.text}</a>}

              {info.button3 && !info.button3.externalRoute && <Button className="btn-ghost px-16 mt-5" title={t(info.button3.text)}
                onClick={() => {
                  //if(info.button2.externalRoute){
                  //  console.log("INFO", info.button2.externalRoute)
                  //  window.open(info.button2.externalRoute, '_blank').focus();
                  //}
                  history.push(info.button3?.route)
                }}
              />}


              {info.button3 && info.button3.externalRoute && <a className="btn-ghost px-16 mt-5" target="_blank" href={info.button3.externalRoute}>{info.button3.text}</a>}

            </>
          )}
        </div>
      </section>
    </main>
  );
}
