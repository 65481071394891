import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'

import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { composeValidators, validateEmail, validateIsfilled, getClientIp, capitalize } from '../../libraries/utils';

import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import TextWithIconInput from '../../components/forms/TextWithIconInput';

import Splash from '../../components/commons/Splash';
import SocialButton from '../../components/commons/SocialButton'

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.from =  (this.props.location && this.props.location.state && this.props.location.state.from) || { pathname: '/' };
    this.state = {
      showPassword: false,
      submitting: false,
      credentials: {},
      doLogin: false,
    }
    this.nodes = {};
    this.onSocialLoginSuccess = this.onSocialLoginSuccess.bind(this);
    this.onSocialLoginFailure = this.onSocialLoginFailure.bind(this);
    this.onSocialLogoutSuccess = this.onSocialLogoutSuccess.bind(this);
    this.onSocialLogoutFailure = this.onSocialLogoutFailure.bind(this);    
  }
  
  componentDidMount() {
    console.log('LOGIN PAGE', this.from);
    this.userToken();
  }
  
  onForgotPassword = async values => {
    console.log('FORGOT PASSWORD');
    history.push(config.ROUTES.FORGOT);
  }
 
  onSubmit = async values => {
    //const IP = await getClientIp();
    //console.log('CLIENT IP', IP);
    this.setState({ submitting: true, credentials: {...values} });
    await this.props.onLogin({ ...values,  }); //user_type: config.USER_TYPE.REGULAR
    console.log('AFTER LOGIN');
    if (this.props.auth.error) { 
      this.setState({ submitting: false });
      notify(this.t(this.props.auth.error.message));
    } else {
      // const arraysFlows = config.ACTION_BY_DEFAULT
      // if(!this.props.auth.user?.tour){
      //   const idFlow=arraysFlows[0].id
      //   history.push(config.ROUTES.FLOW.STEP1.replace(':id',idFlow)); //config.ROUTES.HOME
      // }else{
      //   const array=this.props.auth.user?.tour.split(',');
      //   const index=array.indexOf('0');
      //   if(index < 0) return history.push(config.ROUTES.HOME);
      //   const idFlow=arraysFlows[index].id
      //   const positionFlow = arraysFlows.findIndex(flow => flow.id === idFlow)+1;      
      //   history.push(config.ROUTES.FLOW.STEP1.replace(':id', idFlow));       
      // }
      history.push(config.ROUTES.HOME);

    }
  }
 
  userToken = async () => {
    const token = JSON.parse(localStorage.getItem(config.LOCALSTORAGE_USER_TOKEN));
    if (!this.props.auth.logged && token) {
      console.log('NOT LOGGED, TRY TO GET FROM TOKEN');
      await this.props.onGetToken();
      if (this.props.auth.error) {
        // notify(this.t(this.props.auth.error.message));
        await this.props.onLogout();
        history.push(config.ROUTES.HOME);
      } else {
        history.push(this.from.pathname); //config.ROUTES.HOME
      }
    } else {
      this.setState({ doLogin: true });
    }
  }

  setNodeRef = (provider, node) => {
    if (node) {
      this.nodes[ provider ] = node;
    }
  }

  onSocialLogoutSuccess = (data) => {
    console.log('SOCIAL LOGOUT::', data);
  }

  onSocialLogoutFailure = (err) => {
    console.error('SOCIAL LOGOUT::',err)
  }

  socialLogout = (provider) => {
    console.error('SOCIAL LOGOUT::INIT', provider)
    this.nodes[provider].props.triggerLogout();
  }

  onSocialLoginSuccess = async (user) => {
    console.log('SOCIAL LOGIN::USER', user._provider, user._profile);
    if (user && user._provider && user._profile && user._profile.email) {
      await this.props.onGetFromSocial({ provider: user._provider, email: user._profile.email});
      if (this.props.auth.error && this.props.auth.error.code !== 'LOGIN_ERROR') { 
        notify(this.t(this.props.auth.error.message));
      } else {
        console.log('USER', this.props.auth.user);
        if (this.props.auth.user) {
          console.log('DOING SOCIAL LOGIN');
          history.push(this.from.pathname);
        } else {
          console.log('LOOKING FOR A USER WITH THIS EMAIL', user._profile.email);
          await this.props.onGetUser({ email: user._profile.email});
          if (this.props.user.error && this.props.user.error.code !== 'USER_ERROR') { 
            notify(this.t(this.props.user.error.message));
          } else {
            if (this.props.user.item && this.props.user.item.length) {
              console.log('USER FOUND', this.props.user.item[0]);
              notify(this.t("You are already registered using the same email and a password. Please login using these credentials."));
            } else {
              console.log('DOING SOCIAL REGISTER');
              history.push({
                pathname: config.ROUTES.SIGNUP_FULL,
                state: {
                  user,
                }
              });        
            }
          }
        }
      }
    } else {
      this.onSocialLoginFailure("ERROR:: Something is wrong with your returned profile information");
    }
  }
  
  onSocialLoginFailure = (err) => {
    console.log('SOCIAL LOGIN::ERROR', err);
    console.log('ERROR', err.message);
    const error = err.message.split('\n\nORIGINAL ERROR:')
    let message = "You can't login with your social account in this moment. Please try again later or contact our support.";
    switch (error[0]) {
      case '[google][auth] Authentication failed':
      case '[facebook][auth] Authentication failed':
          message = "Something is wrong with your login. Try again";
        break;
      default:
        break;
    }
    notify(this.t(message));
  }
  


  onPasswordClick = () => {
    this.setState({ showPassword: !this.state.showPassword});
  }

  onSignup = () => {
    history.push(config.ROUTES.SIGNUP);
  }
  
  gotoInitiativesLogin = () => {
    history.push(config.ROUTES.LOGIN_BENEFITS_INITIATIVES.replace(':login_type', "initiatives"));
  }
  gotoBenefitsLogin = () => {
    history.push(config.ROUTES.LOGIN_BENEFITS_INITIATIVES.replace(':login_type', "benefits"));
  }

  render() {
    if (!this.state.doLogin || (!this.props.auth.logged && this.props.auth.loading && !this.state.submitting)) return (
      <Splash />
    );

    const required = value => (validateIsfilled(value) ? undefined : this.t("This field is required"))
    const email = value => (validateEmail(value) ? undefined : this.t("Not a valid email"))
    
    if (this.state.doLogin) return (
      <LayoutSmall
        header={{ 
          logo: true,
          logoSize: 'big',
          className: 'bg-base-200 pt-10'
        }}
      >
        <ToastContainer/>
        <h2 className="text-primary text-2xl text-center mb-10 mt-24">{this.t("Log in").toUpperCase()}</h2>
        <Form
          initialValues={this.state.credentials || {}}
          onSubmit={this.onSubmit}
        >
          {({ handleSubmit, form, submitting, pristine, values, invalid }) => (
            <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto px-4">
              <div className="w-full mb-3 md:mb-0">
                <Field name="email" icon={{ name: 'user_outline',className: 'h-6 w-6 m-3'}}
                  inputClassName='pl-10 rounded-xl outline outline-1 outline-[#2A818933]'
                  component={TextWithIconInput} placeholder={this.t("Email")}
                  validate={composeValidators(required, email)}
                  parse={ v => v.trim() }
                />
              </div>
              <div className="w-full mb-3 md:mb-0">
                <Field name="password" component={TextWithIconInput} placeholder={this.t("Password")}
                  icon={{ name: 'key',className: 'h-6 w-6 m-3'}}
                  inputClassName='pl-10 rounded-xl outline outline-1 outline-[#2A818933]'
                  showPassword={this.state.showPassword}
                  clickPassword={this.onPasswordClick}
                  validate={required}
                />
              </div>
              <div className="flex flex-wrap mb-3">
                <Button
                  className="btn-link ml-auto mb-10 text-primary text-sm"
                  title={this.t("Forgot your password?")}
                  onClick={this.onForgotPassword}
                />
                <Button
                  className="btn-primary btn-block "
                  title={this.t("Log in").toUpperCase()}
                  onClick={handleSubmit}
                  disabled={this.state.submitting || invalid}
                />
              </div>
            </form>
          )}
        </Form>
        {/* <div className="mt-10 p-4">
          <p className="text-primary-content text-center mb-4">o ingresa con</p>
          <div className="flex items-center justify-center w-full gap-4">
            <SocialButton
              provider='google'
              appId={config.GOOGLE_CLIENT_ID}
              onLoginSuccess={this.onSocialLoginSuccess}
              onLoginFailure={this.onSocialLoginFailure}
              onLogoutSuccess={this.onSocialLogoutSuccess}
              onLogoutFailure={this.onSocialLogoutFailure}
              getInstance={this.setNodeRef.bind(this, 'google')}
              key={'google'}
              className="btn btn-accent w-full btn-md text-red-500"
            >
              {this.t("Google")}
            </SocialButton>
            <SocialButton
              provider='facebook'
              appId={config.FACEBOOK_APP_ID}
              onLoginSuccess={this.onSocialLoginSuccess}
              onLoginFailure={this.onSocialLoginFailure}
              onLogoutSuccess={this.onSocialLogoutSuccess}
              getInstance={this.setNodeRef.bind(this, 'facebook')}
              key={'facebook'}
              className="btn btn-accent w-full btn-md text-indigo-900"
              onInternetFailure = {()=>{return true}}
            >
              {this.t("Facebook")}
            </SocialButton>
          </div>
        </div> */}
        <div className="mt-2 flex justify-center items-center">
          <p className="label-text text-center mr-1">
            ¿{capitalize(this.t("no tenés usuario?"))}
            <a className='cursor-pointer font-semibold ml-1' href='https://www.sharyco.com' target='_BLANK'>{this.t("Conocé qué hacemos")}</a>
          </p>
        </div>
      </LayoutSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    user: state.users.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogin: (params) => dispatch(userActions.login(params)),
    onLogout: () => dispatch(userActions.logout()),
    onGetToken: () => dispatch(userActions.getFromToken()),
    onGetFromSocial: (params) => dispatch(userActions.getFromSocial(params)),
    onGetUser: (params) => dispatch(userActions.get(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Login));
