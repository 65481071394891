import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import IconPoints from './IconPoints';
import IconCoins from './IconCoins';
import { getObjectWithJsonDataToFormValues  } from '../../libraries/utils';
import UserOwnerElement from './UserOwnerElement';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../commons/Button';


export default function RequestElement({ request, route, ...rest }) {
  const { t, i18n } = useTranslation(); 
  const user = useSelector(state => state.users.auth.user.id)
  
  const {id, target, type, sub_type,categories,category,details,requester } = getObjectWithJsonDataToFormValues(
    request,
    ['id', 'target', 'type', 'sub_type','categories','category','details','requester']
  );
  const isOwner =  user ===requester.id 

  return (
    <Link
      key={rest.key}
      to={route}
    >
      <div className={clsx("overflow-hidden card white my-4", rest.className)}>
        <div className="flex p-3">
          {/* <div className="w-28 max-h-32 overflow-hidden flex justify-center items-start mr-3">
            <img alt=""  src={url} className="h-full max-w-none" />
          </div> */}
          <div className="w-full flex justify-between flex-col leading-tight ">
            <div className='flex justify-between'>
              <div className='flex gap-1'>
                <UserOwnerElement className="mb-3" starClassName="mt-1" owner={requester} />
                <small className='font-normal text-neutral mt-1'>Necesita</small>
              </div>
              <div>
              {isOwner && 
                <Button
                  className={"btn-xs"} 
                  title="Editar"
                />
              }
              </div>
            </div>
            <div className ="flex justify-between">
              <h5>{t(category.name)}</h5>
              {/* <p className="badge badge-ghost">{sub_type}</p> */}
            </div>
            <p className='text-neutral font-normal mt-1'>"{details}"</p>  
            <div className="flex justify-between items-center">
              
            </div>
          </div>
        </div>
      </div>
    </Link>   
  )
}