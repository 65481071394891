import config from "../../config"
import { request } from "../../libraries/request"

const getAll = (params = {}, token) => {
  console.log("SERVICES::BENEFITS::get", params, token)
  if (params && params.page_num && config.QUERIES_PAGE_SIZE) {
    params.page_size = config.QUERIES_PAGE_SIZE
  }
  if (!params.order_by) {
    params.order_by = "created_at"
    params.order_direction = "DESC"
  }

  return request({
    url: config.BASE_API_URL + config.API.BENEFITS,
    accessToken: token,
    data: params,
  }).then(response => {
    console.log("RETURN FROM API", response)
    if (response.success) {
      response.data.query.page_size = config.QUERIES_PAGE_SIZE
      console.log("_________g", response.data.data)
      //Object.keys(response.data.data).forEach(b => {
      for (var i = 0; i < response.data.data.length; i++) {
        Object.keys(response.data.data[i].json_data).forEach(k => {
          if (k == "rules" || k == "values" || k == "business") {
            if (typeof response.data.data[i].json_data[k] == "string")
              response.data.data[i].json_data[k] = JSON.parse(
                response.data.data[i].json_data[k]
              )
          }
        })
      }
      //Object.keys(b.json_data).forEach(k => {
      //  if(k == "rules" || k == "values" || k == "business"){
      //    if (typeof(response.data.data[b].json_data[k]) == 'string') response.data.data[b].json_data[k] = JSON.parse(response.data.data[b].json_data[k])
      //  }
      //})
      //})
    }

    return response
  })
}

const save = (data, token) => {
  console.log("SERVICES::BENEFITS::save", data, token)
  return request({
    url: config.BASE_API_URL + config.API.BENEFITS,
    accessToken: token,
    method: "POST",
    data,
  }).then(response => {
    console.log("RETURN FROM API", response)
    if (response.success) {
    }
    return response
  })
}

const update = (data, token) => {
  console.log("SERVICES::BENEFITS::update", data, token)
  return request({
    url: config.BASE_API_URL + config.API.BENEFIT.replace("{id}", data.id),
    accessToken: token,
    method: "PATCH",
    data,
  }).then(response => {
    console.log("RETURN FROM API", response)
    if (response.success) {
    }
    return response
  })
}

const get = (id, token) => {
  console.log("SERVICES::BENEFITS::get", id, token)
  return request({
    url: config.BASE_API_URL + config.API.BENEFIT.replace("{id}", id),
    accessToken: token,
    // data: {},
  }).then(response => {
    console.log("RETURN FROM API", response)
    if (response.success) {
      Object.keys(response.data.data.json_data).forEach(k => {
        if (k == "rules" || k == "values" || k == "business") {
          if (typeof response.data.data.json_data[k] == "string")
            response.data.data.json_data[k] = JSON.parse(
              response.data.data.json_data[k]
            )
        }
      })
    }
    return response
  })
}

const del = (id, token) => {
  console.log("SERVICES::BENEFITS::remove", id, token)
  return request({
    url: config.BASE_API_URL + config.API.BENEFIT.replace("{id}", id),
    accessToken: token,
    method: "DELETE",
  }).then(response => {
    console.log("RETURN FROM API", response)
    if (response.success) {
    }
    return response
  })
}

export default {
  getAll,
  save,
  get,
  del,
  update,
}
