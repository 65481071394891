import React from 'react';

const CheckboxInput = ({ input, meta, label, ...rest }) => {
	return (
    <div className={"form-control " + (rest.className && rest.className) }>
      <label className={"cursor-pointer label " + (rest.labelClassName && rest.labelClassName)}>
        <div>
          <span className="checkbox-mark"></span>
          <input
            {...input}
            type="checkbox"
            className={"checkbox " + (rest.checkboxClassName && rest.checkboxClassName) }
            disabled={rest.readOnly || rest.disabled}
            id={input.name}
          />
        </div>
        <span className="label-text">{label}</span>
      </label>
      {!rest.noError && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">{meta && meta.touched && meta.error }</span>
        </label>
      )}
    </div>
  )
}
export default CheckboxInput;