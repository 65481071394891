import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import { isEmptyObject, validateIsfilled, getObjectWithJsonDataToFormValues } from '../../libraries/utils';

import config from '../../config';

import TextareaInput from '../../components/forms/TextareaInput';
import Button from '../../components/commons/Button';
import LayoutSmall from '../../components/layout/LayoutSmall';
import Swal from 'sweetalert2';


import categoriesAction from '../../context/categories/actions';
import transactionsAction from '../../context/transactions/actions';

class StepSearch2 extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      type: null,
      category: null,
      categories: [],
      user: "",
    }
  }
  
  componentDidMount() {
    console.log('STEP_SEARCH2', this.props.location);
    if (isEmptyObject(this.props.location.state)) {
      console.log('NO CATEGORY STATE')
      history.push(config.ROUTES.HOME);
    } else {
      const { type, category, parent, search } = this.props.location.state;
      const userData = this.props.auth.user
      const user = getObjectWithJsonDataToFormValues(
        userData,
        ['id', 'accounts', 'username', 'first_name', 'last_name', 'profile_picture', 'rewards', 'email']
      );
      this.setState({ type, category, user, parent, search });
      this.getCategories({ id: category.id });
    }
  }

  getCategories = async (params) => {
    await this.props.onGetCategoryPath(params);
    const { categories } = this.props;
    if (categories.error) { 
      notify(this.t(categories.error.message));
    } else {
      this.setState({ categories: categories.items})
    }
  }

  onSubmit = async (values, route_title, success_route) => {
    const { category, categories, user,type } = this.state
    let data = {
      json_data: {
        categories:categories,
        category:category,
        details:values.details,
        requester: user
      },
      owner: this.props.auth.user.unique_id,
      target:category.id, 
      type: config.TRANSACTIONS.REQUEST.TYPE,
      sub_type: type,
      source:""
    }
    console.log('TRANSACTION', data);
    await this.props.onSaveTransaction(data);
    console.log('AFTER SAVE TRANSACTION');
    const transaction = this.props.transaction;
    if (transaction.error) { 
      notify(this.t(transaction.error.message));
    } else {
      history.push(config.ROUTES.HOME); 
      
  /*    history.push({
      pathname: success_route.replace(':type', type).replace(':id', category.id),
      state: {
        title:  route_title,
        headline: this.t("Podés verlo en tus ofertas"),
        button:{
          text: "Continuar",
          route: config.ROUTES.USER.INVENTORY,
        },
      }
    }); */
    }
  }

  goBack = () => {
    const {type, parent, search} = this.state
    history.push({
      pathname:config.ROUTES.CATEGORY.replace(':type', 'requests').replace(':sub_type', type),
      state: {parent, search}
    })
  }

  render() {
    const {type, category, categories} = this.state
    console.log("TYPEeeeeeeeeeeeeeeeee",type)
  
    const min_value = category && category.json_data ? category.json_data.min_value : "";
    const max_value = category && category.json_data ? category.json_data.max_value : "";

    const validateForm = values => {
      const errors = {}
        errors.details = (validateIsfilled(values.details) ? undefined : this.t("Este campo es obligatorio"));
        return errors;
    };
    
    let route_title = ""
    let success_route =""
 
    switch (type && type){
      case "products":
         success_route = config.ROUTES.REQUESTS.SUCCESS 
         route_title = this.t("Tu pedido fue registrado")
        break;
  
      case "services":
        success_route = config.ROUTES.REQUESTS.SUCCESS 
        route_title = this.t("Tu pedido fue registrado")
        break; 
      
      case "favors":
        success_route = config.ROUTES.REQUESTS.SUCCESS 
        route_title = this.t("Tu pedido fue registrado")
        break; 
      default: 
    }

    console.log("ROUTEEEEEE", success_route) 
    return (
      <LayoutSmall
        main={{ className: ""}}
        header={{ 
          left: { icon: 'arrow_left', action: () => this.goBack() },
        }}
        container={{ className: "px-0"}}
      >
        <ToastContainer/>
        <section className="bg-primary text-primary-content text-center px-4 pb-4">
          {/* <Icon className="h-16 w-16 mx-auto mb-1" name="pin" /> */}
          <h1>{`${this.t(type)} que necesitás`}</h1>
        </section>
        <section className="p-3">
          <Form 
            validate={validateForm}
            onSubmit={this.onSubmit}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
               
                <div className="ml-1 mb-1 text-sm breadcrumbs">
                  <ul>
                    {categories && categories.map((category, index) => (
                      <li key={index}>{category.name}</li> 
                    ))}
                  </ul>
                </div>
                <div className="w-full mb-3 mt-3 md:mb-0">
                  <h3 className>{category && category.name}</h3>
                </div>
                <div className="w-full mb-3 md:mb-0">
                  <Field name="details" component={TextareaInput} placeholder={this.t("Comentarios")}
                  />
                </div>

                <div className="flex mb-3 md:mb-0">
                  <div className="w-2/4 mr-4">
                    
                      <div className="w-full mb-3 md:mb-0">
                      <h4>{this.t("Puntos Shary sugeridos")}</h4>
                      <div className="flex">
                        <h5>{this.t("Min")} <strong>{min_value}</strong></h5>
                        <h5 className="ml-5">{this.t("Max")} <strong>{max_value}</strong></h5>
                      </div>
                    </div>
                  </div>
                </div>
                   
                <Button
                  className="btn-primary btn-block mt-3"
                  title={this.t("Siguiente")}
                  onClick={() => handleSubmit ( route_title, success_route)}
                  disabled={submitting || pristine}
                />
              </form>
            )}
          </Form>
        </section>
      
      </LayoutSmall>      
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,    
    categories : state.categories.list,
    transaction: state.transactions.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetCategoryPath: (params) => dispatch(categoriesAction.getPath(params)),
    onSaveTransaction: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(StepSearch2));
