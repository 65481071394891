import React, { useCallback, useState } from 'react';
import Icon from '../../libraries/icons';
import ButtonIcon from '../commons/ButtonIcon';
import Cropper from 'react-easy-crop'

const CropperWrapper = ({img, imgType, setImg, closeModal}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [tolerance, setTolerance] = useState(150)
  //const [transparented, setTransparented] = useState(false)
  const [aspectRatio, setAspectRatio] = useState(3/4)

  const [localImg, setLocalImg] = useState(img)

  const createImage = url =>
    new Promise((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', error => reject(error))
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
    })

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
  }

  async function getCroppedImage(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    image.width = image.width / 2;
    image.height = image.height / 2;
  
    const safeArea = Math.max(image.width, image.height) * 2;

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width,
      safeArea / 2 - image.height
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width - pixelCrop.x,
      0 - safeArea / 2 + image.height - pixelCrop.y
    );
    return canvas.toDataURL('image/jpeg');
    //return canvas.toBlob(function(blob){ /*...*/ }, 'image/jpeg', 0.95);
  }
  

  const load_img = async (b64) => {
    return new Promise((resolve) => {
      var img = document.createElement("img");
      img.src = b64;
      img.addEventListener('load', () => resolve(img))
    })
  }

  /*
  const trasparent = async (resize=false, defaultTolerance=null) => {
    var _img = await load_img(img)
    setTransparented(true)
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var width = _img.width;
    var height = _img.height;

    if(resize){
      var MAX_WIDTH = 300;
      var MAX_HEIGHT = 300;
      if (width > height) {
          if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
          }
      } else {
          if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
          }
      }
    }

    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(_img, 0, 0, width, height);
    var imgd = ctx.getImageData(0, 0, width, height), pix = imgd.data, newColor = {r:255,g:255,b:255, a:0};
    for (var i = 0, n = pix.length; i <n; i += 4) {
      var r = pix[i],
      g = pix[i+1],
      b = pix[i+2];

      let _tolerance = parseInt(tolerance)
      if(defaultTolerance) _tolerance = defaultTolerance  
      if(r >= _tolerance && g >= _tolerance && b >= _tolerance){ 
        pix[i] = newColor.r;
        pix[i+1] = newColor.g;
        pix[i+2] = newColor.b;
        pix[i+3] = 255;
      } //else all blacks?
    }
    ctx.putImageData(imgd, 0, 0);
    let dataurl = canvas.toDataURL(imgType);
    setLocalImg(dataurl)
  }
  */

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const setCroppedImageInInput = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImage(
        localImg ? localImg : img,
        croppedAreaPixels,
        rotation
      )
      setCroppedImage(croppedImage)
      setImg(croppedImage)
      setLocalImg(null)
      //setTransparented(false)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  const onChangeZoomRange = (e) => {
    //if(!transparented){
    //  trasparent(true, 255)
    //  setTransparented(true)
    //}
    console.log(parseFloat(e.target.value))
    setZoom(parseFloat(e.target.value))
  }
  //const onChangeToleranceRange = (e)=> {
  //  setTolerance(e.target.value)
  //  trasparent(e.target.value)
  //}
  //const onClickToleranceAdjust = ()=> {trasparent(true)}
  
  return(
    <>
      <div className="flex justify-end">
        {/*<label htmlFor="img-modal" className="btn btn-primary">Accept</label>*/} 
        <label htmlFor="img-modal" className="btn-ghost" onClick={() => {
          setLocalImg(null)
          closeModal()
        }}>
          <Icon className="h-12 w-12 text-gray-400" name="x" />
        </label>
      </div>

      <div className="relative w-full h-60 mb-5">
          <Cropper
            image={localImg ? localImg : img}
            crop={crop}
            rotation={0}
            zoom={zoom}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onRotationChange={() => {}}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
      </div>
      <>
        {/**/}

        <div className="">
          <div className='flex items-center mb-4'>
            <p className="inline-block mr-4 text-base">Zoom</p>
            <input type="range" id="zoom" name="zoom" min="1" max="3" step={"0.1"} value={zoom} onChange={onChangeZoomRange}/>
          </div>
          <div className="flex items-center mb-4"> 
          <p className="inline-block mr-4">Vista</p>

          <div className="btn-group">
            <label className={"btn btn-outline btn-primary btn-sm " + (aspectRatio === 16/6 ? "btn-active" : "") } onClick={()=>{setAspectRatio(16/6)}}>
              <Icon className="h-5" name="rectangule" />
            </label>
            <label className={"btn btn-outline btn-primary btn-sm " + (aspectRatio === 4/3 ? "btn-active" : "") } onClick={()=>{setAspectRatio(4/3)}}>
              <Icon className="h-5" name="square" />
            </label>
          </div>
        </div>
          
        </div>
        {/*<div className="flex items-center mb-10"> 
          <p className="inline-block mr-4">Tolerancia</p>
          <input type="range" id="tolerance" name="tolerance" min="0" max="150" step="15" value={tolerance} onChange={onChangeToleranceRange}/>{tolerance}
        </div>*/}

        {/*<label className="btn" onClick={onClickToleranceAdjust}>Ajustar Tolerancia</label>*/}
      </>
      <label className="btn btn-primary btn-block" onClick={setCroppedImageInInput}>Aceptar</label> 
    </>
  )
}

const ImageUploadFileInputEdit = ({ input, meta, label, inputOnChange, ...rest }) => {
  const [imgValue, setImgValue] = useState(null)
  const [initialImgValue, setInitialImgValue] = useState(null)
  const [imgTypeValue, setImgTypeValue] = useState(null)
  const [imgNameValue, setImgNameValue] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setInitialImgValue(imgValue)
    setModalOpen(true)
  }

  const closeModal = () => {
    setImgValue(initialImgValue)
    setModalOpen(false)
  }

  const acceptModal = async (dataurl) => {
    setModalOpen(false)
    const formData = new FormData();
    //formData.append("myFile",dataurl)
    //console.log(typeof(dataurl))
    let blob = await fetch(dataurl).then(r => r.blob());
    formData.append(
      "myFile",
      blob,
      "image.jpg"
    )
    await inputOnChange && inputOnChange({ file: imgNameValue, data: formData, name: input.name });
  }

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }  

  const inputProps = {
    ...input,
    onChange: async e => {
      const file = e.target.files[0];
      console.log('FILE', file, file.name, file.type);
      const _img64 = await readFile(file)
      setImgValue(_img64)
      setImgTypeValue(file.type)
      setImgNameValue(file.name)
      openModal() 
    }
  };

  const clearAll = () => {
    rest.clickButtonClear({ name: input.name })
    setImgValue(null)
    setImgTypeValue(null)
    setImgNameValue(null)
  }

  return (
    <div className={"form-control " + (rest.className ? rest.className : "")}>
      {label && (
        <label htmlFor={input.name} className={"label " + (rest.labelClassName ? rest.labelClassName : "")}>
          <span className="label-text">{label}</span>
        </label>
      )}
      <div className={"relative input py-10 flex justify-center items-center " + (rest.inputClassName ? rest.inputClassName : "")}>
        {imgValue ? (
            <>
              <img className="absolute w-auto h-full" src={imgValue} alt="" />
              <ButtonIcon
                buttonClassName="btn-ghost btn-sm absolute right-2 z-10"
                className="w-8 h-8"
                onClick={() => rest.clickButtonClear && clearAll()}
                icon="x_circle"
              />
            </>
          ) : (
            rest.img ?  (
              <>
                <img className="absolute w-auto h-full" src={rest.img} alt="" />
                <ButtonIcon
                  buttonClassName="btn-ghost btn-sm absolute right-2 z-10"
                  className="w-8 h-8"
                  onClick={() => rest.clickButtonClear && clearAll()}
                  icon="x_circle"
                />
              </>
            ) : (<Icon className="h-8 w-8 absolute" name="photograph" />)
          )
        }
        {<input {...inputProps}
          type="file" 
          accept="image/png, image/jpeg, image/jpg"
          // capture="camera"
          className={"opacity-0 " 
            + (meta && meta.error && meta.touched ? "input-error invalid-shadow " : "") 
          }
          placeholder={rest.placeholder}
          disabled={rest.disabled}
          readOnly={rest.readOnly}
        />}
        {
          <>
          {/*FIXME: CAMBIAR ID MODAL SI PORQUE SI HAY MAS DE UNO EN LA MISMA PAG VA CARGAR EL MISMO*/}
          <input type="checkbox" id="img-modal" className="modal-toggle" checked={modalOpen} onChange={()=>{}}/> 
          <div className="modal">
            <div className="modal-box relative w-full">

                {/*<img src={imgValue} alt="" /><br/>*/}
                {/*cropper*/}
                <CropperWrapper img={imgValue} imgType={imgTypeValue} setImg={(_img) => {
                  setImgValue(_img)
                  acceptModal(_img)
                }} closeModal={closeModal}/>


            </div>
           
          </div>
          </>
          
        }
      </div>

      {!rest.noError && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">{meta && meta.error && meta.touched}</span>
        </label>
      )}
    </div>
  )
}

export default ImageUploadFileInputEdit;