import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';

import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';
import { isEmptyObject, getObjectWithJsonDataToFormValues } from '../../libraries/utils';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Icon from '../../libraries/icons';
import Button from '../../components/commons/Button';
import ButtonIcon from '../../components/commons/ButtonIcon';

import IconPoints from '../../components/customs/IconPoints';
import IconCoins from '../../components/customs/IconCoins';

import volunteeringAction from "../../context/volunteering/actions";
import assistantsAction from "../../context/assistants/actions";
import transactionsAction from "../../context/transactions/actions";
import MessagesActions from '../../context/messages/actions';
import { Form, Field } from "react-final-form";
import TextareaInput from '../../components/forms/TextareaInput';
import internalsActions from '../../context/internals/actions';
import usersActions from '../../context/users/actions';
import businessesActions from '../../context/businesses/actions';

import MultiLineTextDisplay from '../../components/commons/MultiLineTextDisplay';
import AutoQuery from '../../components/commons/AutoQuery';

class VolunteeringDetail extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      loading: false,
      assistant: null,
      postulated_initiative:"",
      disablePostular: false,
    }
  }
  
  componentDidMount() {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////FIXME: renombrar todo lo que diga volunteering por initiatives porque acá chequea asistencia para los 3,//////
    //////////lo mismo para volunteering attendance confirmation////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    this.getData();
  }

  goBack = () => {
    history.push(this.state.route);
  }

  getData = () => {
    this.setState({loading: true})
    const params = this.props.match.params;
    console.log('___GEN INITIATIVE PAGE', params );
    if (params.id){
      this.getVolunteering(params);
    } else {
      this.goBack();
    }
    const state = this.props.location.state;
    this.setState({ route: {
        pathname: !isEmptyObject(state) ? state.from : config.ROUTES.HOME,
        state: { from: config.ROUTES.VOLUNTEERING.DETAIL }
      }
    });
    this.setState({loading: false})
  }

  getVolunteering = async (params)=> {
    await this.props.onGetVolunteering(params);
    const { volunteering } = this.props;
    if (volunteering.error) { 
      notify(this.t(volunteering.error.message));
    } else {
      //config.TYPES.INITIATIVES.VOLUNTEERING
      await this.props.onGetComments({ type:config.MESSAGES.TYPE.COMMENTS, subject:volunteering.type, related_to: volunteering.item.id});
        const { comments } = this.props;
        if (comments.error) { 
          notify(this.t(comments.error.message))
        } else {
          const assistants = volunteering.item.assistants.filter(asist => asist.assistant === this.props.auth.user.id);
          if(assistants.length > 0){
            let assistant = assistants[0];
            await this.getMyInitiatives(volunteering.item,assistant)   
          }
          const userData = getObjectWithJsonDataToFormValues(
            this.props.auth.user,
            ['id', 'accounts', 'username', 'first_name', 'last_name', 'profile_image', 'rating', 'email']
          );
          const volunteeringData = getObjectWithJsonDataToFormValues(
            volunteering.item,
            ['id', 'img', 'name','assistants', 'max_assistants', 'points', 'coins', 'type', 'details','owner', 'website', 'email', 'place', 'time', 'enabled', 'form']
          );

          //get initiative user
          //this.getInitiativeUser(volunteeringData.owner)
          this.getInitiativeBusiness(volunteeringData.owner)
          
          this.setState({ volunteering: volunteeringData, user:userData, initiativeComments: comments.items})
        }
    }
  }

  getSuccessRoute = (id) => {
    let success_route = ""
    if(this.state.volunteering.type === config.TYPES.INITIATIVES.DONATIONS){
      success_route = config.ROUTES.DONATIONS.SUCCESS.replace(':id', id)
    }
    else if(this.state.volunteering.type === config.TYPES.INITIATIVES.RECYCLING){
      success_route = config.ROUTES.RECYCLING.SUCCESS.replace(':id', id)
    }
    else{
      //(this.state.volunteering.type === config.TYPES.INITIATIVES.VOLUNTEERING)
      success_route = config.ROUTES.VOLUNTEERING.SUCCESS.replace(':id', id)
    }
    return success_route
  }

  getInitiativeBusiness = async (id) => {
    await this.props.onGetBusiness(id);
    const { business } = this.props;
    if (business.error) {
      notify(this.t(business.error.message));
    } else {
      const initiativeBusiness = getObjectWithJsonDataToFormValues(
        business.item,
        ['id', 'name', 'image', 'url', 'address', 'website', 'owner']
      );
      this.setState({ initiativeBusiness });
      //await this.getInitiativeUser(business.item.owner)
    }    
  }  

  getInitiativeUser = async (id) => {
    await this.props.onGetUser({ id });
    const { user } = this.props
    if (user.error) {
      notify(this.t(user.error.message));
    } else {
      const userData = getObjectWithJsonDataToFormValues(
        user.item,
        ['id', 'username', 'first_name', 'last_name', 'profile_image', 'email']
      );
      this.setState({ initiativeUser: userData})
      console.log("initiativeUser", userData)
    }
  }

  getMyInitiatives = async (volunteering,assistant=null)=> {
    console.log("_________initiatives", {target:volunteering.id})
    await this.props.onGetMyInitiatives( { type: config.TRANSACTIONS.INITIATIVE.TYPE, created_by: this.props.auth.user.id, target:volunteering.id, } );
    console.log("____________initiatives", this.props.initiatives,volunteering)
    const { initiatives } = this.props;
    if (initiatives.error) { 
      notify(this.t(initiatives.error.message));
    } else {
      let postulated_initiative = initiatives.items.filter((initiative) => (
        (((initiative.status === config.TRANSACTIONS.INITIATIVE.STATUS.POSTULATED) || (initiative.status === config.TRANSACTIONS.INITIATIVE.STATUS.PENDING)) && initiative.sub_type === volunteering.type ) //|| initiative.status === config.TRANSACTIONS.INITIATIVE.STATUS.ATTENDED  
      ))[0];
      console.log("_____________postulated_initiative", postulated_initiative)
      this.setState({disablePostular: postulated_initiative ? true : false})
      this.setState({
        postulated_initiative, assistant:this.state.assistant ? this.state.assistant : assistant, transaction: postulated_initiative
      });

    }    
  }
    
  saveAttend = async ()=> {
    const { volunteering, user } = this.state;
     
    let data = {
      assistant: user.id,
      initiativeId: volunteering.id
    }
    await this.props.onAttend(data);
    const { assistant } = this.props
    if (assistant.error) { 
      notify(this.t(assistant.error.message));
    } else {
      //MAIL
      //let mailData = {
      //  template: "initiative_postulate",
      //  locale: "es",
      //  to:this.props.auth.user.email,
      //  first_name: this.props.auth.user.first_name,
      //  last_name:  this.props.auth.user.last_name,
      //  params: {
      //      subject: "Postulación iniciativa",
      //      line_1: volunteering.name,
      //  }
      //}
      //await this.props.onSendMail(mailData);
      //const internals = this.props.internals;
      //if (internals.error) {
      //  notify(this.t(internals.error.message));
      //}


      this.saveTransaction(volunteering,user,assistant.id);
    }
  }

  saveTransaction = async (volunteering,user,assistantId) => {
    console.log("SAVING TRANSACTION")

    const code = parseInt(Math.random()*10**9).toString().padStart(9,"0")
    //FIXME: chequear por codigo duplicado (poco probable)
    let data = {
      target: volunteering.id,
      type: config.TRANSACTIONS.INITIATIVE.TYPE,
      sub_type: this.state.volunteering.type,
      status: config.TRANSACTIONS.INITIATIVE.STATUS.POSTULATED,
      source: this.state.initiativeBusiness.id,//id owner iniciativa
      json_data: {
        initiative: volunteering,
        points: volunteering.points,
        coins: volunteering.coins,
        assistant: user,
        code,
        owner: this.state.initiativeBusiness,
      },
      owner: this.props.auth.user.unique_id,
    }
    console.log("DATA", data)

    await this.props.onSaveTransaction(data);
    console.log('AFTER SAVING TRANSACTION');
    const {transaction} = this.props
    if (transaction.error) {
      notify(this.t(transaction.error.message));
    } else {
      let next_route = `${config.ROUTES.MAIN.INITIATIVES}/${this.state.volunteering.type}/${this.state.volunteering.id}`
      const state = {
        title: "Postulación enviada",
        headline: "El organizador te agradece y te espera para participar.",
        button:{
          text: "Continuar",
          route: next_route,
        },
      }

      if(this.state.volunteering.website){
        state.button2 = {
          text: "Ir a la página del organizador",
          route: next_route,
          externalRoute: this.state.volunteering.website,
        }
      }

      //if(this.state.volunteering.type === "volunteering"){
      if(this.state.volunteering.form){
        state.button3 = {
          text: "Ir al formulario de inscripción",
          route: next_route,
          externalRoute: this.state.volunteering.form,
        }
      }
      //}
      
      let success_route = this.getSuccessRoute(volunteering.id)
      console.log(state,success_route)
      history.push({
        pathname: success_route,
        state
      });
    }
  }
      
  cancelAttendance = async () => {
    console.log('CANCEL ATTENDANCE');
    const { volunteering, assistant } = this.state;
    let data = {
      id: assistant.id,
      initiativeId: volunteering.id
    }
    await this.props.onCancelAttend(data);
    const { assistants } = this.props
    if (assistants.error) { 
      notify(this.t(assistants.error.message));
    } else {
      this.cancelTrx(volunteering);
    }

  }

  cancelTrx = async (volunteering) => {
    console.log('CANCEL TRANSACTION', volunteering.id);
    const { postulated_initiative } = this.state
    const message = [
      this.t(`Estás cancelado tu participación `)
    ];

    const result = await Swal.fire({
        title: this.t("¿Are you sure?"),
        text: message.join(''),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t("Yes"),
        cancelButtonText: this.t("Cancel"),
      })
      .then((result) => {
        return result
      });
    console.log('ANSWER', result);
    
    if (result && result.isConfirmed) {
      
      this.updateTrx(postulated_initiative,volunteering, config.TRANSACTIONS.INITIATIVE.STATUS.CANCELED );

    } else if (result && result.isDismissed) {
      Swal.fire('', this.t("Se canceló la participacion"), 'error');
    }

  }

  updateTrx = async (postulated_initiative,volunteering, status) => {
    console.log('UPDATE TRANSACTION');
    await this.props.onChangeTransactionState({
      id: postulated_initiative.id,
      status:status,
    })
    console.log('transaction', this.props.transaction);
    if (this.props.transaction.error) { 
      notify(this.t(this.props.transaction.error.message));
    } else {
      let success_route = this.getSuccessRoute(volunteering.id)
      history.push({
        pathname: success_route,
        state: {
          title: "Postulación cancelada",
          headline: "Podés volver a postularte nuevamente antes de la fecha de inicio.",
          button:{
            text: "Continuar",
            route:  this.state.route,
          },
        }
      });
    }          
  }

  sendMessage = (volunteering) => {
    console.log('MESSAGE', volunteering);
    history.push({
      pathname: config.ROUTES.SEND_MESSAGE,
      state: {
        from:config.ROUTES.VOLUNTEERING.DETAIL.replace(':id', volunteering.id),
        volunteering
        }
      }
    );
  }
  
  commentProduct = async (values, volunteering) => {
    const user = this.props.auth.user.id;

    let data = {
      type:config.MESSAGES.TYPE.COMMENTS,
      source: user,
      target: volunteering.owner,
      related_to: volunteering.id,
      parent: " " ,
     /*  readed:false, */
      subject: volunteering.type,
      body:values.message,
    }
  
    console.log('MESSAGE', data);
    await this.props.onSaveComment(data);
    console.log('AFTER SAVE MESSAGE');
    const sendedMessage = this.props.message
    if (sendedMessage.error) { 
      notify(this.t(sendedMessage.error.message));
    } else {
      console.log(sendedMessage)
      history.push({
        pathname: config.ROUTES.MESSAGE_SUCCESS.replace(':id', sendedMessage.item.id),
        state: {
          title: "Mensaje enviado",
          headline: "Ya podés chequear en tu casilla de mensajes enviados",
          button:{
            text: "Continuar",
            route: config.ROUTES.MESSAGES,
          },
        }
      })
    }}
  
  render() {
    const { volunteering, assistant, initiativeComments, initiativeBusiness } = this.state;
    return (
      <LayoutSmall
        main={{ className: ""}}
        header={{ 
          className: "bg-transparent fixed text-primary",
          left: { icon: 'arrow_left', action: this.goBack },
          /* right: { icon: 'heart'} */
        }}
        container={{ className: "px-0"}}
        loading={this.state.loading}
      > 
        <ToastContainer/>
        
        {volunteering && (
          <>
            <AutoQuery getData={() => {this.getMyInitiatives(this.state.volunteering)}} />
            <div className="bg-white shadow-lg">
              <div className="max-w-md mx-auto">
                <img src={volunteering && volunteering.img} className="max-h-26 mx-auto" alt="" />
                <div className="container p-4">  
                  <h1 className="">{volunteering.name}</h1>
                  {volunteering && (
                    <div className="flex align-center justify-between">
                      <div className="flex mt-2">
                        {(volunteering.coins !== "0") && <><small className="text-gray-400">{this.t("Ganás")}</small> <IconCoins className="mr-4 ml-2" coins={volunteering.coins}/></>}
                        {(volunteering.points !== "0") && <><small className="text-gray-400">{this.t("Ganás")}</small> <IconPoints iconClassName="text-primary"  className="ml-2" points={volunteering.points}/></>}
                      </div>
                      <div>
                        {/* <small className="text-gray-500 mr-3"> 
                          <Icon className="h-3 w-3 inline-block mr-1" name="pin" /> {volunteering.distance}
                        </small> */}
                        {volunteering.max_assistants && (parseInt(volunteering.max_assistants) !== 0) && (
                          <small className="text-primary">{volunteering.assistants.length}/{volunteering.max_assistants}</small>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="flex items-center gap-3 pb-4 mt-4">
                    {/* <ButtonIcon
                      buttonClassName="btn-ghost bg-base-200"
                      className="w-6 h-6 text-neutral"
                      icon="chat_alt"
                      onClick={() => this.sendMessage(volunteering)}
                    /> */}
                    <Button
                      disabled = {this.state.disablePostular || this.state.loading || !volunteering?.enabled}
                      className="btn btn-primary flex-1"
                      title={this.t("Postularte")}
                      onClick = {this.saveAttend}
                    />
                  </div>

                  <div className="flex items-center">
                 
                    {initiativeBusiness && 
                      <div className="h-8 w-8 p-1 rounded-full overflow-hidden flex items-center justify-center mr-2 border border-gray-200">
                        <img className="" src={initiativeBusiness.image} alt="" />
                      </div>
                    }
                    {initiativeBusiness && `Iniciativa de ${initiativeBusiness.name}`}
                  </div>

                </div>
              </div>
            </div>
            <section className="p-4 max-w-md mx-auto">
              <h4>{this.t("Descripción")}</h4>
              {/*<p className="mb-3">{volunteering.details}</p>*/}
              <MultiLineTextDisplay text={volunteering.details}/>

              <h4>{this.t("Lugar")}</h4>
              {/*<p className="mb-3">{volunteering.place}</p>*/}
              <MultiLineTextDisplay text={volunteering.place}/>

              <h4 >{this.t("Horarios")}</h4>
              <MultiLineTextDisplay text={volunteering.time}/>

              {volunteering && volunteering.form && 
                <p className="mt-5"> <strong > Formulario de inscripción: </strong> <a className="link text-primary" target="_blank" href={volunteering.form}> {volunteering.form}</a></p>}
              
              {volunteering && volunteering.website && 
                <p className="mt-5"> <strong > Para más información podés entrar a la siguiente web: </strong> <a className="link text-primary" target="_blank" href={volunteering.website}> {volunteering.website}</a></p>}
            </section>
            {/* <div className="p-4 max-w-md mx-auto">
              <Form  onSubmit={(values) => this.commentProduct(values, volunteering)}>
                {({ handleSubmit, form, submitting, pristine, values }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="w-full mt-3  mb-3 md:mb-0">
                      <h4 className="mb-2">{this.t("Preguntas")}</h4>
                      <Field name="message" component={TextareaInput} placeholder={this.t("Escriba comentario")} />
                    </div>
                    <Button
                      className="btn-primary btn-block"
                      title={this.t("Send")}
                      onClick={handleSubmit}
                      disabled={submitting || pristine}
                    />
                  </form>
                )}
              </Form>
            </div> */}
            {/* {initiativeComments && initiativeComments.map((comment, index) => {
              return(
                <section className="container-fluid p-4 border-gray-200" >
                  <h4 className="text-neutral mb-2">{comment.source.username}</h4>
                  <div className="flex items-center ">
                    <ButtonIcon buttonClassName=" btn-ghost" className="w-4 h-4 text-neutral" icon="chat_alt"/>
                    <p className="text-neutral ">{comment.body}</p>
                  </div>
                </section>
              )
            })} */}
            {assistant && this.state.transaction && (this.state.transaction.status === config.TRANSACTIONS.INITIATIVE.STATUS.ATTENDED) && (
                <section className="container-fluid p-4">
                  <h3>{this.t("Ya asististe a esta iniciativa. Gracias por participar.")}</h3>
                </section>
            )}
            {assistant && this.state.transaction && ((this.state.transaction.status === config.TRANSACTIONS.INITIATIVE.STATUS.POSTULATED) 
              || (this.state.transaction.status === config.TRANSACTIONS.INITIATIVE.STATUS.PENDING)) && (
              <section className="container-fluid p-4">
                {/* QR */}
                <div className="p-4 text-center border-b border-gray-200 mb-5">
                  <h3>{this.t("Presentá este código al participar")}</h3>
                  <QRCode 
                    className="p-4 mx-auto"
                    value={this.state.transaction.json_data?.code ? this.state.transaction.json_data?.code.toString() : this.state.transaction.id}
                    size={178}
                  />
                  <p className="uppercase text-gray-400">{this.t("Código")}</p>
                  <h4>{this.state.transaction.json_data?.code ? this.state.transaction.json_data?.code : this.state.transaction.id}</h4>
                  <Button
                    className="btn-error btn-sm mt-4"
                    title={this.t("Cancelar participación")}
                    onClick = {this.cancelAttendance}
                  />
                </div>
              </section>
            )}
          </>
        )}
      </LayoutSmall>
    ) 
  }
}


const mapStateToProps = state => {
  return {
    auth: state.users.auth, 
    volunteering: state.volunteering.current,
    assistant: state.assistants.current,
    assistants: state.assistants.list,
    transaction:state.transactions.current,
    initiatives:state.transactions.list,
    comments: state.messages.list,
    message: state.messages.current,
    internals: state.internals,
    user: state.users.current,
    business: state.businesses.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetVolunteering : (params) => dispatch(volunteeringAction.get(params)),
    onAttend: (params) => dispatch(assistantsAction.saveOrUpdate(params)),
    onCancelAttend: (params) => dispatch(assistantsAction.del(params)),
    onSaveTransaction: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
    onChangeTransactionState: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
    onGetMyInitiatives :  (params) => dispatch(transactionsAction.getAll(params)),
    onGetComments: (params) => dispatch(MessagesActions.getAll(params)),
    onSaveComment: (params) => dispatch(MessagesActions.saveOrUpdate(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
    onGetUser: (params) => dispatch(usersActions.get(params)),
    onGetBusiness: (params) => dispatch(businessesActions.get(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(VolunteeringDetail));



