const general = {
  DOMAIN_NAME: process.env.REACT_APP_DOMAIN_NAME || 'http://app.sharyco.com',
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL || 'https://apidemo.sharyco.com/api/v1',
  DEBUG_MODE: process.env.REACT_APP_DEBUG_MODE || false,
  ONESIGNAL_APPID: process.env.REACT_APP_ONESIGNAL_APPID || '',
  ONESIGNAL_SAFARI_WEB_ID: process.env.REACT_APP_ONESIGNAL_SAFARI_WEB_ID || 'web.onesignal.auto.',
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID || '.apps.googleusercontent.com',
  FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID || '',
  
  VERSION: 'v20210129.001',
  CLIENT_ID: '11111111111',
  CLIENT_SECRET: 'qwetqutuqwteutuqwteuqwtuetqwyteuqt',
};

export default general;