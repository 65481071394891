import React from 'react';
import { useTranslation } from 'react-i18next';
import IconPoints from './IconPoints';
import IconCoins from './IconCoins';
import { getObjectWithJsonDataToFormValues  } from '../../libraries/utils';
import { zonedTimeToUtc } from 'date-fns-tz';
import config from '../../config';
import logo from '../../assets/img/logo.png';
import { history } from '../../routes';

export default function ActivityBenefitCard({ statuses,types, sub_types, activity, ...rest }) {
  const { t, i18n } = useTranslation(); 
  const { id, status, type, sub_type, initiative, created_at, description, created_by, benefit,} = getObjectWithJsonDataToFormValues(
    activity,
    ['id', 'status','type','sub_type','initiative','created_at', 'description','created_by','benefit']
  );

  const {user, picture, business, name} = getObjectWithJsonDataToFormValues(benefit, ["picture", "business", "user", "name"])

  const dateAndTime = new Date();
  const activityDate = created_at;

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const utcDate1 = zonedTimeToUtc(dateAndTime, timeZone);
  const utcDate2 = zonedTimeToUtc(activityDate, timeZone);
  const timeRemains = t("timeRemains", { date: new Date(created_at) })
  
  const getSubTypeString = (subtype) => subtype === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR ? "favor" : "servicio"
  const serviceCompleted = (status, subtype) => (status === config.TRANSACTIONS.BORROW.STATUS.ON_OWNER) && (subtype === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR || subtype === config.TRANSACTIONS.BORROW.SUB_TYPE.SERVICE)
  
  const gotoBenefit = () => {
    history.push({ 
      pathname: activity && activity.sub_type === 'prize' ?
        config.ROUTES.PRIZES.DETAIL.replace(':id', benefit.id) :
        config.ROUTES.BENEFITS.DETAIL.replace(':id', benefit.id) ,
      state: { fromPath: config.ROUTES.USER.ACTIVITIES }
    })
  }
  return (
    <div className="flex p-4 border-b border-base-200 gap-4" onClick={gotoBenefit}>
      <div className="max-w-[4rem] w-full overflow-hidden flex justify-center items-start">
          <img alt="" src={picture}/> 
      </div>

      <div className='flex-1'>
        <div className="">
              <h5 className="">{t(`Pediste ${name}`)} </h5> 
        </div>
        <div className="flex  justify-between mt-2" >
        </div>
        <div className="flex gap-3 justify-between items-center mt-2">
          <h6 className=" text-gray-500">{timeRemains}</h6>
          <small className={"badge badge-sm badge-ghost "}>
            {(status === config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED) ? "Usado" : "Pendiente"}
          </small>          
        </div>
      </div> 
    </div>
  )
}