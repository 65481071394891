import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '../../libraries/icons';
import config from '../../config';
import notificationsActions from '../../context/notifications/actions';
import settingsActions from '../../context/settings/actions'
import { useDispatch, useSelector } from 'react-redux';
import AutoQuery from './AutoQuery';
import { capitalize, capitalizePhrase } from '../../libraries/utils';

export default function BottomNav ({ active, ...rest }) {
  const { t } = useTranslation();

  const location = useLocation();
  const [menu, setMenu] = useState([]);
  
  const isSelected = (route, location)  => {
    if((location.pathname.includes("favors") || location.pathname.includes("requests")) && route.includes("products")) return true;
    return location.pathname === route
  }
  
  const reduxDispatch = useDispatch()
  const onGetNotifications = async params => reduxDispatch(notificationsActions.getAll(params))
  const onGetSettings = async params => reduxDispatch(settingsActions.getAll(params))
  const notifications = useSelector(state => state.notifications.list)
  const user = useSelector(state => state.users.auth.user)
  const settings = useSelector(state => state.settings)
  const unReadedNotifications = notifications.items.filter(n => n.readed === 0).length
  

  const getNotifications = async () => {
    // TODO: revisar esto
    const params = {target: user.id};
    await onGetNotifications(params);

    if (notifications.error) {
      console.log(notifications.error.message);
    }
  };

  const getSettings = async () => {
    const params = {
      type: "sharyco_settings",
      owner: user.unique_id,
    };
    await onGetSettings(params);
    if (settings.error) {
      console.log(settings.error.message);
    }
  }

  const filterMenu = () => {
    if(!settings.list.items.length) return
    let menu = config.BUTTONBAR.BOTTOM;
    settings.list.items.forEach(item => {
      if(item.type === "sharyco_settings" && (!item.value || item.value === "false")){
        if(item.code === "show_actions")
          menu = menu.filter((section) => section.route !== config.ROUTES.HOME_OPTIONS.ACTIONS)
        if(item.code === "show_offers")
          menu = menu.filter((section) => section.route !== config.ROUTES.HOME_OPTIONS.PRODUCTS)
      }
    });
    setMenu(menu)
  }

  useEffect(() => {
    const getData = async () => {
      await getSettings()
    }
    if(settings.list.items.length){
      return
    }
    getData()
  }, [])

  useEffect(() => {
    filterMenu()
  }, [settings])

  //FIXME: pasar el autoquery de acá a sidebar
  return (
		<section id="bottom-navigation" className="block fixed left-0 right-0 w-full bottom-0 z-10 bg-base-content drop-shadow-[0px_-4px_10px_rgba(41,127,135,0.3)]">
			<AutoQuery getData={getNotifications}/>
      <div id="tabs" className="max-w-md mx-auto flex justify-between">
				{ menu.map((option, index) => (
          <Link
            key={`sb${index}`}
            className={"flex-auto focus:text-primary hover:text-primary justify-center flex items-center text-center py-3 relative "
              + (isSelected(option.route, location) ? "text-white " : "opacity-50")
            }
            to={option.route}
          >
            {/* {option.notifications && !!unReadedNotifications && 
              (<div className="absolute right-2 top-2 badge badge-sm bg-error text-white border-none">{unReadedNotifications}</div>)
            } */}

          <div className="indicator items-center">
            <Icon className={"h-6 w-6 mx-auto " + (isSelected(option.route, location) ? "text-secondary" : "text-white")} name={option.icon} /> 
            {isSelected(option.route, location) && <span className="block text-xs text-white px-2">{capitalizePhrase(t(option.label))}</span>}
          </div>
            {/*<span className="block text-xs">{t(option.label)}</span>*/}
          </Link>
        ))}
			</div>

		</section>
  )
}
