import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';

import DonationCard from '../../components/customs/DonationCard';
import BottomNav from '../../components/commons/BottomNav';
import LayoutSmall from '../../components/layout/LayoutSmall';
import donationsActions from "../../context/donations/actions";


class Donations extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;    
    this.state = {
      donations: [],
    }
  }
  
  componentDidMount() {
    console.log('INICIATIVAS - DONACIONES')
    this.getDonations();
  }

  getDonations = async () => {
    await this.props.onGetAllDonations();
    const { donations } = this.props;
    if (donations.error) {
      notify(this.t(donations.error.message));
    } else {
      this.setState({ donations: donations.items});
    }
  };


  goBack = () => {
    history.push({ pathname: config.ROUTES.HOME, state: { from: config.ROUTES.MAIN.INITIATIVES }});
  }
 
  render() {
    const { donations } = this.state;
    return (
      <LayoutSmall
        main={{ className: ""}}
        header={{ 
          title: "Donaciones",
          className: "bg-white text-primary flex justify-between p-3",
          left: { icon: 'arrow_left', action: this.goBack }
        }}
        container={{ className: "px-0"}}
      >    
        <section className="p-4 pb-16">
          {donations && (donations.length === 0) && <small className="mb-1 text-gray-500">{this.t("No hay donaciones disponibles")}</small>}
          {donations && donations.map((donation, index) => {
              return (
                <DonationCard
                  className="mb-3"
                  key={index}
                  data={donation}
                  route={{ pathname: config.ROUTES.DONATIONS.DETAIL.replace(':id', donation.id), state: { from: config.ROUTES.DONATIONS.LIST }}}
                />
              )
            })
          }
        </section>
        <BottomNav/>
      </LayoutSmall>
    ) 
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    donations:state.donations.list
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllDonations: (params) => dispatch(donationsActions.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Donations));

