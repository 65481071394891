import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import { Form, Field } from "react-final-form";
import { WhenFieldChangesDo } from '../../libraries/forms';
import { history } from '../../routes';
import config from '../../config';

import Loader from '../../components/commons/Loader';
import ServiceElement from '../../components/customs/ServiceElement';
import FavorElement from '../../components/customs/FavorElement';
import CategoryCard from '../../components/customs/CategoryCard';
import ProductCard from '../../components/customs/ProductCard';
import Button from '../../components/commons/Button';

import productsAction from '../../context/products/actions';
import servicesAction from '../../context/services/actions';
import favorsAction from '../../context/favors/actions';
import transactionsAction from '../../context/transactions/actions';
import companiesAction from '../../context/companies/actions';
import Icon from '../../libraries/icons';
import Swal from 'sweetalert2';
import SelectInput from '../../components/forms/SelectInput';

import { capitalize, toBool } from '../../libraries/utils';

import Instructive from '../../components/commons/Instructive';

import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import BottomNav from '../../components/commons/BottomNav';

class Offer extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      products : [],
      services: [],
      favors: [],
      requests: [],
      unique_id: this.props.auth.user.unique_id,
      viewAll: true,
      companiesIds: null,
    }
  }

  componentDidMount() {
    console.log('OFFER PAGE');
    let companiesIds = this.props.auth.user.ancestors.companies.map(c => c.id)
    this.setState({companiesIds, unique_id: companiesIds, viewAll: true}, this.getData)  
  }

  getData = async () => {
    this.getProducts();
    this.getServices();
    this.getFavors();
    this.getRequests();
  }

  getCompany = async () => {
    await this.props.onGetCompany(this.props.auth.user.unique_id);
    const { company } = this.props;
    if (company.error) {
      notify(this.t(company.error.message));
    } else {
      this.setState({ 
        company: company.item
      })
      await this.getCompanies(company.item.owner)
    }    
  }

  getCompanies = async (owner) => {
    await this.props.onGetCompanies({ owner });
    const { companies } = this.props;
    if (companies.error) {
      notify(this.t(companies.error.message));
    } else {
      this.setState({ 
        companies: companies.items,
        companiesIds: companies.items.map(c => c.id),
      })
    }    
  }

  getProducts = async ()=> {
    console.log("getProducts", this.state.unique_id)
    await this.props.onGetProducts({ status: config.STATUS.PRODUCTS.AVAILABLE, unique_id: this.state.unique_id,});
    const { products } = this.props;
    if (products.error) {
      notify(this.t(products.error.message));
    } else {
      this.setState({ 
        products: products.items.slice(0, 4)
                    //.filter(p => p.owner !== this.props.auth.user.id)    
      })
    }    
  }

  getServices = async ()=> {
    await this.props.onGetServices({ status: config.STATUS.SERVICES.AVAILABLE, unique_id: this.state.unique_id,});
    const { services } = this.props;
    if (services.error) {
      notify(this.t(services.error.message));
    } else {
      this.setState({ 
        services: services.items.slice(0, 4)
                    //.filter(s => s.owner !== this.props.auth.user.id)
      })
    }    
  }

  getFavors = async () => {
    await this.props.onGetFavors({ status: config.STATUS.FAVORS.AVAILABLE, unique_id: this.state.unique_id,});
    const { favors } = this.props;
    if (favors.error) {
      notify(this.t(favors.error.message));
    } else {
      this.setState({
        favors: favors.items.slice(0, 4)
                  //.filter(f => f.owner !== this.props.auth.user.id)
      })
    }    
  }

  
  getRequests = async () => {
    const params = {
      type: [config.TRANSACTIONS.REQUEST.TYPE],
      owner: this.state.unique_id,
    }
    await this.props.onGetAllRequests(params);
    const { requests } = this.props;
    if (requests.error) { 
      notify(this.t(requests.error.message));
    } else {
      this.setState({
        requests: requests.items.slice(0, 4) 
        .filter(item => item.created_by !== this.props.auth.user.id)
      })
    }    
  }

  info_points = async () => {
    Swal.fire({
      title:this.t("¿Cómo usar mis Puntos Shary / Impacto?"),
      text: this.t("Para pedir prestado un producto debo disponer de los Puntos Shary requeridos y a cambio obtendré los Puntos de Impacto asociados."),
      icon: 'info',
      showCancelButton: false,
      confirmButtonText: this.t("Ok"),
    })
  }

  setViewAll = (viewAll) => {
    let unique_id = this.props.auth.user.unique_id
    this.setState({viewAll, unique_id: viewAll ? this.state.companiesIds : unique_id}, this.getData)
  }

  // changeOrigin = (e) => {
  //   this.setViewAll(e.value);
  // }

  onSubmit = (value) => {
    console.log('VALUES', value);
    this.setViewAll(toBool(value));
  }

  render() {
    const { products, services, favors, requests, viewAll } = this.state;
    const options = [{ value: true, label: capitalize(this.t('all the accounts')) },{ value: false, label: capitalize(this.t('my office')) }];
    return (
      <LayoutWithSidebar
        main={{ className: "pb-[48px]"}}
        header={{ 
          logo: true,
          //content: <NavButtonBar options={buttonBarUpper} active={topNav} onClick={this.onNavBarClick} />
          tabs: [
            {
              name: "Productos",
              onClick: () => {},
              active: true
            },
            {
              name: "Favores",
              onClick: () => {}
            },
          ]
        }}
 
        container={{ className: "px-0"}}
      >
      <ToastContainer/>

      <div className="w-full max-w-lg mx-auto">
        <Instructive slides={[
          {
            title:"Economía Circular",
            body:"Pedí prestado lo que necesitás canjeando tus puntos Shary. ¡Y no te olvides de publicar lo que quieras ofrecer, asi ganás puntos cuando te lo pidan!",
            img: "howtolend"
          }]} instructive={"show_offers"}> 
        <div className="p-4 mb-2 flex flex-row items-start">
          <Icon className="w-8 mt-2 mr-2" name="information_outline" />
          <h4> Pedí prestado productos, servicios y favores</h4>
        </div>
        {/*<div className="px-4 grid place-content-center pt-2"> 
          <Button
            icon = "sharypoints"
            className="btn-primary btn-sm mt-2 mb-1"
            title={this.t("Acerca de mis puntos Shary / Impacto")}
            onClick={this.info_points}
          />
        </div>*/}
        <section className="p-3">
          <div className='flex justify-between pb-2 border-b'>
            <Form initialValues={{ viewAll }} onSubmit={this.onSubmit}>
              {({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  <Field name="viewAll" component={SelectInput} selectClassName="select-ghost select-sm w-40" options={options} noError={true} />
                  <WhenFieldChangesDo field="viewAll" action={({ field, value, values }) => { this.onSubmit(value); }} />
                </form>
              )}
            </Form>
          </div>   
        </section>

        {/* <SelectInput className="" selectClassName="bg-transparent p-1" input={{ name: 'select', onChange: this.changeOrigin }} options={options} noError={true} /> */}

        <section className="pb-10 p-3">
          <div className="flex justify-between items-center mb-2">
            <h4 className="mb-0"> {this.t("Productos que podés pedir prestado")} </h4>
            <Button 
              className="pl-4 btn btn-xs btn-ghost text-primary mt-1" 
              title={this.t("Ver todos")}
              onClick={() => history.push(config.ROUTES.PRODUCTS.LIST)}
            />
          </div>
          { products.loading && (<Loader/>) }
          {!products.loading && products.length === 0 && (
            <small className="mb-1 text-gray-500">{this.t("No hay productos disponibles")}</small>
          )}
          <div className="grid grid-cols-2 gap-3">
            { products && products.map((product, index) => {
                return (
                  <ProductCard 
                    key={'p' + index}
                    route={{
                      pathname: config.ROUTES.PRODUCTS.DETAIL.replace(':id', product.id),
                      state:{
                        fromPath: config.ROUTES.HOME
                      }
                    }}
                    product={product}
                  />
                )
              })
            }
          </div>
        </section>
        <section className="pb-10">
          <div className="flex justify-between items-center mb-2 px-4">
            <h4 className="mb-0"> {this.t("Servicios")} </h4>
            <Button 
              className="pl-4 btn btn-xs btn-ghost text-primary mt-1" 
              title={this.t("Ver todos")}
              onClick={() => history.push(config.ROUTES.SERVICES.LIST)}
            />
          </div>
          { this.props.services.loading && (<Loader/>) }
          {!this.props.services.loading && services.length === 0 && (
            <small className="pl-4 mb-1 text-gray-500">{this.t("No servicios disponibles")}</small>
          )}
          <div className="container-fluid bg-white shadow">
            { services && services.map((service, index) => {
                return (
                  <ServiceElement 
                    key={'s' + index}
                    route={config.ROUTES.SERVICES.DETAIL.replace(':id', service.id)}
                    service={service}
                  />
                )
              })
            }
          </div>          
        </section>
        <section className="p4 pb-10">
          <div className="flex justify-between items-center mb-2 px-4">
            <h4 className="mb-0"> {this.t("Favores")} </h4>
            <Button 
              className="pl-4 btn btn-xs btn-ghost text-primary mt-1" 
              title={this.t("Ver todos")}
              onClick={() => history.push(config.ROUTES.FAVORS.LIST)}
            />
          </div>
          { this.props.favors.loading && (<Loader/>) }
          {!this.props.favors.loading && favors.length === 0 && (
            <small className="pl-4 mb-1 text-gray-500">{this.t("No favores disponibles")}</small>
          )}
          <div className="container-fluid bg-white shadow">
            {favors && favors.map((favor, index) => {
                return (
                  <FavorElement 
                    key={'f' + index}
                    route={config.ROUTES.FAVORS.DETAIL.replace(':id', favor.id)}
                    favor={favor}
                  />
                )
              })
            }
          </div>        
        </section>
        <section className="p-3">
          <div className="flex justify-between items-center">
            <h4 className="mb-0"> {this.t("Prestá y Ganá Puntos")} </h4>
            <Button 
              className="pl-4 btn btn-xs btn-ghost text-primary mt-1" 
              title={this.t("Ver todos")}
              onClick={() => history.push(config.ROUTES.REQUESTS.LIST)}
            />
          </div>
          <p className="text-primary mb-2">{this.t("Lo que la comunidad busca y no encuentra")}</p>
          <div className="grid grid-cols-2 gap-4">
            { requests && requests.map((request, index) => {
                return (
                  <CategoryCard
                    key={'img' + index}
                    className="card white p-4 col-span-1"
                    request={request}
                    route={{ pathname: config.ROUTES.REQUESTS.PREVIEW.replace(":id", request.id) }}
                      //pathname: config.ROUTES.OFFERS.DETAILS.replace(':type', request.sub_type),
                      //state: {
                      //   type:request.sub_type,
                      //   category:request.json_data.category,
                      //   categories_selected:request.json_data.categories,
                      //   fromRequest: true,
                      //   requesterId: request.json_data.requester.id,
                      //   categoryFromRequest: request.json_data.category.name,
                      //   fromPath: config.ROUTES.HOME,
                      //}}} 
                  />
                )
              })
            }
          </div>      
        </section>
        </Instructive>
      </div>

      <BottomNav/>
      </LayoutWithSidebar>  
    ) 
  }
} 

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    products: state.products.list,
    services: state.services.list,
    favors: state.favors.list,
    requests: state.transactions.list,
    company: state.companies.current,
    companies: state.companies.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetProducts: (params) => dispatch(productsAction.getAll(params)),
    onGetServices: (params) => dispatch(servicesAction.getAll(params)),
    onGetCompanies: (params) => dispatch(companiesAction.getAll(params)),
    onGetCompany: (id) => dispatch(companiesAction.get(id)),
    onGetFavors: (params) => dispatch(favorsAction.getAll(params)),
    onGetAllRequests: (params) => dispatch(transactionsAction.getAll(params)),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Offer));

