import config from '../../config';
import { request } from '../../libraries/request'

const getAll = (params = {}, token) => {
  //console.log('SERVICES::MESSAGES::get', params, token);
  if (params && params.page_num && config.QUERIES_PAGE_SIZE) {
    params.page_size = config.QUERIES_PAGE_SIZE;
  }

  //params.type = "notification";

  if (!params.order_by) { 
    params.order_by = 'messages__created_at';
  }
  if (!params.order_direction) {
    params.order_direction = 'ASC';
  }

  return request({
    url: config.BASE_API_URL + config.API.MESSAGES,
    accessToken: token,
    data: params,
  })
  .then(response => {
    //console.log('RETURN FROM API', response);
    if (response.success) {
      response.data.query.page_size = config.QUERIES_PAGE_SIZE;
    }
    return response;
  })
}

export default {
  getAll,
};