import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'

import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { composeValidators, validateEmail, validateIsfilled } from '../../libraries/utils';

import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';


class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      password: false,
    }
  }
  
  componentDidMount() {
    console.log('FORGOT PASSWORD PAGE');
  }
  
  onSubmit = async values => {
    const data = { ...values };
    await this.props.onForgot(data);
    console.log('AFTER FORGOT');
    const error = this.props.auth.error;
    if (error) { 
      notify(this.t(error.message));
    } else {
      history.push(config.ROUTES.LOGIN);        
    }
  }


  render() {

    const required = value => (validateIsfilled(value) ? undefined : this.t("This field is required"))
    const email = value => (validateEmail(value) ? undefined : this.t("Not a valid email"))

    return (
      <LayoutSmall
        main={{ className: "bg-primary"}}      
        header={{
          logo: true,
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.LOGIN) }
        }}
      >
        <ToastContainer/>
        <h2 className="text-primary-content text-center mb-20 mt-10">{this.t("Reset password")}</h2>
        <Form onSubmit={this.onSubmit}>
          {({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto px-4">
              <div className="w-full mb-3 md:mb-0">
                <Field name="email" inputClassName="text-black" component={TextInput} placeholder={this.t("Email")} validate={composeValidators(required, email)} parse={ v => v.trim() }/>
              </div>
              <div className="flex flex-wrap mb-3">
                <Button
                  className="btn-block btn-secondary"
                  title={this.t("Send")}
                  onClick={handleSubmit}
                />
              </div>
            </form>
          )}
        </Form>
      </LayoutSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onForgot: (params) => dispatch(userActions.forgot(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Forgot));

