import React from 'react';
import { useTranslation } from 'react-i18next';
import IconPoints from './IconPoints';
import IconCoins from './IconCoins';
import { getObjectWithJsonDataToFormValues  } from '../../libraries/utils';
import { zonedTimeToUtc } from 'date-fns-tz';
import config from '../../config';
import logo from '../../assets/img/logo.png';
import { history } from '../../routes';

export default function BussinessHomeCard({business, ...rest}){
  const gotoBenefitsBussinesList = () => {
    history.push({ pathname: config.ROUTES.BENEFITS.LIST, state: { from: config.ROUTES.HOME, businessId: business.id }})
  }
  return (
     <div className="card text-center mr-2" onClick={gotoBenefitsBussinesList} key={rest.key}>
      <div className="card bg-gray-100 h-20 mb-2 p-4">
        <img src={business.json_data?.image} alt="" width="70" />
      </div>
    </div>
    )
}
